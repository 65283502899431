import { mapGetters } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapGetters(["getLocale"]),
  },
  methods: {
    isValidDate(date) {
      return (
        date != undefined &&
        date != "undefined" &&
        date.toString() != "NaN" &&
        date.toString() != "invalide" &&
        ((isNaN(parseInt(date.toString())) &&
          moment(date.toString()).isValid()) ||
          (!isNaN(parseInt(date.toString())) &&
            moment
              .unix(parseInt(date.toString().substring(0, 10)))
              .isValid()) ||
          moment(date, "YYYY-MM-DD").isValid())
      );
    },
    checkIfDatesAreDifferent(date1, date2) {
      if (!date1 || !date2) {
        return true;
      }
      return (
        this.formatDate(date1, false, "date") !==
        this.formatDate(date2, false, "date")
      );
    },
    convertDate(date, initialDate, expectedFormat) {
      return this.formatDate(
        moment(date, initialDate).unix(),
        false,
        expectedFormat
      );
    },
    formatDate(date, returnAge = false, expectedFormat = false) {
      if (!date) {
        return this.$t("undefined");
      }
      let isValidDate = this.isValidDate(date);
      if (!isValidDate) {
        return this.$t("undefined");
      }
      let formattedDate = "";
      if (
        isNaN(parseInt(date.toString())) &&
        moment(date.toString()).isValid()
      ) {
        formattedDate = moment(date, "YYYY-MM-DD").isValid()
          ? moment(date.toString(), "YYYY-MM-DD")
          : moment(date.toString());
      } else if (
        !isNaN(parseInt(date.toString())) &&
        moment.unix(parseInt(date.toString().substring(0, 10))).isValid()
      ) {
        if (date.toString().indexOf("-") > -1) {
          formattedDate = moment(date.toString(), "YYYY-MM-DD");
        } else {
          formattedDate = moment.unix(
            parseInt(date.toString().substring(0, 10))
          );
        }
      }
      formattedDate = formattedDate.locale(this.getLocale);
      if (returnAge) {
        return this.getLocale == "fr"
          ? formattedDate.fromNow(true)
          : formattedDate.fromNow(true) + " old";
      }

      if (expectedFormat === "unix") {
        return moment(formattedDate).unix();
      }
      const momentFormatMap = {
        "year": "YYYY",
        "fullDate": "DD MMMM YYYY, HH:mm",
        "minimal": "DD/MM",
        "DD/MM/YYYY - HH:mm": "DD/MM/YYYY - HH:mm",
      };
      if (momentFormatMap[expectedFormat]) {
        return formattedDate.format(momentFormatMap[expectedFormat]);
      }
      const languageDependantMap = {
        date: {
          fr: "DD MMMM YYYY",
          en: "MMMM D, YYYY",
        },
        hours: {
          fr: "HH:mm",
          en: "hh:mm A",
        },
        easy: {
          fr: "DD/MM/YYYY",
          en: "MM/DD/YYYY",
        },
      };
      if (languageDependantMap[expectedFormat]) {
        return formattedDate.format(
          languageDependantMap[expectedFormat][this.getLocale]
        );
      }
      if (expectedFormat) {
        if (Array.isArray(expectedFormat)) {
          if (this.getLocale === "en") {
            return formattedDate.format(expectedFormat[0]);
          }
          return formattedDate.format(expectedFormat[1]);
        }
        return formattedDate.format(expectedFormat);
      }

      return this.$t("undefined");
    },
  },
};
