<template>
  <ActionBase
    @click="handleClick()"
    :label="$t('validate')"
    :primary="true"
    :disabled="false"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ActionBase from "../../../Popin/Actions/ActionBase.vue";
import db from "../../../../db";
export default {
  components: { ActionBase },
  computed: {
    ...mapGetters("popin", ["popinPayload"]),
  },
  methods: {
    ...mapMutations("popin", ["closePopin"]),
    ...mapActions("popin", ["openToast"]),
    async handleClick() {
      await db
        .collection("V3shadowsMercato")
        .doc(this.popinPayload.id.toString())
        .delete();
      this.openToast({
        translationKey: "squad.toasts.deletedSquad",
        payload: { label: this.popinPayload.label },
        delay: 5000,
      });
      this.closePopin();
    },
  },
};
</script>

<style lang="scss" scoped></style>
