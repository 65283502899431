<template>
  <div class="players-container" v-if="players?.length">
    <div class="players-header-row">
      <div class="list-type-container">
        <img :src="getTypeSrc" />
        <span class="list-type"> {{ $t(typeTranslationKey) }} </span>
      </div>
      <span class="balance-summary"> {{ summary }} </span>
    </div>
    <div class="players-list">
      <BalancePlayer
        v-for="(player, index) of players"
        :name="player.name"
        :price="getPrice(player)"
        :salary="getSalary(player)"
        :oldSalary="getOldSalary(player)"
        :position="getPlayerPosition(player)"
        :displayDifference="type === 'updatedPayroll'"
        :key="'player_' + index"
      />
    </div>
  </div>
</template>

<script>
import mixinPlayers from "../../mixins/mixinPlayers";
import BalancePlayer from "./BalancePlayer.vue";

export default {
  mixins: [mixinPlayers],
  props: {
    summary: { type: String },
    players: { type: Array },
    type: {
      type: String,
      validator: (value) =>
        [
          "incomingTransfers",
          "outgoingTransfers",
          "incomingPayroll",
          "outgoingPayroll",
          "updatedPayroll",
        ].includes(value),
    },
  },
  components: {
    BalancePlayer,
  },
  computed: {
    typeTranslationKey() {
      return {
        incomingTransfers: "incomingTransfers",
        outgoingTransfers: "outgoingTransfers",
        incomingPayroll: "payrollIn",
        outgoingPayroll: "payrollOut",
        updatedPayroll: "payrollUpdated",
      }[this.type];
    },
    isTransfer() {
      return ["incomingTransfers", "outgoingTransfers"].includes(this.type);
    },
    isPayroll() {
      return ["incomingPayroll", "updatedPayroll", "outgoingPayroll"].includes(
        this.type
      );
    },
    isIncoming() {
      return ["incomingPayroll", "incomingTransfers"].includes(this.type);
    },
    isOutgoing() {
      return ["outgoingPayroll", "outgoingTransfers"].includes(this.type);
    },
    getTypeSrc() {
      if (this.isIncoming) {
        return require("../../assets/img/pictos/enter.svg");
      }
      if (this.isOutgoing) {
        return require("../../assets/img/pictos/exit.svg");
      }
      return require("../../assets/img/pictos/signDocument.svg");
    },
  },
  methods: {
    getPrice(player) {
      return this.isTransfer ? player.price : null;
    },
    getSalary(player) {
      if (!this.isPayroll) {
        return;
      }
      if (this.isOutgoing) {
        return player.salary;
      }
      return player.newSalary ?? player.salary;
    },
    getOldSalary(player) {
      if (!this.isPayroll || !player.newSalary) {
        return;
      }
      return player.salary;
    },
    getPlayerPosition(player) {
      if (player.movingPosition) {
        return this.$t(
          this.getPositionTranslationKeyFromAcronym(player.movingPosition)
        );
      }
      return player.primaryPosition;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";
div.players-container {
  width: 100%;
  border-radius: 8px;
  padding: 24px;
  background-color: #fafafa;
  color: $blueDarkest;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

div.players-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: $blueDarkest;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  > div.list-type-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-grow: 1;
    text-transform: uppercase;
    gap: 8px;
  }
  > span.balance-summary {
    align-self: flex-end;
  }
}

.players-list {
  gap: 9px;

  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
@media screen and (max-width: 1200px) {
  div.players-list {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>
