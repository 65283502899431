<template>
  <div class="popinTabsContainer">
    <div
      v-for="(popinTab, popinTabIndex) in popinTabs"
      :key="popinTabIndex"
      :class="{
        popinTabContainer: true,
        isActiveTab: selectedIndex === popinTabIndex,
      }"
    >
      <div
        class="popinTabContent"
        @click="switchTab(popinTabIndex, popinTab.target)"
      >
        {{ $t(`${popinTab.label}`) }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { popinVariants } from "../../enums/popinVariants";
export default {
  props: {
    popinTabs: {
      type: Array,
      default() {
        return Array();
      },
      required: true,
    },
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  methods: {
    ...mapMutations("popin", ["openPopinVariant", "closePopin"]),
    switchTab(tabIndex, tabTarget) {
      this.selectedIndex = tabIndex;
      if (!Object.values(popinVariants).includes(tabTarget)) {
        return;
      }

      this.openPopinVariant({
        popinVariant: tabTarget,
      });
    },
    showPopin() {
      const storePayload = {
        popinVariant: this.popinVariant,
        popinPayload: this.player,
      };
      this.openPopinVariant(storePayload);
    },
    hidePopin() {
      this.closePopin();
    },
  },
  computed: {},
  watch: {},
};
</script>
<style>
.popinTabsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  justify-content: center;
}

.popinTabContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #060052;
  color: #060052;
  cursor: pointer;
  margin-bottom: 20px;
  min-height: 50px;
  max-width: 200px;
}

.popinTabContainer.isActiveTab {
  border-bottom: 4px solid #b34442;
  color: #b34442;
  font-weight: 700;
}
</style>
