<template>
  <div class="shadowPitchComponentContainer">
    <span v-if="compoId" class="cornerContainer">
      {{ getCompoById(compoId, "displayName") }}
    </span>

    <div class="designPitchView">
      <div class="designPitchViewAwaySide">
        <div class="designPitchViewTopLeftCorner designPitchViewCorner"></div>
        <div class="designPitchViewAwaySideGoal designPitchViewGoals"></div>
        <div class="designPitchViewSurface designPitchViewAwaySurface"></div>
        <div class="designPitchViewTopRightCorner designPitchViewCorner"></div>
        <div class="designPitchViewCentralCircle"></div>
      </div>
      <div class="designPitchViewHomeSide">
        <div
          class="designPitchViewBottomLeftCorner designPitchViewCorner"
        ></div>
        <div
          class="designPitchViewBottomRightCorner designPitchViewCorner"
        ></div>
        <div class="designPitchViewHomeSideGoal designPitchViewGoals"></div>
        <div class="designPitchViewSurface designPitchViewHomeSurface"></div>
      </div>
    </div>

    <div class="squadManagementPitchContent">
      <div class="pitch mt-3 text-center">
        <div class="row">
          <div class="col"></div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('LF')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('LCF')"
                @click="$emit('addPlayer', 'LCF')"
                >LCF</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'LCF'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>
                <div
                  v-if="!getCurrentPositionPlayers('LCF').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'LCF')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('CF')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('CF')"
                @click="$emit('addPlayer', 'CF')"
                >CF</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'CF'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('CF').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'CF')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('RF')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('RCF')"
                @click="$emit('addPlayer', 'RCF')"
                >RCF</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'RCF'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('RCF').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'RCF')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col"></div>
        </div>
        <div class="row">
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('LW')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('LW')"
                @click="$emit('addPlayer', 'LW')"
                >LW</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'LW'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('LW').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'LW')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('LAM')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('LAM')"
                @click="$emit('addPlayer', 'LAM')"
                >LAM</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'LAM'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('LAM').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'LAM')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('CAM')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('CAM')"
                @click="$emit('addPlayer', 'CAM')"
                >CAM</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'CAM'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('CAM').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'CAM')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('RAM')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('RAM')"
                @click="$emit('addPlayer', 'RAM')"
                >RAM</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'RAM'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('RAM').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'RAM')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('RW')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('RW')"
                @click="$emit('addPlayer', 'RW')"
                >RW</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'RW'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('RW').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'RW')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('LM')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('LM')"
                @click="$emit('addPlayer', 'LM')"
                >LM</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'LM'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('LM').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'LM')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('LCM')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('LCM')"
                @click="$emit('addPlayer', 'LCM')"
                >LCM</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'LCM'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('LCM').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'LCM')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('CM')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('CM')"
                @click="$emit('addPlayer', 'CM')"
                >CM</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'CM'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('CM').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'CM')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('RCM')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('RCM')"
                @click="$emit('addPlayer', 'RCM')"
                >RCM</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'RCM'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('RCM').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'RCM')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('RM')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('RM')"
                @click="$emit('addPlayer', 'RM')"
                >RM</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'RM'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('RM').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'RM')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('LWB')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('LWB')"
                @click="$emit('addPlayer', 'LWB')"
                >LWB</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'LWB'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('LWB').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'LWB')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('LDM')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('LDM')"
                @click="$emit('addPlayer', 'LDM')"
                >LDM</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'LDM'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('LDM').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'LDM')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('CDM')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('CDM')"
                @click="$emit('addPlayer', 'CDM')"
              >
                CDM
              </span>
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'CDM'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('CDM').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'CDM')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('RDM')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('RDM')"
                @click="$emit('addPlayer', 'RDM')"
                >RDM</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'RDM'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('RDM').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'RDM')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('RWB')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('RWB')"
                @click="$emit('addPlayer', 'RWB')"
                >RWB</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'RWB'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('RWB').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'RWB')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('LB')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('LB')"
                @click="$emit('addPlayer', 'LB')"
                >LB</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'LB'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('LB').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'LB')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('LCB')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('LCB')"
                @click="$emit('addPlayer', 'LCB')"
                >LCB</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'LCB'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('LCB').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'LCB')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('CB')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('CB')"
                @click="$emit('addPlayer', 'CB')"
                >CB</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'CB'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('CB').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'CB')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('RCB')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('RCB')"
                @click="$emit('addPlayer', 'RCB')"
                >RCB</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'RCB'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('RCB').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'RCB')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('RB')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('RB')"
                @click="$emit('addPlayer', 'RB')"
                >RB</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'RB'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('RB').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'RB')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col"></div>
          <div class="col"></div>
          <div class="col">
            <div
              v-if="checkDisplayAccordingCompo('G')"
              class="pitchPositionContainer"
            >
              <span
                class="pitchPosition"
                v-b-popover.hover.top="getInstructionForPosition('G')"
                @click="$emit('addPlayer', 'G')"
                >G</span
              >
              <div class="currentPitchPlayersContainer">
                <div
                  v-for="(player, playerIndex) in getCurrentPositionPlayers(
                    'G'
                  )"
                  :key="playerIndex"
                  :class="{
                    currentPitchPlayer: true,
                    isSelected: isSelectedPlayer(player.addedBy),
                  }"
                >
                  <div>
                    <div>
                      <b>{{ player.name }}</b>
                    </div>
                    <div>
                      {{ formatDate(player.dateOfBirth, false, "year") }}
                      - {{ player.currentTeam }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        v-if="player.isInBucket"
                        class="isBucketPlayerContainer"
                      ></span>
                    </div>
                    <div>{{ displayPlayerPrice(player.price) }}</div>
                  </div>
                </div>

                <div
                  v-if="!getCurrentPositionPlayers('G').length"
                  class="addPlayerContainer"
                  @click="$emit('addPlayer', 'G')"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="col"></div>
          <div class="col"></div>
        </div>
      </div>
    </div>

    <span class="cornerContainer">
      {{ currentShadowPlayers.length || 0 }}
      {{ $tc("plural.player", currentShadowPlayers.length || 1) }}
    </span>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import mixinToText from "../mixins/mixinText";
import mixinDates from "../mixins/mixinDates";
import mixinCompos from "../mixins/mixinCompos";

export default {
  name: "ShadowPitchComponent",
  mixins: [mixinToText, mixinDates, mixinCompos],
  props: {
    compoId: { type: String },
    currentShadowPlayers: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectedScoutId: { type: String },
  },
  data() {
    return {
      matchingCompo: {},
      reduceCompo: {},
    };
  },
  emits: ["addPlayer"],
  mounted() {},
  computed: {
    ...mapGetters(["getCompos", "getGamesPosition"]),
  },
  methods: {
    isSelectedPlayer(contributorId) {
      if (!this.selectedScoutId) {
        return true;
      } else {
        return contributorId && contributorId === this.selectedScoutId;
      }
    },
    displayPlayerPrice(price) {
      if (!price || price === "undefined" || price === "unknown") {
        return "";
      }
      return price + "M €";
    },
    getInstructionForPosition(positionInitiales) {
      const matchingPosition = this.getGamesPosition.filter((position) => {
        return position.abv === positionInitiales;
      })[0];
      if (matchingPosition) {
        let labelToReturn =
          this.$t("edit") +
          " " +
          this.$t(`position.${matchingPosition.label.replaceAll(" ", "")}`);
        labelToReturn += " (";
        matchingPosition.label.split(" ").forEach((word) => {
          labelToReturn += word.charAt(0).toUpperCase();
        });
        labelToReturn += ")";
        return labelToReturn;
      }
    },
    checkDisplayAccordingCompo(currentPosition) {
      const self = this;
      if (!currentPosition) {
        return false;
      }
      const matchingCompo = self.getCompos.filter((compo) => {
        return compo.id === self.compoId;
      });
      if (matchingCompo.length) {
        return matchingCompo[0].players.includes(currentPosition);
      }

      return false;
    },
    getCurrentPositionPlayers(positionInitiales) {
      const toReturn = this.currentShadowPlayers.filter((player) => {
        return player.position === positionInitiales;
      });
      return this.lodash.uniqBy(toReturn, "id");
    },
  },
  watch: {
    compoId() {
      const self = this;
      self.matchingCompo =
        self.getCompos.filter((compo) => {
          return compo.id === self.compoId;
        })[0] || null;
    },
  },
};
</script>
<style scoped>
.designPitchView {
  position: absolute;
  border: 2px solid rgb(216, 216, 216);
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.designPitchViewAwaySide {
  flex: 1;
  border-bottom: 2px solid rgb(216, 216, 216);
}

.designPitchViewTopLeftCorner {
  left: -15px;
  top: -15px;
}

.designPitchViewTopRightCorner {
  right: -15px;
  top: -15px;
}

.designPitchViewBottomLeftCorner {
  left: -15px;
  bottom: -15px;
}

.designPitchViewBottomRightCorner {
  right: -15px;
  bottom: -15px;
}

.designPitchViewCorner {
  width: 30px;
  height: 30px;
  position: absolute;
  border: 2px solid rgb(216, 216, 216);
  margin: 0 auto;
  border-radius: 50px;
}

.designPitchViewGoals,
.designPitchViewSurface {
  position: absolute;
  left: 0;
  right: 0;
  border: 2px solid rgb(216, 216, 216);
  margin: 0 auto;
}

.designPitchViewGoals {
  width: 35%;
  height: 5%;
}

.designPitchViewSurface {
  width: 60%;
  height: 10%;
}

.designPitchViewHomeSideGoal {
  bottom: -2px;
}

.designPitchViewAwaySideGoal {
  top: -2px;
}

.designPitchViewAwaySurface {
  top: -2px;
}

.designPitchViewHomeSurface {
  bottom: -2px;
}

.designPitchViewHomeSide {
  flex: 1;
}

.designPitchViewCentralCircle {
  border: 2px solid rgb(216, 216, 216);
  width: 210px;
  height: 210px;
  border-radius: 500px;
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shadowPitchComponentContainer {
  margin-top: 30px;
  position: relative;
  border: 1px solid #eef2f5;
}
.cornerContainer {
  position: absolute;
  top: 0;
  padding: 5px 15px;
  background: #e3e0ff;
  color: #060052;
  font-weight: bold;
  max-width: fit-content;
  text-align: center;
  z-index: 10;
}

.cornerContainer:first-of-type {
  left: 0px;
}

.cornerContainer:last-of-type {
  right: 0px;
}

.squadManagementPitchContent {
  padding: 30px 0;
  background: white;
}

.pitchPositionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pitchPosition {
  border: 1px solid #060052;
  background: #060052;
  color: white;
  padding: 20px;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  margin: 30px 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.pitchPosition:hover:after {
  position: absolute;
  background: #b34442;
  content: url("../assets/img/whitePencil.svg");
  z-index: 1000;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  cursor: pointer;
}

.currentPitchPlayersContainer {
  width: 100%;
  padding: 5px;
  color: #060052;
}

.squadManagementPitchContent .currentPitchPlayer {
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  font-size: 0.6rem;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 10px;
  margin-bottom: 3px;
  opacity: 0.4;
}

.squadManagementPitchContent .currentPitchPlayer.isSelected {
  opacity: 1;
}

.currentPitchPlayersContainer .currentPitchPlayer:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.currentPitchPlayersContainer .currentPitchPlayer:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.currentPitchPlayer > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 160px;
  text-overflow: ellipsis;
}

.currentPitchPlayer > div > div {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 160px;
  text-overflow: ellipsis;
}

.currentPitchPlayer > div:first-of-type {
  flex: 5;
  text-align: left;
}

.currentPitchPlayer > div:last-of-type {
  flex: 2;
  justify-content: flex-end;
  text-align: right;
}

.addPlayerContainer {
  border: 2px dashed #060052;
  color: #060052;
  padding: 5px 0;
  width: 100%;
  background: white;
}

.addPlayerContainer:hover {
  color: #b34442;
  border: 2px dashed #b34442;
  background-color: #fef6f6;
  cursor: pointer;
}

.isBucketPlayerContainer {
  content: url("../assets/img/goldenStar.svg");
  width: 20px;
  height: 20px;
  display: block;
}
</style>
