<template>
  <ActionBase
    @click="handleClick()"
    :disabled="popinHasErrors"
    :label="$t('save')"
    :primary="false"
  />
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ActionBase from "../../../Popin/Actions/ActionBase.vue";
import db from "../../../../db";
export default {
  components: { ActionBase },
  computed: {
    ...mapGetters("popin", [
      "getPopinShallowPayload",
      "popinPayload",
      "additionalContext",
      "popinHasErrors",
    ]),
  },
  methods: {
    ...mapMutations("popin", ["closePopin"]),
    async handleClick() {
      const playerToUpdateIndex = this.additionalContext.playersOut.findIndex(
        (player) => player.id === this.getPopinShallowPayload.id
      );
      if (playerToUpdateIndex === -1) {
        throw new Error(
          `Player ${
            this.getPopinShallowPayload.id
          } not found in current squad ids (${this.additionalContext.playersOut.map(
            (p) => p.id
          )})`
        );
      }
      Object.assign(
        this.additionalContext.playersOut[playerToUpdateIndex],
        this.getPopinShallowPayload
      );
      await db
        .collection("V3shadowsMercato")
        .doc(this.additionalContext.id)
        .set(
          { playersOut: this.additionalContext.playersOut },
          { merge: true }
        );
      Object.assign(this.popinPayload, this.getPopinShallowPayload);
      this.closePopin();
    },
  },
};
</script>

<style lang="scss" scoped></style>
