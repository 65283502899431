<template>
  <ActionBase
    @click="handleClick()"
    :disabled="false"
    :label="$t('validate')"
    :primary="true"
  />
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ActionBase from "../../../Popin/Actions/ActionBase.vue";
import db from "../../../../db";
import mixinPlayers from "../../../../mixins/mixinPlayers";
export default {
  mixins: [mixinPlayers],
  components: { ActionBase },
  computed: {
    ...mapGetters("popin", [
      "getPopinShallowPayload",
      "popinPayload",
      "additionalContext",
    ]),
  },
  methods: {
    ...mapMutations("popin", ["closePopin"]),

    async handleClick() {
      for (const [index, player] of Object.entries(
        this.getPopinShallowPayload.players
      )) {
        const updatePayload = { positionIndex: parseInt(index) };

        const isInPlayers = this.updatePlayerFieldInList(
          this.additionalContext.players,
          player.id,
          updatePayload
        );
        const isInPlayersIn =
          !isInPlayers &&
          this.updatePlayerFieldInList(
            this.additionalContext.playersIn,
            player.id,
            updatePayload
          );

        if (!isInPlayers && !isInPlayersIn) {
          this.additionalContext.players.push({ ...player, ...updatePayload });
        }
      }
      Object.assign(this.popinPayload, this.getPopinShallowPayload);
      const firebasePayload = {
        players: this.additionalContext.players,
        playersIn: this.additionalContext.playersIn,
      };
      for (const key of Object.keys(firebasePayload)) {
        if (!firebasePayload[key]) {
          delete firebasePayload[key];
        }
      }
      await db
        .collection("V3shadowsMercato")
        .doc(this.additionalContext.id)
        .set(firebasePayload, { merge: true });
      this.closePopin();
    },
  },
};
</script>

<style lang="scss" scoped></style>
