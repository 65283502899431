var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listGamesComponentContainer"},[_c('div',{staticClass:"listGamesComponentContent"},[_c('div',{staticClass:"listGamesListingContainer"},[(_vm.games.length)?_c('div',{staticClass:"listGamesListingContent"},[_c('div',{staticClass:"listGamesListingLink"},[_c('div',{staticClass:"listGamesListingItem"},[_c('div',{staticClass:"medium"},[_vm._v("Date")]),_c('div',{staticClass:"medium d-none d-md-block"},[_vm._v("Competition")]),_c('div',{staticClass:"large"},[_vm._v(_vm._s(_vm.$t("game")))]),_c('div',{staticClass:"tiny"},[_vm._v(_vm._s(_vm.$t("players")))]),(_vm.getCurrentScout.isManager)?_c('div',{staticClass:"customSmall"},[_vm._v(" Scout ")]):_vm._e(),_c('div',{staticClass:"tiny d-none d-md-block"},[_vm._v("Type")]),_c('div',{staticClass:"tiny"},[_vm._v(" "+_vm._s(_vm.$t("status"))+" ")])])]),_vm._l((_vm.games),function(game,gameIndex){return _c('router-link',{key:gameIndex,staticClass:"listGamesListingLink",attrs:{"to":`/game/${game.id}`},on:{"click":function($event){return _vm.markCurrentGameAsSeen(game)}}},[_c('div',{class:{
              listGamesListingItem: true,
              passed: _vm.isPassed(game),
              highlighted: _vm.isHighlited(game),
            }},[_c('div',{staticClass:"medium"},[_vm._v(" "+_vm._s(_vm.formatDate(game.date, false, "DD/MM/YYYY - HH:mm"))+" ")]),_c('div',{staticClass:"medium d-none d-md-block"},[_vm._v(" "+_vm._s(game.competitionLabel ? game.competitionLabel : "N/A")+" ")]),_c('div',{staticClass:"large"},[_vm._v(_vm._s(game.teamHome)+" - "+_vm._s(game.teamAway))]),_c('div',{staticClass:"tiny text-center"},[_vm._v(" "+_vm._s(_vm.getCurrentUserPlayersToDisplay(game))+" ")]),(game.addedBy && _vm.getCurrentScout.isManager)?_c('div',{staticClass:"customSmall"},[_vm._v(" "+_vm._s(_vm.identifyScoutById(game.addedBy, "lastname"))+" ")]):(
                !game.addedBy &&
                game.scoutAssigned &&
                _vm.getCurrentScout.isManager
              )?_c('div',{staticClass:"customSmall"},[_vm._v(" "+_vm._s(_vm.identifyScoutById(game.scoutAssigned, "lastname"))+" ")]):_vm._e(),_c('div',{staticClass:"tiny d-none d-md-block"},[_vm._v(" "+_vm._s(_vm.$t(game.location))+" ")]),(
                _vm.getCurrentScout.isManager &&
                (_vm.getCurrentUserPlayersToDisplay(game) !== 0 ||
                  game.note?.length) &&
                !_vm.isMarkedAsSeen(game) &&
                game.date > _vm.readLimit
              )?_c('div',{staticClass:"tiny"},[_vm._v(" "+_vm._s(_vm.$t("reportStatus.validatedAndToRead"))+" ")]):(
                _vm.getCurrentScout.isManager &&
                (((_vm.getCurrentUserPlayersToDisplay(game) !== 0 ||
                  game.note?.length) &&
                  _vm.isMarkedAsSeen(game)) ||
                  game.date <= _vm.readLimit)
              )?_c('div',{staticClass:"tiny"},[_vm._v(" "+_vm._s(_vm.$t("reportStatus.validatedAndRead"))+" ")]):(
                game.date > _vm.readLimit &&
                !_vm.getCurrentScout.isManager &&
                (game.note || _vm.getCurrentUserPlayersToDisplay(game))
              )?_c('div',{staticClass:"tiny"},[_vm._v(" "+_vm._s(_vm.$t("reportStatus.validated"))+" ")]):(_vm.isToObserve(game))?_c('div',{staticClass:"tiny"},[_vm._v(" "+_vm._s(_vm.$t("reportStatus.toObserve"))+" ")]):(game.date <= _vm.readLimit)?_c('div',{staticClass:"tiny"},[_vm._v(" "+_vm._s(_vm.$t("reportStatus.validated"))+" ")]):_vm._e()])])})],2):_vm._e()]),(!_vm.games.length)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("no.resultForFilteredGames"))+" "+_vm._s(_vm.$t("retryWithOtherFilters"))+" "+_vm._s(_vm.$t("or"))+" "),_c('span',{on:{"click":function($event){return _vm.resetFilters()}}},[_vm._v(_vm._s(_vm.$t("resetFilters")))])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }