var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.popinContent.display)?_c('ShadowPopin',{attrs:{"display":_vm.popinContent.display,"scenario":_vm.popinContent.scenario,"content":_vm.popinContent.content},on:{"closePopin":_vm.closePopin,"updatePlayersList":_vm.updatePlayersList,"triggerPopin":_vm.displayAnotherPopin,"addPlayersToShadow":_vm.addPlayersToShadow,"toggleNotification":_vm.toggleNotificationByEmit,"setContributors":_vm.setContributors}}):_vm._e(),_c('section',{staticClass:"shadowsHeader"},[_c('b-row',[_c('b-col',[_c('h1',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t("nav.shadowTeam"))+",")])])],1)],1),_c('div',{attrs:{"id":"shadowPageContainer"}},[_c('section',{staticClass:"shadowTitleContainer"},[_c('b-row',[_c('b-col',[_c('b-row',{staticClass:"shadowHeader"},[_c('b-col',[_c('b-row',[(_vm.selectedTeamId?.length)?_c('b-col',[_c('p',{staticClass:"selectedShadowNameContainer"},[_vm._v(" "+_vm._s(_vm.newShadowTeamName)+" ")]),_c('span',{staticClass:"contributorsInteractionContainer",on:{"click":function($event){return _vm.triggerPopin('updateShadowName')}}},[_c('img',{attrs:{"src":require("../assets/img/pen.svg"),"alt":"editer la shadow"}})]),(
                      _vm.getCurrentScout.id === _vm.selectedShadow.addedBy || true
                    )?_c('span',{staticClass:"contributorsInteractionContainer",on:{"click":function($event){return _vm.triggerPopin('deleteShadow')}}},[_c('img',{attrs:{"src":require("../assets/img/trash.svg"),"alt":"supprimer la shadow"}})]):_vm._e()]):_vm._e()],1)],1)],1)],1)],1),_c('b-row',[(_vm.newShadowTeamName?.length)?_c('b-col',[_vm._v(" "+_vm._s(_vm.$t("shadowCreatedAtBy", { date: _vm.formatDate(_vm.selectedShadow.date, false, [ "MM/DD/YYYY", "DD/MM/YYYY", ]), scout: _vm.identifyScoutById(_vm.selectedShadow.addedBy, "shortenName"), }))+" "+_vm._s(_vm.$t("lastUpdatedAt", { day: _vm.formatDate(_vm.selectedShadow.updatedAt, false, [ "MM/DD/YYYY", "DD/MM/YYYY", ]), hour: _vm.formatDate(_vm.selectedShadow.updatedAt, false, "HH:mm"), }))+" ")]):_vm._e()],1)],1),(_vm.selectedShadow.id)?_c('section',{staticClass:"shadowContributorsAndActionsContainer"},[_c('b-row',[_c('b-col',{staticClass:"card"},[_c('b-row',[_c('b-col',[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t("filterByContributorScouts")))])]),(!_vm.currentShadowPlayers.length)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("no.contributorsYet"))+" ")]):_c('div',_vm._l((_vm.currentShadowContributors),function(contributorId,contributorIndex){return _c('button',{key:contributorIndex,class:{
                    contributorButton: true,
                    isSelected:
                      !_vm.selectedScoutId ||
                      (_vm.selectedScoutId && _vm.selectedScoutId === contributorId),
                  },on:{"click":function($event){return _vm.selectScout(contributorId)}}},[_vm._v(" "+_vm._s(_vm.identifyScoutById(contributorId, "shortenName"))+" ")])}),0)])],1)],1),_c('b-col',{staticClass:"card",attrs:{"id":"exportContainer"}},[_c('b-row',[_c('b-col',[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t("exportTheShadowTeam")))])]),_c('div',{staticClass:"shadowActionsContainer"},[_c('button',{on:{"click":function($event){return _vm.downloadShadow('pdf')}}},[_vm._v(" "+_vm._s(_vm.$t("PDFFormat"))+" ")]),_c('button',{on:{"click":function($event){return _vm.downloadShadow('png')}}},[_vm._v(" "+_vm._s(_vm.$t("PNGFormat"))+" ")])])])],1)],1)],1)],1):_vm._e(),(_vm.selectedShadow.id)?_c('section',{attrs:{"id":"currentShadowContainer"}},[_c('b-row',[_c('b-col',[_c('div',[_c('ShadowPitchComponent',{attrs:{"compoId":_vm.selectedShadow.compoId,"currentShadowPlayers":_vm.currentShadowPlayers,"selectedScoutId":_vm.selectedScoutId},on:{"addPlayer":_vm.addPlayer}})],1)])],1)],1):_vm._e()]),_c('NotificationComponent',{attrs:{"notification":_vm.notification}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }