export default {
  methods: {
    reduceStringToInitiales(stringToConvert) {
      if (!stringToConvert) {
        return "";
      }
      return stringToConvert
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");
    },
    string_to_slug(stringToTurnIntoSlug) {
      let slugStringToReturn = "";
      if (stringToTurnIntoSlug && stringToTurnIntoSlug.length) {
        slugStringToReturn = stringToTurnIntoSlug;
        slugStringToReturn = slugStringToReturn.replace(/^\s+|\s+$/g, "");
        slugStringToReturn = slugStringToReturn.toLowerCase();
        const from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
        const to = "aaaaaeeeeiiiioooouuuunc------";
        for (let i = 0, l = from.length; i < l; i++) {
          slugStringToReturn = slugStringToReturn.replace(
            new RegExp(from.charAt(i), "g"),
            to.charAt(i)
          );
        }
        slugStringToReturn = slugStringToReturn
          .replace(/[^a-z0-9 -]/g, "")
          .replace(/\s+/g, "-")
          .replace(/-+/g, "-");
        return slugStringToReturn;
      } else {
        return slugStringToReturn;
      }
    },
    stringContains(stringToCompare, search) {
      const sanitizedStringToCompare = stringToCompare
        .toLowerCase()
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "");
      const sanitizedSearch = search
        .toLowerCase()
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "");
      return sanitizedStringToCompare.includes(sanitizedSearch);
    },
    capitalize(string) {
      return string
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
        .join(" ");
    },
  },
};
