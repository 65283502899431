<template>
  <div
    @click.stop="handleClick"
    :class="{
      'action-container': true,
      disabled,
      primary,
    }"
  >
    {{ label }}
  </div>
</template>

<script>
export default {
  emits: ["click"],
  props: {
    disabled: { type: Boolean },
    primary: { type: Boolean },
    label: { type: String },
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit("click");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";
div.action-container {
  border-radius: 100px;
  padding: 12px 36px;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  height: 48px;
  min-width: 208px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: $blueDarkest;
  border: solid 1px #e6e6e6;
  font-size: 1rem;
  font-weight: 700;
  &.primary {
    background-color: $blueDarkest;
    color: #ffffff;
    border: none;
  }
  &.disabled {
    opacity: 0.5;
  }
  &:not(.disabled) {
    cursor: pointer;
  }
}
</style>
