<template>
  <ActionBase
    @click="handleClick()"
    :disabled="popinHasErrors"
    :label="$t('validate')"
    :primary="true"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ActionBase from "../../../Popin/Actions/ActionBase.vue";
import db from "../../../../db";
import mixinPlayers from "../../../../mixins/mixinPlayers";
export default {
  components: { ActionBase },
  mixins: [mixinPlayers],
  computed: {
    ...mapGetters("popin", [
      "getPopinShallowPayload",
      "popinPayload",
      "additionalContext",
      "popinHasErrors",
    ]),
    ...mapGetters(["getCurrentCompanyTeams"]),
  },
  methods: {
    ...mapMutations("popin", ["closePopin"]),
    ...mapActions("popin", ["openToast"]),
    async handleClick() {
      this.additionalContext.playersIn = [
        ...(this.additionalContext.playersIn ?? []),
        this.getPopinShallowPayload,
      ];
      await db
        .collection("V3shadowsMercato")
        .doc(this.additionalContext.id)
        .set({ playersIn: this.additionalContext.playersIn }, { merge: true });
      Object.assign(this.popinPayload, this.getPopinShallowPayload);
      const position = this.$t(
        this.getPositionTranslationKeyFromPlayer(this.getPopinShallowPayload)
      );
      this.openToast({
        translationKey: "squad.toasts.recruitPlayer",
        payload: { player: this.popinPayload.name, position },
        delay: 5000,
      });
      this.closePopin();
    },
  },
};
</script>

<style lang="scss" scoped></style>
