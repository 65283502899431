var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pitchPlayerContainer"},[(_vm.player && _vm.player.id)?_c('div',[_c('div',{class:{
        pitchPlayerContent: true,
        isHovered: _vm.isHovered,
        isShadowPlayer: _vm.isCurrentPlayerACurrentScopeShadowPlayer(_vm.player),
        isCompoPlayer: _vm.player.isStartingPlayer != undefined,
        isSelectedPlayer: _vm.player && _vm.player.id == _vm.getSelectedPlayer.id,
        isInBucket: _vm.isBucketPlayer(_vm.player),
      },on:{"click":function($event){return _vm.selectPlayer(_vm.player)}}},[_c('div',{class:{
          pitchPlayerJerseyContainer: true,
          homePlayer: _vm.team === 'home',
          awayPlayer: _vm.team === 'away',
        }},[_c('div',{staticClass:"notifsContainer"},[(_vm.isGoaler && _vm.isGoaler.id)?_c('div',{class:{
              playerEventContainer: true,
              isGoaler: _vm.isGoaler,
            }},[(_vm.isGoaler.action === 'Goal')?_c('img',{attrs:{"src":require("../assets/img/pictos/goal.svg"),"alt":"commentImg"}}):_vm._e(),(_vm.isGoaler.action === 'Own Goal Against')?_c('img',{attrs:{"src":require("../assets/img/pictos/goal_csc.svg"),"alt":"commentImg"}}):_vm._e()]):_vm._e(),(
              _vm.hasCard.action === 'Yellow Card' ||
              _vm.hasCard.action === 'Red Card'
            )?_c('div',{class:{
              playerEventContainer: true,
              hasCard: _vm.hasCard,
            }},[(_vm.hasCard.action === 'Yellow Card')?_c('img',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                _vm.$t('cardTakenAt', { minute: _vm.hasCard.minute })
              ),expression:"\n                $t('cardTakenAt', { minute: hasCard.minute })\n              ",modifiers:{"hover":true,"top":true}}],attrs:{"id":`yellowCard_${_vm.hasCard.id}`,"src":require("../assets/img/pictos/yellowCard.svg"),"alt":"commentImg"}}):_vm._e(),(_vm.hasCard.action === 'Red Card')?_c('img',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                _vm.$t('cardTakenAt', { minute: _vm.hasCard.minute })
              ),expression:"\n                $t('cardTakenAt', { minute: hasCard.minute })\n              ",modifiers:{"hover":true,"top":true}}],attrs:{"id":`redCard_${_vm.hasCard.id}`,"src":require("../assets/img/pictos/redCard.svg"),"alt":"commentImg"}}):_vm._e()]):_vm._e(),(_vm.isPlayerHavingNote)?_c('div',{class:{
              playerEventContainer: true,
              isPlayerHavingNote: _vm.isPlayerHavingNote,
            }},[_c('img',{attrs:{"src":require("../assets/img/pictos/playerComment.svg"),"alt":"commentImg"}})]):_vm._e(),(_vm.hasBeenReplaced)?_c('div',{class:{
              playerEventContainer: true,
              hasBeenReplaced: _vm.hasBeenReplaced,
            }},[_c('img',{attrs:{"id":`substitution_${_vm.hasBeenReplaced.id}`,"src":require("../assets/img/pictos/substitution.svg"),"alt":"commentImg"}}),_c('b-popover',{attrs:{"target":`substitution_${_vm.hasBeenReplaced.id}`,"triggers":"hover focus","placement":"top"}},[_c('b-row',[_c('b-col',[_vm._v(" "+_vm._s(_vm.$t("substitutionAskedAt", { minute: _vm.hasBeenReplaced.minute, substituerName: _vm.hasBeenReplaced.replacement.name || "mimi", }))+" ")])],1)],1)],1):_vm._e()]),_c('div',{staticClass:"pitchPlayerJerseyContent"},[_vm._v(" "+_vm._s(_vm.player.jerseyNumber)+" ")])]),_c('div',{staticClass:"pitchPlayerName"},[_vm._v(" "+_vm._s(_vm.shorteredName(_vm.player, _vm.isHovered))+" ")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }