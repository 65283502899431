<template>
  <ActionBase
    @click="goBack"
    :disabled="false"
    :label="$t('return')"
    :primary="false"
  ></ActionBase>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ActionBase from "./ActionBase.vue";
export default {
  components: { ActionBase },
  computed: {
    ...mapGetters("popin", ["previousPopinVariant"]),
  },
  methods: {
    ...mapMutations("popin", ["goBack"]),
  },
};
</script>

<style lang="scss" scoped></style>
