import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getCompos"]),
  },
  methods: {
    getCompoById(compoId, returnFormat = false) {
      if (!compoId) {
        return "";
      }
      const toReturn = this.getCompos.filter((compo) => {
        return compo.id === compoId;
      });
      if (returnFormat) {
        if (returnFormat === "displayName") {
          return toReturn[0].label
            ? toReturn[0].schema.split("").join("-") + " " + toReturn[0].label
            : toReturn[0].schema.split("").join("-");
        }
      }
      return toReturn[0].schema.split("").join("-");
    },
  },
};
