<template>
  <div
    :class="{
      customPopoverContainer: true,
      isDisplayed: isDisplayed,
    }"
  >
    <div class="customPopoverContent">
      <span class="customePopoverSpan">
        {{ content }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomPopover",
  props: {
    content: { type: String },
    isDisplayed: { type: Boolean },
  },
};
</script>

<style>
.customPopoverContainer {
  display: none;
}

.isHovered .customPopoverContainer {
  display: block;
  position: absolute;
  bottom: 0;
  top: 42px;
  right: -3px;
  background: #f6f5ff;
  width: fit-content;
  min-width: max-content;
  height: max-content;
  padding: 8px 16px;
  border: solid 1px #060052;
  color: #000000;
  z-index: 10;
  border-radius: 4px;
}

.isHovered .customPopoverContainer::before {
  content: url("data:image/svg+xml,%3Csvg width='141' height='10' viewBox='0 0 141 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M124.5 8.00098L140.5 8.00098L132.5 0.000976562L124.5 8.00098Z' fill='%23060052'/%3E%3Cpath d='M124.5 9.50098L140.5 9.50098L132.5 1.50098L124.5 9.50098Z' fill='%23F6F5FF'/%3E%3C/svg%3E%0A");
  position: absolute;
  top: -12px;
  right: 27px;
}
</style>
