<template>
  <div>
    <b-sidebar
      id="sidebar-player-create"
      :title="$t('create.aPlayer')"
      backdrop-variant="dark"
      v-model="props.display"
      backdrop
      shadow
    >
      <div class="px-3 py-2">
        <b-form @submit.prevent="createPlayer">
          <b-form-group
            id="name"
            :label="$t('fullname')"
            label-for="name"
            class="text-left"
          >
            <b-form-input
              id="name"
              v-model="name"
              type="text"
              required
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="currentTeam"
            :label="$tc('plural.team', 1)"
            label-for="currentTeam"
            class="text-left"
          >
            <b-form-input
              id="currentTeam"
              v-model="currentTeam"
              type="text"
              required
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="nationality"
            :label="$tc('plural.nationality', 1)"
            label-for="nationality"
            class="text-left"
          >
            <b-form-input
              id="nationality"
              v-model="nationality"
              type="text"
              required
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="dateGroupCreatePlayer"
            :label="$t('dateOfBirth')"
            label-for="date"
            class="text-left"
          >
            <b-form-datepicker
              id="dateCreatePlayer"
              v-model="dateOfBirth"
              :locale="$t('locale')"
              :label-no-date-selected="$t('no.dateSelected')"
              :input="translate('dateFormat')"
              :placeholder="$t('no.dateSelected')"
              :date-format-options="{
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              }"
              required
            ></b-form-datepicker>
          </b-form-group>
          <b-form-group
            id="height"
            :label="translateField('height')"
            label-for="height"
            class="text-left"
          >
            <b-form-input
              id="height"
              v-model="height"
              type="number"
              min="0"
              required
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="weight"
            :label="translateField('weight')"
            label-for="weight"
            class="text-left"
          >
            <b-form-input
              id="weight"
              v-model="weight"
              type="number"
              min="0"
              required
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
          <div class="mt-2 p-4 text-left error" v-if="err">
            <ul>
              <li>
                <font-awesome-icon class="mr-2" icon="exclamation-circle" />
              </li>
              <li>
                <p class="mb-0">{{ err }}</p>
              </li>
            </ul>
          </div>
          <b-button class="submit mt-4" type="submit" variant="primary">
            {{ $t("add.thePlayer") }}
          </b-button>
        </b-form>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import moment from "moment";
import db from "../db";
import { mapState } from "vuex";
import mixinTranslation from "../mixins/mixinTranslation";
export default {
  name: "SidebarCreatePlayer",
  mixins: [mixinTranslation],
  data() {
    return {
      props: this.prop,
      currentTeam: null,
      dateOfBirth: null,
      height: null,
      name: null,
      nationality: "",
      weight: null,
      err: "",
    };
  },
  props: ["prop"],
  computed: {
    ...mapState(["currentScoutId", "locale"]),
  },
  methods: {
    translateField(field) {
      switch (field) {
        case "height":
          return this.$t("height") + " (cm)";
        case "weight":
          return this.$t("weight") + " (kg)";
      }
    },
    async createPlayer() {
      var self = this;

      await db
        .collection("V3players")
        .add({
          citizenship: [this.nationality.toString()],
          currentTeam: this.currentTeam.toString(),
          dateOfBirth: !isNaN(parseInt(moment(this.dateOfBirth).unix()))
            ? parseInt(moment(this.dateOfBirth).unix())
            : "undefined",
          height:
            this.height && !isNaN(parseInt(this.height))
              ? parseInt(this.height)
              : "undefined",
          isReferral: false,
          name: this.name.toString(),
          nationality: this.nationality.toString(),
          updatedBy: self.currentScoutId.toString(),
          weight:
            this.weight && !isNaN(parseInt(this.weight))
              ? parseInt(this.weight)
              : "undefined",
        })
        .then(async (docRef) => {
          db.collection("V3players")
            .doc(docRef.id.toString())
            .update({ id: docRef.id.toString() });
        })
        .then(() => {
          self.citizenship = null;
          self.currentTeam = null;
          self.dateOfBirth = null;
          self.height = null;
          self.name = null;
          self.nationality = "";
          self.weight = null;
          self.props.display = false;
          self.err = "";
        })
        .catch(function (error) {
          self.err = error;
        });
    },
  },
};
</script>
