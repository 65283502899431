<template>
  <div class="players-list" :style="{ maxHeight: height + 'px' }">
    <PlayersListItem
      v-for="(player, playerIndex) of players"
      :player="player"
      :key="playerIndex"
      :listVariant="listVariant"
    />
  </div>
</template>

<script>
import { listVariants } from "../../enums/listVariants";
import mixinPlayers from "../../mixins/mixinPlayers";
import PlayersListItem from "./PlayersListItem.vue";
export default {
  mixins: [mixinPlayers],
  components: {
    PlayersListItem,
  },
  props: {
    players: { type: Array },
    listVariant: { type: String },
  },
  data() {
    return {
      height: 0,
    };
  },
  mounted() {
    switch (this.listVariant) {
      case listVariants.AVAILABLE:
        this.height = 434;
        break;

      default:
        this.height = 265;
        break;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";
div.players-list {
  overflow-y: auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $purpleLight;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: #2c1fd6;
    border-radius: 100px;
    border-left: 1px solid $purpleLight;
    border-right: 1px solid $purpleLight;
    width: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #2c1fd6;
  }
}
</style>
