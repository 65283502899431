<template>
  <div class="prospects-card">
    <div class="title-container">
      <span class="title">{{ $t("prospectsCardTitle") }}</span>
      <PlayersCountPill class="pill" :playersCount="filteredPlayers.length" />
      <div class="search-button" @click="openSearchPopin">
        <img src="../../../assets/img/plus_white.svg" />
      </div>
    </div>
    <div class="separator"></div>
    <DropdownSelect
      :elements="macroPositions"
      :placeholder="$t('select.aMacroPosition')"
      @change="handleMacroChange"
      :selected="positionsFilter.macro"
    />
    <DropdownSelect
      :elements="filteredMicroPositions"
      :placeholder="$t('select.aMicroPosition')"
      @change="handleMicroChange"
      :selected="positionsFilter.micro"
    />
    <PlayersList
      class="players-list"
      listVariant="prospects"
      :players="filteredPlayers"
    />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { popinVariants } from "../../../enums/popinVariants";
import mixinPlayers from "../../../mixins/mixinPlayers";
import DropdownSelect from "../../DropdownSelect.vue";
import PlayersCountPill from "../PlayersCountPill.vue";
import PlayersList from "../PlayersList.vue";
export default {
  mixins: [mixinPlayers],
  props: {
    players: { type: Array, default: () => [] },
    macroPositions: { type: Array },
    microPositions: { type: Array },
  },
  data() {
    return {
      filteredMicroPositions: this.microPositions,
      positionsFilter: { micro: null, macro: null },
      filteredPlayers: this.players,
    };
  },
  methods: {
    ...mapMutations("popin", ["openPopinVariant"]),
    openSearchPopin() {
      this.openPopinVariant({
        popinVariant: popinVariants.SEARCH_PROSPECTS,
        popinPayload: {
          players: this.players,
          microPositions: this.microPositions,
          macroPositions: this.macroPositions,
        },
      });
    },
    reloadFilteredPlayers() {
      if (this.positionsFilter.micro) {
        const microTranslationKey = this.getPositionTranslationKey(
          this.positionsFilter.micro
        );
        this.filteredPlayers = this.players.filter(
          (player) =>
            this.getPositionTranslationKeyFromPlayer(player) ===
            microTranslationKey
        );
        return;
      }
      if (this.positionsFilter.macro) {
        const targetMicrosTranslationKey = this.microPositions
          .filter((position) => position.macro === this.positionsFilter.macro)
          .map((p) => this.getPositionTranslationKey(p.value));
        this.filteredPlayers = this.players.filter((player) =>
          targetMicrosTranslationKey.includes(
            this.getPositionTranslationKeyFromPlayer(player)
          )
        );
        return;
      }
      this.filteredPlayers = this.players;
    },
    handleMacroChange(newValue) {
      if (!newValue) {
        this.filteredMicroPositions = this.microPositions;
        this.positionsFilter.micro = null;
        this.positionsFilter.macro = null;
        this.reloadFilteredPlayers();
        return;
      }
      this.filteredMicroPositions = this.microPositions.filter(
        (position) => position.macro === newValue
      );
      this.positionsFilter.macro = newValue;
      this.reloadFilteredPlayers();
    },
    handleMicroChange(newValue) {
      this.positionsFilter.micro = newValue;
      if (newValue) {
        this.positionsFilter.macro = this.microPositions.find(
          (position) => position.value === newValue
        ).macro;
      }
      this.reloadFilteredPlayers();
    },
  },
  components: {
    DropdownSelect,
    PlayersList,
    PlayersCountPill,
  },
  watch: {
    players() {
      this.filteredPlayers = this.players;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

div.prospects-card {
  background-color: $white;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  padding: 24px 11px 24px 24px;
  gap: 24px;
  display: flex;
  flex-direction: column;
}
div.separator {
  width: 100%;
  height: 1px;
  background-color: #e6e6e6;
}

div.title-container {
  display: flex;
  width: 100%;
  padding-right: 10px;
}

span.title {
  flex-basis: 0;
  flex-grow: 1;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  color: $blueDarkest;
}

div.pill {
  height: fit-content;
}

div.players-list {
  width: 100%;
}

div.search-button {
  border-radius: 100px;
  width: 20px;
  height: 20px;
  background-color: $blueDarkest;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  align-self: center;
  margin-left: 10px;
}
</style>
