<template>
  <b-row class="fields-group" align-h="center">
    <b-col class="field" cols="12" xl="6">
      <MiniSquadInput
        class="squad-input"
        :editable="true"
        :disposition="additionalContext.compo"
        :dispositionVersion="additionalContext.compoVersion"
        :required="true"
        :position="initialPosition"
        @change="changePosition"
        @error="addPopinError"
        @unerror="removePopinError"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MiniSquadInput from "../../../Fields/MiniSquadInput.vue";
import mixinText from "../../../../mixins/mixinText";
import mixinPlayers from "../../../../mixins/mixinPlayers";
export default {
  components: { MiniSquadInput },
  computed: {
    ...mapGetters("popin", ["additionalContext", "getPopinShallowPayload"]),
    ...mapGetters(["getGamesPosition"]),
  },
  data() {
    return { initialPosition: this.getPopinShallowPayload?.primaryPosition };
  },
  mixins: [mixinText, mixinPlayers],
  mounted() {
    let position = this.getPopinShallowPayload?.primaryPosition;
    if (this.getPopinShallowPayload?.movingPosition) {
      position = this.capitalize(
        this.getPositionFromAcronym(this.getPopinShallowPayload.movingPosition)
          .label
      );
    }
    this.initialPosition = position;
  },
  methods: {
    ...mapActions("popin", ["addPopinError", "removePopinError"]),
    changePosition(value) {
      this.$store.dispatch("popin/setShallowPayload", {
        movingPosition: value,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
div.squad-input {
  height: 300px;
}
</style>
