<template>
  <b-row class="fields-group" v-if="computedPlayers.length">
    <b-col cols="12">
      <List
        @change="onChange"
        :maxItems="5"
        :columns="columns"
        :items="computedPlayers"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import mixinPlayers from "../../../../mixins/mixinPlayers";
import List from "../../../Fields/List.vue";
export default {
  components: { List },
  mixins: [mixinPlayers],
  data() {
    return {
      computedPlayers: [],
      columns: {
        dragIcon: {
          weight: 0.5,
          align: "left",
        },
        minifiedName: {
          value: (item) => this.getMinifiedPlayerName(item.name),
          weight: 2,
          align: "left",
          title: this.$t("name"),
        },
        birthDate: {
          value: (item) =>
            this.$t("bornIn") +
            this.formatDate(item.dateOfBirth, false, "year"),
          align: "left",
          title: this.$t("dateOfBirth"),
        },
        eoc: {
          value: (item) =>
            `${this.$t("endOfContractAcronym")}: ${
              item.endContract
                ? this.capitalize(
                    this.formatDate(item.endContract, false, "MMMM YYYY")
                  )
                : this.$t("undefined")
            }`,
          align: "left",
          title: this.$t("endOfContractTitle"),
        },
        price: {
          value: (item) =>
            item.price && item.price !== "undefined"
              ? `${item.price} ${this.$t("millions")}`
              : this.$t("undefined"),
          align: "left",
          title: this.$t("price"),
        },
        salary: {
          value: (item) =>
            item.newSalary != null ||
            (item.salary && item.salary !== "undefined")
              ? `${item.newSalary ?? item.salary} ${this.$t("millionsPerYear")}`
              : this.$t("undefined"),
          align: "left",
          title: this.$t("salary"),
        },
        playerAvatar: {
          href: (item) => `/globalReportForPlayer/${item.id}`,
          pictoSrc: () => require("../../../../assets/img/account.svg"),
          align: "right",
          weight: 0.5,
        },
      },
    };
  },
  mounted() {
    this.computedPlayers = this.getPopinShallowPayload.players.map(
      (player) => ({
        ...player,
        isIncoming:
          player && !this.getCurrentCompanyTeams.includes(player.currentTeam),
      })
    );
  },
  computed: {
    ...mapGetters("popin", ["getPopinShallowPayload"]),
    ...mapGetters(["getCurrentCompanyTeams"]),
  },
  methods: {
    onChange(players) {
      this.$store.dispatch("popin/setShallowPayload", { players });
    },
  },
};
</script>
<style lang="scss" scoped>
div.fields-group {
  padding: 24px;
}
</style>
