var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getSelectedPlayer.id)?_c('div',{key:_vm.getSelectedPlayer.id,ref:"gamePlayersContainer",attrs:{"id":"gamePlayersContainer"}},[_c('div',{staticClass:"gameBlocTitle",attrs:{"id":"gamePlayerObservationTitle"}},[_c('h3',{staticClass:"gamePlayerObservationTitle"},[_vm._v(_vm._s(_vm.getSelectedPlayer.name))]),_c('div',{staticClass:"gamePlayerStatusContainer"},[(_vm.isBucketPlayer(_vm.getSelectedPlayer))?_c('span',{attrs:{"id":"gamePlayerIsBucket"}},[_vm._v(" "+_vm._s(_vm.$t("gameViewlegend.bucketPlayer"))+" ")]):_vm._e(),(_vm.isCurrentPlayerACurrentScopeShadowPlayer(_vm.getSelectedPlayer))?_c('span',{attrs:{"id":"gamePlayerIsInShadow"}},[_vm._v(" "+_vm._s(_vm.$t("gameViewlegend.shadowPlayer"))+" ")]):_vm._e()])]),(_vm.getCurrentScout?.isManager && !_vm.playerGlobalComment.length)?_c('div',{staticClass:"noCommentedPlayer"},[_c('p',[_vm._v(_vm._s(_vm.$t("gameViewlegend.playerWasntCommented")))])]):_c('div',[_c('div',{staticClass:"gamePlayerCommentsContainer"},[_c('div',{attrs:{"id":"globalCommentContainer"}},[(
            (_vm.getCurrentScout?.isManager && _vm.playerGlobalComment.length) ||
            _vm.getCurrentScout?.id === _vm.game?.addedBy
          )?_c('p',{staticClass:"playerCommentsLabel"},[_vm._v(" "+_vm._s(_vm.$t("gameViewlegend.globalPlayerObservationLabel"))+" ")]):_vm._e(),(_vm.getCurrentScout?.id === _vm.game?.addedBy && _vm.isEditable)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.playerGlobalComment),expression:"playerGlobalComment"}],staticClass:"playerComment",attrs:{"placeholder":_vm.$t('yourComment')},domProps:{"value":(_vm.playerGlobalComment)},on:{"input":function($event){if($event.target.composing)return;_vm.playerGlobalComment=$event.target.value}}}):(_vm.playerGlobalComment.length)?_c('p',{staticClass:"playerCommentsContent"},[_vm._v(" "+_vm._s(_vm.playerGlobalComment)+" ")]):_vm._e()])]),(
        (_vm.getCurrentScout?.isManager && _vm.scoreSelected !== '') ||
        !_vm.getCurrentScout?.isManager
      )?_c('div',{staticClass:"gamePlayerCommentsContainer"},[_c('p',{staticClass:"playerCommentsLabel"},[_vm._v(" "+_vm._s(_vm.$t("gameViewlegend.playerScore"))+" ")]),_c('div',{staticClass:"scoresContainer"},[_c('span',{class:{
            ABCDNoteContainer: true,
            selected: _vm.scoreSelected == 'A',
            editable: _vm.isUserTheAuthor,
          },on:{"click":function($event){_vm.isUserTheAuthor && _vm.isEditable ? _vm.setPlayerABCDNote('A') : null}}},[_vm._v(" A ")]),_c('span',{class:{
            ABCDNoteContainer: true,
            selected: _vm.scoreSelected == 'B',
            editable: _vm.isUserTheAuthor,
          },on:{"click":function($event){_vm.isUserTheAuthor && _vm.isEditable ? _vm.setPlayerABCDNote('B') : null}}},[_vm._v(" B ")]),_c('span',{class:{
            ABCDNoteContainer: true,
            selected: _vm.scoreSelected == 'C',
            editable: _vm.isUserTheAuthor,
          },on:{"click":function($event){_vm.isUserTheAuthor && _vm.isEditable ? _vm.setPlayerABCDNote('C') : null}}},[_vm._v(" C ")]),_c('span',{class:{
            ABCDNoteContainer: true,
            selected: _vm.scoreSelected == 'D',
            editable: _vm.isUserTheAuthor,
          },on:{"click":function($event){_vm.isUserTheAuthor && _vm.isEditable ? _vm.setPlayerABCDNote('D') : null}}},[_vm._v(" D ")])])]):_vm._e(),(
        (_vm.isUserTheAuthor &&
          (_vm.isEditable || (!_vm.isEditable && _vm.tacticalComment.length))) ||
        (_vm.getCurrentScout?.isManager && _vm.tacticalComment.length)
      )?_c('div',{staticClass:"gamePlayerCommentsContainer"},[_c('p',{class:{
          playerCommentsLabel: true,
        }},[_vm._v(" "+_vm._s(_vm.$t("gameViewlegend.tacticalLegend"))+" "),(!_vm.allFieldsRequired && _vm.isUserTheAuthor)?_c('span',{staticClass:"optionalLabel"},[_vm._v(_vm._s(_vm.$t("gameViewlegend.optional")))]):_vm._e()]),(_vm.isUserTheAuthor && _vm.isEditable)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.tacticalComment),expression:"tacticalComment"}],staticClass:"playerComment",attrs:{"placeholder":_vm.$t('yourComment')},domProps:{"value":(_vm.tacticalComment)},on:{"input":function($event){if($event.target.composing)return;_vm.tacticalComment=$event.target.value}}}):_c('p',{staticClass:"playerCommentsContent"},[_vm._v(" "+_vm._s(_vm.tacticalComment)+" ")])]):_vm._e(),(
        (_vm.isUserTheAuthor &&
          (_vm.isEditable || (!_vm.isEditable && _vm.mentalComment.length))) ||
        (_vm.getCurrentScout?.isManager && _vm.mentalComment.length)
      )?_c('div',{staticClass:"gamePlayerCommentsContainer"},[_c('p',{class:{
          playerCommentsLabel: true,
        }},[_vm._v(" "+_vm._s(_vm.$t("gameViewlegend.mentalLegend"))+" "),(!_vm.allFieldsRequired && _vm.isUserTheAuthor)?_c('span',{staticClass:"optionalLabel"},[_vm._v(_vm._s(_vm.$t("gameViewlegend.optional")))]):_vm._e()]),(_vm.isUserTheAuthor && _vm.isEditable)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.mentalComment),expression:"mentalComment"}],staticClass:"playerComment",attrs:{"placeholder":_vm.$t('yourComment')},domProps:{"value":(_vm.mentalComment)},on:{"input":function($event){if($event.target.composing)return;_vm.mentalComment=$event.target.value}}}):_c('p',{staticClass:"playerCommentsContent"},[_vm._v(" "+_vm._s(_vm.mentalComment)+" ")])]):_vm._e(),(
        (_vm.isUserTheAuthor &&
          (_vm.isEditable || (!_vm.isEditable && _vm.athleticalComment.length))) ||
        (_vm.getCurrentScout?.isManager && _vm.athleticalComment.length)
      )?_c('div',{staticClass:"gamePlayerCommentsContainer"},[_c('p',{class:{
          playerCommentsLabel: true,
        }},[_vm._v(" "+_vm._s(_vm.$t("gameViewlegend.athleticalLegend"))+" "),(!_vm.allFieldsRequired && _vm.isUserTheAuthor)?_c('span',{staticClass:"optionalLabel"},[_vm._v(_vm._s(_vm.$t("gameViewlegend.optional")))]):_vm._e()]),(_vm.isUserTheAuthor && _vm.isEditable)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.athleticalComment),expression:"athleticalComment"}],staticClass:"playerComment",attrs:{"placeholder":_vm.$t('yourComment')},domProps:{"value":(_vm.athleticalComment)},on:{"input":function($event){if($event.target.composing)return;_vm.athleticalComment=$event.target.value}}}):_c('p',{staticClass:"playerCommentsContent"},[_vm._v(" "+_vm._s(_vm.athleticalComment)+" ")])]):_vm._e(),(
        (_vm.isUserTheAuthor &&
          (_vm.isEditable || (!_vm.isEditable && _vm.technicalComment.length))) ||
        (_vm.getCurrentScout?.isManager && _vm.technicalComment.length)
      )?_c('div',{staticClass:"gamePlayerCommentsContainer"},[_c('p',{class:{
          playerCommentsLabel: true,
        }},[_vm._v(" "+_vm._s(_vm.$t("gameViewlegend.technicalLegend"))+" "),(!_vm.allFieldsRequired && _vm.isUserTheAuthor)?_c('span',{staticClass:"optionalLabel"},[_vm._v(_vm._s(_vm.$t("gameViewlegend.optional")))]):_vm._e()]),(_vm.isUserTheAuthor && _vm.isEditable)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.technicalComment),expression:"technicalComment"}],staticClass:"playerComment",attrs:{"placeholder":_vm.$t('yourComment')},domProps:{"value":(_vm.technicalComment)},on:{"input":function($event){if($event.target.composing)return;_vm.technicalComment=$event.target.value}}}):_c('p',{staticClass:"playerCommentsContent"},[_vm._v(" "+_vm._s(_vm.technicalComment)+" ")])]):_vm._e(),(_vm.isUserTheAuthor && _vm.isEditable)?_c('div',{attrs:{"id":"gamePlayerValidateGameNoteContainer"}},[_c('button',{class:{
          formSubmitButton: true,
          disabled: _vm.isSubmitButtonDisabled,
        },attrs:{"disabled":_vm.isSubmitButtonDisabled},on:{"click":_vm.submitNewComments}},[_vm._v(" "+_vm._s(_vm.$t("gameViewlegend.savePlayerObservation"))+" ")])]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }