<template>
  <div class="dropdown-container">
    <div class="input-group-header">
      <span class="input-label">{{ label }}</span>
      <span class="input-example">{{ example }}</span>
    </div>
    <div
      @click="toggleDropdownList"
      :class="{
        'dropdown-value': true,
        'selected': hasValue && !searchable,
        opened,
      }"
    >
      <input
        ref="inputRef"
        @click.stop
        :class="{ visible: searchable && opened }"
        type="text"
        class="dropdown-title-input"
        v-model="textFilter"
      />
      <span class="dropdown-title" v-if="!(searchable && opened)">
        {{ hasValue ? selectedLabel : placeholder }}
      </span>
      <div class="chevron">
        <img v-if="hasValue" src="../assets/img/chevron_down_white.svg" />
        <img v-else-if="opened" src="../assets/img/chevron_up.svg" />
        <img v-else src="../assets/img/chevron_down_grey.svg" />
      </div>
    </div>
    <div :class="{ 'dropdown-list': true, 'hidden': !opened }">
      <div
        :class="{
          'dropdown-list-item': true,
          'selected': selectedValue === item.value,
        }"
        v-for="(item, index) of filteredElements"
        :key="`dropdown_item_${index}`"
        @click="handleClick(item.value)"
      >
        <span>
          {{ item.text }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import mixinText from "../mixins/mixinText";
export default {
  emits: ["change"],
  mixins: [mixinText],
  props: {
    elements: {
      type: Array,
      required: true,
      default: () => [],
      validator: (value) =>
        Array.isArray(value) &&
        value.every((element) => element.value != null && element.text != null),
    },
    selected: { type: String },
    placeholder: { type: String, required: true },
    label: { type: String, required: false },
    example: { type: String, required: false },
    searchable: { type: Boolean, default: false },
  },
  data() {
    return {
      opened: false,
      selectedValue: this.selected,
      textFilter: "",
      filteredElements: this.elements,
      inputRef: ref(null),
    };
  },
  computed: {
    selectedLabel() {
      return this.elements.find(
        (element) => element.value === this.selectedValue
      ).text;
    },
    hasValue() {
      return this.elements.some(
        (element) => element.value === this.selectedValue
      );
    },
  },
  methods: {
    toggleDropdownList() {
      this.opened = !this.opened;
      if (this.opened) {
        console.log(this.$refs.inputRef);
        this.$refs.inputRef.focus();
      }
    },
    handleClick(stringValue) {
      this.selectedValue =
        stringValue === this.selectedValue ? null : stringValue;
      this.opened = false;
      this.$emit("change", this.selectedValue);
    },
  },
  watch: {
    selected: {
      immediate: true,
      deep: true,
      handler(value) {
        this.selectedValue = value;
      },
    },
    textFilter(newValue) {
      this.filteredElements = this.elements.filter((element) =>
        this.stringContains(element.text, newValue)
      );
    },
    elements: {
      deep: true,
      immediate: true,
      handler(value) {
        this.filteredElements = value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
div.dropdown-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  user-select: none;
}

div.dropdown-value {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  background-color: #f0f0f0;
  color: #7a7a7a;
  &.opened {
    border-radius: 4px 4px 0 0;
    color: #111111;
  }
  &.selected {
    background-color: #0b00a3;
    color: #ffffff;
  }
  > .dropdown-title {
    font-size: 0.875rem;
    line-height: 0.875rem;
    padding: 13px;
    flex-grow: 1;
  }
  > .dropdown-title-input {
    background: transparent;
    border: none;
    padding: 13px;
    margin: 0;
    font-size: 14px;
    line-height: 14px;
    width: 100%;
    &:not(.visible) {
      opacity: 0;
      user-select: none;
      width: 0;
      height: 0;
      padding: 0;
    }
  }
  > .chevron {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 13px;
    align-self: center;
  }
}

div.dropdown-list {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow: auto;
  transition: max-height 0.3s ease-out;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $purpleLight;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: #2c1fd6;
    border-radius: 100px;
    border-left: 1px solid $purpleLight;
    border-right: 1px solid $purpleLight;
    width: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #2c1fd6;
  }
  &.hidden {
    max-height: 0;
  }
}

div.dropdown-list-item {
  background-color: #f6f6f6;
  border-top: solid 1px #e7e7e7;
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #2c1fd6;
  &.selected {
    color: #ffffff;
    background-color: #0b00a3;
  }
  &:hover:not(.selected) {
    transition: 0.3s;
    background-color: #e6e6e6;
  }
  > span {
    font-size: 0.875rem;
    padding: 12px;
    width: 100%;
    font-weight: 700;
    line-height: 1rem;
  }
}

div.input-group-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

span.input-label {
  color: #111111;
  font-family: "Proxima Nova", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}
span.input-example {
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #7a7a7a;
  font-family: "Proxima Nova", sans-serif;
}
</style>
