<template>
  <div class="input-group-container">
    <span class="input-label">{{ label }}</span>
    <div class="input-container">
      <input
        autocomplete="off"
        :class="{
          'picker-opened': isPickerOpened,
          errored,
          disabled,
        }"
        :placeholder="placeholderBasedOnLocale"
        type="text"
        :value="stringValue"
        :disabled="disabled"
        :ref="'dateinput_' + id"
        :max="max"
        :min="min"
        @blur="handleBlur"
        @input="handleChange"
      />
      <div class="picker-picto-container" @click="togglePicker">
        <div :class="{ 'picker-picto': 1, 'opened': isPickerOpened }">
          <img
            src="../../assets/img/pictos/datepicker.svg"
            :alt="$t('datePicker.open')"
            v-if="!isPickerOpened"
          />
          <img
            src="../../assets/img/pictos/datepicker-white.svg"
            :alt="$t('datePicker.close')"
            v-if="isPickerOpened"
          />
        </div>
      </div>
    </div>
    <div tabindex="0" class="picker" v-if="isPickerOpened">
      <div class="month-picker">
        <div
          class="previous-month"
          v-if="!disabledPreviousMonth"
          @click="goToPreviousMonth"
        >
          <img
            src="../../assets/img/pictos/chevron_left.svg"
            :alt="$t('datePicker.previousMonth')"
          />
        </div>
        <span class="current-month">{{ currentMonthDisplay }}</span>
        <div
          class="next-month"
          v-if="!disabledNextMonth"
          @click="goToNextMonth"
        >
          <img
            src="../../assets/img/pictos/chevron_right.svg"
            :alt="$t('datePicker.nextMonth')"
          />
        </div>
      </div>
      <div class="separator"></div>
      <div class="picker-grid">
        <div class="picker-days-title">
          <div
            class="picker-day-title"
            v-for="(dayTitle, index) of daysTitle"
            :key="'picker_day_title_' + index"
          >
            <span>{{ dayTitle }}</span>
          </div>
        </div>
        <div
          class="picker-week"
          v-for="(week, index) of currentMonthWeeks"
          :key="'picker_week_' + index"
        >
          <div
            class="picker-day"
            v-for="(day, dayIndex) of week"
            @click="handleClickOnDay(day.date, day.disabled)"
            :class="{
              disabled: day.disabled,
              selected: day.selected,
            }"
            :key="'picker_day_' + dayIndex"
          >
            <span>
              {{ day.number }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import mixinDates from "../../mixins/mixinDates";

export default {
  props: {
    label: { type: String, default: "" },
    min: { type: Date },
    max: { type: Date },
    value: { type: Number },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    name: { type: String, default: "" },
  },
  data() {
    return {
      isPickerOpened: false,
      pickerPeriodDate: null,
      id: this._uid,
      errored: false,
      dateValue: new Date(isNaN(this.value) ? Date.now() : this.value * 1000),
      stringValue: "",
    };
  },
  emits: ["change", "error", "unerror"],
  mounted() {
    this.pickerPeriodDate = this.dateValue;
    if (!isNaN(this.value) && this.value !== 0) {
      this.stringValue = moment(this.dateValue).format("DD / MM / YYYY");
    } else if (this.value === 0) {
      this.stringValue =
        this.getLocale === "fr" ? "DD / MM / YYYY" : "MM / DD / YYYY";
    }
  },
  mixins: [mixinDates],
  computed: {
    ...mapGetters(["getLocale"]),
    placeholderBasedOnLocale() {
      return this.getLocale === "fr" ? "DD / MM / YYYY" : "MM / DD / YYYY";
    },
    pickedDate() {
      return new Date(this.value * 1000);
    },
    disabledPreviousMonth() {
      if (!this.min) {
        return false;
      }
      return (
        moment(this.min).startOf("month").toDate().getTime() ===
        moment(this.pickerPeriodDate).startOf("month").toDate().getTime()
      );
    },
    disabledNextMonth() {
      if (!this.max) {
        return false;
      }
      return (
        moment(this.max).startOf("month").toDate().getTime() ===
        moment(this.pickerPeriodDate).startOf("month").toDate().getTime()
      );
    },
    selectedMonthIndex() {
      return this.pickerPeriodDate.getMonth();
    },
    previousMonthDate() {
      return new Date(
        this.pickerPeriodDate.getFullYear(),
        this.selectedMonthIndex - 1,
        1
      );
    },
    nextMonthDate() {
      return new Date(
        this.pickerPeriodDate.getFullYear(),
        this.selectedMonthIndex + 1,
        1
      );
    },
    daysTitle() {
      const daysTitle = [];
      const currentWeekStartDate = moment().startOf("week").toDate();
      let day = 1;
      while (day <= 7) {
        daysTitle.push(
          new Intl.DateTimeFormat(this.getLocale, { weekday: "short" })
            .format(currentWeekStartDate)
            .slice(0, 3)
            .toUpperCase()
        );
        const currentDate = currentWeekStartDate.getDate();
        currentWeekStartDate.setDate(currentDate + 1);
        ++day;
      }
      return daysTitle;
    },
    currentMonthDisplay() {
      const options = {
        month: "long",
        year: "numeric",
      };
      return new Intl.DateTimeFormat(this.getLocale, options).format(
        this.pickerPeriodDate
      );
    },
    currentMonthWeeks() {
      const currentMoment = moment(this.pickerPeriodDate)
        .startOf("month")
        .startOf("week");
      const weeks = [];
      const endOfMonth = moment(this.pickerPeriodDate).endOf("month").valueOf();
      const pickedDate = moment(this.pickedDate).format("DD/MM/YYYY");
      const minTimestamp = this.min
        ? moment(this.min).startOf("day").toDate().getTime()
        : null;
      const maxTimestamp = this.max
        ? moment(this.max).startOf("day").toDate().getTime()
        : null;
      while (currentMoment.valueOf() < endOfMonth) {
        let i = 0;
        const currentWeek = [];
        while (i < 7) {
          const currentDate = new Date(currentMoment.valueOf());
          const isDayInAnotherMonth =
            currentDate.getMonth() !== this.selectedMonthIndex;
          const isDayBeforeMinDate =
            minTimestamp && minTimestamp > currentDate.getTime();
          const isDayAfterMaxDate =
            maxTimestamp && maxTimestamp < currentDate.getTime();
          const disabled =
            isDayInAnotherMonth || isDayBeforeMinDate || isDayAfterMaxDate;
          let selected = false;
          if (pickedDate === currentMoment.format("DD/MM/YYYY")) {
            selected = true;
          }
          currentWeek.push({
            date: currentDate,
            number: currentDate.getDate(),
            disabled,
            selected,
          });
          currentMoment.add(1, "day");
          ++i;
        }
        weeks.push(currentWeek);
      }
      return weeks;
    },
  },
  methods: {
    setErrored() {
      this.errored = true;
      this.$emit("error", this.id);
    },
    setNonErrored() {
      this.errored = false;
      this.$emit("unerror", this.id);
    },
    handleChange({ target, data }) {
      let newValue = target.value;
      if (!data) {
        if ([3, 4, 5].includes(newValue.length)) {
          newValue = newValue.slice(0, 2);
        } else if ([8, 9, 10].includes(newValue.length)) {
          newValue = newValue.slice(0, 7);
        }
      } else if (!isNaN(parseInt(data))) {
        const newCharacter = newValue.slice(-1);
        let parsedMoment;
        switch (newValue.length) {
          case 2:
          case 7:
            newValue += " / ";
            break;
          case 3:
          case 8:
            newValue = newValue.slice(0, -1) + " / " + newCharacter;
            break;
          case 4:
          case 9:
            newValue = newValue.slice(0, -1) + "/ " + newCharacter;
            break;
          case 5:
          case 10:
            newValue = newValue.slice(0, -1) + " " + newCharacter;
            break;
          case 14:
            parsedMoment = moment(newValue, "DD / MM / YYYY");
            if (parsedMoment.isValid()) {
              const parsedDate = parsedMoment.toDate();
              this.dateValue = parsedDate;
              this.pickerPeriodDate = parsedDate;
            }
            break;
        }
        if (newValue.length > 14) {
          newValue = newValue.slice(0, -1);
        }
      } else {
        newValue = newValue.slice(0, -1);
      }
      target.value = newValue;
      this.stringValue = newValue;
    },
    handleClickOnDay(date, disabled) {
      if (disabled) {
        return;
      }
      this.stringValue = moment(date).format("DD / MM / YYYY");
      this.closePicker();
    },
    handleBlur(event) {
      if (event.relatedTarget?.classList?.contains?.("picker")) {
        event.target.focus();
      } else {
        this.closePicker();
      }
    },
    togglePicker() {
      if (this.disabled) {
        return;
      }
      if (this.isPickerOpened) {
        this.closePicker();
      } else {
        this.openPicker();
      }
    },
    closePicker() {
      this.isPickerOpened = false;
    },
    openPicker() {
      this.$refs[`dateinput_${this.id}`].focus();

      if (!this.stringValue.length) {
        this.stringValue = this.formatDate(
          moment().format("YYYY-MM-DD"),
          false,
          "easy"
        );
        this.pickerPeriodDate = moment().toDate();
      }
      this.isPickerOpened = true;
    },
    goToNextMonth() {
      this.pickerPeriodDate = this.nextMonthDate;
    },
    goToPreviousMonth() {
      this.pickerPeriodDate = this.previousMonthDate;
    },
  },
  watch: {
    stringValue: {
      immediate: true,
      deep: true,
      handler(newStringValue) {
        this.setNonErrored(this.name);

        if (
          newStringValue === "DD / MM / YYYY" ||
          newStringValue === "MM / DD / YYYY"
        ) {
          this.stringValue = "";
          this.dateValue = moment().toDate();
          return;
        }
        const momentValue =
          this.getLocale === "fr"
            ? moment(newStringValue, "DD / MM / YYYY")
            : moment(newStringValue, "MM / DD / YYYY");
        const hasInvalidFormat =
          !momentValue.isValid() || newStringValue.length !== 14;
        const isBeforeMin = this.min && moment(this.min).isAfter(momentValue);
        const isAfterMax = this.max && moment(this.max).isBefore(momentValue);
        const isEmptyAllowed = !this.required && newStringValue === "";
        if (isEmptyAllowed) {
          return;
        }
        if (hasInvalidFormat || isBeforeMin || isAfterMax) {
          this.setErrored(this.name);
          return;
        }

        const secondsValue = parseInt(
          momentValue.toDate().getTime() / 1000,
          10
        );
        const toEmit = {};
        if (this.name.length) {
          toEmit[this.name] = secondsValue;
        } else {
          toEmit.date = secondsValue;
        }
        this.$emit("change", toEmit);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";
div.input-group-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

span.input-label {
  color: #111111;
  font-family: "Proxima Nova", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

input {
  width: 100%;
  height: 40px;
  border: solid 1px #e6e6e6;
  border-radius: 4px;
  padding: 12px;
  font-weight: 700;
  font-size: 0.875rem;
  color: $blueDarkest;
  box-shadow: none;
  &:focus {
    border: solid 2px $blueDarkest;
    box-shadow: 0px 0px 8px 0px rgba(6, 0, 82, 0.08) !important;
  }
  &.picker-opened {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:hover:not(:focus):not(.errored):not(.disabled) {
    border: solid 1px #e6e6e6;
    box-shadow: 0px 0px 8px 0px rgba(6, 0, 82, 0.08);
  }
  &.errored {
    border: solid 2px #b34442;
  }
}

input[disabled] {
  background-color: #e6e6e6;
  cursor: not-allowed;
}

div.input-container {
  position: relative;
}

span.suffix {
  position: absolute;
  top: 10px;
  left: 14px;
  > .hidden {
    pointer-events: none;
    opacity: 0;
  }
  cursor: text;
  user-select: none;
  pointer-events: none;
  color: $blueDarkest;
  font-weight: 700;
  font-size: 0.875rem;
}

div.picker {
  position: fixed;
  transform: translate(0, 70px);
  z-index: 5;
  color: $blueDarkest;
  background-color: #ffffff;
  border: solid 2px $blueDarkest;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 12px;
  gap: 12px;
  display: flex;
  flex-direction: column;
}

div.month-picker {
  display: flex;
  flex-direction: row;
  color: #000000;
}

div.separator {
  width: 100%;
  height: 1px;
  background-color: #e6e6e6;
}

div.previous-month,
div.next-month {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

span.current-month {
  flex-grow: 1;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-transform: capitalize;
}

div.picker-days-title {
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  > div.picker-day-title {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}

div.picker-grid {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-weight: 500;
  > div.picker-week {
    display: flex;
    flex-direction: row;
    gap: 12px;
    > div.picker-day {
      text-align: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
      border: solid 1px transparent;
      border-radius: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:hover:not(.disabled) {
        border-color: #e6e6e6;
      }
      > span {
        font-size: 0.875rem;
      }
      &.disabled {
        cursor: default;
        color: #e6e6e6;
      }
      &.selected {
        color: #ffffff;
        background-color: $blueDarkest;
      }
    }
  }
}

div.picker-picto-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 14px;
  div.picker-picto {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    &.opened {
      background-color: $blueDarkest;
    }
    > img {
      position: absolute;
      margin: 0;
      padding: 0;
    }
  }
}
</style>
