var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown-container"},[_c('div',{staticClass:"input-group-header"},[_c('span',{staticClass:"input-label"},[_vm._v(_vm._s(_vm.label))]),_c('span',{staticClass:"input-example"},[_vm._v(_vm._s(_vm.example))])]),_c('div',{class:{
      'dropdown-value': true,
      'selected': _vm.hasValue && !_vm.searchable,
      opened: _vm.opened,
    },on:{"click":_vm.toggleDropdownList}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.textFilter),expression:"textFilter"}],ref:"inputRef",staticClass:"dropdown-title-input",class:{ visible: _vm.searchable && _vm.opened },attrs:{"type":"text"},domProps:{"value":(_vm.textFilter)},on:{"click":function($event){$event.stopPropagation();},"input":function($event){if($event.target.composing)return;_vm.textFilter=$event.target.value}}}),(!(_vm.searchable && _vm.opened))?_c('span',{staticClass:"dropdown-title"},[_vm._v(" "+_vm._s(_vm.hasValue ? _vm.selectedLabel : _vm.placeholder)+" ")]):_vm._e(),_c('div',{staticClass:"chevron"},[(_vm.hasValue)?_c('img',{attrs:{"src":require("../assets/img/chevron_down_white.svg")}}):(_vm.opened)?_c('img',{attrs:{"src":require("../assets/img/chevron_up.svg")}}):_c('img',{attrs:{"src":require("../assets/img/chevron_down_grey.svg")}})])]),_c('div',{class:{ 'dropdown-list': true, 'hidden': !_vm.opened }},_vm._l((_vm.filteredElements),function(item,index){return _c('div',{key:`dropdown_item_${index}`,class:{
        'dropdown-list-item': true,
        'selected': _vm.selectedValue === item.value,
      },on:{"click":function($event){return _vm.handleClick(item.value)}}},[_c('span',[_vm._v(" "+_vm._s(item.text)+" ")])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }