<template>
  <ActionBase
    @click="handleClick()"
    :label="$t('validate')"
    :primary="true"
    :disabled="popinHasErrors"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ActionBase from "../../../Popin/Actions/ActionBase.vue";
import db from "../../../../db";
import moment from "moment";
export default {
  components: { ActionBase },
  computed: {
    ...mapGetters(["getCurrentScout"]),
    ...mapGetters("popin", ["getPopinShallowPayload", "popinHasErrors"]),
    ...mapGetters(["getCompos"]),
  },
  methods: {
    ...mapMutations("popin", ["closePopin"]),
    ...mapActions("popin", ["openToast"]),
    async handleClick() {
      const documentToAdd = {
        company: this.getCurrentScout.company || "undefined",
        compo: this.getPopinShallowPayload.compo,
        compoVersion: parseInt(this.getPopinShallowPayload.compoVersion),
        date: moment().unix(),
        label: this.getPopinShallowPayload.squadTitle ?? "TODO",
        players: [],
        playersIn: [],
        playersOut: [],
        scoutId: this.getCurrentScout.id || "undefined",
        total: 0,
        totalIn: 0,
        totalOut: 0,
      };

      await db
        .collection("V3shadowsMercato")
        .add(documentToAdd)
        .then((response) => {
          db.collection("V3shadowsMercato")
            .doc(response.id)
            .update({ id: response.id.toString() });
        });
      this.openToast({
        translationKey: "squad.toasts.createSquad",
        payload: {
          name: this.getPopinShallowPayload.squadTitle,
          disposition: this.getPopinShallowPayload.compoLabel,
        },
        delay: 5000,
      });
      this.closePopin();
    },
  },
};
</script>

<style lang="scss" scoped></style>
