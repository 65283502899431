<template>
  <div class="fields-group row">
    <DropdownSelect
      class="field-dropdown col-12 col-lg-6"
      :placeholder="$t('searchBy.placeholderFindByPlayerName')"
      example="Ex: Mikautadze"
      :elements="getPlayersDatalist"
      :label="$t('name')"
      @change="handleNameChange"
      :searchable="true"
    />
    <DropdownSelect
      class="field-dropdown col-12 col-lg-6"
      :placeholder="$t('searchBy.placeholderFindByClub')"
      :example="`Ex: ${this.getCompanyExample}`"
      :label="$t('team')"
      :elements="getClubsDatalist"
      @change="handleTeamChange"
      :searchable="true"
    />
    <DropdownSelect
      class="field-dropdown col-12 col-lg-6"
      :elements="popinPayload.macroPositions"
      :placeholder="$t('searchBy.placeholderFindByPosition')"
      @change="handleMacroChange"
      :label="$t('playerPosition')"
    />
    <DropdownSelect
      class="field-dropdown col-12 col-lg-6"
      :elements="filteredMicroPositions"
      :placeholder="$t('searchBy.placeholderFindByPositionMicro')"
      @change="handleMicroChange"
      :label="$t('microPosition')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import mixinScouts from "../../../../mixins/mixinScouts";
import DropdownSelect from "../../../DropdownSelect.vue";

export default {
  mixins: [mixinScouts],
  components: {
    DropdownSelect,
  },
  data() {
    return {
      positionsFilter: {
        macro: null,
        micro: null,
      },
      filteredMicroPositions: [],
    };
  },
  methods: {
    updatePayload(value) {
      const newFilter = { ...this.popinPayload.filter, ...value };
      const newResults = this.filterResults(newFilter);
      this.$store.dispatch("popin/setPayload", {
        filter: newFilter,
        results: newResults,
        selected: [],
      });
    },
    filterResults(filters) {
      return this.popinPayload.players.filter((player) => {
        let isOk = true;
        if (filters.playerName) {
          isOk =
            isOk &&
            player.name
              .toLowerCase()
              .includes(filters.playerName.toLowerCase());
        }
        if (filters.team) {
          isOk =
            isOk &&
            player.currentTeam
              .toLowerCase()
              .includes(filters.team.toLowerCase());
        }
        if (filters.macroPosition && !filters.microPosition) {
          isOk =
            isOk &&
            this.popinPayload.microPositions
              .filter((micro) => micro.macro === filters.macroPosition)
              .some(
                (micro) =>
                  micro.value?.toLowerCase() ===
                  player.primaryPosition?.toLowerCase()
              );
        }
        if (filters.microPosition) {
          isOk =
            isOk &&
            player.primaryPosition?.toLowerCase() ===
              filters.microPosition?.toLowerCase();
        }
        return isOk;
      });
    },

    handleMacroChange(newValue) {
      if (!newValue) {
        this.filteredMicroPositions = this.popinPayload.microPositions;
        this.positionsFilter.micro = null;
        this.positionsFilter.macro = null;
        this.updatePayload({
          macroPosition: null,
          microPosition: null,
        });
        return;
      }
      this.filteredMicroPositions = this.popinPayload.microPositions.filter(
        (position) => position.macro === newValue
      );

      this.positionsFilter.macro = newValue;
      this.updatePayload({
        macroPosition: this.positionsFilter.macro,
        microPosition: null,
      });
    },
    handleMicroChange(newValue) {
      this.positionsFilter.micro = newValue;
      if (newValue) {
        this.positionsFilter.macro = this.popinPayload.macroPositions.find(
          (position) => position.value === newValue
        )?.macro;
      }
      this.updatePayload({
        macroPosition: this.positionsFilter.macro,
        microPosition: this.positionsFilter.micro,
      });
    },
    handleNameChange(playerName) {
      this.updatePayload({ playerName });
    },
    handleTeamChange(team) {
      this.updatePayload({ team });
    },
  },
  computed: {
    ...mapActions("matchs", ["bindClubs"]),
    ...mapGetters("matchs", ["getClubs"]),
    ...mapGetters("popin", ["popinPayload"]),
    getPlayersDatalist() {
      return this.popinPayload.players.map(({ name }) => ({
        text: name,
        value: name,
      }));
    },
    getClubsDatalist() {
      return this.getClubs.map((item) => ({ text: item, value: item }));
    },
  },
  mounted() {
    if (!this.getClubs.length) {
      this.$store.dispatch("matchs/bindClubs");
    }
    this.filteredMicroPositions = this.popinPayload.microPositions;
    this.$store.dispatch("popin/setPayload", {
      filter: {},
      results: this.popinPayload.players,
      selected: [],
    });
  },
};
</script>

<style lang="scss" scoped>
.field-dropdown {
  margin: 12px 0;
}
</style>
