<template>
  <div class="navbar-container" v-if="currentScout">
    <b-container>
      <div id="versionNumber" :data-versionDate="currentVersionDay">
        v. {{ currentVersion }}
      </div>
    </b-container>
    <b-container>
      <b-navbar toggleable="lg" type="dark" class="mb-0 px-0">
        <b-navbar-brand to="/">
          <img
            v-if="currentScout.company === 'OL'"
            class="mr-2"
            src="../assets/img/ol-mono.svg"
            alt="Logo OL"
          />
          {{ displayCompanyName }} Scout
        </b-navbar-brand>

        <b-navbar-nav class="menu d-block d-sm-block d-md-block d-lg-none">
          <b-nav-item class="toggle-sidebar">
            <font-awesome-icon v-b-toggle.sidebar-right icon="folder-open" />
          </b-nav-item>
        </b-navbar-nav>

        <b-sidebar
          id="sidebar-right"
          right
          shadow
          no-header
          @change="isVisible()"
          v-model="visible"
        >
          <template v-slot:default="{ hide }">
            <div class="px-3 py-4">
              <span class="closer" @click="hide">
                {{ $t("close") }}
                <font-awesome-icon icon="arrow-right" />
              </span>
              <b-row class="mt-5 nav-mobile">
                <b-col cols="6">
                  <b-nav-item exact to="/">
                    <span class="title">{{ $t("nav.dashboard") }}</span>
                    <font-awesome-icon icon="columns" />
                  </b-nav-item>
                </b-col>
                <b-col cols="6">
                  <b-nav-item to="/games">
                    <span class="title">{{ $t("nav.games") }}</span>
                    <font-awesome-icon icon="futbol" />
                  </b-nav-item>
                </b-col>
                <b-col cols="6">
                  <b-nav-item to="/players">
                    <span class="title">{{ $t("nav.players") }}</span>
                    <font-awesome-icon icon="users" />
                  </b-nav-item>
                </b-col>
                <b-col cols="6">
                  <b-nav-item to="/shadows">
                    <span class="title">{{ $t("nav.shadowTeam") }}</span>
                    <font-awesome-icon icon="ghost" />
                  </b-nav-item>
                </b-col>
                <b-col cols="6" v-if="displayShadowManagement">
                  <b-nav-item to="/squad_management">
                    <span class="title">{{ $t("nav.squadManagement") }}</span>
                    <font-awesome-icon icon="ghost" />
                  </b-nav-item>
                </b-col>
                <b-col cols="6">
                  <b-nav-item @click="logout">
                    <span class="title">{{ $t("nav.logout") }}</span>
                    <font-awesome-icon icon="sign-out-alt" />
                  </b-nav-item>
                </b-col>
              </b-row>
            </div>
          </template>
        </b-sidebar>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item exact to="/">{{ $t("nav.dashboard") }}</b-nav-item>
            <b-nav-item to="/games">{{ $t("nav.games") }}</b-nav-item>
            <b-nav-item to="/players">{{ $t("nav.players") }}</b-nav-item>
            <b-nav-item to="/shadows">{{ $t("nav.shadowTeam") }}</b-nav-item>
            <b-nav-item v-if="displayShadowManagement" to="/squad_management">
              {{ $t("nav.squadManagement") }}
            </b-nav-item>
            <b-nav-item @click="logout">
              {{ $t("nav.logout") }}
              <font-awesome-icon class="ml-2" icon="sign-out-alt" />
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <b-container class="switcherContainer mt-2">
        <b-row align-h="end">
          <div
            v-if="currentScout.allowDoubleAccount"
            md="1"
            class="mt-0 mr-3 pr-4"
          >
            <b-row class="text-center mode-switcher">
              <b-col class="switcher-container" @click="switchUserStatus()">
                <div
                  :class="[
                    displaySuperUserToggle ? 'displaySuperUserToggle' : '',
                    'switcher',
                  ]"
                ></div>
              </b-col>
              <b-col
                @click="switchUserStatus()"
                :class="[
                  displaySuperUserToggle ? '' : 'active',
                  'p-0 toggle-option',
                ]"
              >
                <font-awesome-icon icon="user" />
              </b-col>
              <b-col
                @click="switchUserStatus()"
                :class="[
                  displaySuperUserToggle ? 'active' : '',
                  'p-0 toggle-option',
                ]"
              >
                <font-awesome-icon icon="crown" />
              </b-col>
            </b-row>
          </div>
          <div md="1" class="mt-0 mr-3 pr-4">
            <b-row class="text-center mode-switcher">
              <b-col class="switcher-container" @click="switchLanguage()">
                <div
                  :class="[switchToLeft ? 'switchToLeft' : '', 'switcher']"
                ></div>
              </b-col>
              <b-col
                @click="switchLanguage('fr')"
                :class="[switchToLeft ? '' : 'active', 'p-0 toggle-option']"
              >
                fr
              </b-col>
              <b-col
                @click="switchLanguage('en')"
                :class="[switchToLeft ? 'active' : '', 'p-0 toggle-option']"
              >
                en
              </b-col>
            </b-row>
          </div>
        </b-row>
      </b-container>
      <b-container>
        <b-row v-if="isAvailable" class="flex-column">
          <div class="flex-row logAsContainer">
            <div
              :class="{
                logAs: true,
                current: selectedCompany === company.id,
                dash: getCurrentScout.company === company.id,
              }"
              v-for="(company, companyIndex) in getCompanies"
              :key="companyIndex"
              @click="switchCompany(company.id)"
            >
              {{ company.id }}
            </div>
          </div>
          <div>
            <div
              v-if="
                selectedCompany &&
                getNoGhosts.filter((scout) => {
                  return !scout.ghost;
                }).length
              "
              class="loggedAsContainer"
            >
              Logged as :
            </div>
          </div>
          <div class="flex-row">
            <div
              :class="{ logAs: true, current: currentScoutId === scout.id }"
              v-for="(scout, scoutIndex) in getNoGhosts.filter((scout) => {
                return !scout.ghost;
              })"
              :key="scoutIndex"
              @click="switchUserStatus(scout.id)"
            >
              {{ scout.firstname.charAt(0)
              }}{{ scout.lastname.slice(0, 5).toUpperCase() }}
              {{ scout.isManager ? "(m)" : "(s)" }}=>{{ scout.company }}
            </div>
            <div
              v-if="
                selectedCompany &&
                !getNoGhosts.filter((scout) => {
                  return !scout.ghost;
                }).length
              "
              class="loggedAsContainer"
            >
              {{ $t("no.scoutForTheMoment") }}
            </div>
          </div>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import db from "../db";
import firebase from "../firebase";
import moment from "moment";
export default {
  name: "NavbarComponent",
  data() {
    return {
      selectedCompany: "",
      visible: false,
      currentVersion: "6.0.0",
      currentVersionDay: "260624",
    };
  },
  created() {
    this.isVisible();
  },
  mounted() {},
  computed: {
    ...mapState(["currentScout", "locale", "currentScoutId"]),
    ...mapActions([
      "setCurrentScoutId",
      "bindCurrentScout",
      "bindCurrentScoutGames",
      "bindCurrentScoutPlayers",
    ]),
    ...mapGetters([
      "getScouts",
      "getInitialScoutId",
      "getCompanies",
      "getCurrentScout",
    ]),
    isAvailable() {
      return this.getScoutById(this.getInitialScoutId)?.canLogAs || false;
    },
    displayCompanyName() {
      if (this.currentScout.company === "Molenbeek") {
        return "RWDM";
      } else {
        return this.currentScout.company;
      }
    },
    displayShadowManagement() {
      return (
        this.getCompanies?.length &&
        this.currentScout.isManager &&
        this.currentScout.company !== this.getCompanies[0].id
      );
    },
    getNoGhosts() {
      return this.lodash.orderBy(
        this.getScouts.filter((scout) => {
          return !scout.ghost && scout.company === this.selectedCompany;
        }),
        "lastname",
        "asc"
      );
    },
    switchToLeft: function () {
      return this.locale === "en";
    },
    displaySuperUserToggle: function () {
      return this.currentScout.isManager;
    },
  },
  methods: {
    getScoutById(scoutId) {
      return this.lodash.filter(this.getScouts, { id: scoutId })[0];
    },
    setScoutsForCurrentUser() {
      if (this.getCompanies.length) {
        this.$store.dispatch("setScoutsForCurrentUser");
      }
    },
    setManagersForCurrentUser() {
      if (this.getCompanies.length) {
        this.$store.dispatch("setManagersForCurrentUser");
      }
    },
    checkPreferredScoutLanguage() {
      if (
        this.currentScout.language &&
        this.locale &&
        this.currentScout.language != this.locale
      ) {
        this.switchLanguage();
      }
    },
    checkReload() {
      if (
        this.currentScout?.id &&
        (!this.currentScout.lastConnexion ||
          moment(this.currentScout.lastConnexion, "DDMMYY").isBefore(
            moment(this.currentVersionDay, "DDMMYY")
          ))
      ) {
        alert(`${this.$t("youreGoingToBeDisconnected")}`);
        this.logout();
      }
    },
    isVisible() {
      const visible = this.visible;
      if (visible == true) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("login");
        });
    },
    switchLanguage(value) {
      let newLanguage = value;
      if (!value) {
        if (this.locale == "en") {
          newLanguage = "fr";
        } else {
          newLanguage = "en";
        }
      } else {
        if (value == "en") {
          newLanguage = "fr";
        } else {
          newLanguage = "en";
        }
      }
      this.$store.dispatch("setLanguage", newLanguage);

      db.collection("V3scouts")
        .doc(this.currentScoutId)
        .update({ language: newLanguage });
    },
    switchCompany(companyId) {
      if (this.selectedCompany === companyId) {
        this.selectedCompany = "";
      } else {
        this.selectedCompany = companyId;
      }
    },
    switchUserStatus(scoutId) {
      let navigationScoutId = null;
      if (scoutId) {
        navigationScoutId = scoutId;
      } else {
        navigationScoutId = this.currentScoutId;
        if (this.getCurrentScout.allowDoubleAccount) {
          navigationScoutId = this.getCurrentScout.allowDoubleAccount;
        }
      }

      this.$store.dispatch("setCurrentScoutId", { uid: navigationScoutId });
      this.$store.dispatch("bindCurrentScout");
      this.$store.dispatch("bindCurrentScoutGames");
      this.$store.dispatch("bindCurrentScoutPlayers");
      this.setManagersForCurrentUser();
      this.setScoutsForCurrentUser();
    },
  },
  watch: {
    getCompanies: {
      handler(newCompanies) {
        if (newCompanies.length) {
          if (this.getCompanies.length) {
            this.setManagersForCurrentUser();
            this.setScoutsForCurrentUser();
          }
        }
      },
    },
    currentScout: {
      immediate: true,
      handler(currentScout, oldScout) {
        if (
          currentScout?.id &&
          (!oldScout || currentScout?.id !== oldScout?.id)
        ) {
          this.checkPreferredScoutLanguage();
          this.setManagersForCurrentUser();
          this.setScoutsForCurrentUser();

          if (currentScout.language) {
            this.checkReload();
          }
        }
      },
    },
    $route() {
      if (this.getCurrentScout.language) {
        this.checkReload();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/variables.scss";

.loggedAsContainer {
  color: white;
  display: block;
  width: 100%;
}

.logAsContainer {
  display: flex;
  flex-wrap: wrap;
  user-select: none;
}

.logAs {
  border: 1px solid black;
  background: white;
  margin: 5px 3px;
  border-radius: 50px;
  padding: 5px 15px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.logAs.dash:before {
  background: #da5856;
  content: "";
  width: 15px;
  height: 2px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  justify-content: center;
}

.logAs.current {
  background: #da5856;
  color: white;
}

.logAs.dash {
  padding-right: 15px;
}

#versionNumber {
  display: flex;
  justify-content: flex-end;
  color: #857cff;
}

.navbar-container {
  background-color: #0b00a3;
  .switcherContainer {
    margin-top: -20px;
    padding-bottom: 20px;
  }
  .navbar {
    .navbar-brand {
      img {
        height: 3.5rem;

        @media screen and (max-width: $smartphone) {
          height: 2.5rem;
        }
      }

      font-family: "Rubik", sans-serif;
      text-transform: uppercase;
      font-size: 1.3rem;
    }

    .nav-item {
      .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        color: $white;
        font-weight: 600;
        font-weight: 700;

        &.router-link-active {
          text-decoration: underline;
        }
      }
    }

    .menu {
      color: $white;
      border: none;
      font-size: 1.5rem;

      svg:focus {
        outline: none;
      }
    }

    #sidebar-right {
      background-color: $white;

      .b-sidebar-body {
        .closer {
          position: absolute;
          left: 1rem;
          top: 0.75rem;
          font-size: 1.25rem;
          font-weight: 700;
          color: $pink;
        }

        .nav-mobile {
          .nav-item {
            list-style: none;

            .nav-link {
              padding: 1rem;
              background: $blueDark;
              border-radius: 0.75rem;
              box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
                0 6.7px 5.3px rgba(0, 0, 0, 0.028),
                0 12.5px 10px rgba(0, 0, 0, 0.035),
                0 22.3px 17.9px rgba(0, 0, 0, 0.042),
                0 41.8px 33.4px rgba(0, 0, 0, 0.05),
                0 100px 80px rgba(0, 0, 0, 0.07);
              margin-bottom: 2rem;
              height: 8rem;

              .title {
                display: block;
              }

              svg {
                font-size: 2rem;
                position: absolute;
                right: 2rem;
                bottom: 3rem;
              }

              &.router-link-active {
                background-color: $pink;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $desktop) {
  .logAsContainer {
    display: none;
  }
}
</style>
