<template>
  <div v-if="game" class="game">
    <SidebarAddPlayer
      v-if="getCurrentScout && !getCurrentScout?.isManager"
      :homeTeam="homeTeam && homeTeam['Team']"
      :awayTeam="awayTeam && awayTeam['Team']"
      :prop="sidebarAddPlayer"
      @emitPlayerSuccessfullyAdded="loadCurrentGamePlayers"
    />
    <b-row id="gameHeaderContainer">
      <b-col id="gameHeaderContent">
        <b-row
          class="text-center"
          v-if="
            apiMatchingGameInfos &&
            (apiMatchingGameInfos.competition_name || game.competition)
          "
        >
          <b-col>
            {{ apiMatchingGameInfos.competition_name || game.competition }}
          </b-col>
        </b-row>
        <b-row class="text-center">
          <b-col>
            {{ unixToDate(game.date) }}
          </b-col>
        </b-row>
        <b-row id="gameHeaderBottomContainer" class="text-center">
          <b-col md="4" xs="12" id="homeTeamContainer">
            <span>
              {{ game.teamHome }}
            </span>
          </b-col>
          <b-col
            v-if="score.length"
            md="4"
            xs="12"
            id="gameHeaderScoresContainer"
          >
            <b-row>
              <b-col class="gameHeaderScoreContainer" id="gameHeaderHomeScore">
                {{ score[0] }}
              </b-col>
              <b-col>-</b-col>
              <b-col class="gameHeaderScoreContainer" id="gameHeaderHomeScore">
                {{ score[1] }}
              </b-col>
            </b-row>
          </b-col>
          <b-col v-else md="4" xs="12" id="gameHeaderHoursContainer">
            <div>
              {{ unixToHours(game.date) }}
            </div>
          </b-col>
          <b-col md="4" xs="12" id="awayTeamContainer">
            <span>
              {{ game.teamAway }}
            </span>
          </b-col>
        </b-row>
        <b-row id="scorersContainer">
          <b-col sm="12" md="6">
            <div
              v-for="(homeScorer, index) in orderedHomeTeamEvents"
              :key="index"
              class="homeScorerContainer"
            >
              {{ homeScorer.playerName }}
              <span v-if="homeScorer.action == 'Own Goal Against'">
                (CSC)
              </span>
              ({{ homeScorer.minute }}")
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div
              v-for="(awayScorer, index) in orderedAwayTeamEvents"
              :key="index"
              class="awayScorerContainer"
            >
              ({{ awayScorer.minute }}") {{ awayScorer.playerName }}
            </div>
          </b-col>
        </b-row>
        <b-row id="scorerSmartphoneLayoutContainer" class="text-center">
          <b-col>
            <div
              v-for="(scorer, index) in matchEvents"
              :key="index"
              :class="{
                scorerContainer: true,
                homeScorerContainer: scorer.team == game.teamHome,
                awayScorerContainer: scorer.team == game.teamAway,
              }"
            >
              {{ scorer.playerName }} ({{ scorer.minute }}")
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div id="gameContentContainer">
      <b-row id="gameContentContent">
        <b-col
          v-if="game.score && game.score.length"
          id="pitchPlayersContainer"
        >
          <PitchPlayers
            v-if="gamePlayers.length"
            :teamHome="game.teamHome"
            :teamAway="game.teamAway"
            :players="gamePlayers"
            :substitutionsEvents="substitutionsEvents"
            :cardEvents="cardEvents"
            :goals="goals"
            :gameNotes="currentScopeGameNotes"
            :game="game"
          />
        </b-col>
        <b-col v-else id="listPlayersContainer">
          <GameList
            :changeSelectedTeam="changeSelectedTeam"
            :commentedPlayers="commentedPlayers"
            :currentScopeGameNotes="currentScopeGameNotes"
            :orderedMatchingPlayers="orderedMatchingPlayers"
            :game="game"
            :sidebarAddPlayer="sidebarAddPlayer"
            :otherPlayers="otherPlayers"
            :playersFilter="playersFilter"
            :scrollTo="scrollTo"
            @emitFilterValue="setFilterValue"
            @emitOrderPlayers="orderPlayers"
            @emitDisplayAddPlayer="setSidebarAddPlayerDisplayToTrue"
          />
        </b-col>
        <b-col>
          <GamePlayerObservation
            :athleticalPlayerNote="athleticalPlayerNote"
            :game="game"
            :gamePlayerNote="gamePlayerNote"
            :isEditable="isEditable"
            :mentalPlayerNote="mentalPlayerNote"
            :selectedPlayerABCDNote="selectedPlayerABCDNote"
            :tacticalPlayerNote="tacticalPlayerNote"
            :technicalPlayerNote="technicalPlayerNote"
            @setABCDScore="setSelectedPlayerABCDNote"
            @submitGamePlayerNote="submitGamePlayerNote"
          />
          <GameObservation
            :game="game"
            :isEditable="isEditable"
            :gameNoteCategory="gameNoteCategory"
            @updateGameNoteHandler="updateGameNoteHandler"
            @setGameNoteCategory="setGameNoteCategory"
          />
        </b-col>
      </b-row>
    </div>
    <Notification :notification="notification" />
  </div>
  <div v-else>
    <div class="text-center mt-4">
      {{ $t("error.gameNotFound") }}
      <router-link :to="{ name: 'GamesView' }">{{
        $t("gamesList")
      }}</router-link>
    </div>
  </div>
</template>

<script>
import GamePlayerObservation from "@/components/GameView/GamePlayerObservation.vue";
import GameObservation from "@/components/GameView/GameObservation.vue";
import GameList from "@/components/GameView/GameList.vue";
import SidebarAddPlayer from "@/components/SidebarAddPlayer";
import PitchPlayers from "@/components/PitchPlayers";
import Notification from "@/components/Notification";
import moment from "moment";
import axios from "axios";
import db from "../db";
import { mapGetters } from "vuex";
import mixinScouts from "../mixins/mixinScouts";
import mixinPlayers from "../mixins/mixinPlayers";

export default {
  name: "GameView",
  components: {
    SidebarAddPlayer,
    PitchPlayers,
    Notification,
    GameList,
    GameObservation,
    GamePlayerObservation,
  },
  mixins: [mixinScouts, mixinPlayers],
  data() {
    return {
      playersInShadows: [],
      commentedPlayers: [],
      otherPlayers: [],
      awayPlayers: [],
      homePlayers: [],
      homeTeamEvents: [],
      awayTeamEvents: [],
      matchEvents: [],
      gamePlayerNoteId: null,
      gamePlayerNote: "",
      selectedPlayerABCDNote: "",
      tacticalPlayerNote: "",
      mentalPlayerNote: "",
      athleticalPlayerNote: "",
      technicalPlayerNote: "",
      game: {},
      matches: [],
      playersFilter: "hasNote",
      selectedPlayerNotes: [],
      homeTeam: {},
      awayTeam: {},
      gameEvents: [],
      substitutionsEvents: [],
      cardEvents: [],
      goals: [],
      apiMatchingGameInfos: {},
      matchingGameLineup: [],
      gameNoteCategory: "stadium",
      score: [],
      sidebarAddPlayer: {
        display: false,
        playerName: "",
        playerId: null,
        jerseyNumber: null,
        date: null,
        selectedTeam: "home",
      },
      notification: {
        display: false,
        type: "",
        details: "",
      },
      currentScoutGameNotes: [],
    };
  },
  firestore() {
    return {
      game: db.collection("V3games").doc(this.$route.params.id.toString()),
      gamePlayers: db
        .collection("V3games")
        .doc(this.$route.params.id.toString())
        .collection("players"),
    };
  },
  mounted() {
    this.manageLineup();
    this.manageEvents();
    this.loadTeams();
    this.markAsSeen();
    this.orderPlayers();
    if (this.getCurrentScout?.id) {
      this.loadCurrentScoutGameNotes();
    }
  },
  computed: {
    ...mapGetters([
      "getCurrentScout",
      "getScouts",
      "getLocale",
      "getGamesPosition",
      "getCompanies",
      "getSelectedPlayer",
      "getNotes",
      "getCurrentScopeShadowsPlayers",
    ]),
    currentScopeGameNotes() {
      if (this.getCurrentScout?.isManager) {
        return this.getNotes.filter((gameNote) => {
          return gameNote.gameId === this.$route.params.id.toString();
        });
      }
      return this.currentScoutGameNotes;
    },
    isEditable() {
      return this.game.date > moment().subtract(2, "months").unix();
    },
    orderedHomeTeamEvents() {
      return this.lodash.uniqBy(this.homeTeamEvents, "id");
    },
    orderedAwayTeamEvents() {
      return this.lodash.uniqBy(this.awayTeamEvents, "id");
    },
    orderedMatchingPlayers() {
      const self = this;
      if (self.gamePlayers?.length) {
        let gamePlayersToReturn = [];
        const dupplicatedFreePlayers = self.lodash.uniqBy(
          [
            ...new Set([
              ...self.homePlayers,
              ...self.awayPlayers,
              ...self.gamePlayers,
            ]),
          ],
          "id"
        );

        dupplicatedFreePlayers.forEach((player, playerIndex) => {
          dupplicatedFreePlayers[playerIndex].hasNote = self.hasNote(
            player.id,
            self.currentScopeGameNotes
          );
        });
        if (self.game.score?.length) {
          if (self.playersFilter === "hasNote") {
            gamePlayersToReturn = self.lodash.orderBy(
              dupplicatedFreePlayers,
              self.playersFilter,
              "desc"
            );
          } else {
            gamePlayersToReturn = self.lodash.orderBy(
              dupplicatedFreePlayers,
              self.playersFilter,
              "asc"
            );
          }
        } else {
          const filteredPlayers = self.lodash.filter(
            dupplicatedFreePlayers,
            function (player) {
              if (
                (self.sidebarAddPlayer.selectedTeam === "home" &&
                  player.currentTeam === self.homeTeam?.Team &&
                  player.team === "home") ||
                (self.sidebarAddPlayer.selectedTeam === "away" &&
                  player.currentTeam === self.awayTeam?.Team &&
                  player.team === "away")
              ) {
                return player;
              }
              if (self.sidebarAddPlayer.selectedTeam == "home") {
                return (
                  player.currentTeam === self.game.teamHome ||
                  self.sidebarAddPlayer.selectedTeam === player.team
                );
              } else {
                return (
                  player.currentTeam === self.game.teamAway ||
                  self.sidebarAddPlayer.selectedTeam === player.team
                );
              }
            }
          );
          if (self.playersFilter === "hasNote") {
            gamePlayersToReturn = self.lodash.orderBy(
              filteredPlayers,
              self.playersFilter,
              "desc"
            );
          } else {
            gamePlayersToReturn = self.lodash.orderBy(
              filteredPlayers,
              self.playersFilter,
              "asc"
            );
          }
        }
        return gamePlayersToReturn;
      } else if (
        self.matchingGameLineup[0] &&
        self.matchingGameLineup[0].lineup
      ) {
        let homeLineup = [];
        let awayLineup = [];
        let matchingLineupPlayers = [];
        if (self.matchingGameLineup[0].team_name === self.game.teamHome) {
          homeLineup = self.matchingGameLineup[0].lineup;
          awayLineup = self.matchingGameLineup[1].lineup;
        } else {
          homeLineup = self.matchingGameLineup[1].lineup;
          awayLineup = self.matchingGameLineup[0].lineup;
        }
        if (self.sidebarAddPlayer.selectedTeam === "home") {
          const homeTeamPlayers = homeLineup.map((player) => {
            const toReturn = {
              id: player.player_id,
              jerseyNumber: player.jersey_number,
              name: player.player_name,
            };
            if (player.player_nickname) {
              toReturn.nickname = player.player_nickname;
            }
            if (
              player.positions &&
              player.positions[0] &&
              player.positions[0].position
            ) {
              toReturn.gamePositionLabel = player.positions[0].position;
            }
            toReturn.hasNote = self.hasNote(
              player.player_id.toString(),
              self.currentScopeGameNotes
            );
            return toReturn;
          });
          const dupplicatedFreePlayers = self.lodash.uniqBy(
            homeTeamPlayers,
            function (player) {
              return player.id.toString();
            }
          );
          matchingLineupPlayers =
            self.playersFilter === "hasNote"
              ? self.lodash.orderBy(
                  dupplicatedFreePlayers,
                  self.playersFilter,
                  "desc"
                )
              : self.lodash.orderBy(
                  dupplicatedFreePlayers,
                  self.playersFilter,
                  "asc"
                );
        } else if (self.sidebarAddPlayer.selectedTeam === "away") {
          const awayTeamPlayers = awayLineup.map((player) => {
            const toReturn = {
              id: player.player_id,
              jerseyNumber: player.jersey_number,
              name: player.player_name,
            };
            if (player.player_nickname) {
              toReturn.nickname = player.player_nickname;
            }
            if (
              player.positions &&
              player.positions[0] &&
              player.positions[0].position
            ) {
              toReturn.gamePositionLabel =
                player.positions[0].position.charAt(0).toLowerCase() +
                player.positions[0].position.slice(1);
            }
            toReturn.hasNote = self.hasNote(
              player.player_id.toString(),
              self.currentScopeGameNotes
            );
            return toReturn;
          });
          const dupplicatedFreePlayers = self.lodash.uniqBy(
            awayTeamPlayers,
            function (player) {
              return player.id.toString();
            }
          );
          matchingLineupPlayers =
            self.playersFilter === "hasNote"
              ? self.lodash.orderBy(
                  dupplicatedFreePlayers,
                  self.playersFilter,
                  "desc"
                )
              : self.lodash.orderBy(
                  dupplicatedFreePlayers,
                  self.playersFilter,
                  "asc"
                );
        } else {
          matchingLineupPlayers = [];
        }
        return matchingLineupPlayers;
      }
      let orderedMatchingPlayers = [];
      if (self.sidebarAddPlayer.selectedTeam == "home") {
        if (self.playersFilter === "hasNote") {
          orderedMatchingPlayers = self.lodash.orderBy(
            self.homePlayers,
            self.playersFilter,
            "desc"
          );
        } else {
          orderedMatchingPlayers = self.lodash.orderBy(
            self.homePlayers,
            self.playersFilter,
            "asc"
          );
        }
      } else {
        if (self.playersFilter === "hasNote") {
          orderedMatchingPlayers = self.lodash.orderBy(
            self.awayPlayers,
            self.playersFilter,
            "desc"
          );
        } else {
          orderedMatchingPlayers = self.lodash.orderBy(
            self.awayPlayers,
            self.playersFilter,
            "asc"
          );
        }
      }
      return self.lodash.uniqBy(orderedMatchingPlayers, "id");
    },
  },
  methods: {
    setGameNoteCategory(newGameNoteCategory) {
      if (newGameNoteCategory === "data.dot") {
        this.gameNoteCategory = this.$t("data.dot");
      } else {
        this.gameNoteCategory = newGameNoteCategory;
      }
    },
    setSidebarAddPlayerDisplayToTrue() {
      this.sidebarAddPlayer.display = true;
    },
    setFilterValue(filterValue) {
      this.playersFilter = filterValue;
    },
    async markAsSeen() {
      if (!this.$route.params.id || !this.getCurrentScout?.id) {
        return;
      }
      if (
        this.getCurrentScout.isManager &&
        this.game?.id &&
        ((this.getCompanies.length &&
          this.getCurrentScout.company === this.getCompanies[0].id &&
          this.game.playersLength) ||
          this.game[`${this.getCurrentScout.company}PlayersLength`])
      ) {
        const seenBy = {};
        seenBy[`seenBy${this.getCurrentScout.id.toString()}`] = true;
        await db
          .collection("V3games")
          .doc(this.$route.params.id.toString())
          .set(seenBy, { merge: true });
      }
    },

    async loadCurrentScoutGameNotes() {
      const self = this;
      const toReturn = [];
      const notesRef = db
        .collection("V3notes")
        .where("gameId", "==", self.$route.params.id.toString());

      if (self.getCurrentScout?.isManager) {
        await notesRef.get().then((notesSnapshot) => {
          for (let i = 0; i < notesSnapshot.size; i++) {
            toReturn.push(notesSnapshot.docs[i].data());
          }
        });
      } else {
        await notesRef
          .where("authorId", "==", self.getCurrentScout.id.toString())
          .get()
          .then((notesSnapshot) => {
            for (let i = 0; i < notesSnapshot.size; i++) {
              toReturn.push(notesSnapshot.docs[i].data());
            }
          });
      }
      self.currentScoutGameNotes = toReturn;
    },
    setSelectedPlayerABCDNote(letter) {
      if (
        !this.selectedPlayerNotes[0] ||
        this.getCurrentScout?.id === this.selectedPlayerNotes[0]?.authorId
      ) {
        this.selectedPlayerABCDNote = letter;
      }
    },
    async loadCurrentGamePlayers() {
      const self = this;
      self.awayPlayers = [];
      self.homePlayers = [];
      await db
        .collection("V3players")
        .where("currentTeam", "==", self.game.teamAway)
        .get()
        .then((playersSnapshot) => {
          playersSnapshot.docs.forEach((playerDoc) => {
            const playerData = playerDoc.data();
            playerData.hasNote = self.hasNote(
              playerData.id,
              self.currentScopeGameNotes
            );
            self.awayPlayers.push(playerData);
          });
        });
      await db
        .collection("V3players")
        .where("currentTeam", "==", self.game.teamHome)
        .get()
        .then((playersSnapshot) => {
          playersSnapshot.docs.forEach((playerDoc) => {
            const playerData = playerDoc.data();
            playerData.hasNote = self.hasNote(
              playerData.id,
              self.currentScopeGameNotes
            );
            self.homePlayers.push(playerData);
          });
        });
    },
    setDefaultPlayerSelected() {
      const self = this;
      if (!self.getSelectedPlayer.id && self.orderedMatchingPlayers?.length) {
        const goalkeeperId = self.lodash.filter(self.getGamesPosition, {
          abv: "GK",
        })[0].id;

        const matchingPlayer = self.lodash.filter(
          self.orderedMatchingPlayers,
          function (player) {
            return (
              player.gamePositionId == goalkeeperId &&
              player.team == self.game.teamHome
            );
          }
        );
        if (
          matchingPlayer.length &&
          matchingPlayer[0].id &&
          !self.getSelectedPlayer.id
        ) {
          self.$store.dispatch("setSelectedPlayer", matchingPlayer[0]);
        }
      }
    },
    manageLineup() {
      const self = this;
      const newMatchingGameLineup = [];
      this.gamePlayers.forEach((gamePlayer) => {
        const player = gamePlayer;
        const currentShadowPlayersIds = self.getCurrentScopeShadowsPlayers.map(
          (player) => {
            return player.id;
          }
        );
        player.isInShadow = currentShadowPlayersIds.includes(gamePlayer.id);
        newMatchingGameLineup.push(player);
      });
      this.matchingGameLineup = newMatchingGameLineup;
      if (this.orderedMatchingPlayers[0] && !this.getSelectedPlayer?.id) {
        self.$store.dispatch(
          "setSelectedPlayer",
          this.orderedMatchingPlayers[0]
        );
      }
    },
    scrollTo() {
      var element = this.$refs["gamePlayersContainer"];
      if (!element) {
        return;
      }
      var offsets = element.getBoundingClientRect();

      window.scrollTo({
        top: window.top.scrollY + offsets.top - 20,
        behavior: "smooth",
      });
    },
    toggleNotification(notificationType, notificationDetails) {
      this.notification = notificationDetails.message
        ? {
            display: true,
            type: notificationType,
            details: this.$t(
              notificationDetails.message,
              notificationDetails.params
            ),
          }
        : {
            display: true,
            type: notificationType,
            details: this.$t(notificationDetails),
          };
      setTimeout(() => {
        this.notification.display = false;
      }, 6000);
    },
    async submitGamePlayerNote(note) {
      console.log("in submit player note", note.globalComment, "))");
      const now = moment().unix();
      const noteId = this.gamePlayerNoteId
        ? parseInt(this.gamePlayerNoteId)
        : parseInt(now);

      const self = this;
      console.log(
        note.globalComment,
        self.selectedPlayerABCDNote,
        self.getSelectedPlayer
      );
      if (
        note.globalComment.length &&
        self.selectedPlayerABCDNote &&
        self.getSelectedPlayer.id
      ) {
        const batch = db.batch();
        const scoutPlayerNoteRef = db
          .collection("V3scouts")
          .doc(self.getCurrentScout.id.toString())
          .collection("players")
          .doc(self.getSelectedPlayer.id.toString())
          .collection("notes")
          .doc(noteId.toString());

        batch.set(scoutPlayerNoteRef, {
          letterNote: note.selectedPlayerABCDNote.toString(),
          tacticalNote: note.tacticalPlayerNote.toString(),
          mentalNote: note.mentalPlayerNote.toString(),
          athleticalNote: note.athleticalPlayerNote.toString(),
          technicalNote: note.technicalPlayerNote.toString(),
          comment: note.globalComment.toString(),
          gameId: self.$route.params.id.toString(),
          id: noteId.toString(),
        });

        const noteRef = db.collection("V3notes").doc(noteId.toString());
        batch.set(noteRef, {
          athleticalNote: note.athleticalPlayerNote.toString(),
          authorCompany: self.getCurrentScout.company.toString(),
          authorId: self.getCurrentScout.id.toString(),
          comment: note.globalComment.toString(),
          gameId: self.$route.params.id.toString(),
          id: parseInt(noteId),
          isGlobal: false,
          letterNote: note.selectedPlayerABCDNote.toString(),
          mentalNote: note.mentalPlayerNote.toString(),
          playerId: self.getSelectedPlayer.id.toString(),
          tacticalNote: note.tacticalPlayerNote.toString(),
          technicalNote: note.technicalPlayerNote.toString(),
        });

        const scoutGamePlayerNoteRef = db
          .collection("V3scouts")
          .doc(self.getCurrentScout.id.toString())
          .collection("games")
          .doc(self.$route.params.id.toString())
          .collection("players")
          .doc(self.getSelectedPlayer.id.toString())
          .collection("notes")
          .doc(noteId.toString());
        batch.set(scoutGamePlayerNoteRef, {
          letterNote: note.selectedPlayerABCDNote.toString(),
          tacticalNote: note.tacticalPlayerNote.toString(),
          mentalNote: note.mentalPlayerNote.toString(),
          athleticalNote: note.athleticalPlayerNote.toString(),
          technicalNote: note.technicalPlayerNote.toString(),
          comment: note.globalComment.toString(),
          id: noteId.toString(),
        });

        const currentGameInfos = { ...self.game };
        Object.keys(currentGameInfos).forEach((gameAttribute) => {
          if (gameAttribute.slice(0, 6) === "seenBy") {
            currentGameInfos[gameAttribute] = false;
          }
        });

        if (!currentGameInfos[`${self.getCurrentScout.company}Players`]) {
          currentGameInfos[`${self.getCurrentScout.company}Players`] = [];
        } else {
          const filteredPlayers = currentGameInfos[
            `${self.getCurrentScout.company}Players`
          ].filter((player) => {
            return !player.id;
          });

          const uniqPlayers = self.lodash.uniqBy(
            currentGameInfos[`${self.getCurrentScout.company}Players`],
            "id"
          );
          currentGameInfos[`${self.getCurrentScout.company}Players`] = [
            ...filteredPlayers,
            ...uniqPlayers,
          ];
        }
        const playerCompanyIndex = self.lodash.findIndex(
          currentGameInfos[`${self.getCurrentScout.company}Players`],
          {
            id: self.getSelectedPlayer.id,
          }
        );
        if (playerCompanyIndex === -1) {
          currentGameInfos[`${self.getCurrentScout.company}Players`].push({
            id: self.getSelectedPlayer.id,
            isInBucket: self.getSelectedPlayer.isInBucket || false,
            name: self.getSelectedPlayer.name,
          });
        } else {
          currentGameInfos[`${self.getCurrentScout.company}Players`][
            playerCompanyIndex
          ].id = self.getSelectedPlayer.id.toString();
        }

        if (!currentGameInfos[`${self.getCurrentScout.id}Players`]) {
          currentGameInfos[`${self.getCurrentScout.id}Players`] = [];
        } else {
          const filteredPlayers = currentGameInfos[
            `${self.getCurrentScout.id}Players`
          ].filter((player) => {
            return !player.id;
          });

          const uniqPlayers = self.lodash.uniqBy(
            currentGameInfos[`${self.getCurrentScout.id}Players`],
            "id"
          );
          currentGameInfos[`${self.getCurrentScout.id}Players`] = [
            ...filteredPlayers,
            ...uniqPlayers,
          ];
        }
        const playerScoutIndex = self.lodash.findIndex(
          currentGameInfos[`${self.getCurrentScout.id}Players`],
          {
            id: self.getSelectedPlayer.id,
          }
        );
        if (playerScoutIndex === -1) {
          currentGameInfos[`${self.getCurrentScout.id}Players`].push({
            id: self.getSelectedPlayer.id,
            isInBucket: self.getSelectedPlayer.isInBucket || false,
            name: self.getSelectedPlayer.name,
          });
        } else {
          currentGameInfos[`${self.getCurrentScout.id}Players`][
            playerScoutIndex
          ].id = self.getSelectedPlayer.id.toString();
        }

        if (!currentGameInfos.players) {
          currentGameInfos.players = [];
        } else {
          const filteredPlayers = currentGameInfos[
            `${self.getCurrentScout.id}Players`
          ].filter((player) => {
            return !player.id;
          });

          const uniqPlayers = self.lodash.uniqBy(
            currentGameInfos.players,
            "id"
          );
          currentGameInfos.players = [...filteredPlayers, ...uniqPlayers];
        }
        const playersIndex = self.lodash.findIndex(currentGameInfos.players, {
          id: self.getSelectedPlayer.id,
        });
        if (playersIndex === -1) {
          currentGameInfos.players.push({
            id: self.getSelectedPlayer.id,
            isInBucket: self.getSelectedPlayer.isInBucket || false,
            name: self.getSelectedPlayer.name,
          });
        } else {
          currentGameInfos.players[playersIndex].id =
            self.getSelectedPlayer.id.toString();
        }
        currentGameInfos[`${self.getCurrentScout.company}Players`] =
          self.lodash.uniqBy(
            currentGameInfos[`${self.getCurrentScout.company}Players`],
            "id"
          );
        currentGameInfos[`${self.getCurrentScout.id}Players`] =
          self.lodash.uniqBy(
            currentGameInfos[`${self.getCurrentScout.id}Players`],
            "id"
          );

        currentGameInfos.playersLength = currentGameInfos.players.length;
        currentGameInfos[`${self.getCurrentScout.company}PlayersLength`] =
          currentGameInfos[`${self.getCurrentScout.company}Players`].length;
        currentGameInfos[`${self.getCurrentScout.id}PlayersLength`] =
          currentGameInfos[`${self.getCurrentScout.id}Players`].length;
        const gameRef = db
          .collection("V3games")
          .doc(self.$route.params.id.toString());
        batch.set(gameRef, currentGameInfos, { merge: true });

        const gameNoteRef = db
          .collection("V3games")
          .doc(self.$route.params.id.toString())
          .collection("notes")
          .doc(noteId.toString());
        batch.set(gameNoteRef, {
          authorId: self.getCurrentScout.id.toString(),
          authorCompany: self.getCurrentScout.company.toString(),
          comment: note.globalComment.toString(),
          id: noteId.toString(),
          playerId: self.getSelectedPlayer.id.toString(),
        });
        const playerNoteRef = db
          .collection("V3players")
          .doc(self.getSelectedPlayer.id.toString())
          .collection("notes")
          .doc(noteId.toString());
        batch.set(playerNoteRef, {
          authorId: self.getCurrentScout.id.toString(),
          letterNote: note.selectedPlayerABCDNote.toString(),
          tacticalNote: note.tacticalPlayerNote.toString(),
          mentalNote: note.mentalPlayerNote.toString(),
          athleticalNote: note.athleticalPlayerNote.toString(),
          technicalNote: note.technicalPlayerNote.toString(),
          comment: note.globalComment.toString(),
          gameId: self.$route.params.id.toString(),
          id: parseInt(noteId),
        });
        const playerData = await db
          .collection("V3players")
          .doc(self.getSelectedPlayer.id.toString())
          .get()
          .then((playerDoc) => {
            return playerDoc.data();
          });
        if (!playerData.id) {
          playerData.id = self.getSelectedPlayer.id;
        }
        if (!playerData.name) {
          playerData.name = self.getSelectedPlayer.name;
        }
        if (!playerData.currentTeam) {
          playerData.currentTeam = self.getSelectedPlayer.gamePositionLabel;
        }
        const authorCommentedGamesIds =
          playerData[`${self.getCurrentScout.id}CommentedGamesIds`] || [];
        const companyCommentedGamesIds =
          playerData[`${self.getCurrentScout.company}CommentedGamesIds`] || [];

        if (!playerData.commentedGamesId?.length) {
          playerData.commentedGamesId = [];
        }
        playerData.commentedGamesId.push(self.$route.params.id.toString());
        playerData.commentedGamesIdsLength = [
          ...new Set([...playerData.commentedGamesId]),
        ].length;
        playerData.associatedGamesIds = [
          ...new Set([...playerData.commentedGamesId]),
        ];
        authorCommentedGamesIds.push(self.$route.params.id.toString());
        companyCommentedGamesIds.push(self.$route.params.id.toString());

        playerData[`${self.getCurrentScout.id}CommentedGamesIds`] =
          authorCommentedGamesIds;
        playerData[`${self.getCurrentScout.id}AssociatedGamesIds`] = [
          ...new Set([...authorCommentedGamesIds]),
        ];
        playerData[`${self.getCurrentScout.id}CommentedGamesIdsLength`] = [
          ...new Set([...authorCommentedGamesIds]),
        ].length;

        playerData[`${self.getCurrentScout.company}CommentedGamesIds`] =
          companyCommentedGamesIds;
        playerData[`${self.getCurrentScout.company}CommentedGamesIdsLength`] = [
          ...new Set([...companyCommentedGamesIds]),
        ].length;
        playerData[`${self.getCurrentScout.company}AssociatedGamesIds`] = [
          ...new Set([...companyCommentedGamesIds]),
        ];

        const newUpdatedTimestamp = parseInt(moment().unix());
        playerData.updatedAt = newUpdatedTimestamp;
        playerData[`${self.getCurrentScout.id}UpdatedAt`] = newUpdatedTimestamp;
        playerData[`${self.getCurrentScout.company}UpdatedAt`] =
          newUpdatedTimestamp;

        if (playerData[`note${note.selectedPlayerABCDNote}Ids`]) {
          playerData[`note${note.selectedPlayerABCDNote}Ids`].push(noteId);
        } else {
          playerData[`note${note.selectedPlayerABCDNote}Ids`] = [noteId];
        }

        if (
          playerData[
            `${self.getCurrentScout.company}Note${note.selectedPlayerABCDNote}Ids`
          ]
        ) {
          playerData[
            `${self.getCurrentScout.company}Note${note.selectedPlayerABCDNote}Ids`
          ].push(noteId);
        } else {
          playerData[
            `${self.getCurrentScout.company}Note${note.selectedPlayerABCDNote}Ids`
          ] = [noteId];
        }

        if (
          playerData[
            `${self.getCurrentScout.id}Note${note.selectedPlayerABCDNote}Ids`
          ]
        ) {
          playerData[
            `${self.getCurrentScout.id}Note${note.selectedPlayerABCDNote}Ids`
          ].push(noteId);
        } else {
          playerData[
            `${self.getCurrentScout.id}Note${note.selectedPlayerABCDNote}Ids`
          ] = [noteId];
        }

        playerData[`note${note.selectedPlayerABCDNote}IdsLength`] =
          playerData[`note${note.selectedPlayerABCDNote}Ids`];

        playerData[
          `${self.getCurrentScout.company}Note${note.selectedPlayerABCDNote}IdsLength`
        ] =
          playerData[
            `${self.getCurrentScout.company}Note${note.selectedPlayerABCDNote}Ids`
          ].length;

        playerData[
          `${self.getCurrentScout.id}Note${note.selectedPlayerABCDNote}IdsLength`
        ] =
          playerData[
            `${self.getCurrentScout.id}Note${note.selectedPlayerABCDNote}Ids`
          ].length;

        console.log("note", note, "change player", playerData);
        db.collection("V3players")
          .doc(self.getSelectedPlayer.id.toString())
          .set(playerData);
        await db
          .collection("V3scouts")
          .doc(self.getCurrentScout.id.toString())
          .collection("players")
          .doc(self.getSelectedPlayer.id.toString())
          .get()
          .then(async (scoutPlayerDoc) => {
            if (scoutPlayerDoc.exists) {
              const scoutPlayerData = scoutPlayerDoc.data();
              scoutPlayerData.commentedGamesId = authorCommentedGamesIds;
              scoutPlayerData.associatedGamesIds = [
                ...new Set([...authorCommentedGamesIds]),
              ];
              scoutPlayerData.commentedGamesIdsLength = [
                ...new Set([...authorCommentedGamesIds]),
              ].length;

              scoutPlayerData.updatedAt = parseInt(now);
              await db
                .collection("V3scouts")
                .doc(self.getCurrentScout.id.toString())
                .collection("players")
                .doc(self.getSelectedPlayer.id.toString())
                .set(scoutPlayerData, { merge: true });
            } else {
              db.collection("V3scouts")
                .doc(self.getCurrentScout.id.toString())
                .collection("players")
                .doc(self.getSelectedPlayer.id.toString())
                .set(
                  {
                    associatedGamesIds: [self.$route.params.id.toString()],
                    citizenship: playerData.citizenship || [],
                    commentedGamesIds: [self.$route.params.id.toString()],
                    currentTeam:
                      playerData.currentTeam?.toString() || "undefined",
                    dateOfBirth:
                      parseInt(playerData.dateOfBirth) || "undefined",
                    endContract:
                      parseInt(playerData.endContract) || "undefined",
                    foot: playerData.foot?.toString() || "undefined",
                    height: parseInt(playerData.height) || "undefined",
                    id: playerData.id?.toString() || "undefined",
                    isReferral: playerData.isReferral || false,
                    name: playerData.name?.toString() || "undefined",
                    nationality:
                      playerData.nationality?.toString() || "undefined",
                    price: parseInt(playerData.price) || "undefined",
                    weight: parseInt(playerData.weight) || "undefined",
                    updatedAt: parseInt(now),
                  },
                  { merge: true }
                );
            }
          });

        db.collection("V3games")
          .doc(self.$route.params.id.toString())
          .collection("players")
          .doc(self.getSelectedPlayer.id.toString())
          .set({ hasNote: true }, { merge: true });
        let matchingPlayerIndex = self.lodash.findIndex(self.gamePlayers, {
          id: self.getSelectedPlayer.id,
        });
        if (matchingPlayerIndex !== -1) {
          self.gamePlayers[matchingPlayerIndex].hasNote = 1;
        }
        batch
          .commit()
          .then(() => {
            self.loadSelectedPlayerNotes();
            self.manageLineup();
            self.manageEvents();
            self.loadTeams();
            self.loadCurrentScoutGameNotes();
            self.toggleNotification("success", "notification.success.add.note");
          })
          .catch((error) => {
            console.log("Error :", error);
            self.toggleNotification("error", "notification.error.add.note");
            scoutPlayerNoteRef.delete();
            noteRef.delete();
            scoutGamePlayerNoteRef.delete();
          });
      }
    },
    updateGameNoteHandler(newNote) {
      this.game.note = newNote;
      this.submitGameNote();
    },
    submitGameNote() {
      const batch = db.batch();
      if (this.$route.params.id.toString() === "dashboard") {
        this.toggleNotification("error", "notification.error.add.note");
        return;
      }
      const gameRef = db
        .collection("V3games")
        .doc(this.$route.params.id.toString());
      batch.set(
        gameRef,
        {
          addedBy: this.game.addedBy,
          date: this.game.date,
          id: this.game.id,
          location: this.gameNoteCategory,
          note: this.game.note,
          noteLength: this.game.note.length || 0,
          teamAway: this.game.teamAway,
          teamHome: this.game.teamHome,
        },
        { merge: true }
      );
      const scoutGameRef = db
        .collection("V3scouts")
        .doc(this.getCurrentScout.id.toString())
        .collection("games")
        .doc(this.$route.params.id);
      batch.set(scoutGameRef, {
        date: this.game.date,
        id: this.game.id,
        location: this.gameNoteCategory,
        note: this.game.note,
        teamAway: this.game.teamAway,
        teamHome: this.game.teamHome,
      });

      batch
        .commit()
        .then(() => {
          this.toggleNotification("success", "notification.success.add.note");
        })
        .catch((error) => {
          console.log("Error :", error);
          this.toggleNotification("error", "notification.error.add.note");
        });
    },
    async loadSelectedPlayerNotes() {
      if (!this.getSelectedPlayer?.id || !this.getCurrentScout?.id) {
        return;
      }
      const self = this;
      const toReturn = [];
      const noteRef = db
        .collection("V3notes")
        .where("gameId", "==", self.$route.params.id.toString())
        .where("playerId", "==", self.getSelectedPlayer.id.toString());

      if (self.getCurrentScout?.isManager) {
        await noteRef.get().then((playerNotesSnapshot) => {
          playerNotesSnapshot.docs.forEach((playerNoteDoc) => {
            const playerNoteDatas = playerNoteDoc.data();
            const authorName = self.identifyScoutById(
              playerNoteDatas.authorId,
              "fullName"
            );
            if (authorName.length) {
              playerNoteDatas.authorName = authorName;
            }

            toReturn.push(playerNoteDatas);
          });
        });
      } else {
        await noteRef
          .where("authorId", "==", self.getCurrentScout.id.toString())
          .get()
          .then((playerNotesSnapshot) => {
            playerNotesSnapshot.docs.forEach((playerNoteDoc) => {
              const playerNoteDatas = playerNoteDoc.data();
              const authorName = self.identifyScoutById(
                self.getCurrentScout.id.toString(),
                "fullName"
              );
              if (authorName.length) {
                playerNoteDatas.authorName = authorName;
              }
              toReturn.push(playerNoteDatas);
            });
          });
      }
      self.selectedPlayerNotes = toReturn;
    },
    assignNoteToCategories() {
      if (this.selectedPlayerNotes.length) {
        this.selectedPlayerABCDNote =
          this.selectedPlayerNotes[0].letterNote || "";
        this.gamePlayerNoteId = this.selectedPlayerNotes[0].id || "";
        this.gamePlayerNote = this.selectedPlayerNotes[0].comment || "";
        this.tacticalPlayerNote =
          this.selectedPlayerNotes[0].tacticalNote || "";
        this.mentalPlayerNote = this.selectedPlayerNotes[0].mentalNote || "";
        this.athleticalPlayerNote =
          this.selectedPlayerNotes[0].athleticalNote || "";
        this.technicalPlayerNote =
          this.selectedPlayerNotes[0].technicalNote || "";
        this.setSelectedPlayerABCDNote(
          this.selectedPlayerNotes[0].letterNote || ""
        );
      } else {
        this.gamePlayerNoteId = "";
        this.gamePlayerNote = "";
        this.tacticalPlayerNote = "";
        this.mentalPlayerNote = "";
        this.athleticalPlayerNote = "";
        this.technicalPlayerNote = "";
        this.setSelectedPlayerABCDNote("");
      }
    },
    changeSelectedTeam(teamSide) {
      this.sidebarAddPlayer.selectedTeam = teamSide;
    },
    async loadTeams() {
      const self = this;

      if (
        self.game.apiGameId &&
        self.game.competitionLabel?.length &&
        self.game.score?.length
      ) {
        return;
      }

      await axios
        .get("https://ds-ol-scout-app-py-api-3.azurewebsites.net/teams/")
        .then(async (response) => {
          if (!self.game) {
            self.game = {};
          }
          const awayTeam = self.lodash.filter(response.data, {
            Team: self.game.teamAway,
          });
          const homeTeam = self.lodash.filter(response.data, {
            Team: self.game.teamHome,
          });
          self.homeTeam = homeTeam[0] || {};
          self.awayTeam = awayTeam[0] || {};
        });
      if (!self.homeTeam || !self.awayTeam) {
        return;
      }
      const now = moment();
      const gameDate = moment.unix(self.game.date);
      const APIformattedDate = moment.unix(self.game.date).format("YYYY-MM-DD");
      const APIformattedDatePlusOne = moment(APIformattedDate, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
      const APIformattedDateSubOne = moment(APIformattedDate, "YYYY-MM-DD")
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      self.matches = await axios
        .get(
          `https://ds-ol-scout-app-py-api-3.azurewebsites.net/matches/${
            now.isAfter(gameDate) ? "" : "future"
          }`
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log("An error occured", error);
          return [];
        });
      if (self.homeTeam && self.awayTeam) {
        const apiMatchingGameInfos = self.lodash.filter(
          self.matches,
          function (match) {
            return (
              match["home_team_id"] === self.homeTeam["Team Id"] &&
              match["away_team_id"] === self.awayTeam["Team Id"] &&
              (match["match_date"] === APIformattedDate ||
                match["match_date"] === APIformattedDatePlusOne ||
                match["match_date"] === APIformattedDateSubOne)
            );
          }
        );
        if (apiMatchingGameInfos.length) {
          self.apiMatchingGameInfos = apiMatchingGameInfos[0];
          self.sidebarAddPlayer.selectedTeam = "home";
        }
        if (
          apiMatchingGameInfos[0]?.home_score ||
          apiMatchingGameInfos[0]?.home_score == 0 ||
          apiMatchingGameInfos[0]?.away_score ||
          apiMatchingGameInfos[0]?.away_score == 0
        ) {
          db.collection("V3games")
            .doc(self.game.id)
            .set(
              {
                apiGameId: apiMatchingGameInfos[0].match_id,
                competitionLabel: apiMatchingGameInfos[0].competition_name,
                score: [
                  apiMatchingGameInfos[0].home_score,
                  apiMatchingGameInfos[0].away_score,
                ],
              },
              { merge: true }
            );
          self.score = [
            apiMatchingGameInfos[0].home_score,
            apiMatchingGameInfos[0].away_score,
          ];
        }
      }
    },
    unixToDate(unixNumber) {
      if (!unixNumber) {
        return;
      }
      if (moment.unix(unixNumber).isValid()) {
        return this.getLocale && this.getLocale == "fr"
          ? moment.unix(unixNumber).format("DD/MM/YYYY")
          : moment.unix(unixNumber).format("MM-DD-YYYY");
      }
    },
    unixToHours(unixNumber) {
      if (moment.unix(unixNumber).isValid()) {
        return this.getLocale && this.getLocale == "fr"
          ? moment.unix(unixNumber).format("HH") +
              "h" +
              moment.unix(unixNumber).format("mm")
          : moment.unix(unixNumber).format("HH") +
              ":" +
              moment.unix(unixNumber).format("mm");
      }
    },
    async manageEvents() {
      const self = this;
      if (!this.gameEvents.length) {
        await db
          .collection("V3games")
          .doc(this.$route.params.id.toString())
          .collection("events")
          .get()
          .then((gameEventsSnapshot) => {
            if (!gameEventsSnapshot.empty) {
              gameEventsSnapshot.docs.forEach((gameEventDoc) => {
                self.gameEvents.push(gameEventDoc.data());
              });
              self.manageEvents();
            }
          });
      } else {
        self.substitutionsEvents = [];
        self.cardEvents = [];
        self.goals = [];
        self.homeTeamEvents = [];
        self.awayTeamEvents = [];
        self.matchEvents = [];
        self.lodash
          .orderBy(self.gameEvents, "minute", "asc ")
          .forEach((gameEvent) => {
            if (
              gameEvent.action == "Goal" ||
              gameEvent.action == "Own Goal Against"
            ) {
              self.matchEvents.push(gameEvent);
              if (gameEvent.action == "Goal") {
                if (gameEvent.team === self.homeTeam["Team"]) {
                  self.homeTeamEvents.push(gameEvent);
                } else {
                  self.awayTeamEvents.push(gameEvent);
                }
              } else if (gameEvent.action == "Own Goal Against") {
                if (gameEvent.team === self.awayTeam["Team"]) {
                  self.homeTeamEvents.push(gameEvent);
                } else {
                  self.awayTeamEvents.push(gameEvent);
                }
              }
              self.goals.push(gameEvent);
            } else if (gameEvent.action == "Substitution") {
              self.substitutionsEvents.push(gameEvent);
            } else if (
              gameEvent.action == "Yellow Card" ||
              gameEvent.action == "Red Card"
            ) {
              self.cardEvents.push(gameEvent);
            }
          });
      }
    },
    async loadMatchEvents() {
      const self = this;
      self.homeTeamEvents = [];
      self.awayTeamEvents = [];
      self.matchEvents = [];
      const apiMatchingGameEvents = await axios({
        method: "get",
        url: `https://data.statsbombservices.com/api/v1/events/${self.apiMatchingGameInfos.match_id}`,
        auth: {
          username: "lyon@statsbomb.com",
          password: "fOO3QnhF",
        },
      })
        .then(async (response) => {
          return response.data;
        })
        .catch((error) => {
          console.log("Error match events :", error);
        });
      apiMatchingGameEvents.forEach((apiMatchingGameEvent) => {
        if (apiMatchingGameEvent.shot?.outcome?.name == "Goal") {
          self.matchEvents.push({
            id: apiMatchingGameEvent.index,
            action: apiMatchingGameEvent.shot.outcome.name,
            playerName: apiMatchingGameEvent.player.name,
            minute: apiMatchingGameEvent.minute,
            team: apiMatchingGameEvent.team.name,
          });
          if (apiMatchingGameEvent.team.name === self.homeTeam["Team"]) {
            self.homeTeamEvents.push({
              id: apiMatchingGameEvent.index,
              minute: apiMatchingGameEvent.minute,
              playerName: apiMatchingGameEvent.player.name,
            });
          } else {
            self.awayTeamEvents.push({
              id: apiMatchingGameEvent.index,
              minute: apiMatchingGameEvent.minute,
              playerName: apiMatchingGameEvent.player.name,
            });
          }
        } else if (apiMatchingGameEvent.type.name == "Bad Behaviour") {
          self.matchEvents.push({
            action: apiMatchingGameEvent.bad_behaviour.card.name,
            id: apiMatchingGameEvent.index,
            minute: apiMatchingGameEvent.minute,
            playerName: apiMatchingGameEvent.player.name,
            team: apiMatchingGameEvent.team.name,
          });
        } else if (apiMatchingGameEvent.type.name == "Foul Committed") {
          if (
            apiMatchingGameEvent.foul_committed &&
            apiMatchingGameEvent.foul_committed.card
          ) {
            self.matchEvents.push({
              action: apiMatchingGameEvent.foul_committed.card.name,
              id: apiMatchingGameEvent.index,
              minute: apiMatchingGameEvent.minute,
              playerName: apiMatchingGameEvent.player.name,
              team: apiMatchingGameEvent.team.name,
            });
          }
        } else if (apiMatchingGameEvent.type.name == "Substitution") {
          self.matchEvents.push({
            action: "Substitution",
            id: apiMatchingGameEvent.index,
            minute: apiMatchingGameEvent.minute,
            playerName: apiMatchingGameEvent.player.name,
            replacement: apiMatchingGameEvent.substitution.replacement,
            team: apiMatchingGameEvent.team.name,
          });
        } else if (apiMatchingGameEvent.type.name == "Own Goal Against") {
          self.matchEvents.push({
            action: "Own Goal Against",
            id: apiMatchingGameEvent.index,
            minute: apiMatchingGameEvent.minute,
            playerName: apiMatchingGameEvent.player.name,
            team: apiMatchingGameEvent.team.name,
          });
        }
      });
      self.matchEvents.forEach((matchEvent) => {
        db.collection("V3games")
          .doc(self.$route.params.id.toString())
          .collection("events")
          .doc(matchEvent.id.toString())
          .set(matchEvent);
      });

      self.manageEvents();
    },
    async loadMatchLineup() {
      const self = this;
      const apiMatchingGameLineup = await axios({
        method: "get",
        url: `https://data.statsbombservices.com/api/v3/lineups/${self.apiMatchingGameInfos.match_id}`,
        auth: {
          username: "lyon@statsbomb.com",
          password: "fOO3QnhF",
        },
      })
        .then(async (response) => {
          return response.data;
        })
        .catch((error) => {
          console.log("Error lineup :", error);
        });

      if (apiMatchingGameLineup.length) {
        apiMatchingGameLineup.forEach((apiMatchingGameTeam) => {
          apiMatchingGameTeam.lineup.forEach((teamLineup) => {
            const playerInfos = {
              id: teamLineup.player_id,
              isStartingPlayer:
                teamLineup.positions[0]?.from === "00:00" || false,
              jerseyNumber: teamLineup.jersey_number,
              name: teamLineup.player_name,
              team: apiMatchingGameTeam.team_name,
            };

            if (teamLineup.player_nickname) {
              playerInfos.nickname = teamLineup.player_nickname;
            }

            if (teamLineup.positions.length) {
              let splittedPosition = teamLineup.positions[0].position;
              let positionInitials = "";
              if (teamLineup.positions[0].position.indexOf(" ") > -1) {
                splittedPosition = splittedPosition.split(" ");
                positionInitials = splittedPosition
                  .map((positionWord) => positionWord.charAt(0))
                  .join("");
              } else {
                if (splittedPosition == "Goalkeeper") {
                  positionInitials = "GK";
                }
              }
              const matchingPosition = self.lodash.filter(
                self.getGamesPosition,
                { abv: positionInitials.toUpperCase() }
              );
              if (!matchingPosition.length) {
                this.toggleNotification("error", {
                  message: "notification.error.unknown",
                  params: {
                    position:
                      teamLineup.positions[0].position +
                      " - " +
                      positionInitials,
                  },
                });
              } else {
                playerInfos.gamePositionId = matchingPosition[0].id;
                db.collection("V3players")
                  .doc(playerInfos.id.toString())
                  .get()
                  .then((playerDoc) => {
                    const playerData = playerDoc.data() || {
                      id: playerInfos.id.toString(),
                      name: playerInfos.name,
                      currentTeam: playerInfos.team,
                      isReferral: false,
                    };
                    db.collection("V3games")
                      .doc(self.apiMatchingGameInfos.match_id.toString())
                      .collection("players")
                      .doc(teamLineup.player_id.toString())
                      .set(playerData, { merge: true });
                  });

                db.collection("V3players")
                  .doc(playerInfos.id.toString())
                  .collection("games")
                  .doc(self.$route.params.id.toString())
                  .collection("gamesPositions")
                  .doc(playerInfos.gamePositionId.toString())
                  .set({
                    addedBy: self.getCurrentScout.id.toString(),
                    gamePositionId: playerInfos.gamePositionId.toString(),
                  });

                db.collection("V3players")
                  .doc(playerInfos.id.toString())
                  .collection("gamesPositions")
                  .doc(playerInfos.gamePositionId.toString())
                  .set(matchingPosition[0], { merge: true });

                db.collection("V3players")
                  .doc(playerInfos.id.toString())
                  .collection("gamesPositions")
                  .doc(playerInfos.gamePositionId.toString())
                  .collection("games")
                  .doc(self.$route.params.id.toString())
                  .set({
                    addedBy: self.getCurrentScout.id.toString(),
                    date: parseInt(self.game.date),
                    id: self.game.id.toString(),
                    teamAway: self.game.teamAway.toString(),
                    teamHome: self.game.teamHome.toString(),
                  });

                db.collection("V3gamesPositions")
                  .doc(playerInfos.gamePositionId.toString())
                  .collection("players")
                  .doc(playerInfos.id.toString())
                  .set({
                    gameId: self.game.id.toString(),
                    id: playerInfos.id,
                    name: playerInfos.name,
                  });
              }

              db.collection("V3players")
                .doc(playerInfos.id.toString())
                .collection("games")
                .doc(self.$route.params.id.toString())
                .set({
                  addedBy: self.getCurrentScout.id.toString(),
                  date: parseInt(self.game.date),
                  id: self.game.id.toString(),
                  teamAway: self.game.teamAway.toString(),
                  teamHome: self.game.teamHome.toString(),
                });

              db.collection("V3games")
                .doc(self.$route.params.id.toString())
                .collection("players")
                .doc(playerInfos.id.toString())
                .set(playerInfos, { merge: true });
              db.collection("V3games")
                .doc(self.$route.params.id.toString())
                .set(
                  {
                    apiGameId: self.apiMatchingGameInfos.match_id,
                    competitionLabel:
                      self.apiMatchingGameInfos.competition_name,
                  },
                  { merge: true }
                );

              db.collection("V3players")
                .doc(playerInfos.id.toString())
                .get()
                .then((playerDoc) => {
                  const playerDatas = playerDoc.data() || {
                    id: playerInfos.id.toString(),
                    name: playerInfos.name,
                    currentTeam: playerInfos.team,
                    isReferral: false,
                  };
                  if (playerDatas.associatedGamesIds) {
                    if (
                      self.lodash.indexOf(
                        playerDatas.associatedGamesIds,
                        self.apiMatchingGameInfos.match_id.toString()
                      ) == -1
                    ) {
                      playerDatas.associatedGamesIds.push(
                        self.apiMatchingGameInfos.match_id.toString()
                      );
                    }
                  } else {
                    playerDatas.associatedGamesIds = [
                      self.apiMatchingGameInfos.match_id.toString(),
                    ];
                  }
                  db.collection("V3players")
                    .doc(playerInfos.id.toString())
                    .set(playerDatas, { merge: true });

                  db.collection("V3scouts")
                    .doc(self.getCurrentScout.id.toString())
                    .collection("players")
                    .doc(playerInfos.id.toString())
                    .get()
                    .then((scoutPlayerDoc) => {
                      if (scoutPlayerDoc.exists) {
                        const scoutPlayerDatas = scoutPlayerDoc.data();
                        if (scoutPlayerDatas.associatedGamesIds) {
                          if (
                            self.lodash.indexOf(
                              scoutPlayerDatas.associatedGamesIds,
                              self.$route.params.id.toString()
                            ) == -1
                          ) {
                            scoutPlayerDatas.associatedGamesIds.push(
                              self.$route.params.id.toString()
                            );
                          }
                        } else {
                          scoutPlayerDatas.associatedGamesIds = [
                            self.$route.params.id.toString(),
                          ];
                        }
                        db.collection("V3scouts")
                          .doc(self.getCurrentScout.id.toString())
                          .collection("players")
                          .doc(playerInfos.id.toString())
                          .set(scoutPlayerDatas, { merge: true });
                      } else {
                        const scoutPlayerToAdd = {
                          citizenship: playerDatas.citizenship || [],
                          currentTeam: playerDatas.currentTeam.toString(),
                          dateOfBirth: !isNaN(parseInt(playerDatas.dateOfBirth))
                            ? parseInt(playerDatas.dateOfBirth)
                            : "undefined",
                          id: playerDatas.id.toString(),
                          isReferral: playerDatas.isReferral ? true : false,
                          name: playerDatas.name.toString(),
                          nationality:
                            playerDatas.nationality?.toString() || "",
                        };

                        if (playerDatas.endContract) {
                          scoutPlayerToAdd.endContract =
                            playerDatas.endContract.toString();
                        }

                        if (playerDatas.foot) {
                          scoutPlayerToAdd.foot = playerDatas.foot.toString();
                        }
                        if (playerDatas.height) {
                          scoutPlayerToAdd.height =
                            playerDatas.height.toString();
                        }
                        if (playerDatas.price) {
                          scoutPlayerToAdd.price = parseFloat(
                            playerDatas.price
                          );
                        }
                        if (playerDatas.weight) {
                          scoutPlayerToAdd.weight =
                            playerDatas.weight.toString();
                        }

                        scoutPlayerToAdd.associatedGamesIds = [
                          self.$route.params.id.toString(),
                        ];

                        db.collection("V3scouts")
                          .doc(self.getCurrentScout.id.toString())
                          .collection("players")
                          .doc(playerInfos.id.toString())
                          .set(scoutPlayerToAdd, { merge: true });
                      }
                    });

                  db.collection("V3scouts")
                    .doc(self.getCurrentScout.id.toString())
                    .collection("players")
                    .doc(playerInfos.id.toString())
                    .collection("games")
                    .doc(self.$route.params.id.toString())
                    .set(
                      {
                        date: parseInt(self.game.date),
                        id: self.game.id.toString(),
                        teamAway: self.game.teamAway.toString(),
                        teamHome: self.game.teamHome.toString(),
                        gamePositionId: playerInfos.gamePositionId.toString(),
                      },
                      { merge: true }
                    );
                  db.collection("V3scouts")
                    .doc(self.getCurrentScout.id.toString())
                    .collection("games")
                    .doc(self.$route.params.id.toString())
                    .set(
                      {
                        date: parseInt(self.game.date),
                        id: self.game.id.toString(),
                        location: self.game.location.toString() || "",
                        teamAway: self.game.teamAway.toString(),
                        teamHome: self.game.teamHome.toString(),
                      },
                      { merge: true }
                    );

                  db.collection("V3scouts")
                    .doc(self.getCurrentScout.id.toString())
                    .collection("games")
                    .doc(self.$route.params.id.toString())
                    .collection("players")
                    .doc(playerInfos.id.toString())
                    .set(
                      {
                        gamePositionId: playerInfos.gamePositionId.toString(),
                        id: playerInfos.id.toString(),
                        jerseyNumber: playerInfos.jerseyNumber.toString(),
                        name: playerInfos.name.toString(),
                        team:
                          playerInfos.team == self.homeTeam["Team"]
                            ? "home"
                            : "away",
                      },
                      { merge: true }
                    );
                });
            }
          });
        });
      }

      self.matchingGameLineup = apiMatchingGameLineup;
      if (self.orderedMatchingPlayers[0] && !self.getSelectedPlayer.id) {
        self.$store.dispatch(
          "setSelectedPlayer",
          self.orderedMatchingPlayers[0]
        );
      }
    },
    orderPlayers(setFirstPlayer = true) {
      const matchingPlayersShallowCopy = this.orderedMatchingPlayers;
      matchingPlayersShallowCopy.forEach((matchingPlayerShallowCopy) => {
        if (!matchingPlayerShallowCopy.isInBucket) {
          matchingPlayerShallowCopy.isInBucket = false;
        }
        matchingPlayerShallowCopy.isInShadow = this.playersInShadows.includes(
          matchingPlayerShallowCopy.id
        );
      });

      const notCommentedPlayers = matchingPlayersShallowCopy.filter(
        (player) => !player.hasNote
      );
      const notCommentedPlayersInBucket = notCommentedPlayers.filter(
        (player) => player.isInBucket
      );
      const notCommentedPlayersNotInBucket = notCommentedPlayers.filter(
        (player) => !player.isInBucket
      );
      notCommentedPlayersNotInBucket.sort((player1, player2) => {
        const player1inBucket = player1.isInShadow || false;
        const player2InBucket = player2.isInShadow || false;
        return player2InBucket - player1inBucket;
      });
      this.commentedPlayers = this.lodash.orderBy(
        matchingPlayersShallowCopy.filter((player) => player.hasNote),
        ["isInBucket", "isInShadow", "name"],
        ["desc", "desc", "asc"]
      );
      this.otherPlayers = this.lodash.orderBy(
        notCommentedPlayersInBucket.concat(notCommentedPlayersNotInBucket),
        ["isInBucket", "isInShadow", "name"],
        ["desc", "desc", "asc"]
      );
      if (this.commentedPlayers.length && setFirstPlayer) {
        this.$store.dispatch("setSelectedPlayer", this.commentedPlayers[0]);
      } else {
        this.$store.dispatch("setSelectedPlayer", this.otherPlayers[0]);
      }
    },
  },
  watch: {
    getCurrentScopeShadowsPlayers: {
      deep: true,
      immediate: true,
      handler() {
        this.playersInShadows = this.getCurrentScopeShadowsPlayers.map(
          (player) => {
            return player.id;
          }
        );
      },
    },
    selectedPlayerNotes: {
      deep: true,
      immediate: true,
      handler() {
        this.assignNoteToCategories();
      },
    },
    shadowPlayersIds: {
      deep: true,
      handler() {
        this.manageLineup();
      },
    },
    orderedMatchingPlayers() {
      const self = this;
      const firstPlayer = self.lodash.filter(self.orderedMatchingPlayers, {
        team: self.sidebarAddPlayer.selectedTeam,
      })[0];
      if (firstPlayer && !this.getSelectedPlayer.id) {
        self.$store.dispatch("setSelectedPlayer", firstPlayer);
      }
    },
    getCurrentScout: {
      async handler() {
        if (this.getCurrentScout?.id) {
          this.markAsSeen();
          this.loadCurrentScoutGameNotes();
        }
      },
      deep: true,
    },
    getSelectedPlayer: {
      deep: true,
      immediate: true,
      handler() {
        const self = this;
        self.loadSelectedPlayerNotes();
        if (self.getSelectedPlayer) {
          if (self.getSelectedPlayer.label) {
            const matchingPosition = self.lodash.filter(self.getGamesPosition, {
              label: self.getSelectedPlayer.label,
            });

            if (matchingPosition[0]) {
              self.getSelectedPlayer.selectedPosition =
                matchingPosition[0].label.charAt(0).toUpperCase() +
                matchingPosition[0].label.slice(1);
            }
          } else if (self.getSelectedPlayer.positions?.length) {
            self.getSelectedPlayer.selectedPosition =
              self.getSelectedPlayer.positions[0].position
                .charAt(0)
                .toUpperCase() +
              self.getSelectedPlayer.positions[0].position.slice(1);
          } else {
            self.getSelectedPlayer.selectedPosition = "undefined";
          }
          this.assignNoteToCategories();
        }
      },
    },
    game() {
      this.markAsSeen();
      if (this.game && this.game.teamAway && this.game.teamHome) {
        this.loadCurrentGamePlayers();
        this.loadTeams();
        this.homeTeam["Team"] = this.game.teamHome;
        this.awayTeam["Team"] = this.game.teamAway;
        if (this.game.location === "data.dot") {
          this.gameNoteCategory = this.$t("data.dot");
        } else {
          this.gameNoteCategory = this.game.location;
        }
        if (this.game.score?.length) {
          this.score = this.game.score;
        }
      }
    },
    apiMatchingGameInfos() {
      this.loadMatchLineup();

      if (!this.gameEvents.length) {
        this.loadMatchEvents();
      } else {
        this.manageEvents();
      }
    },
    gamePlayers() {
      this.manageLineup();
      this.setDefaultPlayerSelected();
    },
    matchingGameLineup() {
      this.setDefaultPlayerSelected();
    },
  },
};
</script>
<style scoped>
#scorerSmartphoneLayoutContainer {
  display: none;
}

#scorersContainer {
  font-size: 0.9rem;
  margin-top: 20px;
  justify-content: center;
}

#scorersContainer > div:first-of-type {
  text-align: right;
}

.scorerContainer {
  font-size: 0.9rem;
  margin-top: 10px;
}

.homeScorerContainer,
.awayScorerContainer {
  position: relative;
  margin: 5px 0;
}

.homeScorerContainer:after,
.awayScorerContainer:before {
  content: url("../assets/img/ballon_icon.svg");
  padding: 3px 10px;
}

#gameHeaderScoresContainer {
  padding-top: 20px;
  font-size: 3rem;
  flex-direction: column;
}

#gameHeaderScoresContainer .row {
  flex-wrap: nowrap;
  align-items: center;
}

.gameHeaderScoreContainer {
  background-color: white;
  border-radius: 3px;
  color: #0b0790;
  font-size: 3.5rem;
  font-weight: bold;
  padding: 10px 20px;
  margin: 0 15px;
}

#pitchPlayersContainer {
  background-color: #0b0790;
  padding: 30px 24px;
  border-radius: 8px;
  max-width: 50%;
  margin-right: 8px;
}

#gameContentContent > .col > .row {
  padding: 30px 15px;
}

#listPlayersContainer {
  padding: 0 12px 0 0;
}

#gameContentContent > .col:last-of-type {
  padding: 0 0 0 12px;
}

#gamePlayersContainer {
  background-color: #efefef;
  margin: 0;
  border-radius: 8px;
}

select {
  width: 100%;
}

#gameContentContent {
  background-color: white;
  border-radius: 16px;
  padding: 25px;
  max-width: 1200px;
  margin: 40px auto;
  box-shadow: 0px 0px 8px 0px #06005214;
  display: flex;
  flex-direction: row;
}

#gameContentContainer {
  background: #f6f9fc;
  padding: 10px;
}

#gameHeaderContent {
  margin: 0 auto;
  max-width: 1200px;
}

#gameHeaderContainer {
  background: #0b00a3;
  color: white;
  padding-bottom: 50px;
  font-size: 1.25rem;
  margin: 0;
}

#gameHeaderContainer .row:first-of-type {
  font-weight: bold;
}

#gameHeaderContainer .row:nth-child(-n + 2) {
  font-size: 1rem;
}

#gameHeaderContainer .row:last-of-type {
  justify-content: center;
}

#gameHeaderHoursContainer {
  border: 1px solid #8b95ea;
  border-radius: 3px;
  padding: 10px;
  margin: 20px 0;
  font-weight: bold;
  max-width: 150px;
  font-size: 1.8rem;
}

#gameHeaderBottomContainer {
  margin: 0 auto;
  justify-content: center;
  max-width: 1200px;
}

#gameHeaderBottomContainer > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

#gameHeaderBottomContainer span {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}

#homeTeamContainer {
  justify-content: flex-end;
}

#awayTeamContainer {
  justify-content: flex-start;
}
@media screen and (max-width: 992px) {
  #gameHeaderBottomContainer span {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 768px) {
  #gameHeaderBottomContainer span {
    font-size: 1.2rem;
  }

  .homeScorerContainer,
  .awayScorerContainer {
    text-align: center;
  }

  #scorerSmartphoneLayoutContainer {
    display: block;
    margin-top: 10px;
  }

  .homeScorerContainer:after,
  .awayScorerContainer:before {
    display: none;
  }

  .homeScorerContainer:before,
  .awayScorerContainer:after {
    content: url("../assets/img/ballon_icon.svg");
    padding: 3px 10px;
  }

  #scorersContainer {
    display: none;
  }

  #gamePlayersContainer {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #gameHeaderScoresContainer {
    padding-bottom: 20px;
  }

  #gameHeaderBottomContainer {
    margin-top: 10px;
  }

  #gameTeamsNames {
    padding: 0;
  }

  #gameContentContent {
    flex-direction: column;
  }

  #listPlayersContainer {
    padding-right: 15px;
  }

  #gameContentContent > .col:last-of-type {
    padding-left: 15px;
  }

  #pitchPlayersContainer {
    padding-right: 30px;
  }
}
</style>
