<script>
import { Bar } from "vue-chartjs";

export default {
  name: "ChartComponent",
  extends: Bar,
  props: {
    label: { type: String },
    chartData: { type: Array },
    chartColors: {
      type: Object,
    },
  },
  data: () => ({
    x: [],
    y: [],
    options: {
      legend: {
        position: "bottom",
        align: "start",
      },
    },
  }),
  mounted() {
    this.manageDatas();
  },
  computed: {
    capitalizedLabel() {
      return (
        this.$t(this.label).charAt(0).toUpperCase() +
        this.$t(this.label).slice(1)
      );
    },
  },
  methods: {
    manageDatas() {
      this.x = this.chartData.map((d) => {
        return d.x;
      });
      this.y = this.chartData.map((d) => {
        return d.y;
      });
      const {
        pointBorderColor,
        borderColor,
        pointBackgroundColor,
        backgroundColor,
      } = this.chartColors;
      this.renderChart(
        {
          labels: this.x,
          datasets: [
            {
              label: this.capitalizedLabel,
              data: this.y,
              borderColor,
              pointBorderColor,
              pointBackgroundColor,
              backgroundColor,
            },
          ],
        },
        this.options
      );
    },
  },
  watch: {
    chartData() {
      this.manageDatas();
    },
  },
};
</script>
