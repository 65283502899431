var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shadowPitchComponentContainer"},[(_vm.compoId)?_c('span',{staticClass:"cornerContainer"},[_vm._v(" "+_vm._s(_vm.getCompoById(_vm.compoId, "displayName"))+" ")]):_vm._e(),_vm._m(0),_c('div',{staticClass:"squadManagementPitchContent"},[_c('div',{staticClass:"pitch mt-3 text-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"}),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('LF'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('LCF')),expression:"getInstructionForPosition('LCF')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'LCF')}}},[_vm._v("LCF")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'LCF'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('LCF').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'LCF')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('CF'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('CF')),expression:"getInstructionForPosition('CF')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'CF')}}},[_vm._v("CF")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'CF'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('CF').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'CF')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('RF'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('RCF')),expression:"getInstructionForPosition('RCF')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'RCF')}}},[_vm._v("RCF")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'RCF'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('RCF').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'RCF')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('LW'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('LW')),expression:"getInstructionForPosition('LW')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'LW')}}},[_vm._v("LW")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'LW'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('LW').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'LW')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('LAM'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('LAM')),expression:"getInstructionForPosition('LAM')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'LAM')}}},[_vm._v("LAM")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'LAM'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('LAM').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'LAM')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('CAM'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('CAM')),expression:"getInstructionForPosition('CAM')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'CAM')}}},[_vm._v("CAM")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'CAM'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('CAM').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'CAM')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('RAM'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('RAM')),expression:"getInstructionForPosition('RAM')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'RAM')}}},[_vm._v("RAM")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'RAM'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('RAM').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'RAM')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('RW'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('RW')),expression:"getInstructionForPosition('RW')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'RW')}}},[_vm._v("RW")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'RW'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('RW').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'RW')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('LM'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('LM')),expression:"getInstructionForPosition('LM')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'LM')}}},[_vm._v("LM")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'LM'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('LM').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'LM')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('LCM'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('LCM')),expression:"getInstructionForPosition('LCM')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'LCM')}}},[_vm._v("LCM")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'LCM'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('LCM').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'LCM')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('CM'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('CM')),expression:"getInstructionForPosition('CM')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'CM')}}},[_vm._v("CM")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'CM'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('CM').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'CM')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('RCM'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('RCM')),expression:"getInstructionForPosition('RCM')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'RCM')}}},[_vm._v("RCM")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'RCM'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('RCM').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'RCM')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('RM'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('RM')),expression:"getInstructionForPosition('RM')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'RM')}}},[_vm._v("RM")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'RM'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('RM').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'RM')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('LWB'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('LWB')),expression:"getInstructionForPosition('LWB')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'LWB')}}},[_vm._v("LWB")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'LWB'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('LWB').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'LWB')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('LDM'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('LDM')),expression:"getInstructionForPosition('LDM')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'LDM')}}},[_vm._v("LDM")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'LDM'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('LDM').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'LDM')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('CDM'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('CDM')),expression:"getInstructionForPosition('CDM')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'CDM')}}},[_vm._v(" CDM ")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'CDM'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('CDM').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'CDM')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('RDM'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('RDM')),expression:"getInstructionForPosition('RDM')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'RDM')}}},[_vm._v("RDM")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'RDM'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('RDM').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'RDM')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('RWB'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('RWB')),expression:"getInstructionForPosition('RWB')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'RWB')}}},[_vm._v("RWB")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'RWB'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('RWB').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'RWB')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('LB'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('LB')),expression:"getInstructionForPosition('LB')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'LB')}}},[_vm._v("LB")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'LB'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('LB').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'LB')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('LCB'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('LCB')),expression:"getInstructionForPosition('LCB')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'LCB')}}},[_vm._v("LCB")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'LCB'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('LCB').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'LCB')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('CB'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('CB')),expression:"getInstructionForPosition('CB')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'CB')}}},[_vm._v("CB")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'CB'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('CB').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'CB')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('RCB'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('RCB')),expression:"getInstructionForPosition('RCB')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'RCB')}}},[_vm._v("RCB")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'RCB'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('RCB').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'RCB')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('RB'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('RB')),expression:"getInstructionForPosition('RB')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'RB')}}},[_vm._v("RB")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'RB'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('RB').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'RB')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"}),_c('div',{staticClass:"col"}),_c('div',{staticClass:"col"},[(_vm.checkDisplayAccordingCompo('G'))?_c('div',{staticClass:"pitchPositionContainer"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getInstructionForPosition('G')),expression:"getInstructionForPosition('G')",modifiers:{"hover":true,"top":true}}],staticClass:"pitchPosition",on:{"click":function($event){return _vm.$emit('addPlayer', 'G')}}},[_vm._v("G")]),_c('div',{staticClass:"currentPitchPlayersContainer"},[_vm._l((_vm.getCurrentPositionPlayers(
                  'G'
                )),function(player,playerIndex){return _c('div',{key:playerIndex,class:{
                  currentPitchPlayer: true,
                  isSelected: _vm.isSelectedPlayer(player.addedBy),
                }},[_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(player.name))])]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(player.dateOfBirth, false, "year"))+" - "+_vm._s(player.currentTeam)+" ")])]),_c('div',[_c('div',[(player.isInBucket)?_c('span',{staticClass:"isBucketPlayerContainer"}):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.displayPlayerPrice(player.price)))])])])}),(!_vm.getCurrentPositionPlayers('G').length)?_c('div',{staticClass:"addPlayerContainer",on:{"click":function($event){return _vm.$emit('addPlayer', 'G')}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"col"}),_c('div',{staticClass:"col"})])])]),_c('span',{staticClass:"cornerContainer"},[_vm._v(" "+_vm._s(_vm.currentShadowPlayers.length || 0)+" "+_vm._s(_vm.$tc("plural.player", _vm.currentShadowPlayers.length || 1))+" ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"designPitchView"},[_c('div',{staticClass:"designPitchViewAwaySide"},[_c('div',{staticClass:"designPitchViewTopLeftCorner designPitchViewCorner"}),_c('div',{staticClass:"designPitchViewAwaySideGoal designPitchViewGoals"}),_c('div',{staticClass:"designPitchViewSurface designPitchViewAwaySurface"}),_c('div',{staticClass:"designPitchViewTopRightCorner designPitchViewCorner"}),_c('div',{staticClass:"designPitchViewCentralCircle"})]),_c('div',{staticClass:"designPitchViewHomeSide"},[_c('div',{staticClass:"designPitchViewBottomLeftCorner designPitchViewCorner"}),_c('div',{staticClass:"designPitchViewBottomRightCorner designPitchViewCorner"}),_c('div',{staticClass:"designPitchViewHomeSideGoal designPitchViewGoals"}),_c('div',{staticClass:"designPitchViewSurface designPitchViewHomeSurface"})])])
}]

export { render, staticRenderFns }