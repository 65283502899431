<template>
  <ActionBase
    @click="handleClick"
    :disabled="false"
    :label="$t('cancel')"
    :primary="false"
  ></ActionBase>
</template>

<script>
import { mapMutations } from "vuex";
import ActionBase from "./ActionBase.vue";
export default {
  components: { ActionBase },
  methods: {
    ...mapMutations("popin", ["closePopin"]),
    handleClick() {
      this.closePopin();
    },
  },
};
</script>

<style lang="scss" scoped></style>
