<template>
  <b-row class="fields-group">
    <b-col cols="12">
      <TextInput
        :value="getPopinShallowPayload.label ?? ''"
        :label="$t('squadName')"
        name="title"
        :required="true"
        @change="setTitle"
        :placeholder="$t('instructions.defineASquadName')"
        @error="addPopinError"
        @unerror="removePopinError"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TextInput from "../../../Fields/TextInput.vue";
export default {
  components: { TextInput },
  computed: {
    ...mapGetters("popin", ["getPopinShallowPayload"]),
  },
  methods: {
    ...mapActions("popin", ["addPopinError", "removePopinError"]),
    setTitle({ title }) {
      Object.assign(this.getPopinShallowPayload, { squadTitle: title });
    },
  },
};
</script>
<style lang="scss" scoped>
div.fields-group {
  padding: 24px;
  border-radius: 8px;
  background-color: #fafafa;
}
</style>
