<template>
  <div class="container">
    <b-row class="cardContainer">
      <b-col>
        <b-row>
          <b-col>
            <ul id="tabsContainer">
              <li
                :class="{ tabContainer: true, active: notesTab === 'global' }"
                @click="toggleNoteTab('global')"
              >
                <span>{{ $t("globalReport") }}</span>
              </li>
              <li
                :class="{ tabContainer: true, active: notesTab === 'games' }"
                @click="toggleNoteTab('games')"
              >
                <span>{{ $tc("plural.observation", 2) | capitalize }}</span>
              </li>
              <li
                :class="{
                  tabContainer: true,
                  active: notesTab === 'incomingGames',
                }"
                @click="toggleNoteTab('incomingGames')"
              >
                <span>{{ $t("incomingGames") | capitalize }}</span>
              </li>
            </ul>
          </b-col>
        </b-row>
        <b-row
          :class="{
            gamesDesign: notesTab == 'games',
            globalDesign: notesTab == 'global',
          }"
          v-if="notesTab != 'incomingGames'"
        >
          <b-col class="cardColumnContainer" md="6">
            <p
              class="cardColumnTitle"
              v-if="getCurrentScout.isManager && notesTab != 'incomingGames'"
            >
              {{ $t("scoutsNotes") }}
            </p>
            <p class="cardColumnTitle" v-else-if="notesTab == 'global'">
              {{ $t("myNotes") }}
            </p>
            <p class="cardColumnTitle" v-else-if="notesTab == 'games'">
              {{ $t("games") }}
            </p>
            <p class="cardColumnTitle" v-else-if="notesTab == 'incomingGames'">
              {{ $t("incomingGames") }}
            </p>
            <div class="notesContainer" v-if="notesTab === 'global'">
              <Note
                v-for="(note, index) in globalNotes"
                :key="index"
                :note="note"
              >
                {{ note }}
              </Note>
              <div v-if="!globalNotes.length">
                {{ $t("no.noteAtTheMomentForThePlayer") }}
              </div>
            </div>
            <div class="gamesContainer" v-else-if="notesTab == 'games'">
              <div
                v-for="(game, index) in groupedNotesByGame"
                :key="index"
                :class="{
                  gameContainer: true,
                  activeGame: currentGameId === index,
                }"
                @click="currentGameId = index"
              >
                <div class="gameContent">
                  <div
                    class="gameNotification"
                    v-if="getGameLetter(index).length"
                  >
                    {{ getGameLetter(index) }}
                  </div>
                  <div class="gameInfosContainer">
                    <div class="gameTeams" v-if="getGameById(index)">
                      {{ getGameById(index).teamHome }} -
                      {{ getGameById(index).teamAway }}
                    </div>
                    <div class="gameInfos" v-if="getGameById(index)">
                      {{ formatDate(getGameById(index).date, false, "date") }}
                      -
                      <span
                        class="pinStadium"
                        v-if="getGameById(index).location == 'stadium'"
                      >
                        <font-awesome-icon class="mr-2" icon="map-pin" />
                      </span>
                      <span
                        class="pinVideo"
                        v-if="getGameById(index).location == 'video'"
                      >
                        <font-awesome-icon class="mr-2" icon="film" />
                      </span>
                      <span
                        class="pinStats"
                        v-if="getGameById(index).location == 'data.dot'"
                      >
                        <font-awesome-icon class="mr-2" icon="chart-bar" />
                      </span>
                    </div>
                  </div>
                  <div class="isSelectedContainer">
                    <span v-if="currentGameId === index">-</span>
                    <span v-else>+</span>
                  </div>
                </div>
              </div>
              <div v-if="!gamesHavingNotes.length">
                {{ $t("no.gameAtTheMomentForThePlayer") }}
              </div>
            </div>
          </b-col>
          <b-col class="cardColumnContainer" md="6">
            <p
              class="cardColumnTitle"
              v-if="getCurrentScout.isManager && notesTab == 'global'"
            >
              {{ $t("cellNote") }}
            </p>
            <p
              class="cardColumnTitle"
              v-if="getCurrentScout.isManager && notesTab == 'games'"
            >
              {{ $tc("plural.note", getCurrentGameNotes.length) }}
            </p>
            <p
              class="cardColumnTitle"
              v-else-if="!getCurrentScout.isManager && notesTab == 'games'"
            >
              {{ $t("myNotes") }}
            </p>
            <div class="markContainer" v-if="notesTab == 'global'">
              <p class="markTitle" v-if="getCurrentScout.isManager">
                {{ $tc("plural.mark", 1) }} :
              </p>
              <div id="starsContainer" v-if="getCurrentScout.isManager">
                <div class="starContainer">
                  <div class="starContent">
                    <img
                      :src="getStarSource(1)"
                      :alt="translate('star')"
                      @click="updateMark(1)"
                    />
                  </div>
                  <div class="starContent">
                    <img
                      :src="getStarSource(2)"
                      :alt="translate('star')"
                      @click="updateMark(2)"
                    />
                  </div>
                  <div class="starContent">
                    <img
                      :src="getStarSource(3)"
                      :alt="translate('star')"
                      @click="updateMark(3)"
                    />
                  </div>
                  <div class="starContent">
                    <img
                      :src="getStarSource(4)"
                      :alt="translate('star')"
                      @click="updateMark(4)"
                    />
                  </div>
                  <div class="starContent">
                    <img
                      :src="getStarSource(5)"
                      :alt="translate('star')"
                      @click="updateMark(5)"
                    />
                  </div>
                </div>
              </div>
              <p class="markTitle">{{ $tc("plural.note", 1) }} :</p>
              <div
                :class="{ addNoteContainer: true, editing: editingCellNote }"
              >
                <textarea
                  name="comment"
                  id="cellComment"
                  :placeholder="$t('yourComment')"
                  :disabled="!editingCellNote"
                  v-model="comment"
                />
                <div
                  class="editingCellNoteContainer"
                  @click="editingCellNote = !editingCellNote"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </div>
              </div>
              <button
                class="validateCellNote"
                @click="submit"
                v-if="editingCellNote"
              >
                {{ $t("validate") }}
              </button>
            </div>

            <div class="notesContainer" v-if="this.notesTab == 'games'">
              <Note
                v-for="(note, index) in getCurrentGameNotes"
                :key="index"
                :note="note"
              />
              <div
                class="linkToGameContainer"
                v-if="getCurrentGameNotes.length"
              >
                <router-link
                  v-if="getCurrentScout.isManager"
                  :to="'/game/' + currentGameId"
                >
                  {{ $t("accessReport") }}
                </router-link>
                <router-link v-else :to="'/game/' + currentGameId">
                  {{ $t("accessReport") }}
                </router-link>
              </div>
              <div v-else>
                {{ $t("no.noteAtTheMomentForThePlayer") }}
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col class="matchsContainer">
            <div
              v-for="(incomingGame, index) in orderedNotes.sliceArray"
              :key="index"
              class="matchContainer"
            >
              <b-row class="matchContent">
                <b-col class="matchInfos">
                  <div class="matchDate">
                    {{ formatDate(incomingGame.match_date, false, "date") }}
                  </div>
                  <div class="matchTeams">
                    {{ incomingGame.home_team_name }} -
                    {{ incomingGame.away_team_name }}
                  </div>
                </b-col>
                <b-col>
                  <b-row class="matchActionsContainer">
                    <b-col
                      v-if="!getCurrentScout.isManager"
                      class="matchActionsContent"
                    >
                      <div class="locationsInstructionsContainer">
                        {{ $t("scheduleAReport") }}
                      </div>
                      <div
                        class="locationsContainer"
                        :id="'scoutGame_' + incomingGame.match_id"
                        :ref="'scoutGame_' + incomingGame.match_id"
                      >
                        <div
                          :class="{
                            locationContainer: true,
                            stadiumLocation: true,
                            active: checkLocation(
                              'stadium',
                              incomingGame.match_id
                            ),
                          }"
                          @click="setLocation(incomingGame.match_id, 'stadium')"
                        >
                          {{ $t("stadium") | capitalize }}
                        </div>
                        <div
                          :class="{
                            locationContainer: true,
                            videoLocation: true,
                            active: checkLocation(
                              'video',
                              incomingGame.match_id
                            ),
                          }"
                          @click="setLocation(incomingGame.match_id, 'video')"
                        >
                          {{ $t("video ") | capitalize }}
                        </div>
                        <button
                          @click="validateLocation(incomingGame)"
                          class="submitButton"
                        />
                      </div>
                    </b-col>
                    <b-col v-else class="matchActionsContent">
                      <div class="locationsInstructionsContainer">
                        {{ $t("selectAScout") }}
                      </div>
                      <div class="scoutSelectorContainer">
                        <form
                          @submit="associateScoutToMatch"
                          class="associateScoutToMatchForm"
                        >
                          <input
                            type="hidden"
                            name="gameId"
                            :value="incomingGame.match_id"
                          />
                          <select
                            @change="setScoutViewer"
                            :name="incomingGame.match_id"
                            :id="incomingGame.match_id"
                          >
                            <option disabled selected value="disabled">
                              {{ $t("select.aScout") }}
                            </option>
                            <option
                              :value="scout.id"
                              v-for="(scout, scoutIndex) in availableScouts"
                              :key="scoutIndex"
                              :selected="
                                isSelected(incomingGame.scoutAssigned, scout.id)
                              "
                            >
                              {{ scout.firstname + " " + scout.lastname }}
                            </option>
                          </select>
                          <button
                            type="submit"
                            :ref="`game_${incomingGame.match_id}`"
                            class="submitButton"
                          />
                        </form>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <b-col md="12">
              <b-pagination
                v-if="orderedNotes.length > perPage"
                v-model="currentPage"
                :total-rows="orderedNotes.length"
                :per-page="perPage"
                align="right"
                hide-goto-end-buttons
                hide-ellipsis
              />
            </b-col>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Note from "@/components/Note";
import { mapGetters, mapActions } from "vuex";
import db from "../db";
import moment from "moment";
import axios from "axios";
import mixinDates from "../mixins/mixinDates";
import mixinTranslation from "../mixins/mixinTranslation";
import mixinScouts from "../mixins/mixinScouts";
export default {
  name: "PlayerComments",
  mixins: [mixinDates, mixinTranslation, mixinScouts],
  data() {
    return {
      cellNote: {},
      editingCellNote: false,
      initialMessage: "",
      currentGameId: null,
      checkedNotes: [],
      notesGames: [],
      notesTab: "global",
      mark: 0,
      comment: "",
      gamesHavingNotes: [],
      incomingGames: [],
      associatedGamesAndScouts: [],
      storedIncomingGames: [],
      incomingPlayerTeamMatchs: [],
      availableScouts: [],
      currentScoutGamesLocations: [],
      currentPage: 1,
      perPage: 6,
      scoutsIdForCurrentUser: [],
      managersIdForCurrentUser: [],
    };
  },
  props: ["playerNotes", "playerMarks", "playerTeam", "player"],
  components: { Note },
  mounted() {
    const self = this;
    if (!self.getGames.length) {
      self.bindGames();
    }
    if (self.getCurrentScout.isManager) {
      self.availableScouts = self.lodash.filter(
        self.getScouts,
        function (scout) {
          return (
            !scout.ghost && !scout.isManager && self.isAvailableScout(scout.id)
          );
        }
      );
    }
    self.managePlayerMarks();
    self.setStoredIncomingGames();
    self.loadIncomingGames();
  },
  filters: {
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
  },
  computed: {
    ...mapGetters([
      "getCurrentScout",
      "getScouts",
      "getGames",
      "getCurrentScoutGames",
      "getManagersForCurrentUser",
      "getScoutsForCurrentUser",
      "getCompanies",
    ]),
    getScoutsForCurrentUser() {
      return this.$store.getters.getScoutsForCurrentUser;
    },
    getCurrentCompanyScoutsIds() {
      const self = this;
      if (self.getCurrentScout.company === self.getCompanies[0].id) {
        return self.getScouts.map((scout) => scout.id);
      } else {
        const toReturn = self.getScouts.filter((scout) => {
          if (scout.company === self.getCurrentScout.company) {
            return scout.id;
          }
        });

        return toReturn.map((scout) => scout.id);
      }
    },
    getCurrentGameNotes() {
      const self = this;
      if (self.currentGameId) {
        const toReturn = [];
        self.notesGames.forEach((noteGame) => {
          if (
            noteGame.gameId === self.currentGameId &&
            !self.getCurrentScout.isManager
          ) {
            toReturn.push(noteGame);
          }
          if (
            self.getCurrentScout.isManager &&
            self.getCurrentCompanyScoutsIds.includes(noteGame.authorId) &&
            noteGame.gameId === self.currentGameId
          ) {
            toReturn.push(noteGame);
          }
        });
        return toReturn;
      }
      return [];
    },
    globalNotes() {
      const self = this;
      self.checkNotes();
      const managersIdForCurrentUser = self.getManagersForCurrentUser.map(
        (manager) => manager.id
      );
      const scoutsIdForCurrentUser = self.getScoutsForCurrentUser.map(
        (scout) => scout.id
      );
      self.updateManagersList(managersIdForCurrentUser);
      self.updateScoutsList(scoutsIdForCurrentUser);
      const currentCompanyScoutsNotes = self.checkedNotes.filter((note) => {
        if (self.getCurrentScout.isManager) {
          if (managersIdForCurrentUser.includes(note.authorId)) {
            return true;
          } else if (scoutsIdForCurrentUser.includes(note.authorId)) {
            return true;
          }
          return false;
        }
        return self.getCurrentScout.id === note.authorId;
      });

      if (self.getCurrentScout.isManager) {
        if (
          self.getCompanies[0] &&
          self.getCurrentScout.company === self.getCompanies[0].id
        ) {
          let notes = self.lodash.filter(self.checkedNotes, function (note) {
            if ((note.isGlobal || !note.gameId) && note.category != "cell") {
              return note;
            }
          });
          return notes;
        } else {
          let notes = self.lodash.filter(
            currentCompanyScoutsNotes,
            function (note) {
              if (note.category == "cell") {
                self.cellNote = note;
                self.comment = note.comment;
                self.initialMessage = note.comment;
              }

              if ((note.isGlobal || !note.gameId) && note.category != "cell") {
                return note;
              }
            }
          );
          notes = notes.filter((note) => {
            return self.isTheScoutInCurrentScoutCompany(note.authorId);
          });
          return notes;
        }
      }
      const notes = self.lodash.filter(
        currentCompanyScoutsNotes,
        function (note) {
          if ((note.isGlobal || !note.gameId) && note.category != "cell") {
            return note;
          }
        }
      );
      return notes;
    },
    groupedNotesByGame() {
      console.log(this.notesGames);
      const groupedNotesByGame = this.lodash.groupBy(this.notesGames, "gameId");
      if (groupedNotesByGame["null"]) {
        delete groupedNotesByGame["null"];
      }
      return groupedNotesByGame;
    },
    orderedNotes() {
      return {
        length: this.incomingPlayerTeamMatchs.length,
        sliceArray: this.incomingPlayerTeamMatchs.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
        ),
      };
    },
  },
  methods: {
    ...mapActions(["bindGames"]),

    scoutSceneName(scout) {
      if (scout.isManager) {
        return scout.firstname.charAt(0) + ". " + scout.lastname + " (m)";
      }
      return scout.firstname.charAt(0) + ". " + scout.lastname;
    },
    isGivenScoutInCurrentUserScope(scoutElement, filter) {
      const self = this;
      if (filter === "byId") {
        const mergedCurrentUserColleaguesId = self.lodash
          .uniqBy(
            self.lodash.union(
              self.getScoutsForCurrentUser,
              self.getManagersForCurrentUser
            ),
            "id"
          )
          .map((user) => user.id);
        return mergedCurrentUserColleaguesId.includes(scoutElement);
      } else {
        const mergedCurrentUserColleaguesSceneName = self.lodash
          .uniqBy(
            self.lodash.union(
              self.getScoutsForCurrentUser,
              self.getManagersForCurrentUser
            ),
            "id"
          )
          .map((user) => self.scoutSceneName(user));
        return mergedCurrentUserColleaguesSceneName.includes(scoutElement);
      }
    },
    isAvailableScout(scoutId) {
      const scoutsIdForCurrentUser = this.getScoutsForCurrentUser.map(
        (scout) => {
          return scout.id;
        }
      );
      return scoutsIdForCurrentUser.includes(scoutId);
    },
    updateManagersList(newList) {
      this.managersIdForCurrentUser = newList;
    },
    updateScoutsList(newList) {
      this.scoutsIdForCurrentUser = newList;
    },
    isTheScoutInCurrentScoutCompany(scoutId) {
      const self = this;
      return self.getScouts.filter((scout) => {
        if (scout.id === scoutId) {
          return (
            self.getCurrentScout.company === self.getCompanies[0].id ||
            scout.company === self.getCurrentScout.company
          );
        }
      }).length;
    },
    isSelected(scoutAssigned, scoutId) {
      return scoutAssigned == scoutId;
    },
    setStoredIncomingGames() {
      const now = moment().unix();
      this.storedIncomingGames = this.lodash.filter(
        this.getGames,
        function (game) {
          return game.date > now;
        }
      );
    },
    setScoutViewer(e) {
      const self = this;
      const gameId = e.target.id;

      if (this.$refs["game_" + gameId]) {
        this.$refs["game_" + gameId][0].classList.remove("validated");
      }

      const selectedMatchIndex = self.lodash.findIndex(
        self.incomingPlayerTeamMatchs,
        function (match) {
          return match.match_id == gameId;
        }
      );
      if (selectedMatchIndex > -1) {
        self.associatedGamesAndScouts[gameId] = {
          scoutAssigned: e.target.value,
          companyGame: self.identifyScoutById(e.target.value, "company"),
          teamAway:
            self.incomingPlayerTeamMatchs[selectedMatchIndex].away_team_name,
          teamHome:
            self.incomingPlayerTeamMatchs[selectedMatchIndex].home_team_name,
          date: moment(
            self.incomingPlayerTeamMatchs[selectedMatchIndex].match_date,
            "YYYY-MM-DD"
          ).unix(),
          id: gameId,
        };
      }
    },
    async validateLocation(game) {
      const self = this;
      if (self.currentScoutGamesLocations[game.match_id]) {
        const gameUpdated = {
          date: moment(game.match_date, "YYYY-MM-DD").unix(),
          id: game.match_id.toString(),
          location: self.currentScoutGamesLocations[game.match_id].toString(),
          scoutAssigned: self.getCurrentScout.id,
          companyGame: self.getCurrentScout.company,
          teamAway: game.away_team_name.toString(),
          teamHome: game.home_team_name.toString(),
        };

        const scoutGameUpdated = {
          date: moment(game.match_date, "YYYY-MM-DD").unix(),
          id: game.match_id.toString(),
          location: self.currentScoutGamesLocations[game.match_id].toString(),
          teamAway: game.away_team_name.toString(),
          teamHome: game.home_team_name.toString(),
        };
        const batch = db.batch();
        const scoutGameRef = db
          .collection("V3scouts")
          .doc(self.getCurrentScout.id.toString())
          .collection("games")
          .doc(game.match_id.toString());
        batch.set(scoutGameRef, scoutGameUpdated, { merge: true });
        const gameRef = db.collection("V3games").doc(game.match_id.toString());
        batch.set(gameRef, gameUpdated, { merge: true });
        batch
          .commit()
          .then(() => {
            self.$refs[
              "scoutGame_" + game.match_id
            ][0].children[2].classList.add("validated");
            setTimeout(() => {
              self.$refs[
                "scoutGame_" + game.match_id
              ][0].children[2].classList.remove("validated");
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    checkLocation(location, gameId) {
      const matchingGameIndex = this.lodash.findIndex(
        this.getCurrentScoutGames,
        function (game) {
          return game.id == gameId;
        }
      );
      if (matchingGameIndex == -1) {
        return this.currentScoutGamesLocations[gameId]
          ? this.currentScoutGamesLocations[gameId] == location
          : false;
      } else {
        return (
          this.getCurrentScoutGames[matchingGameIndex].location == location
        );
      }
    },
    setLocation(gameId, location) {
      const matchingGameIndex = this.lodash.findIndex(
        this.getCurrentScoutGames,
        function (game) {
          return game.id == gameId;
        }
      );
      if (matchingGameIndex > -1) {
        this.getCurrentScoutGames[matchingGameIndex].location = location;
      } else {
        this.getCurrentScoutGames.push({
          id: gameId,
          location: location,
        });
      }
      this.currentScoutGamesLocations[gameId] = location;

      const submiButton = this.$refs["scoutGame_" + gameId][0].children[2];
      submiButton.classList.remove("validated");
    },
    async associateScoutToMatch(e) {
      const self = this;
      e.preventDefault();
      const gameId = e.target.elements[0].value.toString();
      if (!self.associatedGamesAndScouts[gameId]) {
        return;
      }
      const batch = db.batch();
      const gameRef = db.collection("V3games").doc(gameId.toString());
      await gameRef.get().then((gameDoc) => {
        if (gameDoc.exists) {
          const gameDatas = gameDoc.data();
          if (
            gameDatas.playersIdsToWatch &&
            gameDatas.playersIdsToWatch.length
          ) {
            const playersIdsToWatch = gameDatas.playersIdsToWatch;
            self.associatedGamesAndScouts[gameId].playersIdsToWatch =
              playersIdsToWatch;
          }
        }
      });
      if (
        !self.associatedGamesAndScouts[gameId].playersIdsToWatch ||
        !self.associatedGamesAndScouts[gameId].playersIdsToWatch.length
      ) {
        self.associatedGamesAndScouts[gameId].playersIdsToWatch = [];
      }

      self.associatedGamesAndScouts[gameId].playersIdsToWatch.push(
        self.player.id
      );
      batch.set(gameRef, self.associatedGamesAndScouts[gameId], {
        merge: true,
      });

      await gameRef.get().then(async (gameDoc) => {
        if (gameDoc.exists) {
          const gameDatas = gameDoc.data();
          if (gameDatas.scoutAssigned) {
            await db
              .collection("V3scouts")
              .doc(gameDatas.scoutAssigned.toString())
              .collection("games")
              .doc(gameId.toString())
              .get()
              .then(async (scoutGameDoc) => {
                if (scoutGameDoc.exists) {
                  const scoutGameDatas = scoutGameDoc.data();
                  if (!scoutGameDatas.location) {
                    await db
                      .collection("V3scouts")
                      .doc(gameDatas.scoutAssigned.toString())
                      .collection("games")
                      .doc(gameId.toString())
                      .delete();
                  }
                }
              });
          }
        }
      });

      const scoutGameRef = db
        .collection("V3scouts")
        .doc(self.associatedGamesAndScouts[gameId].scoutAssigned.toString())
        .collection("games")
        .doc(gameId.toString());

      batch.set(scoutGameRef, {
        date: self.associatedGamesAndScouts[gameId].date,
        id: self.associatedGamesAndScouts[gameId].id,
        teamAway: self.associatedGamesAndScouts[gameId].teamAway,
        teamHome: self.associatedGamesAndScouts[gameId].teamHome,
      });
      batch.commit().then(() => {
        if (self.$refs["game_" + gameId]) {
          self.$refs["game_" + gameId][0].classList.add("validated");
        }
        setTimeout(() => {
          self.$refs["game_" + gameId][0].classList.remove("validated");
        }, 3000);
      });
    },
    loadIncomingGames() {
      const self = this;
      axios
        .get(
          "https://ds-ol-scout-app-py-api-3.azurewebsites.net/matches/future/"
        )
        .then((response) => {
          self.incomingGames = response.data;
        })
        .catch((error) => {
          console.log("An error occured", error);
          self.incomingGames = [];
        });
    },
    updateMark(newMark) {
      this.mark = newMark;
      this.editingCellNote = true;
    },
    managePlayerMarks() {
      if (this.playerMarks) {
        this.mark = this.playerMarks[this.getCurrentScout.company] || 0;
      }
    },
    getGameById(gameId) {
      return this.lodash.filter(this.gamesHavingNotes, { id: gameId })[0];
    },
    getGameLetter(gameId) {
      const self = this;
      if (!self.groupedNotesByGame[gameId][0]) {
        return false;
      }

      if (
        self.groupedNotesByGame[gameId].length &&
        self.groupedNotesByGame[gameId][0].id < 1690848000
      ) {
        return true;
      }
      return self.groupedNotesByGame[gameId][0].letterNote || false;
    },
    submit() {
      const self = this;
      if (this.mark) {
        db.collection("V3players")
          .doc(self.$route.params.playerId.toString())
          .get()
          .then((playerDoc) => {
            const playerData = playerDoc.data();
            if (!playerData.marks) {
              playerData.marks = {};
            }
            playerData.marks[self.getCurrentScout.company] = this.mark;
            db.collection("V3players")
              .doc(self.$route.params.playerId.toString())
              .set(playerData);
          });
      }

      if (
        this.initialMessage == this.comment ||
        (!this.comment && this.initialMessage == this.comment)
      ) {
        this.editingCellNote = false;
        return;
      }

      let category = null;
      if (self.getCurrentScout.isManager) {
        category = "cell";
      }
      let now = moment().unix();
      let previousDate = now;

      if (self.cellNote.id) {
        previousDate = self.cellNote.id;
      }
      const batch = db.batch();

      const refScoutPlayerNotes = db
        .collection("V3scouts")
        .doc(self.getCurrentScout.id.toString())
        .collection("players")
        .doc(self.$route.params.playerId.toString())
        .collection("notes")
        .doc(previousDate.toString());

      const refNote = db.collection("V3notes").doc(previousDate.toString());

      const playerNoteRef = db
        .collection("V3players")
        .doc(self.$route.params.playerId.toString())
        .collection("notes")
        .doc(previousDate.toString());

      if (!this.comment) {
        batch.delete(refScoutPlayerNotes);
        batch.delete(refNote);
        batch.delete(playerNoteRef);
      } else {
        batch.set(
          refScoutPlayerNotes,
          {
            category: category,
            comment: self.comment.toString(),
            gameId: null,
            id: parseInt(previousDate),
            updatedAt: now.toString(),
          },
          { merge: true }
        );
        batch.set(
          refNote,
          {
            authorId: self.getCurrentScout.id.toString(),
            category: category,
            comment: self.comment.toString(),
            authorCompany: self.getCurrentScout.company,
            id: parseInt(previousDate),
            updatedAt: now.toString(),
            gameId: null,
            isGlobal: true,
            playerId: self.$route.params.playerId.toString(),
          },
          { merge: true }
        );
        batch.set(
          playerNoteRef,
          {
            authorId: self.getCurrentScout.id.toString(),
            category: category,
            comment: self.comment.toString(),
            authorCompany: self.getCurrentScout.company,
            gameId: null,
            id: parseInt(previousDate),
            updatedAt: now.toString(),
          },
          { merge: true }
        );
      }

      batch
        .commit()
        .then(function () {
          self.err = "";
          if (!self.getCurrentScout.isManager) {
            self.comment = "";
          }
          self.editingCellNote = false;
        })
        .catch(function (error) {
          self.err = error;
        });
    },
    toggleNoteTab(tab) {
      this.notesTab = tab;
    },
    getStarSource(scoreValue) {
      if (this.mark < scoreValue) {
        return require("../assets/img/emptyStar.svg");
      } else if (this.mark >= scoreValue) {
        return require("../assets/img/fullStar.svg");
      }
    },
    checkNotes() {
      if (!this.playerNotes.length) {
        return;
      }
      const groupedNotes = this.lodash.groupBy(this.playerNotes, "authorId");
      if (this.getCurrentScout.isManager) {
        this.checkedNotes = this.lodash.orderBy(
          this.playerNotes,
          ["id"],
          ["desc"]
        );
      } else if (groupedNotes[this.getCurrentScout.id]) {
        this.checkedNotes = this.lodash.orderBy(
          groupedNotes[this.getCurrentScout.id],
          ["id"],
          ["desc"]
        );
      } else {
        this.checkedNotes = [];
      }
    },
  },
  watch: {
    getScouts() {
      const self = this;
      if (self.getCurrentScout.isManager) {
        self.availableScouts = self.lodash.filter(
          self.getScouts,
          function (scout) {
            return (
              !scout.ghost &&
              !scout.isManager &&
              self.isAvailableScout(scout.id)
            );
          }
        );
      }
    },
    incomingGames() {
      const self = this;
      const currentPlayerTeamMatchs = self.lodash.filter(
        self.incomingGames,
        (match) => {
          return (
            match.home_team_name === self.playerTeam ||
            match.away_team_name === self.playerTeam
          );
        }
      );
      const now = moment().format("YYYY-MM-DD");
      self.incomingPlayerTeamMatchs = self.lodash.filter(
        currentPlayerTeamMatchs,
        function (incomingGame) {
          return moment(incomingGame.match_date).isAfter(now);
        }
      );
      self.incomingPlayerTeamMatchs.forEach((incomingGame, index) => {
        self.currentScoutGamesLocations[incomingGame.match_id] = null;
        const matchingGame = self.lodash.filter(
          self.storedIncomingGames,
          function (game) {
            return game.id == incomingGame.match_id;
          }
        );
        if (matchingGame[0] && matchingGame[0].scoutAssigned) {
          self.incomingPlayerTeamMatchs[index].scoutAssigned =
            matchingGame[0].scoutAssigned;
        }
      });
    },
    getGames() {
      this.setStoredIncomingGames();
    },
    storedIncomingGames() {
      const self = this;
      self.storedIncomingGames.forEach((storedIncomingGame) => {
        self.associatedGamesAndScouts[storedIncomingGame.id] = {
          scoutAssigned: storedIncomingGame.scoutAssigned,
          companyGame: self.identifyScoutById(
            storedIncomingGame.scoutAssigned,
            "company"
          ),
          teamAway: storedIncomingGame.teamAway,
          teamHome: storedIncomingGame.teamHome,
          date: storedIncomingGame.date,
          id: storedIncomingGame.id,
        };
      });
    },
    getCurrentScout: {
      handler() {
        this.loadIncomingGames();
        this.notesTab = "global";
      },
    },
    playerMarks() {
      this.managePlayerMarks();
    },
    playerNotes() {
      this.checkNotes();
    },
    checkedNotes() {
      const self = this;
      this.gamesHavingNotes = [];
      const notesGames = this.lodash.filter(this.checkedNotes, (note) => {
        const author = self.getScouts.filter(
          (scout) => scout.id === note.authorId
        )[0];
        if (author) {
          if (
            this.isGivenScoutInCurrentUserScope(self.scoutSceneName(author))
          ) {
            if (note.gameId) {
              if (!this.currentGameId) {
                this.currentGameId = note.gameId;
              }
              db.collection("V3games")
                .doc(note.gameId)
                .get()
                .then((gameDoc) => {
                  const gameDatas = gameDoc.data();
                  this.gamesHavingNotes.push(gameDatas);
                });
              return note;
            }
          }
        }
      });
      this.notesGames = notesGames;
    },
  },
};
</script>
<style scoped>
.associateScoutToMatchForm {
  display: flex;
}

.matchActionsContent {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 0 10px;
}

.submitButton {
  width: 30px;
  height: 30px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #0b0790;
  background: transparent;
  color: #0b0790;
  position: relative;
  border-width: 1px;
  transition: 1s;
}

.submitButton.validated {
  border: 0;
  background: #0b0790;
  color: white;
}

.submitButton:before {
  content: url(../assets/img/iconValidate.svg);
  display: block;
  width: 100%;
}

.submitButton.validated:before {
  content: url(../assets/img/iconValidateValidated.svg);
}

.matchActionsContent select {
  border-radius: 5px;
  border: 0;
}

.locationsInstructionsContainer {
  margin-right: 10px;
}

.locationsContainer {
  display: flex;
}

.stadiumLocation:hover {
  background-color: rgba(214, 40, 40, 0.8);
  border-color: rgba(214, 40, 40, 0.8);
}

.videoLocation:hover {
  background-color: rgba(4, 103, 241, 0.8);
  border-color: rgba(4, 103, 241, 0.8);
}

.stadiumLocation.active {
  background-color: #d62828;
  border-color: #d62828;
}

.videoLocation.active {
  background-color: #0467f1;
  border-color: #0467f1;
}

.locationContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 5px 10px;
  margin: 0 10px;
  border: 1px solid #0b0790;
}

.locationContainer:hover {
  color: white;
}

.locationContainer.active {
  color: white;
}

.matchContainer {
  color: #0b0790;
}

.matchContainer:nth-child(odd) {
  background: #dbdef8;
}

.matchContainer:nth-child(even) {
  background: #edeffb;
}

.matchContent {
  margin: 10px 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.matchInfos {
  display: flex;
  border-right: 2px solid #8b95ea;
  align-items: center;
  font-weight: 600;
  flex: 1;
}

.matchActionsContainer {
  flex: 1;
  display: flex;
  justify-content: center;
}

.matchDate {
  margin-right: 20px;
}

.matchTeams {
  text-transform: uppercase;
}

.pinStadium {
  color: #0467f1;
}

.pinVideo {
  color: #d62828;
}

.pinStats {
  color: #b362d0;
}

#cellComment {
  position: relative;
  background: rgba(255, 255, 255, 0.9);
}

.editing #cellComment {
  background: rgba(255, 255, 255, 1);
}

.addNoteContainer {
  position: relative;
  margin-bottom: 14px;
  width: 100%;
  color: #04004b;
}

.editingCellNoteContainer {
  position: absolute;
  display: inline-block;
  width: 35px;
  height: 35px;
  padding: 7px 10px 10px 10px;
  background: #da5856;
  color: white;
  border-radius: 50%;
  top: -15px;
  right: 10px;
}

.editing .editingCellNoteContainer {
  display: none;
}

.addNoteContainer textarea {
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  border: 0;
  min-height: 200px;
  display: block;
}

#tabsContainer {
  display: flex;
  flex-direction: row;
  padding: 0;
  justify-content: center;
  margin-bottom: 30px;
}

#tabsContainer li {
  flex: 1;
}

.tabContainer {
  list-style: none;
  padding: 0 20px;
  color: #7891a4;
  font-weight: bold;
  position: relative;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabContainer:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -7px;
  height: 1px;
  width: 100%;
  background: #7891a4;
}

.tabContainer span {
  position: relative;
  padding: 0 13px;
  cursor: pointer;
}

.tabContainer.active {
  color: #0b0790;
}

.tabContainer.active:after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background: #0b0790;
  bottom: -9px;
  left: 0;
  z-index: 1;
}

.cardContainer {
  background-color: white;
  border-radius: 20px;
  padding: 30px !important;
  margin-top: 30px;
}

.gamesDesign {
  background: #8b95ea;
}

.gamesDesign .cardColumnContainer:last-of-type {
  background: transparent;
}

.cardColumnContainer {
  background: #0b0790;
  padding: 30px;
}

.cardColumnTitle {
  color: white;
  text-transform: uppercase;
  font-size: 1.125rem;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
}

.cardColumnTitle:after {
  content: "";
  width: 20%;
  max-width: 150px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  bottom: -6px;
  left: 0;
  border-bottom: 1px solid #8b95ea;
}

.notesContainer,
.gamesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: white;
}

.linkToGameContainer {
  width: 100%;
  text-align: center;
}

.linkToGameContainer a {
  text-decoration: none;
  color: white;
  display: inline-block;
  background: #0b0790;
  padding: 6px 15px;
}

.gamesDesign .gameContainer {
  display: block;
  padding: 20px;
  position: relative;
  background: white;
  border-radius: 0px;
  color: #04004b;
  margin-bottom: 20px;
  width: 100%;
}

.gamesDesign .gameContainer.activeGame {
  background: #8b95ea;
}

.gameContent {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.gameNotification {
  background: #da5856;
  color: white;
  border-radius: 50%;
  width: 30px;
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
}

.gameInfosContainer {
  flex: 10;
  padding-left: 10px;
}

.isSelectedContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: 100;
  flex: 1;
  color: #da5856;
}

.activeGame .gameTeams {
  color: white;
}

.gameTeams {
  font-weight: bold;
  color: #8b95ea;
}

.starContainer {
  display: flex;
}

.starContent {
  margin-right: 15px;
}

.starContent img {
  width: 30px;
  height: 30px;
}

#starsContainer {
  margin-bottom: 20px;
}

.markTitle {
  color: white;
  text-transform: capitalize;
}

.validateCellNote {
  background: #da5856;
  padding: 7px 15px;
  color: white;
  border: 0;
  float: right;
}

.validateCellNote:disabled {
  background: #944443;
  cursor: not-allowed;
}

@media screen and (max-width: 992px) {
  .matchActionsContent {
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 786px) {
  .globalDesign .cardColumnContainer,
  .gamesDesign .cardColumnContainer {
    margin-bottom: 20px;
  }

  .gamesDesign {
    background: transparent;
  }

  .gamesDesign .cardColumnContainer:last-of-type {
    background-color: #8b95ea;
  }

  /* .cardColumnContainer:nth-child(even) {
    margin-left: 0px;
  }

  .cardColumnContainer:nth-child(odd) {
    margin-right: 0px;
  } */

  .tabContainer {
    font-size: 1rem;
  }

  .matchInfos {
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
  }

  .matchTeams {
    font-weight: bold;
    margin: 0 10px;
  }

  .matchDate {
    margin-right: 0px;
  }

  .tabContainer span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .tabContainer {
    padding: 0;
    font-size: 0.9rem;
  }

  .matchInfos {
    border-right: 0;
  }
}
</style>
