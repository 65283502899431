var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-container"},[(_vm.showColumnsTitle)?_c('div',{staticClass:"list-header list-item"},_vm._l((_vm.columnsDefinitions),function(column,columnIndex){return _c('div',{key:`column_title_${columnIndex}`,staticClass:"list-item-column",style:({
        'width': column.width,
        'align-items': _vm.getFlexAlignment(column.align),
      })},[_c('span',[_vm._v(" "+_vm._s(column.title)+" ")])])}),0):_vm._e(),_c('Draggable',{staticClass:"list",attrs:{"ghost-class":"temporary-item","dragClass":'dragging',"disabled":!_vm.orderable,"draggable":".draggable","animation":100},model:{value:(_vm.computedItems),callback:function ($$v) {_vm.computedItems=$$v},expression:"computedItems"}},_vm._l((Math.max(_vm.maxItems, _vm.computedItems.length)),function(index){return _c('div',{key:`item_${index}`,staticClass:"list-item",class:{
        'draggable': _vm.computedItems[index - 1],
        orderable: _vm.orderable,
        'is-incoming': _vm.computedItems[index - 1]?.isIncoming,
      },on:{"dragstart":_vm.startDrag,"dragend":_vm.endDrag}},[(_vm.computedItems[index - 1])?_vm._l((_vm.columnsDefinitions),function(column,columnIndex){return _c('div',{key:`column_${index}_${columnIndex}`,staticClass:"list-item-column",class:{ clickable: !!column.href || !!column.action },style:({
            'width': column.width,
            'align-items': _vm.getFlexAlignment(column.align),
          }),on:{"click":function($event){return _vm.handleColumnClick(_vm.computedItems[index - 1], column)}}},[(column?.pictoSrc)?_c('img',{staticClass:"picto",attrs:{"src":column.pictoSrc(_vm.computedItems[index - 1])}}):(column.name === 'dragIcon')?_c('div',{staticClass:"draggable-icon"},[_c('img',{attrs:{"src":require("../../assets/img/draggable.svg")}})]):(column)?_c('span',[_vm._v(" "+_vm._s(column.value(_vm.computedItems[index - 1]))+" ")]):_vm._e()])}):_vm._e()],2)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }