<template>
  <div
    class="players-list-item"
    :class="{ ...getEndContractColorClass(player.endContract) }"
    @click="showPopin"
  >
    <div class="player-infos">
      <span class="player-name">
        {{ player.name }}
      </span>
      <span class="player-position">
        {{ $t(positionTranslationKey)
        }}<span v-if="player.endContract">
          / {{ $t("endOfContractAcronym") }} :
          <span class="player-end-contract" v-if="player.endContract">
            {{ formatDate(player.endContract, false, "MMMM YYYY") }}
          </span>
        </span>
      </span>
    </div>
    <div class="budget-parent">
      <div class="budget-infos">
        <span class="player-price" v-if="hasPrice">
          {{ player.price }} {{ $t("millions") }}
        </span>
        <span v-if="displaySalary && hasSalary" class="player-salary">
          {{ player.salary }} {{ $t("millionsPerYear") }}
        </span>
      </div>
      <div v-if="displayExitPicto" class="exit-picto">
        <img
          :id="`exit_${player.id}`"
          src="../../assets/img/pictos/exit.svg"
          alt="exit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mixinPlayers from "../../mixins/mixinPlayers";
import mixinDates from "../../mixins/mixinDates";
import { popinVariants } from "../../enums/popinVariants";
import { listVariants } from "../../enums/listVariants";
import { mapMutations } from "vuex";
export default {
  mixins: [mixinPlayers, mixinDates],

  data() {
    return {
      displayExitPicto: false,
      displaySalary: false,
    };
  },
  props: {
    player: { type: Object },
    listVariant: { type: String },
  },
  methods: {
    ...mapMutations("popin", ["openPopinVariant", "closePopin"]),
    showPopin() {
      const storePayload = {
        popinVariant: this.popinVariant,
        popinPayload: this.player,
      };
      this.openPopinVariant(storePayload);
    },
    hidePopin() {
      this.closePopin();
    },
  },
  mounted() {
    switch (this.listVariant) {
      case listVariants.SOLD:
        this.displayExitPicto = true;
        break;
      case listVariants.AVAILABLE:
        this.displaySalary = true;
        break;
      default:
        break;
    }
  },
  computed: {
    hasSalary() {
      return !isNaN(parseFloat(this.player.salary));
    },
    hasPrice() {
      return !isNaN(parseFloat(this.player.price));
    },
    positionTranslationKey() {
      return this.getPositionTranslationKeyFromPlayer(this.player);
    },
    popinVariant() {
      switch (this.listVariant) {
        case listVariants.SOLD:
          return popinVariants.PREVENT_SELL;
        case listVariants.AVAILABLE:
          return popinVariants.SELL_PLAYER;
        case listVariants.PROSPECTS:
          return popinVariants.SHOW_PROSPECT_INFOS;
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";

div.players-list-item {
  padding: 13px 12px 13px 20px;
  height: 58px;
  display: flex;
  flex-direction: row;
  background-color: #fafafa;
  cursor: pointer;
  justify-content: space-between;
}

div.budget-parent {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 6px;
  max-width: 30%;
  overflow-x: hidden;
  overflow-y: clip;
}

div.budget-infos {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: center;
  word-break: break-all;
  max-width: 100%;
  white-space: nowrap;
  overflow-x: clip;
  overflow-y: clip;
  text-overflow: ellipsis;
  text-align: left;
}

div.exit-picto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div.player-infos {
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  max-width: 70%;
}

span.player-name {
  font-weight: 700;
  font-size: 0.875rem;
  color: $blueDarkest;
  word-break: break-all;
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
  text-align: left;
}

span.player-position {
  font-weight: 400;
  font-size: 0.75rem;
  color: $blueDarkest;
}

span.player-end-contract {
  text-transform: capitalize;
}

span.player-price {
  font-weight: 700;
  color: $blueDarkest;
  word-break: break-all;
  max-width: 100%;
  white-space: nowrap;
  overflow-x: clip;
  text-align: left;
}
span.player-salary {
  font-weight: 400;
  color: $blueDarkest;
  font-size: 0.75rem;
}
</style>
