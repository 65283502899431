<template>
  <div id="gameTeamsContainer">
    <div>
      <div id="gameTeamsNames" class="text-center">
        <div
          :class="{
            teamName: true,
            active: sidebarAddPlayer.selectedTeam === 'home',
          }"
          @click="changeDisplayedTeam('home')"
        >
          {{ game.teamHome }}
        </div>
        <div
          :class="{
            teamName: true,
            active: sidebarAddPlayer.selectedTeam === 'away',
          }"
          @click="changeDisplayedTeam('away')"
        >
          {{ game.teamAway }}
        </div>
      </div>
      <div id="gameViewLegend">
        <span class="gameViewLegendElement bucket">{{
          $t("gameViewlegend.bucketPlayer")
        }}</span>
        <span class="gameViewLegendElement shadowPlayer">{{
          $t("gameViewlegend.shadowPlayer")
        }}</span>
        <span class="gameViewLegendElement comment">{{
          $t("gameViewlegend.commentadded")
        }}</span>
      </div>
      <div
        @click="scrollToTopView"
        id="commentedPlayerscontainer"
        v-if="commentedPlayers.length"
      >
        <div
          v-for="(player, index) in commentedPlayers"
          :key="index"
          :class="{
            gamePlayerContainer: true,
            playerActive:
              player.id &&
              getSelectedPlayer.id &&
              player.id === getSelectedPlayer.id,
          }"
          @click="setSelectedPlayer(player)"
        >
          <div
            :class="{
              playerName: true,
              textBold: hasNote(player.id.toString(), currentScopeGameNotes),
            }"
          >
            <span v-if="player.isInBucket" class="bucketPlayerIcon"></span>
            <span v-if="player.isInShadow" class="shadowPlayerIcon"></span>
            {{ player.name }}
          </div>

          <div class="togglePlayerSelectionContainer">
            <span
              v-if="hasNote(player.id.toString(), currentScopeGameNotes)"
              class="togglePlayerCommented"
            ></span>
            <span v-else class="togglePlayer"></span>
          </div>
        </div>
      </div>
      <div
        v-if="commentedPlayers.length && otherPlayers.length"
        class="playersListSeparator"
      ></div>
      <div
        @click="scrollToTopView"
        id="otherPlayersContainer"
        v-if="otherPlayers.length"
      >
        <div
          v-for="(player, index) in otherPlayers"
          :key="index"
          :class="{
            gamePlayerContainer: true,
            playerActive: player.id === getSelectedPlayer.id,
            selected:
              player?.id &&
              getSelectedPlayer?.id &&
              player.id === getSelectedPlayer.id,
          }"
          @click="setSelectedPlayer(player)"
        >
          <div
            :class="{
              playerName: true,
              textBold: hasNote(player.id.toString(), currentScopeGameNotes),
            }"
          >
            <span v-if="player.isInBucket" class="bucketPlayerIcon"></span>
            <span v-if="player.isInShadow" class="shadowPlayerIcon"></span>
            {{ player.name }}
          </div>

          <div class="togglePlayerSelectionContainer">
            <span
              v-if="hasNote(player.id.toString(), currentScopeGameNotes)"
              class="togglePlayerCommented"
            ></span>
            <span v-else class="togglePlayer"></span>
          </div>
        </div>
      </div>
      <div id="gameOrderPlayersRow">
        <div id="filterPlayerButtonContainer">
          <button
            v-if="!getCurrentScout?.isManager"
            @click="setAddPlayerToTrue"
          >
            {{ $t("add.aPlayer") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import mixinPlayers from "../../mixins/mixinPlayers";

export default {
  name: "GameList",
  props: {
    changeSelectedTeam: {
      type: Function,
    },
    commentedPlayers: {
      type: Array,
      default: () => [],
    },
    currentScopeGameNotes: {
      type: Array,
      default: () => [],
    },
    orderedMatchingPlayers: {
      type: Array,
      default: () => [],
    },
    game: {},
    sidebarAddPlayer: {},
    otherPlayers: {
      type: Array,
      default: () => [],
    },
    scrollTo: {
      type: Function,
    },
  },
  mixins: [mixinPlayers],
  data() {
    return {
      playersInShadows: [],
      currentScopeScoutsIds: [],
    };
  },
  emits: ["emitDisplayAddPlayer", "emitFilterValue", "emitOrderPlayers"],
  mounted() {
    if (!this.getShadows.length) {
      this.$store.dispatch("bindScoutsShadows");
    }
    if (!this.getCompanies.length) {
      this.$store.dispatch("bindCompanies");
    }
    if (!this.getScouts.length) {
      this.$store.dispatch("bindScouts");
    }
    if (!this.getCurrentScout) {
      this.$store.dispatch("bindCurrentScout");
    }
  },
  methods: {
    setAddPlayerToTrue() {
      this.$emit("emitDisplayAddPlayer");
    },
    updatePlayersFilterValue(event) {
      this.$emit("emitFilterValue", event.target.value);
    },
    scrollToTopView() {
      this.scrollTo();
    },
    setSelectedPlayer(player) {
      this.$store.dispatch("setSelectedPlayer", player);
    },
    changeDisplayedTeam(teamType) {
      this.changeSelectedTeam(teamType);
    },
    arraysHaveCommonValues(array1, array2) {
      return array1.some((value) => array2.includes(value));
    },
    loadShadowsPlayers() {
      if (
        !this.getCurrentScout ||
        !this.getCompanies.length ||
        !this.getScouts.length ||
        !this.getShadows.length
      ) {
        return;
      }
      this.currentScopeScoutsIds = this.getCurrentScopeScouts.map((scout) => {
        return scout.id;
      });
      this.$emit("emitOrderPlayers");
    },
  },
  computed: {
    ...mapGetters([
      "getCurrentScout",
      "getScouts",
      "getCompanies",
      "getShadows",
      "getSelectedPlayer",
      "getCurrentScopeScouts",
      "getCurrentScopeShadowsPlayers",
    ]),
  },
  watch: {
    orderedMatchingPlayers: {
      deep: true,
      handler: function () {
        if (this.orderedMatchingPlayers.length) {
          this.loadShadowsPlayers();
        }
      },
    },
    getCurrentScout: {
      deep: true,
      handler: function () {
        if (this.getCurrentScout?.id) {
          this.loadShadowsPlayers();
        }
      },
    },
    getCompanies: {
      deep: true,
      handler: function () {
        if (this.getCompanies.length) {
          this.loadShadowsPlayers();
        }
      },
    },
    getShadows: {
      deep: true,
      handler: function () {
        if (this.getShadows.length) {
          this.loadShadowsPlayers();
        }
      },
    },
  },
  destroyed() {
    this.$store.dispatch("setSelectedPlayer", {});
  },
};
</script>
<style>
#gameTeamsContainer {
  background-color: white !important;
}

#gameTeamsNames {
  display: flex;
  flex-direction: row;
}

.teamName {
  width: 50%;
  color: #b34442;
  padding: 16px 0;
  font-size: 0.875rem;
  font-family: "Proxima Nova", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 0.875rem;
  cursor: pointer;
  position: relative;
}

.teamName:after {
  width: 100%;
  content: "";
  display: block;
  height: 4px;
  background: #b34442;
  position: absolute;
  bottom: 0%;
}

.teamName:last-of-type:after {
  background: #141466;
}

.teamName.active {
  font-weight: 900;
  background: #fdeae9;
}

.teamName:last-of-type.active {
  font-weight: 900;
  background: #e1e1fd;
}

.teamName.active {
  font-weight: 900;
}

.teamName:last-of-type {
  color: #141466;
}

#gameTeamsContainer {
  border: solid 1px #f0f0f0;
  border-radius: 4px;
  padding: 24px;
}

#gameOrderPlayersRow {
  padding: 24px 0;
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px #f6f5ff;
  justify-content: center;
}

#filterPlayerSelectContainer {
  width: 55%;
}

#orderPlayers {
  border: solid 1px #e3e0ff;
  color: #060052;
  font-size: 0.875rem;
  line-height: 1.313rem;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 400;
  padding: 12px 8px;
  width: 100%;
  border-radius: 4px;
}

#filterPlayerButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#filterPlayerButtonContainer button {
  background-color: #060052;
  color: #ffffff;
  padding: 12px 24px;
  border-radius: 100px;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  outline: 0;
  border: 0;
}

.gamePlayerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: solid 1px #f0f0f0;
  padding: 12px;
  margin: 12px 0;
  color: #060052;
  transition: background 0.1s ease, color 0.1s ease;
  border-radius: 4px;
}

.gamePlayerContainer:first-of-type {
  margin-top: 0;
}

.gamePlayerContainer:hover,
.gamePlayerContainer.playerActive {
  background-color: #060052;
  color: white;
  cursor: pointer;
}

.togglePlayer,
.togglePlayerCommented,
.bucketPlayerIcon,
.shadowPlayerIcon {
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
}

.bucketPlayerIcon {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.00227 14.2698L13.1523 16.7798C13.9123 17.2398 14.8423 16.5598 14.6423 15.6998L13.5423 10.9798L17.2123 7.79977C17.8823 7.21977 17.5223 6.11977 16.6423 6.04977L11.8123 5.63977L9.92227 1.17977C9.58227 0.369766 8.42227 0.369766 8.08227 1.17977L6.19227 5.62977L1.36227 6.03977C0.48227 6.10977 0.12227 7.20977 0.79227 7.78977L4.46227 10.9698L3.36227 15.6898C3.16227 16.5498 4.09227 17.2298 4.85227 16.7698L9.00227 14.2698Z' fill='%23C88C28'/%3E%3C/svg%3E%0A");
  background-position: 0 25%;
  margin-right: 8px;
}

.shadowPlayerIcon {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.4686 13.9657H3.49714L2.92571 13.3943V5.78286H1.31429L0.765714 5.34857L0 2.28571L0.377143 1.6L5.24572 0L5.97714 0.365714C6.04181 0.540228 6.12214 0.708533 6.21714 0.868571C6.29829 0.986286 6.39086 1.09714 6.49143 1.2C6.68343 1.39314 6.91314 1.54514 7.16571 1.64571C7.42401 1.75606 7.70198 1.81295 7.98286 1.81295C8.26374 1.81295 8.5417 1.75606 8.8 1.64571C9.05291 1.54487 9.28241 1.39317 9.47429 1.2C9.57714 1.09143 9.672 0.977143 9.76 0.857143L9.93143 0.525714C9.95374 0.473603 9.97282 0.420168 9.98857 0.365714L10.72 0L15.5886 1.62286L16 2.28571L15.2457 5.32571L14.6857 5.76H13.04V13.3714L12.4686 13.9657Z' fill='%23B34442'/%3E%3C/svg%3E%0A");
  background-position: 0 27%;
  margin-right: 8px;
}

.togglePlayer {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='10' fill='%23060052'/%3E%3Cpath d='M9.36842 13.3684C9.36842 13.5359 9.43496 13.6966 9.55341 13.815C9.67185 13.9335 9.83249 14 10 14C10.1675 14 10.3281 13.9335 10.4466 13.815C10.565 13.6966 10.6316 13.5359 10.6316 13.3684V10.6316H13.3684C13.5359 10.6316 13.6966 10.565 13.815 10.4466C13.9335 10.3281 14 10.1675 14 10C14 9.83249 13.9335 9.67185 13.815 9.55341C13.6966 9.43496 13.5359 9.36842 13.3684 9.36842H10.6316V6.63158C10.6316 6.46407 10.565 6.30343 10.4466 6.18499C10.3281 6.06654 10.1675 6 10 6C9.83249 6 9.67185 6.06654 9.55341 6.18499C9.43496 6.30343 9.36842 6.46407 9.36842 6.63158V9.36842H6.63158C6.46407 9.36842 6.30343 9.43496 6.18499 9.55341C6.06654 9.67185 6 9.83249 6 10C6 10.1675 6.06654 10.3281 6.18499 10.4466C6.30343 10.565 6.46407 10.6316 6.63158 10.6316H9.36842V13.3684Z' fill='white'/%3E%3C/svg%3E ");
}

.togglePlayerCommented {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.275 10.5C1.67163 10.5 1.09298 10.2603 0.666332 9.83367C0.239687 9.40702 0 8.82837 0 8.225V2.275C0 1.67163 0.239687 1.09298 0.666332 0.666332C1.09298 0.239687 1.67163 0 2.275 0H11.725C12.0238 0 12.3196 0.0588446 12.5956 0.173174C12.8716 0.287503 13.1224 0.455079 13.3337 0.666332C13.5449 0.877585 13.7125 1.12838 13.8268 1.4044C13.9412 1.68041 14 1.97624 14 2.275V8.225C14 8.52376 13.9412 8.81959 13.8268 9.0956C13.7125 9.37162 13.5449 9.62241 13.3337 9.83367C13.1224 10.0449 12.8716 10.2125 12.5956 10.3268C12.3196 10.4412 12.0238 10.5 11.725 10.5H7.7084L4.2 13.125C4.07 13.2223 3.91549 13.2815 3.75376 13.296C3.59203 13.3105 3.42945 13.2797 3.28423 13.207C3.13901 13.1344 3.01687 13.0227 2.93147 12.8846C2.84608 12.7465 2.8008 12.5874 2.8007 12.425V10.5H2.275Z' fill='%23060052'/%3E%3C/svg%3E ");
}

.gamePlayerContainer:hover .togglePlayerCommented,
.gamePlayerContainer.playerActive .togglePlayerCommented {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.275 10.5C1.67163 10.5 1.09298 10.2603 0.666332 9.83367C0.239687 9.40702 0 8.82837 0 8.225V2.275C0 1.67163 0.239687 1.09298 0.666332 0.666332C1.09298 0.239687 1.67163 0 2.275 0H11.725C12.0238 0 12.3196 0.0588446 12.5956 0.173174C12.8716 0.287503 13.1224 0.455079 13.3337 0.666332C13.5449 0.877585 13.7125 1.12838 13.8268 1.4044C13.9412 1.68041 14 1.97624 14 2.275V8.225C14 8.52376 13.9412 8.81959 13.8268 9.0956C13.7125 9.37162 13.5449 9.62241 13.3337 9.83367C13.1224 10.0449 12.8716 10.2125 12.5956 10.3268C12.3196 10.4412 12.0238 10.5 11.725 10.5H7.7084L4.2 13.125C4.07 13.2223 3.91549 13.2815 3.75376 13.296C3.59203 13.3105 3.42945 13.2797 3.28423 13.207C3.13901 13.1344 3.01687 13.0227 2.93147 12.8846C2.84608 12.7465 2.8008 12.5874 2.8007 12.425V10.5H2.275Z' fill='%23ffffff'/%3E%3C/svg%3E ");
}

.gamePlayerContainer:hover .togglePlayer,
.gamePlayerContainer.playerActive .togglePlayer {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2' y='2' width='20' height='20' rx='10' fill='white'/%3E%3Cpath d='M11.3684 15.3684C11.3684 15.5359 11.435 15.6966 11.5534 15.815C11.6719 15.9335 11.8325 16 12 16C12.1675 16 12.3281 15.9335 12.4466 15.815C12.565 15.6966 12.6316 15.5359 12.6316 15.3684V12.6316H15.3684C15.5359 12.6316 15.6966 12.565 15.815 12.4466C15.9335 12.3281 16 12.1675 16 12C16 11.8325 15.9335 11.6719 15.815 11.5534C15.6966 11.435 15.5359 11.3684 15.3684 11.3684H12.6316V8.63158C12.6316 8.46407 12.565 8.30343 12.4466 8.18499C12.3281 8.06654 12.1675 8 12 8C11.8325 8 11.6719 8.06654 11.5534 8.18499C11.435 8.30343 11.3684 8.46407 11.3684 8.63158V11.3684H8.63158C8.46407 11.3684 8.30343 11.435 8.18499 11.5534C8.06654 11.6719 8 11.8325 8 12C8 12.1675 8.06654 12.3281 8.18499 12.4466C8.30343 12.565 8.46407 12.6316 8.63158 12.6316H11.3684V15.3684Z' fill='%23060052'/%3E%3C/svg%3E ");
}

.gamePlayerContainer:hover .bucketPlayerIcon,
.gamePlayerContainer.playerActive .bucketPlayerIcon {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.00227 14.2698L13.1523 16.7798C13.9123 17.2398 14.8423 16.5598 14.6423 15.6998L13.5423 10.9798L17.2123 7.79977C17.8823 7.21977 17.5223 6.11977 16.6423 6.04977L11.8123 5.63977L9.92227 1.17977C9.58227 0.369766 8.42227 0.369766 8.08227 1.17977L6.19227 5.62977L1.36227 6.03977C0.48227 6.10977 0.12227 7.20977 0.79227 7.78977L4.46227 10.9698L3.36227 15.6898C3.16227 16.5498 4.09227 17.2298 4.85227 16.7698L9.00227 14.2698Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
}

.gamePlayerContainer:hover .shadowPlayerIcon,
.gamePlayerContainer.playerActive .shadowPlayerIcon {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.4686 13.9657H3.49714L2.92571 13.3943V5.78286H1.31429L0.765714 5.34857L0 2.28571L0.377143 1.6L5.24572 0L5.97714 0.365714C6.04181 0.540228 6.12214 0.708533 6.21714 0.868571C6.29829 0.986286 6.39086 1.09714 6.49143 1.2C6.68343 1.39314 6.91314 1.54514 7.16571 1.64571C7.42401 1.75606 7.70198 1.81295 7.98286 1.81295C8.26374 1.81295 8.5417 1.75606 8.8 1.64571C9.05291 1.54487 9.28241 1.39317 9.47429 1.2C9.57714 1.09143 9.672 0.977143 9.76 0.857143L9.93143 0.525714C9.95374 0.473603 9.97282 0.420168 9.98857 0.365714L10.72 0L15.5886 1.62286L16 2.28571L15.2457 5.32571L14.6857 5.76H13.04V13.3714L12.4686 13.9657Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
}

.playerName {
  font-size: 1rem;
  font-weight: 500;
  font-family: "Proxima Nova", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.playerName.textBold {
  font-weight: 700;
}

#gameViewLegend {
  display: flex;
  flex-direction: row;
  padding: 32px 0;
}

.gameViewLegendElement {
  width: calc(100% / 3);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.875rem;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 400;
  color: #060052;
}

.gameViewLegendElement::before {
  content: "";
  height: 16px;
  display: block;
  margin-right: 6px;
  transform: translateY(-4px);
}

.gameViewLegendElement.shadowPlayer {
  justify-content: center;
}

.gameViewLegendElement.comment {
  justify-content: end;
}

.gameViewLegendElement.bucket::before {
  content: url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.00227 14.2698L13.1523 16.7798C13.9123 17.2398 14.8423 16.5598 14.6423 15.6998L13.5423 10.9798L17.2123 7.79977C17.8823 7.21977 17.5223 6.11977 16.6423 6.04977L11.8123 5.63977L9.92227 1.17977C9.58227 0.369766 8.42227 0.369766 8.08227 1.17977L6.19227 5.62977L1.36227 6.03977C0.48227 6.10977 0.12227 7.20977 0.79227 7.78977L4.46227 10.9698L3.36227 15.6898C3.16227 16.5498 4.09227 17.2298 4.85227 16.7698L9.00227 14.2698Z' fill='%23C88C28'/%3E%3C/svg%3E%0A");
  transform: translateY(-4px);
}

.gameViewLegendElement.shadowPlayer::before {
  content: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.4686 13.9657H3.49714L2.92571 13.3943V5.78286H1.31429L0.765714 5.34857L0 2.28571L0.377143 1.6L5.24572 0L5.97714 0.365714C6.04181 0.540228 6.12214 0.708533 6.21714 0.868571C6.29829 0.986286 6.39086 1.09714 6.49143 1.2C6.68343 1.39314 6.91314 1.54514 7.16571 1.64571C7.42401 1.75606 7.70198 1.81295 7.98286 1.81295C8.26374 1.81295 8.5417 1.75606 8.8 1.64571C9.05291 1.54487 9.28241 1.39317 9.47429 1.2C9.57714 1.09143 9.672 0.977143 9.76 0.857143L9.93143 0.525714C9.95374 0.473603 9.97282 0.420168 9.98857 0.365714L10.72 0L15.5886 1.62286L16 2.28571L15.2457 5.32571L14.6857 5.76H13.04V13.3714L12.4686 13.9657Z' fill='%23B34442'/%3E%3C/svg%3E%0A");
  transform: translateY(-1px);
}

.gameViewLegendElement.comment::before {
  content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.275 10.5C1.67163 10.5 1.09298 10.2603 0.666332 9.83367C0.239687 9.40702 0 8.82837 0 8.225V2.275C0 1.67163 0.239687 1.09298 0.666332 0.666332C1.09298 0.239687 1.67163 0 2.275 0H11.725C12.0238 0 12.3196 0.0588446 12.5956 0.173174C12.8716 0.287503 13.1224 0.455079 13.3337 0.666332C13.5449 0.877585 13.7125 1.12838 13.8268 1.4044C13.9412 1.68041 14 1.97624 14 2.275V8.225C14 8.52376 13.9412 8.81959 13.8268 9.0956C13.7125 9.37162 13.5449 9.62241 13.3337 9.83367C13.1224 10.0449 12.8716 10.2125 12.5956 10.3268C12.3196 10.4412 12.0238 10.5 11.725 10.5H7.7084L4.2 13.125C4.07 13.2223 3.91549 13.2815 3.75376 13.296C3.59203 13.3105 3.42945 13.2797 3.28423 13.207C3.13901 13.1344 3.01687 13.0227 2.93147 12.8846C2.84608 12.7465 2.8008 12.5874 2.8007 12.425V10.5H2.275Z' fill='%23060052'/%3E%3C/svg%3E ");
  transform: translateY(1px);
}

#commentedPlayerscontainer .gamePlayerContainer:last-of-type {
  margin-bottom: 0;
}

.playersListSeparator {
  height: 0.5px;
  background-color: #e6e6e6;
  width: 100%;
  margin: 24px 0;
}
</style>
