var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"gameTeamsContainer"}},[_c('div',[_c('div',{staticClass:"text-center",attrs:{"id":"gameTeamsNames"}},[_c('div',{class:{
          teamName: true,
          active: _vm.sidebarAddPlayer.selectedTeam === 'home',
        },on:{"click":function($event){return _vm.changeDisplayedTeam('home')}}},[_vm._v(" "+_vm._s(_vm.game.teamHome)+" ")]),_c('div',{class:{
          teamName: true,
          active: _vm.sidebarAddPlayer.selectedTeam === 'away',
        },on:{"click":function($event){return _vm.changeDisplayedTeam('away')}}},[_vm._v(" "+_vm._s(_vm.game.teamAway)+" ")])]),_c('div',{attrs:{"id":"gameViewLegend"}},[_c('span',{staticClass:"gameViewLegendElement bucket"},[_vm._v(_vm._s(_vm.$t("gameViewlegend.bucketPlayer")))]),_c('span',{staticClass:"gameViewLegendElement shadowPlayer"},[_vm._v(_vm._s(_vm.$t("gameViewlegend.shadowPlayer")))]),_c('span',{staticClass:"gameViewLegendElement comment"},[_vm._v(_vm._s(_vm.$t("gameViewlegend.commentadded")))])]),(_vm.commentedPlayers.length)?_c('div',{attrs:{"id":"commentedPlayerscontainer"},on:{"click":_vm.scrollToTopView}},_vm._l((_vm.commentedPlayers),function(player,index){return _c('div',{key:index,class:{
          gamePlayerContainer: true,
          playerActive:
            player.id &&
            _vm.getSelectedPlayer.id &&
            player.id === _vm.getSelectedPlayer.id,
        },on:{"click":function($event){return _vm.setSelectedPlayer(player)}}},[_c('div',{class:{
            playerName: true,
            textBold: _vm.hasNote(player.id.toString(), _vm.currentScopeGameNotes),
          }},[(player.isInBucket)?_c('span',{staticClass:"bucketPlayerIcon"}):_vm._e(),(player.isInShadow)?_c('span',{staticClass:"shadowPlayerIcon"}):_vm._e(),_vm._v(" "+_vm._s(player.name)+" ")]),_c('div',{staticClass:"togglePlayerSelectionContainer"},[(_vm.hasNote(player.id.toString(), _vm.currentScopeGameNotes))?_c('span',{staticClass:"togglePlayerCommented"}):_c('span',{staticClass:"togglePlayer"})])])}),0):_vm._e(),(_vm.commentedPlayers.length && _vm.otherPlayers.length)?_c('div',{staticClass:"playersListSeparator"}):_vm._e(),(_vm.otherPlayers.length)?_c('div',{attrs:{"id":"otherPlayersContainer"},on:{"click":_vm.scrollToTopView}},_vm._l((_vm.otherPlayers),function(player,index){return _c('div',{key:index,class:{
          gamePlayerContainer: true,
          playerActive: player.id === _vm.getSelectedPlayer.id,
          selected:
            player?.id &&
            _vm.getSelectedPlayer?.id &&
            player.id === _vm.getSelectedPlayer.id,
        },on:{"click":function($event){return _vm.setSelectedPlayer(player)}}},[_c('div',{class:{
            playerName: true,
            textBold: _vm.hasNote(player.id.toString(), _vm.currentScopeGameNotes),
          }},[(player.isInBucket)?_c('span',{staticClass:"bucketPlayerIcon"}):_vm._e(),(player.isInShadow)?_c('span',{staticClass:"shadowPlayerIcon"}):_vm._e(),_vm._v(" "+_vm._s(player.name)+" ")]),_c('div',{staticClass:"togglePlayerSelectionContainer"},[(_vm.hasNote(player.id.toString(), _vm.currentScopeGameNotes))?_c('span',{staticClass:"togglePlayerCommented"}):_c('span',{staticClass:"togglePlayer"})])])}),0):_vm._e(),_c('div',{attrs:{"id":"gameOrderPlayersRow"}},[_c('div',{attrs:{"id":"filterPlayerButtonContainer"}},[(!_vm.getCurrentScout?.isManager)?_c('button',{on:{"click":_vm.setAddPlayerToTrue}},[_vm._v(" "+_vm._s(_vm.$t("add.aPlayer"))+" ")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }