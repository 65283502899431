<template>
  <b-row class="fields-group" align-h="center">
    <b-col class="field" cols="12" lg="6">
      <NumberInput
        @error="addPopinError"
        @unerror="removePopinError"
        :label="$t('sellingPrice')"
        :value="getPopinShallowPayload.price"
        @change="changePrice"
        name="price"
        :min="0"
        :step="0.1"
        :suffix="$t('millions')"
        :required="true"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NumberInput from "../../../Fields/NumberInput.vue";
export default {
  components: { NumberInput },
  computed: {
    ...mapGetters("popin", ["getPopinShallowPayload"]),
  },
  methods: {
    ...mapActions("popin", ["addPopinError", "removePopinError"]),
    changePrice(price) {
      this.$store.dispatch("popin/setShallowPayload", { price });
    },
  },
};
</script>
<style lang="scss" scoped>
div.field {
  padding: 24px;
  border-radius: 8px;
  background-color: #fafafa;
}
</style>
