<template>
  <div class="player" v-if="getCurrentScout">
    <PlayerHeader :player="player" />
    <PlayerCareer :player="player" :playerPriority="scoutPlayerPriority" />
    <PlayerComments
      :playerTeam="player.currentTeam"
      :playerNotes="filteredNotes"
      :playerMarks="player.marks"
      :player="player"
    />
  </div>
</template>

<script>
import db from "../db";
import { mapGetters } from "vuex";
import PlayerHeader from "@/components/PlayerHeader";
import PlayerCareer from "@/components/PlayerCareer";
import PlayerComments from "@/components/PlayerComments";
export default {
  name: "PlayerComponent",
  components: {
    PlayerHeader,
    PlayerCareer,
    PlayerComments,
  },
  firestore() {
    return {
      player: db
        .collection("V3players")
        .doc(this.$route.params.playerId.toString()),
      playerNotes: db
        .collection("V3players")
        .doc(this.$route.params.playerId.toString())
        .collection("notes"),
    };
  },
  data() {
    return {
      player: {},
      scoutPlayerPriority: 0,
      playerNotes: [],
      filteredNotes: [],
    };
  },
  mounted() {
    if (this.getCurrentScout && this.getCurrentScout.id) {
      this.loadScoutPlayer();
    }
  },
  methods: {
    manageNotes() {
      if (this.$route.params.scoutId) {
        this.filteredNotes = this.lodash.filter(this.playerNotes, (note) => {
          return note.authorId == this.$route.params.scoutId;
        });
      } else {
        this.filteredNotes = this.playerNotes;
      }
    },
    async loadScoutPlayer() {
      const scoutPlayer = await db
        .collection("V3scouts")
        .doc(this.getCurrentScout.id.toString())
        .collection("players")
        .doc(this.$route.params.playerId.toString())
        .get()
        .then((scoutPlayerDoc) => {
          return scoutPlayerDoc.data();
        });
      if (scoutPlayer) {
        this.scoutPlayerPriority = scoutPlayer.priority;
      }
    },
  },
  computed: {
    ...mapGetters(["getCurrentScout"]),
  },
  watch: {
    getCurrentScout: {
      handler() {
        if (this.getCurrentScout) {
          this.loadScoutPlayer();
          this.manageNotes();
        }
      },
    },
    playerNotes() {
      this.manageNotes();
    },
  },
};
</script>
