import * as actions from "../../components/GameView/Popin/Actions";
import * as fieldsGroups from "../../components/GameView/Popin/FieldsGroups";
import { popinVariants } from "../../enums/popinVariants";

export const gamesPopins = (_state, _getters, _rootState, rootGetters) => ({
  [popinVariants.FIND_GAME]: {
    title: { key: "create.aGame" },
    subtitle: "",
    fieldsGroups: [fieldsGroups.FindGame],
    tabs: [
      {
        label: "suggestedGame",
        target: popinVariants.FIND_GAME,
      },
      {
        label: "create.aGame",
        target: popinVariants.CREATE_GAME,
      },
    ],
    actions: [
      {
        to: popinVariants.QUERY_GAME,
        translationKey: "search.dot",
        primary: true,
      },
    ],
  },
  [popinVariants.CREATE_GAME]: {
    title: { key: "create.aGame" },
    subtitle: "",
    fieldsGroups: [fieldsGroups.CreateGame],
    tabs: [
      {
        label: "suggestedGame",
        target: popinVariants.FIND_GAME,
      },
      {
        label: "create.aGame",
        target: popinVariants.CREATE_GAME,
      },
    ],
    actions: [actions.CreateGame],
  },
  [popinVariants.QUERY_GAME]: {
    title: { key: "search.aGame" },
    subtitle: {
      key: "plural.matchingResult",
      payload: {
        nb: rootGetters["matchs/getMatchingResult"]?.length,
      },
      plural: true,
    },
    fieldsGroups: [fieldsGroups.SelectGame],
    actions: [
      {
        to: popinVariants.FIND_GAME,
        translationKey: "edit",
        primary: false,
      },
      actions.CreateGame,
    ],
  },
});
