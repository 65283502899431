<template>
  <div class="minisquad-container" :class="{ errored }">
    <div class="minisquad-content">
      <div
        :class="{
          position: true,
          active: positionAcronym === pickedPositionAcronym,
          editable,
        }"
        :key="'position_' + index"
        :style="
          sanitize(
            getPositionMap[positionAcronym][disposition][dispositionVersion]
          )
        "
        v-for="(positionAcronym, index) of positionsAcronyms"
        @click="clickOnPosition(positionAcronym)"
      >
        <span>{{ positionAcronym }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mixinText from "../../mixins/mixinText";
import mixinPlayers from "../../mixins/mixinPlayers";

export default {
  mixins: [mixinPlayers, mixinText],
  props: {
    editable: { type: Boolean, default: false },
    disposition: { type: String, required: true },
    dispositionVersion: { type: Number, required: true },
    required: { type: Boolean, default: false },
    position: { type: String },
  },
  data() {
    return {
      pickedPositionAcronym: null,
      errored: false,
    };
  },
  emits: ["change", "error", "unerror"],
  methods: {
    addError() {
      this.errored = true;
      this.$emit("error", "position");
    },
    clearError() {
      this.errored = false;
      this.$emit("unerror", "position");
    },
    changePickedPosition(positionAcronym) {
      this.pickedPositionAcronym = positionAcronym;
    },
    clearPickedPosition() {
      this.pickedPositionAcronym = null;
      if (this.required) {
        this.addError();
      }
    },
    emitFromAcronym(positionAcronym) {
      const acronym =
        this.getReverseSpecificAcronymsMap[positionAcronym] ?? positionAcronym;
      this.$emit("change", acronym);
    },
    clickOnPosition(positionAcronym) {
      if (!this.editable) {
        return;
      }
      this.emitFromAcronym(positionAcronym);
      this.clearError();
      if (this.pickedPositionAcronym !== positionAcronym) {
        this.changePickedPosition(positionAcronym);
        return;
      }
      this.clearPickedPosition();
      return;
    },
    sanitize(style) {
      delete style.transform;
      if (style.right) {
        style.left = `${100 - parseFloat(style.right.replace("%", ""))}%`;
      }
      if (style.bottom) {
        style.top = `${100 - parseFloat(style.bottom.replace("%", ""))}%`;
      }
      delete style.bottom;
      delete style.right;

      return style;
    },
  },
  computed: {
    ...mapGetters(["getGamesPosition"]),
    positionsAcronyms() {
      return Object.entries(this.getPositionMap)
        .filter(
          ([, value]) => value[this.disposition]?.[this.dispositionVersion]
        )
        .map(([key]) => key);
    },
  },
  watch: {
    position: {
      immediate: true,
      handler() {
        this.clearError();
        if (!this.position) {
          this.addError();
          return;
        }
        const acronym = this.reduceStringToInitiales(this.position);
        if (!this.positionsAcronyms.includes(acronym)) {
          this.addError();
          return;
        }
        this.pickedPositionAcronym = acronym;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";

div.minisquad-container {
  border: solid 1px #e6e6e6;
  border-radius: 8px;
  padding: 24px;
  height: 300px;
  &.errored {
    border: solid 1px #b34442;
  }
}

div.minisquad-content {
  position: relative;
  width: 90%;
  height: 90%;
}

div.position {
  &.editable {
    cursor: pointer;
  }
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  background-color: $blueDarkest;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    background-color: #2c1fd6;
  }
  > span {
    font-size: 0.75rem;
    font-weight: 700;
    color: white;
    user-select: none;
  }
}
</style>
