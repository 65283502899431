<template>
  <div>
    <ShadowPopin
      v-if="popinContent.display"
      :display="popinContent.display"
      :scenario="popinContent.scenario"
      :content="popinContent.content"
      @closePopin="closePopin"
      @updatePlayersList="updatePlayersList"
      @triggerPopin="displayAnotherPopin"
      @addPlayersToShadow="addPlayersToShadow"
      @toggleNotification="toggleNotificationByEmit"
      @setContributors="setContributors"
    />
    <section class="shadowsHeader">
      <b-row>
        <b-col>
          <h1 class="mb-3">{{ $t("nav.shadowTeam") }},</h1>
        </b-col>
        <b-col>
          <button @click="triggerPopin('createShadow')">
            {{ $t("create.aShadow") }}
          </button>
        </b-col>
      </b-row>
    </section>
    <div
      id="shadowPageContainer"
      :class="{ scoutView: !getCurrentScout.isManager }"
    >
      <div id="shadowPageContent">
        <div id="shadowPageTitleContainer">
          <h2>{{ $t("shadowsView.mainTitle") }}</h2>
        </div>
        <div class="shadowPageFiltersContainer">
          <div
            v-if="currentScopeShadows.length"
            class="shadowPageFiltersContent"
          >
            <h4 class="smallTitle">Filtrer</h4>
            <div class="shadowPageFilters">
              <div class="shadowPageFilter searchBarContainer">
                <label for="searchBar" class="filterLabel">{{
                  $t("shadowsView.searchBarLabel")
                }}</label>
                <input
                  id="searchBar"
                  type="text"
                  :value="searchKeywords"
                  @input="filterResults"
                />
              </div>
              <div class="shadowPageFilter">
                <label for="" class="filterLabel">{{
                  $t("shadowsView.bySchemeLabel")
                }}</label>
                <div
                  class="filterCheckboxContainer"
                  @click="hideFiltersDropdown"
                >
                  <div class="filterCheckboxContentWrapper">
                    <div class="filterCheckboxContent">
                      <div
                        v-for="composition in selectedCompositionsByFilter"
                        :key="composition"
                        class="selectedCheckboxFilter"
                        @click="removeActiveFilter(composition)"
                      >
                        {{ getCompoById(composition) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filterCheckboxListContainer">
                  <div
                    v-for="composition in availableCompositions"
                    :key="composition"
                    class="filterCheckbox"
                    :class="{
                      checkboxActive:
                        selectedCompositionsByFilter.includes(composition),
                    }"
                  >
                    <input
                      class="filterCheckboxInput"
                      type="checkbox"
                      :id="composition"
                      :name="getCompoById(composition)"
                      :value="composition"
                      v-model="selectedCompositionsByFilter"
                      @change="handleCheckedFilters"
                    />
                    <label class="filterCheckboxLabel" :for="composition">{{
                      getCompoById(composition)
                    }}</label>
                  </div>
                </div>
              </div>
              <div class="shadowPageFilter" v-if="getCurrentScout.isManager">
                <label for="" class="filterLabel">{{
                  $t("shadowsView.byContributorsLabel")
                }}</label>
                <div
                  class="filterCheckboxContainer"
                  @click="hideFiltersDropdown"
                >
                  <div class="filterCheckboxContentWrapper">
                    <div class="filterCheckboxContent">
                      <div
                        v-for="author in selectedAuthorsByFilter"
                        :key="author"
                        class="selectedCheckboxFilter"
                        @click="removeActiveFilter(author)"
                      >
                        {{ identifyScoutById(author, "shortenName") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filterCheckboxListContainer">
                  <div
                    v-for="author in availableAuthors"
                    :key="author"
                    class="filterCheckbox"
                    :class="{
                      checkboxActive: selectedAuthorsByFilter.includes(author),
                    }"
                  >
                    <input
                      class="filterCheckboxInput"
                      type="checkbox"
                      :id="author"
                      :name="identifyScoutById(author, 'shortenName')"
                      :value="author"
                      v-model="selectedAuthorsByFilter"
                      @change="handleCheckedFilters"
                    />
                    <label class="filterCheckboxLabel" :for="author">{{
                      identifyScoutById(author, "shortenName")
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="shadowPageNoResults alignedCenter">
            {{ $t("shadowsView.noTeamsForTheMoment") }}
          </div>
        </div>
        <div
          class="shadowPageResultsTableContainer"
          v-if="currentScopeShadows.length"
        >
          <div
            class="shadowPageResultsTableContent"
            v-if="currentScopeShadowsDisplayed.length"
          >
            <div class="shadowPageResultsTableHeaderContainer">
              <div class="shadowPageResultsTableHeaderContent">
                <div
                  class="tableHeaderCell largeCell withArrows"
                  @click="setNewOrder('text', textFilterType)"
                >
                  {{ $t("shadowsView.nameOfTeamLabel") }}
                </div>
                <div class="tableHeaderCell">
                  {{ $t("shadowsView.tableSchemeLabel") }}
                </div>
                <div
                  class="tableHeaderCell smallCell withArrows"
                  @click="setNewOrder('numberPlayers', numberPlayerFilterType)"
                >
                  {{ $t("shadowsView.tablePlayersLabel") }}
                </div>
                <div class="tableHeaderCell" v-if="getCurrentScout.isManager">
                  {{ $t("shadowsView.tableAuthorLabel") }}
                </div>
                <div
                  class="tableHeaderCell mediumCell withArrows"
                  @click="setNewOrder('updatedAt', updateFilterType)"
                >
                  {{ $t("shadowsView.tableLastUpdateLabel") }}
                </div>
              </div>
            </div>
            <div class="shadowPageResultsTableRows">
              <router-link
                v-for="(shadow, shadowIndex) in displayedShadows"
                :key="shadowIndex"
                class="shadowPageResultsTableRow"
                :to="`/shadow/${shadow.value}`"
              >
                <div class="tableRowCell largeCell">{{ shadow.text }}</div>
                <div class="tableRowCell">
                  {{ getCompoById(shadow.composition) }}
                </div>
                <div class="tableRowCell smallCell">
                  {{ shadow.numberPlayers }}
                </div>
                <div class="tableRowCell" v-if="getCurrentScout.isManager">
                  {{ identifyScoutById(shadow.author, "shortenName") }}
                </div>
                <div class="tableRowCell mediumCell">
                  {{
                    $t("shadowsView.lastUpdate", {
                      day: formatDate(shadow.updatedAt, false, [
                        "MM/DD/YYYY",
                        "DD/MM/YYYY",
                      ]),
                      hour: formatDate(shadow.updatedAt, false, "HH:mm"),
                    })
                  }}
                </div>
              </router-link>
            </div>
          </div>
          <div v-else class="shadowPageResultsTableContent noResults">
            <div class="shadowPageNoResults">
              {{ $t("shadowsView.noSearchResults")
              }}<span
                class="shadowPageNoResultsResetFilters"
                @click="resetSearchFilters"
                >{{ $t("shadowsView.noSearchResultsResetToggle") }}</span
              >.
            </div>
          </div>
          <div
            class="shadowPagePaginationContainer"
            v-if="numberOfPages && currentScopeShadows.length"
          >
            <div class="shadowPagePaginationContent">
              <button
                type="button"
                class="shadowPagePaginationElement shadowPagePaginationArrow previous"
                :class="{ buttonDisabled: activePageNumber == 1 }"
                @click="goToPreviousPage"
              ></button>
              <button
                type="button"
                class="shadowPagePaginationElement"
                :class="{ numberPageActive: page === activePageNumber }"
                v-for="page in numberOfPages"
                :key="page"
                @click="setPageNumber(page)"
              >
                {{ page }}
              </button>
              <button
                type="button"
                class="shadowPagePaginationElement shadowPagePaginationArrow next"
                :class="{ buttonDisabled: activePageNumber == numberOfPages }"
                @click="goToNextPage"
              ></button>
            </div>
          </div>
          <div class="shadowPageGlobalStatsContainer">
            <div class="shadowPageGlobalStatsContent">
              <h2>{{ $t("shadowsView.globalStatsTitle") }}</h2>
              <div class="shadowPageGlobalStatsElements">
                <div class="shadowPageGlobalStatsElement">
                  <div class="shadowPageGlobalStatsElementContent">
                    <span class="shadowPageGlobalStatsElementNumber">{{
                      currentScopeShadows.length
                    }}</span>
                    {{ $t("shadowsView.globalStatsCreatedTeamsLabel") }}
                  </div>
                </div>
                <div class="shadowPageGlobalStatsElement">
                  <div class="shadowPageGlobalStatsElementContent">
                    <span class="shadowPageGlobalStatsElementNumber">{{
                      totalPlayersUsed
                    }}</span>
                    {{ $t("shadowsView.globalStatsUsedPlayersLabel") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import db from "../db";
import mixinScouts from "../mixins/mixinScouts";
import mixinDates from "../mixins/mixinDates";
import ShadowPopin from "../components/ShadowPopin.vue";
import mixinCompos from "../mixins/mixinCompos";
import moment from "moment";

export default {
  name: "Shadow-Teams",
  mixins: [mixinScouts, mixinDates, mixinCompos],
  components: { ShadowPopin },
  data() {
    return {
      selectedScoutId: null,
      currentShadowPlayers: [],
      selectedShadow: {},
      currentScopeShadows: [],
      currentShadowContributors: [],
      selectedTeamId: "",
      newShadowTeamName: "",
      popinContent: {
        display: false,
        scenario: "",
        players: [],
        content: {},
      },
      notification: {
        display: false,
        type: "",
        details: "",
      },
      shadowAuthor: "",
      currentScopeShadowsDisplayed: [],
      textFilterType: 0,
      numberPlayerFilterType: 0,
      updateFilterType: 0,
      lastUpdatedFilter: "",
      availableAuthors: [],
      availableCompositions: [],
      selectedAuthorsByFilter: [],
      selectedCompositionsByFilter: [],
      searchKeywords: "",
      numberOfPages: 1,
      activePageNumber: 1,
      shadowsPerPage: 30,
      totalPlayersUsed: 0,
    };
  },
  mounted() {
    if (!this.getShadows.length) {
      this.$store.dispatch("bindScoutsShadows");
    }
    if (!this.getCompanies.length) {
      this.$store.dispatch("bindCompanies");
    }
    if (this.getShadows.length && this.getCompanies.length) {
      this.setCurrentShadow();
    }

    document.body.onclick = (event) => {
      let targettedElement = event.target;
      if (
        !targettedElement.classList.contains("filterCheckboxInput") &&
        !targettedElement.classList.contains("filterCheckboxLabel") &&
        !targettedElement.classList.contains("filterCheckbox") &&
        !targettedElement.classList.contains("filterCheckboxListContainer") &&
        !targettedElement.classList.contains("filterCheckboxContainer")
      ) {
        this.closeCheckboxes();
      }
    };
    document.onscroll = () => {
      this.closeCheckboxes();
    };
  },
  computed: {
    ...mapGetters([
      "getShadows",
      "getCurrentScout",
      "getCompanies",
      "getScouts",
    ]),
    displayedShadows() {
      let startIndex = 0;
      if (this.activePageNumber != 1) {
        startIndex = (this.activePageNumber - 1) * this.shadowsPerPage;
      }
      if (this.activePageNumber == this.numberOfPages) {
        return this.currentScopeShadowsDisplayed.slice(startIndex);
      }
      let endIndex = startIndex + this.shadowsPerPage;
      return this.currentScopeShadowsDisplayed.slice(startIndex, endIndex);
    },
  },
  methods: {
    async updatePlayersList(payload) {
      this.popinContent.content.players = payload.data;
      const now = moment().unix();
      await db
        .collection("V3shadows")
        .doc(this.selectedShadow.id.toString())
        .set({ players: payload.data || [], updatedAt: now }, { merge: true });

      this.selectedShadow.updatedAt = now;
    },
    setContributors(payload) {
      const self = this;
      if (
        payload?.contributor &&
        !self.selectedShadow.contributors.includes(payload.contributor)
      ) {
        const newContributors = self.selectedShadow.contributors || [];
        newContributors.push(payload.contributor);
        db.collection("V3shadows")
          .doc(self.selectedShadow.id.toString())
          .set({ contributors: newContributors }, { merge: true });
      }
    },
    async addPlayersToShadow(payload) {
      const self = this;
      let matchingIndexes = [];
      if (self.popinContent.content.players) {
        self.popinContent.content.players.forEach((player, playerIndex) => {
          if (player.position === self.popinContent.content.selectedPosition) {
            matchingIndexes.push(playerIndex);
          }
        });
      } else {
        self.popinContent.content.players = [];
      }

      let newPlayersForSelectedPosition =
        self.popinContent.content.players || [];
      if (matchingIndexes.length) {
        matchingIndexes = matchingIndexes.sort((a, b) => b - a);
        matchingIndexes.forEach((indexToRemove) => {
          newPlayersForSelectedPosition.splice(indexToRemove, 1);
        });
        newPlayersForSelectedPosition = [
          ...newPlayersForSelectedPosition,
          ...payload.data,
        ];
      } else {
        newPlayersForSelectedPosition = [
          ...self.popinContent.content.players,
          ...payload.data,
        ];
      }
      const now = moment().unix();
      await db
        .collection("V3shadows")
        .doc(self.selectedShadow.id.toString())
        .set(
          {
            players: newPlayersForSelectedPosition,
            updatedAt: now,
          },
          { merge: true }
        );
      self.setContributors({ contributor: self.getCurrentScout.id });

      self.currentShadowPlayers = newPlayersForSelectedPosition;
      self.popinContent.content.players = newPlayersForSelectedPosition;
      self.selectedShadow.updatedAt = now;
      self.closePopin();
    },
    closeCheckboxes() {
      const checkboxesContainers = document.querySelectorAll(
        ".filterCheckboxListContainer.dropdownVisible"
      );
      const checkboxesFiltersContainers = document.querySelectorAll(
        ".filterCheckboxContainer.dropdownActive"
      );
      if (checkboxesContainers) {
        Array.from(checkboxesContainers).forEach((checkboxesContainer) => {
          checkboxesContainer.classList.remove("dropdownVisible");
        });
      }
      if (checkboxesFiltersContainers) {
        Array.from(checkboxesFiltersContainers).forEach(
          (checkboxesFiltersContainer) => {
            checkboxesFiltersContainer.classList.remove("dropdownActive");
          }
        );
      }
    },
    setNewOrder(orderToApply, filterType) {
      if (
        this.lastUpdatedFilter != orderToApply &&
        this.lastUpdatedFilter.length
      ) {
        filterType = 0;
      }
      if (filterType && orderToApply != "text") {
        this.currentScopeShadowsDisplayed.sort((shadow1, shadow2) =>
          shadow1[orderToApply] > shadow2[orderToApply]
            ? 1
            : shadow2[orderToApply] > shadow1[orderToApply]
            ? -1
            : 0
        );
      } else if (!filterType && orderToApply == "text") {
        this.currentScopeShadowsDisplayed.sort((shadow1, shadow2) =>
          shadow1[orderToApply].toUpperCase() <
          shadow2[orderToApply].toUpperCase()
            ? -1
            : shadow1[orderToApply].toUpperCase() >
              shadow2[orderToApply].toUpperCase()
            ? 1
            : 0
        );
      } else if (filterType && orderToApply == "text") {
        this.currentScopeShadowsDisplayed.sort((shadow1, shadow2) =>
          shadow1[orderToApply].toUpperCase() >
          shadow2[orderToApply].toUpperCase()
            ? -1
            : shadow1[orderToApply].toUpperCase() <
              shadow2[orderToApply].toUpperCase()
            ? 1
            : 0
        );
      } else {
        this.currentScopeShadowsDisplayed.sort((shadow1, shadow2) =>
          shadow1[orderToApply] < shadow2[orderToApply]
            ? 1
            : shadow2[orderToApply] < shadow1[orderToApply]
            ? -1
            : 0
        );
      }
      if (orderToApply === "text") {
        this.textFilterType = !this.textFilterType;
      } else if (orderToApply === "numberPlayers") {
        this.numberPlayerFilterType = !this.numberPlayerFilterType;
      } else if (orderToApply === "updatedAt") {
        this.updateFilterType = !this.updateFilterType;
      }
      this.lastUpdatedFilter = orderToApply;
      this.activePageNumber = 1;
    },
    filterResults() {
      this.searchKeywords = event.target.value;
      this.handleCheckedFilters();
      this.textFilterType = 0;
      this.numberPlayerFilterType = 0;
      this.updateFilterType = 0;
      this.lastUpdatedFilter = "";
    },
    selectleScout(contributorId) {
      if (this.selectedScoutId === contributorId) {
        this.selectedScoutId = null;
      } else {
        this.selectedScoutId = contributorId;
      }
    },
    toggleNotificationByEmit(payload) {
      this.toggleNotification(payload.type, payload.details);
    },
    setCurrentShadow() {
      this.getCurrentScopeShadows();
    },

    checkShadowToDisplay() {
      if (
        this.popinContent.scenario === "createShadow" ||
        this.popinContent.scenario === "deleteShadow"
      ) {
        this.setCurrentShadow();
      }
    },
    toggleNotification(notificationType, notificationDetails) {
      this.notification.display = true;
      this.notification.type = notificationType;

      this.notification.details = notificationDetails.message
        ? this.$t(notificationDetails.message, notificationDetails.params)
        : this.$t(notificationDetails);
      setTimeout(() => {
        this.notification.display = false;
      }, 6000);
    },
    displayAnotherPopin(payload) {
      this.triggerPopin(payload.target, payload.data);
    },

    async closePopin() {
      const self = this;
      self.popinContent.display = false;
    },
    triggerPopin(action, payload) {
      const self = this;
      self.popinContent.display = true;

      if (action) {
        self.popinContent.scenario = action;
        self.popinContent.content = self.selectedShadow;
        if (action === "addPlayer") {
          self.popinContent.content.selectedPosition = payload;
        }
      } else {
        if (payload?.newShadowName) {
          self.newShadowName = payload.newShadowName;
        }
      }
    },
    handleCheckedFilters() {
      let filteredResults = [];
      if (
        this.selectedAuthorsByFilter.length &&
        !this.selectedCompositionsByFilter.length
      ) {
        filteredResults = this.currentScopeShadows.filter((shadow) => {
          if (
            (this.selectedAuthorsByFilter.includes(shadow.author) ||
              shadow.contributors.some((contributor) =>
                this.selectedAuthorsByFilter.includes(contributor)
              )) &&
            shadow.text
              .toUpperCase()
              .includes(this.searchKeywords.toUpperCase())
          ) {
            return true;
          } else {
            return false;
          }
        });
      } else if (
        !this.selectedAuthorsByFilter.length &&
        this.selectedCompositionsByFilter.length
      ) {
        filteredResults = this.currentScopeShadows.filter((shadow) => {
          if (
            shadow.text
              .toUpperCase()
              .includes(this.searchKeywords.toUpperCase()) &&
            this.selectedCompositionsByFilter.includes(shadow.composition)
          ) {
            return true;
          } else {
            return false;
          }
        });
      } else if (
        this.selectedAuthorsByFilter.length &&
        this.selectedCompositionsByFilter.length
      ) {
        filteredResults = this.currentScopeShadows.filter((shadow) => {
          if (
            (this.selectedAuthorsByFilter.includes(shadow.author) ||
              shadow.contributors.some((contributor) =>
                this.selectedAuthorsByFilter.includes(contributor)
              )) &&
            this.selectedCompositionsByFilter.includes(shadow.composition) &&
            shadow.text
              .toUpperCase()
              .includes(this.searchKeywords.toUpperCase())
          ) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        filteredResults = this.currentScopeShadows.filter((shadow) =>
          shadow.text.toUpperCase().includes(this.searchKeywords.toUpperCase())
        );
      }
      this.currentScopeShadowsDisplayed = filteredResults;
      this.setNumberOfPages();
      this.activePageNumber = 1;
    },
    async getCurrentScopeShadows() {
      let toReturn = [];
      if (this.getCurrentScout.company === this.getCompanies[0].id) {
        toReturn = this.getShadows;
      } else if (this.getCurrentScout.isManager) {
        toReturn = this.lodash.filter(this.getShadows, {
          usedInClub: this.getCurrentScout.company,
        });
      } else {
        toReturn = this.getShadows.filter((shadow) => {
          return shadow.addedBy === this.getCurrentScout.id;
        });
      }
      toReturn = toReturn.map((shadow) => {
        if (shadow.addedBy && shadow.compoId && !shadow.isGhost) {
          return {
            text: shadow.label,
            value: shadow.id,
            numberPlayers: shadow.players.length || 0,
            players: shadow.players || [],
            composition: shadow.compoId,
            author: shadow.addedBy,
            updatedAt: shadow.updatedAt,
            contributors: shadow.contributors || [],
          };
        }
      });

      toReturn = toReturn.filter((elementToReturn) => {
        return elementToReturn;
      });

      this.currentScopeShadows = toReturn;
      if (toReturn.length) {
        const matchingShadow = this.currentScopeShadows.filter((shadow) => {
          return shadow.value === this.selectedTeamId;
        });
        if (
          matchingShadow.length &&
          this.popinContent.scenario !== "createShadow"
        ) {
          this.selectedTeamId = matchingShadow[0].value;
        } else {
          this.selectedTeamId = toReturn[0].value;
        }
      }
      this.currentScopeShadowsDisplayed = this.currentScopeShadows;
      let authorsArray = [];
      let compositionsArray = [];
      let totalPlayersUsedArray = 0;
      let playersAddedInShadows = [];
      this.currentScopeShadowsDisplayed.map((shadow) => {
        authorsArray.push(shadow.contributors);
        authorsArray.push(shadow.author);
        compositionsArray.push(shadow.composition);
        if (shadow.players.length) {
          shadow.players.forEach((player) => {
            playersAddedInShadows.push(player.id);
          });
        }
      });
      let playersAddedUniqueValues = [...new Set(playersAddedInShadows)];
      if (playersAddedUniqueValues.length) {
        totalPlayersUsedArray = playersAddedUniqueValues.length;
      }
      this.availableAuthors = [...new Set(authorsArray.flat())];
      this.availableCompositions = [...new Set(compositionsArray)];
      this.totalPlayersUsed = totalPlayersUsedArray;
      this.setNewOrder("updatedAt", this.updateFilterType);
      this.setNumberOfPages();
    },
    removeActiveFilter(valueToRemove) {
      let filterToToggle = document.getElementById(`${valueToRemove}`);
      if (filterToToggle) {
        filterToToggle.click();
      }
    },
    hideFiltersDropdown(event) {
      const dropdownContainer = event.target;
      if (dropdownContainer.classList.contains("filterCheckboxContainer")) {
        const parentDiv = dropdownContainer.parentNode;
        const dropdown = parentDiv.querySelector(
          ".filterCheckboxListContainer"
        );
        if (dropdown.classList.contains("dropdownVisible")) {
          dropdown.classList.remove("dropdownVisible");
          dropdownContainer.classList.remove("dropdownActive");
        } else {
          this.closeCheckboxes();
          dropdown.classList.add("dropdownVisible");
          dropdownContainer.classList.add("dropdownActive");
        }
      }
    },
    setNumberOfPages() {
      let pages = Math.ceil(
        this.currentScopeShadowsDisplayed.length / this.shadowsPerPage
      );
      if (pages > 1) {
        this.numberOfPages = pages;
      } else {
        this.numberOfPages = 0;
      }
    },
    setPageNumber(pageNumber) {
      this.activePageNumber = pageNumber;
    },
    goToNextPage() {
      this.activePageNumber++;
    },
    goToPreviousPage() {
      this.activePageNumber--;
    },
    resetSearchFilters() {
      this.searchKeywords = "";
      this.textFilterType = 0;
      this.numberPlayerFilterType = 0;
      this.updateFilterType = 0;
      this.lastUpdatedFilter = "";
      this.selectedAuthorsByFilter.forEach((authorToRemove) => {
        this.removeActiveFilter(authorToRemove);
      });
      this.selectedCompositionsByFilter.forEach((compositionToRemove) => {
        this.removeActiveFilter(compositionToRemove);
      });
      this.handleCheckedFilters();
    },
  },
  watch: {
    getShadows: {
      handler() {
        if (this.getShadows.length && this.getCompanies.length) {
          this.getCurrentScopeShadows();
        }
      },
      deep: true,
    },
    getCompanies: {
      handler() {
        if (this.getShadows.length && this.getCompanies.length) {
          this.getCurrentScopeShadows();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
h1 {
  font-weight: bold;
}

#shadowPageContainer {
  width: 100%;
  max-width: 1200px;
  margin: 32px auto;
}

section > .row:first-of-type {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

.shadowsHeader {
  background-color: #0a008f;
}

.shadowsHeader .col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.shadowsHeader .col:first-of-type {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  color: white;
  justify-content: center;
  align-items: flex-start;
}

.shadowsHeader .col:first-of-type span {
  margin-bottom: 10px;
}

select {
  max-width: 300px;
  font-weight: bold !important;
}

button {
  background-color: #060052;
  border-radius: 50px !important;
  color: white;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem !important;
  display: inline-flex;
  margin: 0 5px;
  padding: 5px 18px;
}

button:hover {
  background-color: #b34442;
}

.shadowsHeader button {
  content: "+";
  margin-right: 10px;
  font-size: 1.7rem;
}

.shadowsHeader button:before {
  content: "+";
  margin-right: 10px;
  font-size: 1.7rem;
}

.shadowPageFiltersContainer {
  background: #ffffff;
  border: solid 1px #e3e0ff;
  box-shadow: 0px 0px 8px 0px #06005214;
  padding: 24px;
  border-radius: 8px;
  margin: 16px 0;
  display: block;
}

.shadowPageResultsTableContainer {
  margin: 16px 0;
  display: inline-block;
  width: 100%;
}

.shadowPageResultsTableContent {
  background: #ffffff;
  border: solid 1px #e3e0ff;
  box-shadow: 0px 0px 8px 0px #06005214;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.shadowPageResultsTableHeaderContainer {
  background: #000000;
  width: 100%;
  border-radius: 8px 8px 0 0;
  color: white;
  padding: 0 48px;
}

.shadowPageResultsTableHeaderContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tableHeaderCell {
  display: flex;
  justify-content: space-between;
  width: 15%;
  font-weight: 1rem;
  font-family: "Proxima Nova", sans-serif;
  padding: 16px 0;
}

.tableHeaderCell.largeCell,
.tableRowCell.largeCell {
  width: 35%;
}

.tableHeaderCell.smallCell,
.tableRowCell.smallCell {
  width: 10%;
}

.scoutView .tableHeaderCell,
.scoutView .tableRowCell,
.scoutView .tableHeaderCell.smallCell,
.scoutView .tableRowCell.smallCell {
  width: 17.5%;
}
.scoutView .tableHeaderCell.mediumCell {
  width: 30%;
}

.scoutView .tableHeaderCell.largeCell,
.scoutView .tableRowCell.largeCell {
  width: 35%;
}

.tableHeaderCell.mediumCell,
.tableRowCell.mediumCell {
  width: 25%;
}

.tableRowCell {
  width: 15%;
  color: #060052;
  font-weight: 500;
  font-family: "Proxima Nova", sans-serif;
  font-size: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 16px;
}

.tableHeaderCell.withArrows {
  cursor: pointer;
}

.tableHeaderCell.withArrows::after {
  content: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.681036 5.22727H4.88418L2.78261 7.42437L0.681036 5.22727ZM2.66585 7.54643C2.6659 7.54638 2.66595 7.54633 2.666 7.54628L2.66585 7.54643ZM2.89922 7.54628C2.89927 7.54633 2.89932 7.54638 2.89937 7.54643L2.89922 7.54628ZM2.78261 0.575629L4.88418 2.77273H0.681036L2.78261 0.575629Z' fill='white' stroke='white'/%3E%3C/svg%3E%0A");
  margin-right: 32px;
}

.tableHeaderCell.withArrows:last-of-type:after {
  margin-right: 16px;
}

#shadowPageTitleContainer h2,
.shadowPageGlobalStatsContainer h2 {
  color: #141414;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2rem;
  margin-bottom: 0;
}

#shadowPageTitleContainer h2 {
  margin-bottom: 32px;
  margin-top: 40px;
}

.shadowPageResultsTableRows {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
}

.shadowPageResultsTableRow {
  width: 100%;
  margin: 8px 0;
  border: solid 1px #f0f0f0;
  padding: 16px 24px;
  text-decoration: none;
  color: #000000;
  transition: background 0.2s ease-in;
  display: flex;
  flex-direction: row;
}

.shadowPageResultsTableRow:hover {
  background: #f9f9f9;
}

.shadowPageFilters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.shadowPageFilter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 32%;
  position: relative;
}

.scoutView .shadowPageFilter {
  width: 49%;
}

#searchBar {
  border: solid 1px#D0CCFF;
  width: 100%;
  color: #000000;
  font-weight: 0.875rem;
  padding: 12px 40px 12px 16px;
  border-radius: 4px;
  font-family: "Proxima Nova", sans-serif;
}

.searchBarContainer {
  position: relative;
}

.searchBarContainer::after {
  content: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.6665 21L15.6665 15M3.6665 10C3.6665 10.9193 3.84756 11.8295 4.19935 12.6788C4.55113 13.5281 5.06675 14.2997 5.71676 14.9497C6.36677 15.5998 7.13844 16.1154 7.98772 16.4672C8.837 16.8189 9.74725 17 10.6665 17C11.5858 17 12.496 16.8189 13.3453 16.4672C14.1946 16.1154 14.9662 15.5998 15.6163 14.9497C16.2663 14.2997 16.7819 13.5281 17.1337 12.6788C17.4854 11.8295 17.6665 10.9193 17.6665 10C17.6665 9.08075 17.4854 8.1705 17.1337 7.32122C16.7819 6.47194 16.2663 5.70026 15.6163 5.05025C14.9662 4.40024 14.1946 3.88463 13.3453 3.53284C12.496 3.18106 11.5858 3 10.6665 3C9.74725 3 8.837 3.18106 7.98772 3.53284C7.13844 3.88463 6.36677 4.40024 5.71676 5.05025C5.06675 5.70026 4.55113 6.47194 4.19935 7.32122C3.84756 8.1705 3.6665 9.08075 3.6665 10Z' stroke='%23060052' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  height: 32px;
  width: 16px;
  display: block;
  position: absolute;
  bottom: 5px;
  right: 18px;
}

#searchBar::placeholder {
  color: #7a7a7a;
  font-weight: 0.875rem;
  font-family: "Proxima Nova", sans-serif;
}

.smallTitle {
  color: #141414;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
}

.filterLabel {
  color: #141414;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.filterCheckboxContainer {
  border: solid 1px #060052;
  border-radius: 4px;
  height: 48px;
  max-height: 48px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px 44px 12px 12px;
  position: relative;
}

.filterCheckboxContentWrapper {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.filterCheckboxContainer::after {
  content: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5417 0L12.333 1.85844L6.26302 8L0.333008 1.8468L2.14706 0.0116464L6.28516 4.30513L10.5417 0Z' fill='%23060052'/%3E%3C/svg%3E%0A");
  position: absolute;
  height: calc(100% - 24px);
  right: 16px;
  top: 11px;
  transition: transform 0.3s ease-in;
}

.filterCheckboxContainer.dropdownActive::after {
  transform: rotate(180deg);
}

.filterCheckboxContent {
  display: flex;
  align-items: center;
}

.filterCheckboxListContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateY(calc(100% + 16px));
  display: none;
  border-radius: 4px;
  overflow: hidden;
  border: solid 1px #dbdbdb;
}

.filterCheckboxListContainer.dropdownVisible {
  display: block;
}

.filterCheckbox {
  padding: 16px 16px;
  border-bottom: solid 1px #dbdbdb;
  background-color: #f9f9f9;
  display: flex;
}

.filterCheckbox:last-of-type {
  border-bottom: 0;
}

.filterCheckboxInput {
  margin-right: 8px;
  border: solid 1px #dbdbdb;
}

.filterCheckboxLabel {
  line-height: 0.875rem;
  font-size: 0.875rem;
  margin-bottom: 0;
  font-family: "Proxima Nova", sans-serif;
  width: 100%;
  cursor: pointer;
  transform: translateY(1px);
}

.selectedCheckboxFilter {
  background-color: #141464;
  color: #ffffff;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.875rem;
  padding: 8px 12px;
  margin: 0 4px;
  flex: 0 0 auto;
}
.selectedCheckboxFilter:first-of-type {
  margin-left: 0;
}

.selectedCheckboxFilter::after {
  content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.44853 0.93368L4.99928 4.38293L1.55003 0.93368C1.46707 0.857765 1.35802 0.816784 1.24559 0.819275C1.13317 0.821766 1.02604 0.867537 0.946523 0.947052C0.867008 1.02657 0.821237 1.1337 0.818746 1.24612C0.816255 1.35854 0.857236 1.4676 0.933151 1.55056L4.38065 4.9998L0.932276 8.44818C0.890147 8.48833 0.85647 8.5365 0.833223 8.58985C0.809976 8.6432 0.797629 8.70066 0.796908 8.75885C0.796188 8.81704 0.807108 8.87479 0.829026 8.9287C0.850944 8.98261 0.883419 9.0316 0.924541 9.07278C0.965663 9.11396 1.0146 9.1465 1.06848 9.1685C1.12236 9.19049 1.1801 9.2015 1.23829 9.20086C1.29648 9.20022 1.35396 9.18795 1.40734 9.16478C1.46073 9.14161 1.50894 9.108 1.54915 9.06593L4.99928 5.61756L8.44853 9.0668C8.53149 9.14272 8.64054 9.1837 8.75296 9.18121C8.86539 9.17872 8.97252 9.13295 9.05203 9.05343C9.13155 8.97392 9.17732 8.86679 9.17981 8.75436C9.1823 8.64194 9.14132 8.53289 9.0654 8.44993L5.61615 5.00068L9.0654 1.55056C9.10753 1.51041 9.14121 1.46224 9.16446 1.40889C9.1877 1.35554 9.20005 1.29808 9.20077 1.23988C9.20149 1.18169 9.19057 1.12394 9.16865 1.07003C9.14673 1.01612 9.11426 0.967135 9.07314 0.925955C9.03202 0.884775 8.98308 0.852231 8.9292 0.830236C8.87532 0.808241 8.81758 0.79724 8.75939 0.797878C8.7012 0.798516 8.64372 0.810781 8.59033 0.833952C8.53695 0.857124 8.48874 0.890733 8.44853 0.932805V0.93368Z' fill='white'/%3E%3C/svg%3E%0A");
  margin-left: 8px;
  transform: translateY(2px);
}

.shadowPagePaginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 0 0;
}

.shadowPagePaginationContent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}

.shadowPagePaginationElement {
  height: 3rem;
  width: 3rem;
  border-radius: 8px !important;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px;
  border: solid 1px #e6e6e6;
  cursor: pointer;
  font-weight: 700;
  font-family: "Proxima Nova", sans-serif;
  color: #060052;
}

.shadowPagePaginationElement:hover,
.shadowPagePaginationElement.numberPageActive {
  background-color: #060052;
  border: solid 1px #060052;
  color: #ffffff;
}

.shadowPagePaginationArrow.buttonDisabled {
  pointer-events: none;
  background-color: #e6e6e6;
  border: solid 1px #e6e6e6;
}

.shadowPagePaginationArrow::before {
  transform: translateY(2px);
}

.shadowPagePaginationArrow.previous.buttonDisabled::before {
  content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 10.2087L6.14156 12L-2.59209e-07 5.93002L6.1532 -8.0726e-08L7.98835 1.81405L3.69487 5.95215L8 10.2087Z' fill='%23292929'/%3E%3C/svg%3E%0A");
}

.shadowPagePaginationArrow.next.buttonDisabled::before {
  content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-4.46236e-07 1.79132L1.85844 -8.12351e-08L8 6.06998L1.8468 12L0.0116464 10.1859L4.30513 6.04785L-4.46236e-07 1.79132Z' fill='%23292929'/%3E%3C/svg%3E%0A");
}

.shadowPagePaginationArrow.previous.buttonDisabled:hover::before {
  content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 10.2087L6.14156 12L-2.59209e-07 5.93002L6.1532 -8.0726e-08L7.98835 1.81405L3.69487 5.95215L8 10.2087Z' fill='%23292929'/%3E%3C/svg%3E%0A");
}

.shadowPagePaginationArrow.next.buttonDisabled:hover::before {
  content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-4.46236e-07 1.79132L1.85844 -8.12351e-08L8 6.06998L1.8468 12L0.0116464 10.1859L4.30513 6.04785L-4.46236e-07 1.79132Z' fill='%23292929'/%3E%3C/svg%3E%0A");
}

.shadowPagePaginationArrow.previous::before {
  content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 10.2087L6.14156 12L-2.59209e-07 5.93002L6.1532 -8.0726e-08L7.98835 1.81405L3.69487 5.95215L8 10.2087Z' fill='%23060052'/%3E%3C/svg%3E%0A");
}

.shadowPagePaginationArrow.previous:hover::before {
  content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 10.2087L6.14156 12L-2.59209e-07 5.93002L6.1532 -8.0726e-08L7.98835 1.81405L3.69487 5.95215L8 10.2087Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
}

.shadowPagePaginationArrow.next::before {
  content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-4.46236e-07 1.79132L1.85844 -8.12351e-08L8 6.06998L1.8468 12L0.0116464 10.1859L4.30513 6.04785L-4.46236e-07 1.79132Z' fill='%23060052'/%3E%3C/svg%3E%0A");
}

.shadowPagePaginationArrow.next:hover::before {
  content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-4.46236e-07 1.79132L1.85844 -8.12351e-08L8 6.06998L1.8468 12L0.0116464 10.1859L4.30513 6.04785L-4.46236e-07 1.79132Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
}

.shadowPageGlobalStatsContainer {
  padding: 48px 0;
  margin-top: 48px;
  width: 100%;
  border-top: solid 1px #d0ccff;
}

.shadowPageGlobalStatsElements {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 48px;
}

.shadowPageGlobalStatsElement {
  width: calc(50% - 12px);
  border: solid 1px #e3e0ff;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px 0px #06005214;
  padding: 24px;
  border-radius: 8px;
}

.shadowPageGlobalStatsElementContent {
  font-family: "Montserrat", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 3rem;
  display: flex;
}

.shadowPageGlobalStatsElementNumber {
  font-weight: 700;
  font-size: 1.75rem;
  margin-right: 0.75rem;
}

.shadowPageResultsTableContent.noResults {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  min-height: unset;
}

.shadowPageNoResults {
  font-family: "Proxima Nova", sans-serif;
  font-weight: 500;
  font-size: 1rem;
}

.shadowPageNoResults.alignedCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shadowPageNoResultsResetFilters {
  text-decoration: underline;
  color: #141464;
  cursor: pointer;
  transition: color 0.2s ease-in;
}

.shadowPageNoResultsResetFilters:hover {
  color: #0a008f;
}
</style>
