<template>
  <ActionBase
    @click="handleClick()"
    :disabled="popinHasErrors"
    :label="$t('save')"
    :primary="false"
  />
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ActionBase from "../../../Popin/Actions/ActionBase.vue";
import db from "../../../../db";
export default {
  components: { ActionBase },
  computed: {
    ...mapGetters("popin", [
      "getPopinShallowPayload",
      "popinPayload",
      "additionalContext",
      "popinHasErrors",
    ]),
  },
  methods: {
    ...mapMutations("popin", ["closePopin"]),
    async handleClick() {
      const playerToUpdateIndex = this.additionalContext.players?.findIndex(
        (player) => player.id === this.getPopinShallowPayload.id
      );
      const playerInToUpdateIndex = this.additionalContext.playersIn?.findIndex(
        (player) => player.id === this.getPopinShallowPayload.id
      );
      const updatePayload = {};
      if (playerToUpdateIndex < 0 && playerInToUpdateIndex < 0) {
        this.additionalContext.players.push(this.getPopinShallowPayload);
        updatePayload.players = this.additionalContext.players;
      } else if (playerToUpdateIndex >= 0) {
        Object.assign(
          this.additionalContext.players[playerToUpdateIndex],
          this.getPopinShallowPayload
        );
        updatePayload.players = this.additionalContext.players;
      } else {
        Object.assign(
          this.additionalContext.playersIn[playerInToUpdateIndex],
          this.getPopinShallowPayload
        );
        updatePayload.playersIn = this.additionalContext.playersIn;
      }
      await db
        .collection("V3shadowsMercato")
        .doc(this.additionalContext.id)
        .set(updatePayload, { merge: true });
      Object.assign(this.popinPayload, this.getPopinShallowPayload);
      this.closePopin();
    },
  },
};
</script>

<style lang="scss" scoped></style>
