import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getLocale"]),
  },
  methods: {
    translate(translationId, number = 1) {
      if (!number || number > 1) {
        return this.$tc(translationId, number);
      }
    },
  },
};
