<template>
  <div>
    <b-row>
      <b-col>
        <div class="gameBlocTitle pitchPlayerMainTitle">
          <h3>{{ $t("starters") }}</h3>
        </div>
        <div class="bucketShadowContainer">
          <span class="legendElement playerBucket">
            {{ $t("gameViewlegend.bucketPlayer") }}
          </span>
          <span class="legendElement playerShadow">
            {{ $t("gameViewlegend.shadowPlayer") }}
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row class="pitchViewRowContainer">
      <div class="pitchViewContainer">
        <div class="pitchViewContent">
          <div class="designPitchView">
            <div class="designPitchViewAwaySide">
              <div
                class="designPitchViewTopLeftCorner designPitchViewCorner"
              ></div>
              <div
                class="designPitchViewAwaySideGoal designPitchViewGoals"
              ></div>
              <div
                class="designPitchViewSurface designPitchViewAwaySurface"
              ></div>
              <div
                class="designPitchViewTopRightCorner designPitchViewCorner"
              ></div>
              <div class="designPitchViewCentralCircle"></div>
            </div>
            <div class="designPitchViewHomeSide">
              <div
                class="designPitchViewBottomLeftCorner designPitchViewCorner"
              ></div>
              <div
                class="designPitchViewBottomRightCorner designPitchViewCorner"
              ></div>
              <div
                class="designPitchViewHomeSideGoal designPitchViewGoals"
              ></div>
              <div
                class="designPitchViewSurface designPitchViewHomeSurface"
              ></div>
            </div>
          </div>
          <div class="pitchViewHomePlayers">
            <b-row v-if="findPlayerForPosition('GK', teamHome).id">
              <b-col />
              <b-col />
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('GK', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('GK', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('GK', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('GK', teamHome))"
                />
              </b-col>
              <b-col />
              <b-col />
            </b-row>
            <b-row
              v-if="
                findPlayerForPosition('RB', teamHome).id ||
                findPlayerForPosition('RCB', teamHome).id ||
                findPlayerForPosition('CB', teamHome).id ||
                findPlayerForPosition('LCB', teamHome).id ||
                findPlayerForPosition('LB', teamHome).id
              "
            >
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RB', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RB', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RB', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('RB', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RCB', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RCB', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RCB', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('RCB', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('CB', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('CB', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('CB', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('CB', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LCB', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LCB', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LCB', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('LCB', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LB', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LB', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LB', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('LB', teamHome))"
                />
              </b-col>
            </b-row>
            <b-row
              v-if="
                findPlayerForPosition('RWB', teamHome).id ||
                findPlayerForPosition('RDM', teamHome).id ||
                findPlayerForPosition('CDM', teamHome).id ||
                findPlayerForPosition('LDM', teamHome).id ||
                findPlayerForPosition('LWB', teamHome).id
              "
            >
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RWB', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RWB', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RWB', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('RWB', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RDM', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RDM', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RDM', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('RDM', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('CDM', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('CDM', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('CDM', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('CDM', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LDM', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LDM', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LDM', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('LDM', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LWB', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LWB', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LWB', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('LWB', teamHome))"
                />
              </b-col>
            </b-row>
            <b-row
              v-if="
                findPlayerForPosition('RM', teamHome).id ||
                findPlayerForPosition('RCM', teamHome).id ||
                findPlayerForPosition('CM', teamHome).id ||
                findPlayerForPosition('LCM', teamHome).id ||
                findPlayerForPosition('LM', teamHome).id
              "
            >
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RM', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RM', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RM', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('RM', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RCM', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RCM', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RCM', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('RCM', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('CM', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('CM', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('CM', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('CM', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LCM', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LCM', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LCM', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('LCM', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LM', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LM', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LM', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('LM', teamHome))"
                />
              </b-col>
            </b-row>
            <b-row
              v-if="
                findPlayerForPosition('RAM', teamHome).id ||
                findPlayerForPosition('CAM', teamHome).id ||
                findPlayerForPosition('LAM', teamHome).id
              "
            >
              <b-col />
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RAM', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RAM', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RAM', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('RAM', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('CAM', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('CAM', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('CAM', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('CAM', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LAM', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LAM', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LAM', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('LAM', teamHome))"
                />
              </b-col>
              <b-col />
            </b-row>
            <b-row
              v-if="
                findPlayerForPosition('RW', teamHome).id ||
                findPlayerForPosition('LW', teamHome).id
              "
            >
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RW', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RW', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RW', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('RW', teamHome))"
                />
              </b-col>
              <b-col />
              <b-col />
              <b-col />
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LW', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LW', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LW', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('LW', teamHome))"
                />
              </b-col>
            </b-row>
            <b-row
              v-if="
                findPlayerForPosition('RCF', teamHome).id ||
                findPlayerForPosition('CF', teamHome).id ||
                findPlayerForPosition('LCF', teamHome).id
              "
            >
              <b-col />
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RCF', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RCF', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RCF', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('RCF', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('CF', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('CF', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('CF', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('CF', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LCF', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LCF', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LCF', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('LCF', teamHome))"
                />
              </b-col>
              <b-col />
            </b-row>
            <b-row
              v-if="
                findPlayerForPosition('SS', teamHome).id ||
                findPlayerForPosition('S', teamHome).id
              "
            >
              <b-col />
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('SS', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('SS', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('SS', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('SS', teamHome))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="home"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('S', teamHome)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('S', teamHome))
                  "
                  :hasCard="hasCard(findPlayerForPosition('S', teamHome))"
                  :isGoaler="isGoaler(findPlayerForPosition('S', teamHome))"
                />
              </b-col>
              <b-col />
              <b-col />
            </b-row>
          </div>
          <div class="pitchViewAwayPlayers">
            <b-row
              v-if="
                findPlayerForPosition('S', teamAway).id ||
                findPlayerForPosition('SS', teamAway).id
              "
            >
              <b-col />
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('S', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('S', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('S', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('S', teamAway))"
                />
              </b-col>
              <b-col />
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('SS', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('SS', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('SS', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('SS', teamAway))"
                />
              </b-col>
              <b-col />
            </b-row>
            <b-row
              v-if="
                findPlayerForPosition('LCF', teamAway).id ||
                findPlayerForPosition('CF', teamAway).id ||
                findPlayerForPosition('RCF', teamAway).id
              "
            >
              <b-col />
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LCF', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LCF', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LCF', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('LCF', teamAway))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('CF', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('CF', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('CF', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('CF', teamAway))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RCF', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RCF', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RCF', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('RCF', teamAway))"
                />
              </b-col>
              <b-col />
            </b-row>
            <b-row
              v-if="
                findPlayerForPosition('LW', teamAway).id ||
                findPlayerForPosition('RW', teamAway).id
              "
            >
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LW', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LW', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LW', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('LW', teamAway))"
                />
              </b-col>
              <b-col />
              <b-col />
              <b-col />
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RW', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RW', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RW', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('RW', teamAway))"
                />
              </b-col>
            </b-row>
            <b-row
              v-if="
                findPlayerForPosition('LAM', teamAway).id ||
                findPlayerForPosition('CAM', teamAway).id ||
                findPlayerForPosition('RAM', teamAway).id
              "
            >
              <b-col />
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LAM', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LAM', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LAM', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('LAM', teamAway))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('CAM', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('CAM', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('CAM', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('CAM', teamAway))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RAM', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RAM', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RAM', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('RAM', teamAway))"
                />
              </b-col>
              <b-col />
            </b-row>
            <b-row
              v-if="
                findPlayerForPosition('LM', teamAway).id ||
                findPlayerForPosition('LCM', teamAway).id ||
                findPlayerForPosition('CM', teamAway).id ||
                findPlayerForPosition('RCM', teamAway).id ||
                findPlayerForPosition('RM', teamAway).id
              "
            >
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LM', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LM', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LM', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('LM', teamAway))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LCM', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LCM', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LCM', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('LCM', teamAway))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('CM', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('CM', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('CM', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('CM', teamAway))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RCM', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RCM', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RCM', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('RCM', teamAway))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RM', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RM', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RM', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('RM', teamAway))"
                />
              </b-col>
            </b-row>
            <b-row
              v-if="
                findPlayerForPosition('LWB', teamAway).id ||
                findPlayerForPosition('LDM', teamAway).id ||
                findPlayerForPosition('CDM', teamAway).id ||
                findPlayerForPosition('RDM', teamAway).id ||
                findPlayerForPosition('RWB', teamAway).id
              "
            >
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LWB', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LWB', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LWB', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('LWB', teamAway))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LDM', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LDM', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LDM', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('LDM', teamAway))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('CDM', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('CDM', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('CDM', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('CDM', teamAway))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RDM', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RDM', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RDM', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('RDM', teamAway))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RWB', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RWB', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RWB', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('RWB', teamAway))"
                />
              </b-col>
            </b-row>
            <b-row
              v-if="
                findPlayerForPosition('LB', teamAway).id ||
                findPlayerForPosition('LCB', teamAway).id ||
                findPlayerForPosition('CB', teamAway).id ||
                findPlayerForPosition('RCB', teamAway).id ||
                findPlayerForPosition('RB', teamAway).id
              "
            >
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LB', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LB', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LB', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('LB', teamAway))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('LCB', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('LCB', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('LCB', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('LCB', teamAway))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('CB', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('CB', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('CB', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('CB', teamAway))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RCB', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RCB', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RCB', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('RCB', teamAway))"
                />
              </b-col>
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('RB', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('RB', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('RB', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('RB', teamAway))"
                />
              </b-col>
            </b-row>
            <b-row v-if="findPlayerForPosition('GK', teamAway).id">
              <b-col />
              <b-col />
              <b-col>
                <PitchPlayer
                  team="away"
                  :gameNotes="gameNotes"
                  :player="findPlayerForPosition('GK', teamAway)"
                  :hasBeenReplaced="
                    hasBeenReplaced(findPlayerForPosition('GK', teamAway))
                  "
                  :hasCard="hasCard(findPlayerForPosition('GK', teamAway))"
                  :isGoaler="isGoaler(findPlayerForPosition('GK', teamAway))"
                />
              </b-col>
              <b-col />
              <b-col />
            </b-row>
          </div>
        </div>
      </div>
    </b-row>
    <b-row v-if="homeSubstituers.length || awaySubstituers.length">
      <b-col>
        <div class="gameBlocTitle pitchPlayerMainTitle">
          <h3>{{ $t("change") }}</h3>
        </div>
      </b-col>
    </b-row>

    <div
      id="teamsChangesContainer"
      v-if="homeSubstituers.length || awaySubstituers.length"
    >
      <div v-if="homeSubstituers.length" class="teamChangesContainer">
        <div class="gameBlocTitle pitchTeamName">
          <h4 class="teamChangesTitle">{{ teamHome }}</h4>
        </div>
        <div class="substituerContainer">
          <div
            class="substituerContent"
            v-for="(substituer, index) in homeSubstituers"
            :key="index"
          >
            <PitchPlayer
              team="home"
              :gameNotes="gameNotes"
              :player="substituer"
              :hasCard="hasCard(substituer)"
              :isGoaler="isGoaler(substituer)"
            />
          </div>
        </div>
      </div>
      <div v-if="awaySubstituers.length" class="teamChangesContainer">
        <div class="gameBlocTitle pitchTeamName">
          <h4 class="teamChangesTitle">{{ teamAway }}</h4>
        </div>
        <div class="substituerContainer">
          <div
            class="substituerContent"
            v-for="(substituer, index) in awaySubstituers"
            :key="index"
          >
            <PitchPlayer
              team="away"
              :gameNotes="gameNotes"
              :player="substituer"
              :hasCard="hasCard(substituer)"
              :isGoaler="isGoaler(substituer)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="reportAProblemContainer">
      <button
        class="reportAProblemButton"
        @click="reportAProblem(game.hasProblem)"
        color="red"
        align="right"
        :highlight="game.hasProblem"
      >
        {{
          game.hasProblem ? $t("compoErrorSignaled") : $t("report.aCompoError")
        }}
      </button>
    </div>
    <NotificationComponent :notification="notification" />
  </div>
</template>
<script>
import PitchPlayer from "@/components/PitchPlayer";
import NotificationComponent from "@/components/Notification";
import db from "../db";
import { mapGetters } from "vuex";
export default {
  name: "PitchPlayers",
  components: {
    PitchPlayer,
    NotificationComponent,
  },
  props: {
    teamAway: String,
    teamHome: String,
    players: Array,
    substitutionsEvents: Array,
    cardEvents: Array,
    goals: Array,
    gameNotes: Array,
    game: Object,
  },
  mounted() {
    this.setPlayersByPosition();
    if (this.getBucketPlayers.length) {
      this.$store.dispatch("bindBucketPlayers");
    }
  },
  data() {
    return {
      playersByPosition: {},
      notification: {
        display: false,
        type: "",
        details: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getGamesPosition", "getBucketPlayers"]),
    homeSubstituers() {
      const self = this;
      const dupplicatedFreePlayers = self.lodash.uniqBy(
        self.players,
        function (player) {
          return player.id.toString();
        }
      );
      return self.lodash.filter(dupplicatedFreePlayers, function (player) {
        if (
          (player.team == "home" || player.team == self.teamHome) &&
          !player.isStartingPlayer
        ) {
          return player;
        }
      });
    },
    awaySubstituers() {
      const self = this;
      const dupplicatedFreePlayers = self.lodash.uniqBy(
        self.players,
        function (player) {
          return player.id.toString();
        }
      );
      return self.lodash.filter(dupplicatedFreePlayers, function (player) {
        if (
          (player.team == "away" || player.team == self.teamAway) &&
          !player.isStartingPlayer
        ) {
          return player;
        }
      });
    },
  },
  methods: {
    setPlayersByPosition() {
      const toReturn = [];
      const self = this;
      self.players.forEach((player) => {
        const abvForPlayerPosition = self.lodash.filter(self.getGamesPosition, {
          id: player.gamePositionId,
        })[0]?.abv;
        if (abvForPlayerPosition && !toReturn[abvForPlayerPosition]) {
          toReturn[abvForPlayerPosition] = [];
        }
        toReturn[abvForPlayerPosition].push(player);
      });
      this.playersByPosition = toReturn;
    },
    toggleNotification(notificationType, notificationDetails) {
      this.notification = notificationDetails.message
        ? {
            display: true,
            type: notificationType,
            details: this.$t(
              notificationDetails.message,
              notificationDetails.params
            ),
          }
        : {
            display: true,
            type: notificationType,
            details: this.$t(notificationDetails),
          };
      setTimeout(() => {
        this.notification.display = false;
      }, 6000);
    },
    reportAProblem(hasProblem) {
      const self = this;
      db.collection("V3games")
        .doc(self.game.id.toString())
        .set({ hasProblem: !hasProblem }, { merge: true })
        .then(() => {
          self.toggleNotification("success", {
            message: "notification.success.signalCompoError",
          });
        })
        .catch(() => {
          self.toggleNotification("error", {
            message: "notification.error.unknown",
          });
        });
    },
    hasBeenReplaced(player) {
      const self = this;
      return self.lodash.filter(
        self.substitutionsEvents,
        function (substitionEvent) {
          return substitionEvent.playerName == player.name;
        }
      )[0];
    },
    hasCard(player) {
      const self = this;
      const cards = self.lodash.filter(self.cardEvents, function (cardEvent) {
        return cardEvent.playerName == player.name;
      });
      if (cards?.length) {
        return cards[cards.length - 1];
      }
      return {};
    },
    isGoaler(player) {
      if (player.id) {
        const self = this;
        return self.lodash.filter(self.goals, function (goal) {
          return goal.playerName == player.name;
        })[0];
      }
    },
    findPositionAbvById(positionId) {
      return this.lodash.filter(this.getGamesPosition, { id: positionId })[0]
        ?.abv;
    },
    findPlayerForPosition(positionAbbreviation, team) {
      const self = this;
      if (!positionAbbreviation) {
        return {};
      }
      return (
        self.lodash.filter(
          self.playersByPosition[positionAbbreviation],
          function (player) {
            if (player.team == team) {
              return player.isStartingPlayer;
            }
          }
        )[0] || {}
      );
    },
  },
  watch: {
    players: {
      deep: true,
      immediate: true,
      handler() {
        this.setPlayersByPosition();
      },
    },
  },
};
</script>

<style scoped>
.reportAProblemContainer .col {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.pitchViewAwayPlayers > .row,
.pitchViewHomePlayers > .row {
  min-height: 30px;
  max-width: 100%;
  margin: 0 auto 30px;
  width: 100%;
}

.pitchViewHomePlayers > .row:last-of-type {
  margin-bottom: 0;
}

.substituerContent {
  width: 50%;
  padding: 0 12px;
}

#teamsChangesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.teamChangesContainer {
  width: calc(50% - 8px);
  align-items: center;
}

.substituerContainer {
  display: flex;
  flex-wrap: wrap;
}

.gameBlocTitle {
  margin-bottom: 15px;
}

.teamChangesTitle {
  font-size: 0.875rem;
  font-weight: 700;
  font-family: "Proxima Nova", sans-serif;
  color: #ffffff;
  text-transform: uppercase;
  padding: 16px 0;
  border-bottom: solid 4px #ffffff;
}

.gameBlocTitle.pitchTeamName {
  width: 100%;
  text-align: center;
}

.pitchViewContainer {
  width: 100%;
  height: 1350px;
}

.gameBlocTitle.pitchPlayerMainTitle {
  font-size: 1.5rem;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 24px;
}
.gameBlocTitle.pitchPlayerMainTitle h3 {
  color: #ffffff;
}

.pitchViewRowContainer {
  justify-content: center;
  padding: 0px 14px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.reportAProblemContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0 16px;
}

.reportAProblemButton {
  background-color: #b34442;
  padding: 10px 24px;
  border-radius: 100px;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
  font-family: "Proxima Nova", sans-serif;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
}

.reportAProblemButton:hover {
  background-color: #8d3534;
}

.reportAProblemButton::before {
  content: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.8001 3.14844L22.4342 18.1024C22.5658 18.3305 22.6351 18.5891 22.6351 18.8524C22.6351 19.1157 22.5658 19.3744 22.4342 19.6024C22.3025 19.8304 22.1132 20.0198 21.8851 20.1515C21.6571 20.2831 21.3985 20.3524 21.1352 20.3524H3.86715C3.60385 20.3524 3.34519 20.2831 3.11716 20.1515C2.88914 20.0198 2.69979 19.8304 2.56814 19.6024C2.43649 19.3744 2.36719 19.1157 2.36719 18.8524C2.36719 18.5891 2.4365 18.3305 2.56815 18.1024L11.2021 3.14844C11.7791 2.14844 13.2221 2.14844 13.8001 3.14844ZM12.5011 15.0004C12.2359 15.0004 11.9816 15.1058 11.794 15.2933C11.6065 15.4809 11.5011 15.7352 11.5011 16.0004C11.5011 16.2657 11.6065 16.52 11.794 16.7075C11.9816 16.8951 12.2359 17.0004 12.5011 17.0004C12.7664 17.0004 13.0207 16.8951 13.2083 16.7075C13.3958 16.52 13.5011 16.2657 13.5011 16.0004C13.5011 15.7352 13.3958 15.4809 13.2083 15.2933C13.0207 15.1058 12.7664 15.0004 12.5011 15.0004ZM12.5011 8.00044C12.2562 8.00047 12.0198 8.09039 11.8368 8.25315C11.6537 8.41591 11.5368 8.64019 11.5081 8.88344L11.5011 9.00044V13.0004C11.5014 13.2553 11.599 13.5005 11.774 13.6858C11.949 13.8711 12.1881 13.9827 12.4425 13.9976C12.697 14.0125 12.9475 13.9298 13.143 13.7662C13.3384 13.6026 13.464 13.3705 13.4941 13.1174L13.5011 13.0004V9.00044C13.5011 8.73522 13.3958 8.48087 13.2083 8.29333C13.0207 8.10579 12.7664 8.00044 12.5011 8.00044Z' fill='white'/%3E%3C/svg%3E%0A");
  width: fit-content;
  display: block;
  margin-right: 8px;
  transform: translateY(3px);
}

.pitchViewRowContainer img {
  width: 90%;
  padding-top: 30px;
  margin: 0 auto;
  position: absolute;
  z-index: 0;
}

.designPitchView {
  position: absolute;
  border: 2px solid white;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.designPitchViewAwaySide {
  flex: 1;
  border-bottom: 2px solid white;
}

.designPitchViewTopLeftCorner {
  left: -15px;
  top: -15px;
}

.designPitchViewTopRightCorner {
  right: -15px;
  top: -15px;
}

.designPitchViewBottomLeftCorner {
  left: -15px;
  bottom: -15px;
}

.designPitchViewBottomRightCorner {
  right: -15px;
  bottom: -15px;
}

.designPitchViewCorner {
  width: 30px;
  height: 30px;
  position: absolute;
  border: 2px solid white;
  margin: 0 auto;
  border-radius: 50px;
}

.designPitchViewGoals,
.designPitchViewSurface {
  position: absolute;
  left: 0;
  right: 0;
  border: 2px solid white;
  margin: 0 auto;
}

.designPitchViewGoals {
  width: 35%;
  height: 5%;
}

.designPitchViewSurface {
  width: 60%;
  height: 10%;
}

.designPitchViewHomeSideGoal {
  bottom: -2px;
}

.designPitchViewAwaySideGoal {
  top: -2px;
}

.designPitchViewAwaySurface {
  top: -2px;
}

.designPitchViewHomeSurface {
  bottom: -2px;
}

.designPitchViewHomeSide {
  flex: 1;
}

.designPitchViewCentralCircle {
  border: 2px solid white;
  width: 210px;
  height: 210px;
  border-radius: 500px;
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pitchViewContent {
  height: 100%;
  position: relative;
}

.pitchViewAwayPlayers,
.pitchViewHomePlayers {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 8px;
  max-height: 675px;
  height: 675px;
}

.pitchViewContent .col {
  max-width: calc(100% / 5);
  padding: 0 4px;
}

.bucketShadowContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
}

.legendElement {
  display: flex;
  align-items: center;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  padding: 0 8px;
}

.legendElement.playerBucket {
  color: #c88c28;
}

.legendElement.playerShadow {
  color: #b34442;
}

.legendElement::before {
  content: "";
  margin-right: 8px;
  transform: translateY(3px);
}

.legendElement.playerBucket::before {
  content: url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.00227 14.2698L13.1523 16.7798C13.9123 17.2398 14.8423 16.5598 14.6423 15.6998L13.5423 10.9798L17.2123 7.79977C17.8823 7.21977 17.5223 6.11977 16.6423 6.04977L11.8123 5.63977L9.92227 1.17977C9.58227 0.369766 8.42227 0.369766 8.08227 1.17977L6.19227 5.62977L1.36227 6.03977C0.48227 6.10977 0.12227 7.20977 0.79227 7.78977L4.46227 10.9698L3.36227 15.6898C3.16227 16.5498 4.09227 17.2298 4.85227 16.7698L9.00227 14.2698Z' fill='%23C88C28'/%3E%3C/svg%3E%0A");
}

.legendElement.playerShadow::before {
  content: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.4686 13.9657H3.49714L2.92571 13.3943V5.78286H1.31429L0.765714 5.34857L0 2.28571L0.377143 1.6L5.24572 0L5.97714 0.365714C6.04181 0.540228 6.12214 0.708533 6.21714 0.868571C6.29829 0.986286 6.39086 1.09714 6.49143 1.2C6.68343 1.39314 6.91314 1.54514 7.16571 1.64571C7.42401 1.75606 7.70198 1.81295 7.98286 1.81295C8.26374 1.81295 8.5417 1.75606 8.8 1.64571C9.05291 1.54487 9.28241 1.39317 9.47429 1.2C9.57714 1.09143 9.672 0.977143 9.76 0.857143L9.93143 0.525714C9.95374 0.473603 9.97282 0.420168 9.98857 0.365714L10.72 0L15.5886 1.62286L16 2.28571L15.2457 5.32571L14.6857 5.76H13.04V13.3714L12.4686 13.9657Z' fill='%23B34442'/%3E%3C/svg%3E%0A");
}
</style>
