<template>
  <ActionBase
    @click="handleClick()"
    :disabled="popinHasErrors"
    :label="$t('validate')"
    :primary="true"
  />
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ActionBase from "../../../Popin/Actions/ActionBase.vue";
import db from "../../../../db";
export default {
  components: { ActionBase },
  computed: {
    ...mapGetters("popin", [
      "getPopinShallowPayload",
      "popinPayload",
      "additionalContext",
      "popinHasErrors",
    ]),
    ...mapGetters(["getCurrentCompanyTeams"]),
  },
  methods: {
    ...mapMutations("popin", ["closePopin"]),
    async handleClick() {
      const inPlayersIndex =
        this.additionalContext.players?.findIndex(
          (player) => player.id === this.getPopinShallowPayload.id
        ) ?? -1;
      if (
        this.getCurrentCompanyTeams?.[0] !==
        this.getPopinShallowPayload.currentTeam
      ) {
        Object.assign(this.getPopinShallowPayload, {
          currentTeam: this.getCurrentCompanyTeams?.[0],
          previousTeam: this.getPopinShallowPayload.currentTeam,
        });
      }
      if (inPlayersIndex === -1) {
        this.additionalContext.players = [
          ...(this.additionalContext.players ?? []),
          this.getPopinShallowPayload,
        ];
      } else {
        this.additionalContext.players[inPlayersIndex] =
          this.getPopinShallowPayload;
      }
      await db
        .collection("V3shadowsMercato")
        .doc(this.additionalContext.id)
        .set({ players: this.additionalContext.players }, { merge: true });
      Object.assign(this.popinPayload, this.getPopinShallowPayload);
      this.closePopin();
    },
  },
};
</script>

<style lang="scss" scoped></style>
