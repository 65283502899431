import { render, staticRenderFns } from "./BalancePlayer.vue?vue&type=template&id=64f33070&scoped=true&"
import script from "./BalancePlayer.vue?vue&type=script&lang=js&"
export * from "./BalancePlayer.vue?vue&type=script&lang=js&"
import style0 from "./BalancePlayer.vue?vue&type=style&index=0&id=64f33070&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f33070",
  null
  
)

export default component.exports