<template>
  <ActionBase
    @click="handleClick()"
    :disabled="false"
    :label="$t('validate')"
    :primary="true"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ActionBase from "../../../Popin/Actions/ActionBase.vue";
import db from "../../../../db";
export default {
  components: { ActionBase },
  computed: {
    ...mapGetters("popin", [
      "getPopinShallowPayload",
      "popinPayload",
      "additionalContext",
    ]),
    ...mapGetters(["getCurrentCompanyTeams"]),
  },
  methods: {
    ...mapMutations("popin", ["closePopin"]),
    ...mapActions("popin", ["openToast"]),
    async handleClick() {
      this.additionalContext.playersIn =
        this.additionalContext.playersIn.filter(
          (p) => p.id !== this.getPopinShallowPayload.id
        );
      await db
        .collection("V3shadowsMercato")
        .doc(this.additionalContext.id)
        .set({ playersIn: this.additionalContext.playersIn }, { merge: true });
      Object.assign(this.popinPayload, this.getPopinShallowPayload);
      this.openToast({
        translationKey: "squad.toasts.cancelRecruitment",
        payload: { player: this.popinPayload.name },
        delay: 5000,
      });
      this.closePopin();
    },
  },
};
</script>

<style lang="scss" scoped></style>
