<template>
  <div class="pitch-container">
    <div class="pitch-header">
      <div class="pitch-top-pill left">
        <span>{{ compoLabel }}</span>
      </div>
      <div class="pitch-top-pill right">
        <span
          >{{ players.length }}
          {{ $tc("plural.player", players.length || 1) }}</span
        >
      </div>
    </div>
    <div
      class="pitch"
      :style="{
        paddingBottom: `calc(60px + ${
          (playersPerPosition.G?.length ?? 0) * 3
        }rem)`,
      }"
    >
      <div class="pitch-content">
        <div
          class="position-container"
          :key="'position_' + index"
          :style="
            sanitize(getPositionMap[positionAcronym][compo][compoVersion])
          "
          v-for="(positionAcronym, index) of positionsAcronyms"
        >
          <div
            class="position-acronym"
            @click="clickOnPosition(positionAcronym)"
          >
            <span>{{ positionAcronym }}</span>
          </div>
          <div
            class="position-badge"
            v-if="playersPerPosition[positionAcronym]?.length"
          >
            <span>{{ playersPerPosition[positionAcronym].length }}</span>
          </div>
          <div
            class="compo-players-list"
            v-if="playersPerPosition[positionAcronym]?.length"
          >
            <div
              :class="{
                ...getEndContractColorClass(player.endContract),
                'is-incoming': isPlayerIncoming(player),
              }"
              @click="openChangePlayerPopin(player)"
              class="compo-players-item"
              v-for="(player, index) of playersPerPosition[positionAcronym]"
              :key="`compo_player_${index}`"
            >
              <span class="compo-player-name">
                {{ getMinifiedPlayerName(player.name) }}
              </span>

              <span class="compo-player-eoc" v-if="player.endContract">
                {{ $t("endOfContractAcronym") }} :
                {{ formatDate(player.endContract, false, "MMMM YYYY") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pitch-footer-container">
      <div class="pitch-footer">
        <div
          class="pitch-footer-pill"
          v-for="({ className, text }, index) of endContractThresholds"
          :class="className"
          :key="`threshold_${index}`"
        >
          <span> {{ text }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { popinVariants } from "../../../enums/popinVariants";
import mixinPlayers from "../../../mixins/mixinPlayers";
export default {
  mixins: [mixinPlayers],
  props: {
    compo: { type: String, required: true },
    compoVersion: { type: Number, required: true },
    players: { type: Array, default: () => [] },
  },
  data() {
    return {
      playersPerPosition: {},
    };
  },
  computed: {
    ...mapGetters(["getCurrentCompanyTeams"]),
    compoLabel() {
      return [...this.compo].join("-");
    },
    positionsAcronyms() {
      return Object.entries(this.getPositionMap)
        .filter(([, value]) => value[this.compo]?.[this.compoVersion])
        .map(([key]) => key);
    },
    endContractThresholds() {
      const lessThanThresholds = Object.keys(this.getEndContractThresholds());
      const currentYear = new Date().getFullYear();
      return [
        ...lessThanThresholds,
        `player-accent-color-${lessThanThresholds.length + 1}`,
        "player-accent-color-unknown",
      ].map((className, index) => {
        let text = "";
        switch (index) {
          case lessThanThresholds.length + 1:
            text = this.$t("undefined");
            break;
          case lessThanThresholds.length:
            text = this.$t("legend.contractEndOverJune", {
              year: currentYear + index - 1,
            });
            break;
          default:
            text = this.$t("legend.contractEndSummer", {
              year: currentYear + index,
            });
            break;
        }
        return {
          className,
          text,
        };
      });
    },
  },
  methods: {
    ...mapMutations("popin", ["openPopinVariant"]),
    clickOnPosition(positionAcronym) {
      this.openPopinVariant({
        popinVariant: popinVariants.ORGANIZE_POSITION_PLAYERS,
        popinPayload: {
          players: this.playersPerPosition[positionAcronym],
          positionAcronym,
        },
      });
    },
    isPlayerIncoming(player) {
      return !this.getCurrentCompanyTeams.includes(player.currentTeam);
    },
    openChangePlayerPopin(player) {
      this.openPopinVariant({
        popinVariant: popinVariants.UPDATE_PLAYER_FROM_COMPO,
        popinPayload: { ...player, isIncoming: this.isPlayerIncoming(player) },
      });
    },
    sanitize(style) {
      delete style.transform;
      if (style.right) {
        style.left = `${100 - parseFloat(style.right.replace("%", ""))}%`;
      }
      if (style.bottom) {
        style.top = `${100 - parseFloat(style.bottom.replace("%", ""))}%`;
      }
      delete style.bottom;
      delete style.right;

      return style;
    },
  },
  watch: {
    players: {
      deep: true,
      immediate: true,
      handler(players) {
        const groupedPlayers = players.reduce((acc, player) => {
          const acronym =
            player.movingPosition ??
            this.reduceStringToInitiales(player.primaryPosition);
          return {
            ...acc,
            [acronym]: [...(acc[acronym] ?? []), player],
          };
        }, {});
        for (const key of Object.keys(groupedPlayers)) {
          groupedPlayers[key] = this.sortByPositionIndex(groupedPlayers[key]);
        }

        this.playersPerPosition = groupedPlayers;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

div.pitch-container {
  background-color: #ffffff;
  border: solid 1px #e3e0ff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
div.pitch-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  > .pitch-top-pill {
    padding: 8px 16px;
    background-color: #e3e0ff;
    cursor: default;
    line-height: 1.5rem;
    font-weight: 700;
    color: $blueDarkest;
    &.left {
      border-top-left-radius: 8px;
      border-bottom-right-radius: 8px;
      align-self: flex-start;
    }
    &.right {
      border-top-right-radius: 8px;
      border-bottom-left-radius: 8px;
      align-self: flex-end;
    }
  }
}

div.pitch {
  border-radius: 8px;
  padding: 2.5rem;
  height: 1000px;
}

div.pitch-content {
  position: relative;
  width: calc(100% - 4.25rem);
  height: 100%;
  margin: auto;
}

div.position-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 8.5rem;
  margin: 0 -4.25rem -4.25rem -4.25rem;
}

div.position-badge {
  position: absolute;
  width: 20px;
  user-select: none;
  height: 20px;
  background-color: #e3e0ff;
  border-radius: 100px;
  transform: translate(0, -50%);
  display: flex;
  right: 1.5rem;
  align-items: center;
  justify-content: center;
  > span {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.125rem;
    text-align: center;
  }
}

div.position-acronym {
  cursor: pointer;
  align-self: center;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: $blueDarkest;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    background-color: #2c1fd6;
  }
  > span {
    font-size: 1.25rem;
    font-weight: 700;
    color: white;
    user-select: none;
  }
}

div.compo-players-list {
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  gap: 1px;
  border-radius: 4px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $purpleLight;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: #2c1fd6;
    border-radius: 100px;
    border-left: 1px solid $purpleLight;
    border-right: 1px solid $purpleLight;
    width: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #2c1fd6;
  }
}

div.compo-players-item {
  cursor: pointer;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #fafafa;
  &.is-incoming {
    background-color: #ffe1e1;
  }
  > span.compo-player-name {
    font-weight: 700;
    font-size: 0.825rem;
    line-height: 0.825rem;
    color: $blueDarkest;
  }
  > span.compo-player-eoc {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: $blueDarkest;
  }
}

div.pitch-footer-container {
  width: 100%;
}
div.pitch-footer {
  display: flex;
  flex-direction: row;
  margin: 12px;
  padding: 12px;
  gap: 24px;
  justify-content: center;
  background-color: #f9f9f9;
  border-radius: 8px;
}
.pitch-footer-pill {
  user-select: none;
  border-radius: 4px;
  display: flex;
  background-color: #ffffff;
  padding: 8px;
  > span {
    font-weight: 400;
    font-size: 0.825rem;
    line-height: 0.825rem;
    color: $blueDarkest;
  }
}
</style>
