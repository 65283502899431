<template>
  <div class="squad_management">
    <Popin v-if="isPopinOpened" :additionalContext="currentShadow" />
    <NotificationToast
      v-if="toastInfos.isOpened"
      :translationKey="toastInfos.translationKey"
      :payload="toastInfos.payload"
    />
    <section class="squad-header">
      <div class="squad-header-content col-12">
        <span class="squad-title">
          {{ $t("pageTitle.squad") }}
        </span>
        <div class="create-squad-container" @click="openCreateSquadPopin">
          <img src="../assets/img/plus_white.svg" />
          <span>{{ $t("create.aSquad") }}</span>
        </div>
      </div>
    </section>

    <section class="squad-title">
      <div class="squad-title-content col-12">
        <div class="squad-title-row">
          <div class="squad-name-title">
            <span class="squad-name" v-if="currentShadow?.compo">
              {{ getScoutById(currentShadow?.scoutId)?.company }} - Compo
              {{ formatCompo(currentShadow.compo)
              }}{{ currentShadow.label ? ` - ${currentShadow.label}` : "" }}
            </span>
            <div
              class="squad-title-action rename-squad"
              @click="openRenameSquadPopin"
            >
              <img src="../assets/img/pen.svg" />
            </div>
            <div
              class="squad-title-action delete-squad"
              @click="openDeleteSquadPopin"
            >
              <img src="../assets/img/trash.svg" />
            </div>
          </div>
          <div class="squad-name-select">
            <DropdownInput
              :isPrimary="true"
              :options="squadsListOptions"
              :selected="getSquadOption(currentShadow)"
              @change="switchSquadManagement"
            />
          </div>
        </div>
        <div
          class="squad-title-row squad-subtitle"
          v-if="this.currentShadow.date"
        >
          <span>
            {{
              $t("pageSubtitle.squad", {
                createdDate: getSquadCreatedDate,
                name: identifyScoutById(getCurrentScout.id, "fullName"),
              })
            }}
          </span>
          <span v-if="this.currentShadow.update">
            {{
              $t("pageSubtitle.squadUpdate", {
                updateDate: getSquadUpdateDate,
                updateTime: getSquadUpdateTime,
              })
            }}
          </span>
        </div>
      </div>
    </section>
    <section>
      <b-container fluid class="main-container">
        <b-row>
          <b-col md="12" class="balance-pitch-container">
            <b-row>
              <b-col md="6" cols="12">
                <BalanceCard
                  variant="transfers"
                  :balance="getTotalSquadManagement"
                  :incoming="getTotalIn"
                  :outgoing="getTotalOut"
                  :playersIn="currentShadow.playersIn"
                  :playersOut="currentShadow.playersOut"
                />
              </b-col>
              <b-col md="6" cols="12">
                <BalanceCard
                  variant="salary"
                  :balance="getNewPayroll"
                  :incoming="salaryIn"
                  :outgoing="salaryOut"
                  :updates="salaryUpdates"
                  :playersIn="currentShadow.playersIn"
                  :playersUpdated="playersUpdated"
                  :playersOut="currentShadow.playersOut"
                  :balanceDifferencePercentage="getSalaryPercentDiff"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                :md="getCurrentScout?.company === getCompanies[0]?.id ? 12 : 8"
              >
                <PitchCard
                  v-if="currentShadow.compo && currentShadow.compoVersion"
                  :compo="currentShadow.compo"
                  :compoVersion="currentShadow.compoVersion"
                  :players="mergedPlayers"
                />
              </b-col>
              <b-col
                md="4"
                v-if="
                  getCurrentScout?.company &&
                  getCurrentScout?.company !== getCompanies[0]?.id
                "
              >
                <div class="squadManagementPlayersSelectContainer mb-4">
                  <CurrentSquadCard
                    class="players-card align-items-center"
                    :players="orderedStandPlayersNotSold"
                    :playersOut="currentShadow.playersOut"
                  />
                  <ProspectsCard
                    class="players-card align-items-center"
                    :players="orderedProspects"
                    :macroPositions="macroSelectOptions"
                    :microPositions="microSelectOptions"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <NotificationComponent :notification="notification" />
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import db from "../db";
import mixinDates from "../mixins/mixinDates";
import mixinNotification from "../mixins/mixinNotification";
import mixinToText from "../mixins/mixinText";
import mixinPlayers from "../mixins/mixinPlayers";
import CurrentSquadCard from "../components/SquadManagement/Cards/CurrentSquadCard.vue";
import Popin from "../components/Popin/Popin.vue";
import NotificationToast from "../components/NotificationToast.vue";
import { popinVariants } from "../enums/popinVariants";
import ProspectsCard from "../components/SquadManagement/Cards/ProspectsCard.vue";
import PitchCard from "../components/SquadManagement/Cards/PitchCard.vue";
import BalanceCard from "../components/SquadManagement/Cards/BalanceCard.vue";
import mixinScouts from "../mixins/mixinScouts";
import DropdownInput from "../components/Fields/DropdownInput.vue";

export default {
  components: {
    Popin,
    PitchCard,
    BalanceCard,
    CurrentSquadCard,
    ProspectsCard,
    DropdownInput,
    NotificationToast,
  },
  name: "SquadManagement",
  data() {
    return {
      salaryIn: 0,
      salaryOut: 0,
      salaryUpdates: 0,
      orderedProspects: [],
      mergedPlayers: [],
      currentScoutShadows: [],
      onStandPlayers: [],
      prospectsPlayers: [],
      currentShadow: {
        id: null,
        label: null,
        compo: null,
        compoVersion: null,
      },
      currentCompoPlayers: [],
      incomingPlayers: [],
      outPlayers: [],
    };
  },
  mixins: [
    mixinNotification,
    mixinToText,
    mixinDates,
    mixinPlayers,
    mixinScouts,
  ],
  mounted() {
    if (this.getCurrentScout?.id) {
      this.loadCurrentManagerSquads();
    }
    if (this.scoutsPlayers.length == 0) {
      this.bindScoutsPlayers();
    }
  },
  methods: {
    ...mapActions(["bindScoutsPlayers"]),
    ...mapMutations("popin", ["openPopinVariant"]),
    async manageProspects() {
      const self = this;
      self.orderedProspects = [];
      const groupedProspects = self.lodash.groupBy(
        self.prospectsPlayers,
        "playerId"
      );

      const prospectsToArray = Object.entries(groupedProspects);

      const sortedProspects = self.lodash.sortBy(
        Object.fromEntries(prospectsToArray),
        function (o) {
          return o.length;
        }
      );
      self.orderedProspects =
        self.lodash(sortedProspects).reverse().value()[0] || [];
    },
    getScoutById(scoutId) {
      return this.lodash.filter(this.getScouts, { id: scoutId })[0];
    },
    mergePlayers() {
      this.mergedPlayers = this.incomingPlayers
        .concat(this.currentCompoPlayers)
        .reduce(
          (acc, player) => [
            ...acc,
            ...(acc.some((p) => p.id === player.id) ? [] : [player]),
          ],
          []
        )
        .filter(
          (player) => !this.lodash.find(this.outPlayers, { id: player.id })
        );
      this.loadCurrentPlayersSalary();
    },
    openCreateSquadPopin() {
      this.openPopinVariant({
        popinVariant: popinVariants.CREATE_SQUAD,
        popinPayload: {},
      });
    },
    openDeleteSquadPopin() {
      this.openPopinVariant({
        popinVariant: popinVariants.DELETE_SQUAD,
        popinPayload: this.currentShadow,
      });
    },
    openRenameSquadPopin() {
      this.openPopinVariant({
        popinVariant: popinVariants.RENAME_SQUAD,
        popinPayload: this.currentShadow,
      });
    },
    async fetchLastPlayersUpdated() {
      const self = this;
      self.prospectsPlayers = self.getPlayers.filter((player) => {
        return (
          ![
            ...(this.currentShadow.players ?? []),
            ...(this.currentShadow.playersIn ?? []),
          ].some(({ id }) => player.id === id) &&
          !self.getCurrentCompanyTeams.includes(player.currentTeam) &&
          player.updatedAt >= parseInt(moment().subtract(12, "months").unix())
        );
      });
      this.manageProspects();
    },
    switchSquadManagement(option) {
      this.currentShadow = this.currentScoutShadows.find(
        (shadow) => shadow.id === option.id
      );
      this.groupPlayers();
    },
    formatCompo(compo) {
      return compo?.split("").join("-") ?? "";
    },
    groupPlayers() {
      Object.assign(this, { onStandPlayers: [], currentCompoPlayers: [] });
      for (const teamPlayer of this.getCurrentTeamPlayers) {
        const positionAcronym =
          teamPlayer.movingPosition ??
          this.reduceStringToInitiales(teamPlayer.primaryPosition);
        const sanitizedPositionAcronym =
          this.getReverseSpecificAcronymsMap[positionAcronym] ??
          positionAcronym;
        const isPositionInCompo = this.matchingCompo?.players?.includes(
          sanitizedPositionAcronym
        );
        const isPlayerOnMainTeam =
          teamPlayer.currentTeam === this.getCurrentCompanyTeams?.[0];

        const isOnStand = !isPositionInCompo || !isPlayerOnMainTeam;
        if (isOnStand) {
          this.onStandPlayers.push(teamPlayer);
        } else {
          this.currentCompoPlayers.push(teamPlayer);
        }
      }
    },
    async loadCurrentManagerSquads() {
      const self = this;
      self.currentScoutShadows = [];
      console.time("loadCurrentManagerSquads");
      let shadowRef = db
        .collection("V3shadowsMercato")
        .where("scoutId", "==", self.getCurrentScout.id.toString());
      if (!self.getCompanies.length) {
        return;
      }
      if (self.getCurrentScout?.company === self.getCompanies[0]?.id) {
        shadowRef = db.collection("V3shadowsMercato");
        // .where("company", "==", self.getCurrentScout.company.toString());
      }
      await shadowRef
        .orderBy("date", "desc")
        .get()
        .then((shadowsManagementSnapshot) => {
          console.timeEnd("loadCurrentManagerSquads");
          return shadowsManagementSnapshot.docs.forEach(
            (squadManagementDoc) => {
              if (self.getCurrentScout?.company === self.getCompanies[0]?.id) {
                const squadManagementDatas = squadManagementDoc.data();
                if (squadManagementDatas.players?.length) {
                  self.currentScoutShadows.push(squadManagementDoc.data());
                }
              } else {
                self.currentScoutShadows.push(squadManagementDoc.data());
              }
            }
          );
        });
      if (self.currentScoutShadows[0]) {
        if (!self.currentScoutShadows[0].id) {
          self.loadCurrentManagerSquads();
          return;
        }
        self.currentShadow = self.currentScoutShadows[0];
      }
    },
    loadUpdatedPlayersSalary() {
      this.salaryUpdates = 0;
      for (const player of this.playersUpdated) {
        this.salaryUpdates +=
          parseFloat(player.newSalary ?? 0) - parseFloat(player.salary ?? 0);
      }
    },
    loadIncomingPlayersSalary() {
      this.salaryIn = 0;
      for (const player of this.incomingPlayers) {
        this.salaryIn += player.newSalary ?? player.salary ?? 0;
      }
    },
    loadOutgoingPlayersSalary() {
      this.salaryOut = 0;
      const outPlayers = this.outPlayers.filter((player) =>
        this.getCurrentCompanyTeams.includes(player.currentTeam)
      );
      for (const player of outPlayers) {
        this.salaryOut += player.salary ?? 0;
      }
    },
    async loadCurrentPlayersSalary() {
      this.loadUpdatedPlayersSalary();
      this.loadIncomingPlayersSalary();
      this.loadOutgoingPlayersSalary();
    },
    getSquadOption(squad) {
      return {
        label: squad?.compo
          ? `${
              this.getScoutById(squad?.scoutId)?.company
            } - Compo ${this.formatCompo(squad.compo)}${
              squad.label ? ` - ${squad.label}` : ""
            }`
          : "",
        id: squad.id,
      };
    },
  },
  computed: {
    ...mapState(["scoutsPlayers"]),
    ...mapState("popin", ["toastInfos"]),
    ...mapGetters([
      "getCurrentScout",
      "getCompos",
      "getCompanies",
      "getGamesPosition",
      "getScouts",
      "getPlayersForCurrentCompany",
      "getCurrentCompanyTeams",
      "getPlayers",
    ]),
    ...mapGetters("popin", ["isPopinOpened", "previousPopinVariant"]),
    squadsListOptions() {
      return this.currentScoutShadows.map((squad) =>
        this.getSquadOption(squad)
      );
    },
    getSquadCreatedDate() {
      return this.formatDate(this.currentShadow.date, false, "DD / MM / YYYY");
    },
    getSquadUpdateDate() {
      return this.formatDate(
        this.currentShadow.update,
        false,
        "DD / MM / YYYY"
      );
    },
    getSquadUpdateTime() {
      return this.formatDate(this.currentShadow.update, false, "hours");
    },
    getSalaryPercentDiff() {
      if (this.getNewPayroll === this.getCurrentPayroll) {
        return null;
      }
      return (
        ((this.getNewPayroll - this.getCurrentPayroll) /
          this.getCurrentPayroll) *
        100
      ).toFixed(2);
    },
    getNewPayroll() {
      return (
        parseFloat(this.getCurrentPayroll) + parseFloat(this.getSalaryBalance)
      ).toFixed(2);
    },
    getSalaryBalance() {
      return (this.salaryIn - this.salaryOut).toFixed(2);
    },
    getCurrentPayroll() {
      return this.getPlayersForCurrentCompany
        .reduce((salary, player) => {
          if (
            player.salary &&
            !["undefined", "unknown", "NaN"].includes(player.salary) &&
            !isNaN(player.salary)
          ) {
            return salary + parseFloat(player.salary);
          }
          return salary;
        }, 0)
        .toFixed(2);
    },
    macroSelectOptions() {
      const self = this;
      return this.lodash
        .uniqBy(this.getGamesPosition, "macro")
        .map((position) => {
          return {
            text: self.$t(`macroPositions.${position.macro}`),
            value: position.macro,
          };
        });
    },
    microSelectOptions() {
      const self = this;
      return this.getGamesPosition.map((position) => {
        return {
          text: self.$t(`position.${position.label.replaceAll(" ", "")}`),
          value: position.label,
          macro: position.macro,
        };
      });
    },
    getTotalIn() {
      const self = this;
      let sum = null;
      const uniqPlayers = self.lodash.uniqBy(self.incomingPlayers, "id");
      uniqPlayers.forEach((player) => {
        if (
          self.getCurrentCompanyTeams &&
          !self.getCurrentCompanyTeams?.includes(player.currentTeam) &&
          !isNaN(parseFloat(player.price))
        ) {
          if (sum === null) {
            sum = 0;
          }
          sum += parseFloat(player.price);
        }
      });

      return sum || 0;
    },
    getTotalOut() {
      const self = this;
      let sum = null;
      const uniqPlayers = self.lodash.uniqBy(self.outPlayers, "id");

      uniqPlayers.forEach((player) => {
        if (
          self.getCurrentCompanyTeams &&
          self.getCurrentCompanyTeams.includes(player.currentTeam) &&
          !isNaN(parseFloat(player.price))
        ) {
          if (sum === null) {
            sum = 0;
          }
          sum += parseFloat(player.price);
        }
      });
      return sum || 0;
    },
    getTotalSquadManagement() {
      let toReturn = 0;
      if (this.getTotalIn) {
        toReturn = this.getTotalIn * -1;
      }

      if (this.getTotalOut) {
        toReturn += this.getTotalOut;
      }

      return toReturn;
    },
    matchingCompo() {
      const self = this;
      if (!self.currentShadow.compo) {
        return {};
      }
      const toReturn =
        self.getCompos.filter((compo) => {
          return (
            compo.schema.toString() === self.currentShadow.compo.toString() &&
            compo.version.toString() ===
              self.currentShadow.compoVersion.toString()
          );
        })[0] || {};
      return toReturn;
    },
    orderedStandPlayers() {
      const self = this;
      const mergedStandPlayers = self.onStandPlayers.filter((player) =>
        self.getCurrentCompanyTeams?.includes(player.currentTeam)
      );
      return this.lodash.orderBy(mergedStandPlayers, "name", "asc");
    },
    orderedStandPlayersNotSold() {
      return this.orderedStandPlayers.filter(
        (player) =>
          !player.transferType &&
          !this.outPlayers.some((outPlayer) => outPlayer.id === player.id) &&
          !this.incomingPlayers.some((outPlayer) => outPlayer.id === player.id)
      );
    },
    playersUpdated() {
      return (
        this.currentShadow.players?.filter(
          (player) =>
            player.salary !== player.newSalary &&
            !this.outPlayers.some((outPlayer) => outPlayer.id === player.id)
        ) ?? []
      );
    },
    getCurrentTeamPlayers() {
      return this.getPlayersForCurrentCompany
        .filter(
          ({ primaryPosition, isArchivePlayer }) =>
            primaryPosition &&
            primaryPosition !== "undefined" &&
            !isArchivePlayer
        )
        .map(
          (player) =>
            this.currentShadow.players?.find(
              (shadowPlayer) => shadowPlayer.id === player.id
            ) ?? player
        );
    },
  },
  watch: {
    isPopinOpened: {
      handler(isPopinOpened) {
        if (isPopinOpened) {
          return;
        }
        if (
          [
            popinVariants.CREATE_SQUAD,
            popinVariants.DELETE_SQUAD,
            popinVariants.RENAME_SQUAD,
          ].includes(this.previousPopinVariant)
        ) {
          this.loadCurrentManagerSquads();
        }
      },
    },
    currentShadow: {
      immediate: true,
      deep: true,
      handler() {
        this.incomingPlayers = this.currentShadow.playersIn ?? [];
        this.outPlayers = this.currentShadow.playersOut ?? [];
        this.loadCurrentPlayersSalary();
        this.groupPlayers();
        this.fetchLastPlayersUpdated();
      },
    },
    getCompanies() {
      this.loadCurrentManagerSquads();
    },
    getCurrentScout: {
      handler() {
        const self = this;
        if (this.getCurrentScout?.company) {
          if (!self.getCurrentScout?.isManager) {
            self.$router.push("dashboard");
          } else {
            self.loadCurrentManagerSquads();
          }
        } else {
          self.toggleNotification(
            "error",
            "notification.error.userWithNoCompany"
          );
        }
        this.fetchLastPlayersUpdated();
      },
      deep: true,
    },
    getPlayersForCurrentCompany: {
      handler() {
        this.groupPlayers();
        this.fetchLastPlayersUpdated();
      },
    },
    incomingPlayers: {
      handler() {
        this.mergePlayers();
      },
      deep: true,
    },
    outPlayers: {
      handler() {
        this.mergePlayers();
      },
      deep: true,
    },
    currentCompoPlayers: {
      handler() {
        this.mergePlayers();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
.squadManagementPlayersSelectContainer {
  height: 100%;
}
div.main-container {
  max-width: 1400px;
}

div.players-card {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
}

div.balance-pitch-container {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

section.squad-header {
  background-color: #0b00a3;
  padding: 24px 0 48px 0;
  align-items: center;
  justify-content: center;
  display: flex;
  div.squad-header-content {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1400px;
    > .squad-title {
      font-weight: 800;
      font-size: 2.5rem;
      color: #ffffff;
    }
    > .create-squad-container {
      align-self: flex-end;
      cursor: pointer;
      user-select: none;
      border-radius: 100px;
      background-color: $blueDarkest;
      padding: 12px 24px;
      display: flex;
      flex-direction: row;
      gap: 8px;
      > span {
        color: #f6f5ff;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 700;
      }
    }
  }
}

section.squad-title {
  padding: 48px 0;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  div.squad-title-content {
    max-width: 1400px;
    flex-grow: 1;
    gap: 24px;
    display: flex;
    flex-direction: column;
  }
}

div.squad-name-title {
  font-weight: 700;
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

div.squad-title-action {
  width: 32px;
  height: 32px;
  border-radius: 100px;
  border: solid 1px #e3e0ff;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px 0px #06005214;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

div.squad-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div.squad-subtitle {
  font-size: 0.875rem;
  font-weight: 500;
  color: #141414;
}
</style>
