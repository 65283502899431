var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"gameObservationContainer"}},[_c('div',{attrs:{"id":"gameObservationContent"}},[_c('div',{staticClass:"gameBlocTitle text-center"},[_c('h3',[_vm._v(_vm._s(_vm.$t("gameViewlegend.observationTitle")))])]),(
        !_vm.getCurrentScout?.isManager && _vm.getCurrentScout?.id === _vm.game.addedBy
      )?_c('div',{staticClass:"globalObservationContent"},[_c('div',{attrs:{"id":"gameSelectObservationContainer"}},[_c('div',{attrs:{"id":"gameSelectObservationContent"}},[_c('label',{attrs:{"for":"observationType"}},[_vm._v(" "+_vm._s(_vm.$t("gameViewlegend.observationType"))+" ")]),(
              !_vm.getCurrentScout.isManager &&
              _vm.getCurrentScout?.id === _vm.game.addedBy &&
              _vm.isEditable
            )?_c('select',{attrs:{"name":"observationType","id":"observationType"},domProps:{"value":_vm.gameNoteCategory}},[_c('option',{attrs:{"value":"stadium"}},[_vm._v("Stadium")]),_c('option',{attrs:{"value":"video"}},[_vm._v("Video")]),_c('option',{attrs:{"value":"data.dot"}},[_vm._v("Data")])]):_c('span',[_vm._v(" "+_vm._s(_vm.gameNoteCategory)+" ")])])]),(
          !_vm.getCurrentScout.isManager && _vm.getCurrentScout?.id === _vm.game.addedBy
        )?_c('div',{attrs:{"id":"gameCommentContainer"}},[_c('div',{attrs:{"id":"gameCommentContent"}},[(
              _vm.isEditable ||
              (!_vm.isEditable && _vm.textareaContent && _vm.textareaContent.length)
            )?_c('label',{attrs:{"for":"gameComment"}},[_vm._v(" "+_vm._s(_vm.$t("gameViewlegend.observationComment"))+" ")]):_vm._e(),(_vm.isEditable)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.textareaContent),expression:"textareaContent"}],attrs:{"placeholder":_vm.$t('gameViewlegend.generalObservation'),"id":"gameComment"},domProps:{"value":(_vm.textareaContent)},on:{"input":function($event){if($event.target.composing)return;_vm.textareaContent=$event.target.value}}}):_c('span',{attrs:{"id":"gameComment"}},[_vm._v(" "+_vm._s(_vm.textareaContent && _vm.textareaContent.length ? _vm.textareaContent : "")+" ")])])]):_vm._e(),(_vm.isEditable)?_c('div',{attrs:{"id":"gamePlayerValidateGameNoteContainer"}},[_c('Button',{class:{
            formSubmitButton: true,
            buttonDisable: !_vm.textareaContent || !_vm.textareaContent.length,
          },on:{"click":_vm.submitNewNote}},[_vm._v(_vm._s(_vm.$t("gameViewlegend.submitGeneralObservation")))])],1):_vm._e()]):_vm._e(),(_vm.getCurrentScout?.isManager)?_c('div',{staticClass:"globalObservationContent"},[(_vm.gameNoteCategory.length)?_c('div',{staticClass:"gameObservationElementContainer"},[_c('span',{staticClass:"observationLegend"},[_vm._v(_vm._s(_vm.$t("gameViewlegend.observationType")))]),_c('p',{staticClass:"gameObservationElementContent"},[_vm._v(" "+_vm._s(_vm.gameNoteCategory)+" ")])]):_vm._e(),(_vm.game.addedBy?.length)?_c('div',{staticClass:"gameObservationElementContainer"},[_c('span',{staticClass:"observationLegend"},[_vm._v(_vm._s(_vm.$t("gameViewlegend.gameCreatedBy")))]),(_vm.getCurrentScout?.isManager)?_c('p',{staticClass:"gameObservationElementContent"},[_vm._v(" "+_vm._s(_vm.identifyScoutById(_vm.game.addedBy, "fullName"))+" ")]):_vm._e()]):_vm._e(),(_vm.textareaContent && _vm.textareaContent.length)?_c('div',{staticClass:"gameObservationElementContainer"},[_c('span',{staticClass:"observationLegend"},[_vm._v(_vm._s(_vm.$t("gameViewlegend.observationComment")))]),_c('p',{staticClass:"gameObservationElementContent gameNoteContent"},[_vm._v(" "+_vm._s(_vm.textareaContent)+" ")])]):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }