<template>
  <div class="list-results-container" v-if="popinPayload.results.length > 0">
    <List
      :showColumnsTitle="true"
      :columns="getColumns"
      :items="popinPayload.results"
      :orderable="false"
      :maxItems="0"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import mixinPlayers from "../../../../mixins/mixinPlayers";
import List from "../../../Fields/List.vue";
import { popinVariants } from "../../../../enums/popinVariants";

export default {
  components: { List },
  mixins: [mixinPlayers],
  methods: {
    ...mapMutations("popin", ["openPopinVariant"]),
  },
  computed: {
    ...mapGetters("popin", ["popinPayload"]),
    getColumns() {
      return {
        minifiedName: {
          value: (item) => this.getMinifiedPlayerName(item.name),
          align: "left",
          title: this.$t("name"),
        },
        eoc: {
          value: (item) =>
            `${this.$t("endOfContractAcronym")}: ${
              item.endContract
                ? this.capitalize(
                    this.formatDate(item.endContract, false, "MMMM YYYY")
                  )
                : this.$t("undefined")
            }`,
          align: "left",
          title: this.$t("endOfContractTitle"),
        },
        position: {
          value: (item) =>
            this.$t(this.getPositionTranslationKeyFromPlayer(item)),
          align: "left",
          title: this.$t("filters.position"),
        },
        price: {
          value: (item) =>
            item.price && item.price !== "undefined"
              ? `${item.price} ${this.$t("millions")}`
              : this.$t("undefined"),
          align: "left",
          title: this.$t("price"),
        },
        salary: {
          value: (item) =>
            item.salary && item.salary !== "undefined"
              ? `${item.salary} ${this.$t("millionsPerYear")}`
              : this.$t("undefined"),
          align: "left",
          title: this.$t("salary"),
        },
        addToSquad: {
          pictoSrc: () => require("../../../../assets/img/plus_blue.svg"),
          align: "center",
          title: this.$t("select.short"),
          action: (item) => {
            this.openPopinVariant({
              popinVariant: popinVariants.RECRUIT_PROSPECT,
              popinPayload: item,
            });
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/variables.scss";
div.list-results-container {
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $purpleLight;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: #2c1fd6;
    border-radius: 100px;
    border-left: 1px solid $purpleLight;
    border-right: 1px solid $purpleLight;
    width: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #2c1fd6;
  }
}
</style>
