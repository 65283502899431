import axios from "axios";
import lodash from "lodash";
export default {
  namespaced: true,
  state: () => ({
    countries: [],
    clubs: [],
    ligues: [],
    matchs: [],
    popinShallow: [],
    matchingResult: [],
    selectedMatch: 0,
    selectedMatchObservation: "",
  }),
  mutations: {
    updateSelectedMatch(state, payload) {
      state.selectedMatch = payload;
    },
    updateSelectedMatchObservation(state, payload) {
      state.selectedMatchObservation = payload;
    },
    updateMatchingResult(state, payload) {
      state.matchingResult = payload;
    },
    updateCountries(state, payload) {
      state.countries = payload;
    },
    updateClubs(state, payload) {
      state.clubs = payload;
    },
    updateLigues(state, payload) {
      state.ligues = payload;
    },
    updatePayload(state, payload) {
      if (Array.isArray(payload)) {
        for (const property in payload) {
          const toStore = [];
          toStore[property] = payload[property];
          const matchingItemByProperty = state.popinShallow.findIndex(
            (itemToCheck) => {
              return itemToCheck[property] || false;
            }
          );
          if (matchingItemByProperty > -1) {
            state.popinShallow.splice(matchingItemByProperty, 1);
          }
          state.popinShallow.unshift(toStore);
        }
      }
    },
    updateMatchs(state, payload) {
      state.matchs = payload;
    },
  },
  actions: {
    setSelectedMatch({ commit }, payload) {
      commit("updateSelectedMatch", payload);
    },
    setSelectedMatchObservation({ commit }, payload) {
      commit("updateSelectedMatchObservation", payload);
    },
    setMatchingResult({ commit }, payload) {
      commit("updateMatchingResult", payload);
    },
    setPayload({ commit }, payload) {
      commit("updatePayload", payload);
    },
    async bindCountries({ commit }) {
      await axios
        .get("https://ds-ol-scout-app-py-api-3.azurewebsites.net/competitions/")
        .then((apiResponse) => {
          commit(
            "updateCountries",
            lodash.uniq(
              apiResponse.data.map((match) => match.competition_country)
            )
          );
        });
    },
    async bindClubs({ commit }) {
      await axios
        .get("https://ds-ol-scout-app-py-api-3.azurewebsites.net/teams/")
        .then((apiResponse) => {
          commit(
            "updateClubs",
            lodash.uniq(apiResponse.data.map((team) => team.Team))
          );
        });
    },
    async bindLigues({ commit }) {
      await axios
        .get("https://ds-ol-scout-app-py-api-3.azurewebsites.net/competitions/")
        .then((apiResponse) => {
          commit(
            "updateLigues",
            lodash.uniq(apiResponse.data.map((match) => match.competition))
          );
        });
    },
    async bindMatchs({ commit }) {
      let toReturn = [];
      await axios
        .get("https://ds-ol-scout-app-py-api-3.azurewebsites.net/matches/")
        .then(async (passedMatchs) => {
          toReturn = passedMatchs.data;
          await axios
            .get(
              "https://ds-ol-scout-app-py-api-3.azurewebsites.net/matches/future/"
            )
            .then((incomingMatchs) => {
              toReturn = [...toReturn, ...incomingMatchs.data];
            });
        });
      commit("updateMatchs", toReturn);
    },
  },
  getters: {
    getCountries: (state) => {
      return state.countries;
    },
    getLigues: (state) => {
      return state.ligues;
    },
    getClubs: (state) => {
      return state.clubs;
    },
    getPopinShallow: (state) => {
      return state.popinShallow;
    },
    getMatchs: (state) => {
      return state.matchs;
    },
    getMatchingResult: (state) => {
      return state.matchingResult;
    },
    getSelectedMatch: (state) => {
      return state.selectedMatch;
    },
    getSelectedObservationMatch: (state) => {
      return state.selectedMatchObservation;
    },
  },
};
