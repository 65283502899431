<template>
  <ActionBase
    @click="handleClick()"
    :disabled="false"
    :label="$t('validate')"
    :primary="true"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ActionBase from "../../../Popin/Actions/ActionBase.vue";
import db from "../../../../db";
export default {
  components: { ActionBase },
  computed: {
    ...mapGetters("popin", [
      "getPopinShallowPayload",
      "popinPayload",
      "additionalContext",
    ]),
  },
  methods: {
    ...mapMutations("popin", ["closePopin"]),
    ...mapActions("popin", ["openToast"]),
    async handleClick() {
      const playerToUpdateIndex = this.additionalContext.playersOut.findIndex(
        (player) => player.id === this.getPopinShallowPayload.id
      );
      delete this.getPopinShallowPayload.transferType;
      if (playerToUpdateIndex === -1) {
        throw new Error(
          `Player ${
            this.getPopinShallowPayload.id
          } not found in current squad ids (${this.additionalContext.playersOut.map(
            (p) => p.id
          )})`
        );
      }
      this.additionalContext.playersOut.splice(playerToUpdateIndex, 1);
      await db
        .collection("V3shadowsMercato")
        .doc(this.additionalContext.id)
        .set(
          { playersOut: this.additionalContext.playersOut },
          { merge: true }
        );
      Object.assign(this.popinPayload, this.getPopinShallowPayload);
      this.openToast({
        translationKey: "squad.toasts.cancelSell",
        payload: { player: this.popinPayload.name },
        delay: 5000,
      });
      this.closePopin();
    },
  },
};
</script>

<style lang="scss" scoped></style>
