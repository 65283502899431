<template>
  <b-row class="field-group">
    <b-col>
      <ListingMatching name="newGame" :list="matchingResults" />
    </b-col>
  </b-row>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import ListingMatching from "./ListingMatching.vue";

export default {
  data() {
    return {
      matchingResults: [],
      minDate: moment().subtract(5, "years"),
      maxDate: moment().add(1, "weeks"),
    };
  },
  props: {},
  components: {
    ListingMatching,
  },
  mounted() {
    this.setMatchingResults();
  },
  methods: {
    setMatchingResults() {
      const self = this;
      let toReturn = this.getMatchs.filter((match) => {
        let isValidMatch = false;
        if (self.getPopinShallowPayload.findGameByClub) {
          if (
            match.home_team_name ===
              self.getPopinShallowPayload.findGameByClub ||
            match.away_team_name === self.getPopinShallowPayload.findGameByClub
          ) {
            isValidMatch = true;
          }
        }
        if (self.getPopinShallowPayload.findGameByLigue) {
          if (
            match.competition_name ===
            self.getPopinShallowPayload.findGameByLigue
          ) {
            isValidMatch = true;
          }
        }
        if (self.getPopinShallowPayload.findGameByCountryContinent) {
          if (
            match.country_name ===
            self.getPopinShallowPayload.findGameByCountryContinent
          ) {
            isValidMatch = true;
          }
        }
        if (self.getPopinShallowPayload.findGameByDate) {
          if (
            match.match_date ===
            moment
              .unix(self.getPopinShallowPayload.findGameByDate)
              .format("YYYY-MM-DD")
          ) {
            isValidMatch = true;
          }
        }
        return isValidMatch;
      });

      toReturn = toReturn.filter((match) => {
        return (
          (moment(match.match_date, "YYYY-MM-DD").isAfter(this.minDate) ||
            moment(match.match_date, "YYYY-MM-DD").isSame(this.minDate)) &&
          (moment(match.match_date, "YYYY-MM-DD").isBefore(this.maxDate) ||
            moment(match.match_date, "YYYY-MM-DD").isSame(this.maxDate))
        );
      });
      this.matchingResults = self.lodash.orderBy(
        toReturn,
        "match_date",
        "desc"
      );
      this.$store.dispatch("matchs/setMatchingResult", toReturn);
    },
  },
  computed: {
    ...mapGetters("matchs", ["getPopinShallow", "getMatchs"]),
    ...mapGetters("popin", ["getPopinShallowPayload"]),
  },
};
</script>
<style scoped></style>
