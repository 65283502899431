<template>
  <div>
    <b-row>
      <b-col class="listingContainer">
        <div v-for="(listItem, listItemIndex) in list" :key="listItemIndex">
          <div v-if="name === 'newGame'" class="listMatchContainer">
            <div
              :class="{
                listMatchContent: true,
                isActive: selectedIndex === listItem.match_id,
              }"
              @click="selectCurrentItem('newGame', listItem)"
            >
              <div class="listMatchHeader">
                <div>
                  {{ listItem.home_team_name }} {{ listItem.home_score }} -
                  {{ listItem.away_score }} {{ listItem.away_team_name }}
                </div>
                <div>
                  {{ convertDate(listItem.match_date, "YYYY-MM-DD", "easy") }}
                </div>
              </div>
              <div class="listMatchBody">
                <div>
                  {{ listItem.competition_name }} ({{ listItem.country_name }})
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <RadioInputs
          :title="createGameRadioOptionsTitle"
          :radioName="createGameRadioOptionsTitle"
          :options="createGameRadioOptions"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import mixinDates from "../../../../mixins/mixinDates";
import RadioInputs from "../../../Fields/RadioInputs.vue";
export default {
  props: {
    name: { type: String, required: true },
    list: { type: Array },
  },
  mixins: [mixinDates],
  data() {
    return {
      selectedIndex: null,
      createGameRadioOptions: ["stadium", "video", "data.dot"],
    };
  },
  components: { RadioInputs },
  computed: {
    ...mapGetters("matchs", ["getPopinShallow"]),
    createGameRadioOptionsTitle() {
      return "gameViewlegend.observationType";
    },
  },
  methods: {
    selectCurrentItem(subject, item) {
      this.selectedIndex = item.match_id;
      const toDispatch = {};
      toDispatch[subject] = item.match_id;
      this.$store.dispatch("matchs/setPayload", toDispatch);
      this.$store.dispatch("matchs/setSelectedMatch", item);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../assets/scss/variables.scss";

.listingContainer {
  max-height: 300px;
  overflow: auto;
  margin: 20px -15px 30px -15px;
  user-select: none;

  &::-webkit-scrollbar {
    width: 10px;
    height: 90%;
  }

  &::-webkit-scrollbar-track {
    background: $purpleLight;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: #2c1fd6;
    border-radius: 100px;
    border-left: 1px solid $purpleLight;
    border-right: 1px solid $purpleLight;
    width: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #2c1fd6;
  }
}

.listMatchContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  padding: 10px;
  background-color: #fafafa;
  color: #060052;
  cursor: pointer;
}

.listMatchContent.isActive {
  color: #fafafa;
  background-color: #060052;
}

.listMatchHeader {
  font-weight: bold;
}

.listMatchHeader,
.listMatchBody {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
</style>
