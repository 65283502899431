<template>
  <b-row class="popin-container closable" @click="handleCloseClick">
    <b-col class="popin-card-container" cols="12" lg="6">
      <div class="popin-card-content">
        <img
          class="exit-button closable"
          @click="handleCloseClick"
          :id="`close_${getPopinShallowPayload.id ?? 'popin'}`"
          src="../../assets/img/crossClose.svg"
          alt="Close Popin"
        />
        <div class="title-container">
          <span class="title">{{ title }}</span>
          <div class="subtitle" v-if="subtitle?.length">
            <span
              v-for="(subtitleLine, index) of subtitle.split('\n')"
              :key="index"
              class="subtitle"
              >{{ subtitleLine }}</span
            >
          </div>
        </div>
        <div v-if="popinInfos?.tabs?.length">
          <TabsSwitcher :popinTabs="popinInfos?.tabs" />
        </div>
        <div class="form-content">
          <component
            class="form-element"
            v-for="(field, index) of popinInfos?.fieldsGroups"
            :key="'field' + index"
            :is="field"
          />
        </div>
        <div class="form-actions">
          <template v-for="(action, index) of popinInfos.actions">
            <component
              v-if="!action.to"
              class="form-element"
              :is="action"
              :key="'action' + index"
            />
            <GoToAction
              v-else
              class="form-element"
              :primary="action.primary ?? false"
              :disabled="
                action.disabled ||
                checkRequirement(action.requirement || []) ||
                false
              "
              :to="action.to"
              :translationKey="action.translationKey"
              :key="'goto' + index"
            />
          </template>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import GoToAction from "./Actions/GoToAction.vue";
import TabsSwitcher from "./tabsSwitcher.vue";

export default {
  name: "Pop-In",
  components: {
    GoToAction,
    TabsSwitcher,
  },
  props: {
    additionalContext: { type: Object },
  },
  data() {
    return {
      popinTabs: [],
    };
  },
  mounted() {
    if (this.additionalContext) {
      this.setAdditionalContext(this.additionalContext);
    }
  },
  computed: {
    ...mapGetters("popin", ["getPopinShallowPayload", "popinInfos"]),
    title() {
      return this.parseTitleField("title") || "";
    },
    subtitle() {
      return this.parseTitleField("subtitle") || "";
    },
  },
  methods: {
    ...mapMutations("popin", ["closePopin", "setAdditionalContext"]),
    ...mapGetters("matchs", ["getMatchs", "getMatchingResult"]),
    checkRequirement(requirement = []) {
      if (requirement.length) {
        for (let i in requirement) {
          switch (i) {
            case "matchs/getMatchs":
              return !this.getMatchs?.length;
            case "matchs/getMatchingResult":
              return !this.getMatchingResult;
            default:
              return false;
          }
        }
      }
      return false;
    },
    handleCloseClick(event) {
      if (event.target.classList.contains("closable")) {
        event.stopPropagation();
        this.closePopin();
      }
    },
    translateOrGetString(field = "") {
      if (typeof field === "string") {
        return field;
      }
      if (field.plural) {
        return this.$tc(field.key, field.payload.nb, field.payload);
      }
      const translated = this.$t(field.key, field.payload);
      if (translated !== field.key) {
        return translated;
      }
      return this.$t(field.fallbackKey, field.payload);
    },
    parseTitleField(field = "") {
      if (Array.isArray(this.popinInfos[field])) {
        return this.popinInfos[field]
          .map((value) => this.translateOrGetString(value))
          .join("");
      }
      return this.translateOrGetString(this.popinInfos[field]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";

div.popin-container {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  margin: 0 auto;
}

div.popin-card-container {
  position: relative;
  z-index: 10001;
  border-radius: 24px;
  border: solid 1px #d0ccff;
  background-color: #ffffff;
  max-height: 80vh;
  overflow: auto;
}

div.popin-card-content {
  overflow: auto;
  padding: 0 48px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 48px 0;

  &::-webkit-scrollbar {
    width: 10px;
    height: 90%;
  }

  &::-webkit-scrollbar-track {
    background: $purpleLight;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: #2c1fd6;
    border-radius: 100px;
    border-left: 1px solid $purpleLight;
    border-right: 1px solid $purpleLight;
    width: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #2c1fd6;
  }
}

img.exit-button {
  position: absolute;
  cursor: pointer;
  top: 24px;
  right: 24px;
}

div.title-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  color: $blueDarkest;
}

span.title {
  font-size: 2rem;
  font-weight: 700;
}

span.subtitle {
  font-size: 1rem;
  font-weight: 500;
}

div.subtitle {
  display: flex;
  flex-direction: column;
}

span.title,
span.subtitle {
  text-align: center;
}

div.form-content {
  gap: 24px;
  display: flex;
  flex-direction: column;
}

div.form-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 24px;
}
</style>
