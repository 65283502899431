<template>
  <div class="balance-container">
    <div class="balance-row">
      <div class="balance-resume">
        <span>
          {{ $t(translations.resume) }} : {{ fixedBalance }}
          {{ $t("millions") }}
          {{ balanceDifferenceDisplay }}
        </span>
      </div>
      <div
        class="detail-action balance-text-right"
        @click="handleClickOnDetail"
      >
        <span>
          {{ $t(translations.details) }}
        </span>
        <img src="../../../assets/img/info.svg" />
      </div>
    </div>
    <div class="balance-row">
      <div class="balance-incoming">
        <span>
          {{ $t(translations.incoming) }} :
          {{ ((incoming ?? 0) + (updates ?? 0)).toFixed(2) }}
          {{ $t("millions") }}
        </span>
      </div>
      <div class="balance-outgoing balance-text-right">
        <span>
          {{ $t(translations.outgoing) }} : {{ Math.abs(outgoing) }}
          {{ $t("millions") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { popinVariants } from "../../../enums/popinVariants";
export default {
  props: {
    variant: {
      type: String,
      validator: (variant) => ["transfers", "salary"].includes(variant),
    },
    balance: { type: [String, Number], required: true },
    incoming: { type: [String, Number], required: true },
    updates: { type: [String, Number], required: false },
    outgoing: { type: [String, Number], required: true },
    playersIn: { type: Array },
    playersOut: { type: Array },
    playersUpdated: { type: Array },
    balanceDifferencePercentage: { type: [String, Number], required: false },
  },
  methods: {
    ...mapMutations("popin", ["openPopinVariant"]),
    handleClickOnDetail() {
      const popinPayload = {
        playersIn: this.playersIn,
        playersOut: this.playersOut,
        playersUpdated: this.playersUpdated,
        summary: `${this.fixedBalance} ${this.$t("millions")}${
          this.balanceDifferenceDisplay
        }`,
        incomingSummary: `${this.incoming.toFixed(2)} ${this.$t("millions")}`,
        outgoingSummary: `${Math.abs(this.outgoing).toFixed(2)} ${this.$t(
          "millions"
        )}`,
        updatedSummary:
          this.updates != null
            ? `${this.updates.toFixed(2)} ${this.$t("millions")}`
            : null,
      };
      switch (this.variant) {
        case "transfers":
          this.openPopinVariant({
            popinVariant: popinVariants.TRANSFERS_BALANCE,
            popinPayload,
          });
          break;
        case "salary":
          this.openPopinVariant({
            popinVariant: popinVariants.PAYROLL_BALANCE,
            popinPayload,
          });
          break;
        default:
          return;
      }
    },
  },
  computed: {
    translations() {
      const translations = {
        transfers: {
          resume: "transfersBalance",
          details: "showDetails",
          incoming: "incomingTransfers",
          outgoing: "outgoingTransfers",
        },
        salary: {
          resume: "projectedPayroll",
          details: "showDetails",
          incoming: "payrollIn",
          outgoing: "payrollOut",
        },
      };
      return translations[this.variant] ?? {};
    },
    fixedBalance() {
      return typeof this.balance === "number"
        ? this.balance.toFixed(2)
        : this.balance;
    },
    balanceDifferenceDisplay() {
      if (!this.balanceDifferencePercentage) {
        return "";
      }
      const sign = this.balanceDifferencePercentage > 0 ? "+" : "";
      return ` (${sign}${this.balanceDifferencePercentage}%)`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

div.balance-container {
  background-color: #ffffff;

  border: solid 1px #e3e0ff;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px #06005214;
}

div.balance-row {
  display: flex;
  flex-direction: row;
}

div.balance-resume {
  font-weight: 700;
  line-height: 1.25rem;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  color: #141414;
  flex-grow: 1;
}

div.balance-text-right {
  text-align: right;
}

div.detail-action {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $blueDarkest;
  cursor: pointer;
  display: flex;
  > img {
    height: 17px;
    width: 17px;
    align-self: center;
    margin-left: 8px;
  }
}

div.balance-incoming,
div.balance-outgoing {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  flex-grow: 1;
  color: #141414;
}
</style>
