import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getScouts", "getCurrentScout"]),
    getCompanyExample() {
      return this.getCurrentScout?.company ?? "Botafogo"
    }
  },
  methods: {
    identifyScoutById(scoutId, format) {
      const matchingScout = this.getScouts.filter((scout) => {
        return scout.id === scoutId;
      })[0];
      if (matchingScout) {
        if (format === "lastname") {
          return matchingScout.lastname;
        } else if (format === "firstname") {
          return matchingScout.firstname;
        } else if (format === "shortenName") {
          return (
            matchingScout.firstname.charAt(0) + ". " + matchingScout.lastname
          );
        } else if (format === "fullName") {
          return matchingScout.firstname + " " + matchingScout.lastname;
        } else if (format === "status") {
          return matchingScout.isManager ? "manager" : "scout";
        } else if (format === "company") {
          return matchingScout.company;
        }
      }
      return "";
    },
  },
};
