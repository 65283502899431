<template>
  <div class="pill">
    {{ playersCount }} {{ $tc("plural.player", playersCount || 1) }}
  </div>
</template>
<script>
export default {
  name: "PlayersCountPill",
  props: {
    playersCount: { type: Number, default: 0 },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/variables.scss";
div.pill {
  background-color: $purpleLight;
  padding: 4px 8px;
  border-radius: 4px;
  width: fit-content;
  color: $blueDarkest;
  font-weight: 700;
  font-size: 0.75rem;
}
</style>
