<template>
  <div
    :class="{
      notificationContent: true,
      displayNotification: notification.display,
      successNotification: notification.type === 'success',
      errorNotification: notification.type === 'error',
    }"
  >
    {{ notification.details }}
  </div>
</template>
<script>
export default {
  name: "NotificationComponent",
  props: {
    notification: Object,
  },
};
</script>

<style scoped>
.notificationContent {
  display: block;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1.2rem;
  color: white;
  position: fixed;
  right: 50px;
  bottom: -200px;
  transition: 2s;
  z-index: 10000;
}

.displayNotification {
  bottom: 50px;
}

.notificationContent.successNotification {
  background: green;
}

.notificationContent.errorNotification {
  background: red;
}
</style>
