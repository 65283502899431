<template>
  <div class="player-container">
    <div class="player-row">
      <div class="player-name">
        <span>
          {{ name }}
        </span>
      </div>
      <div class="player-price" v-if="price">
        <span> {{ price }} {{ $t("millions") }} </span>
      </div>
      <div class="player-salary" v-if="salary">
        <span> {{ salary }} {{ $t("millionsPerYear") }} </span>
      </div>
    </div>
    <div class="player-row">
      <div class="player-position">
        {{ position }}
      </div>
      <div class="player-salary-difference" v-if="displayDifference">
        <span> ({{ getSalaryDifference }}{{ $t("millions") }}) </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: { type: String, required: true },
    price: { type: [Number, String], required: false },
    salary: { type: [Number, String], required: false },
    oldSalary: { type: [Number, String], required: false },
    displayDifference: { type: Boolean, default: false },
    position: { type: String, required: true },
  },
  computed: {
    getSalaryDifference() {
      const difference = (this.salary ?? 0) - (this.oldSalary ?? 0);
      const sign = !this.oldSalary || this.salary > this.oldSalary ? "+" : "";
      return `${sign}${difference.toFixed(2)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";

div.player-container {
  border-radius: 4px;
  background-color: #ffffff;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  div.player-row {
    display: flex;
    flex-direction: row;
    .player-name,
    .player-position {
      flex-grow: 1;
    }
    .player-price,
    .player-salary,
    .player-old-salary {
      align-self: flex-end;
    }
  }
}

div.player-name,
div.player-price,
div.player-salary {
  font-size: 0.875rem;
  font-weight: 700;
  color: $blueDarkest;
  > span {
    white-space: pre;
  }
}

div.player-salary-difference {
  font-size: 0.75rem;
  font-weight: 400;
}

div.player-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  > span {
    white-space: nowrap;
  }
}

div.player-position {
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 400;
}
</style>
