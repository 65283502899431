<template>
  <div class="home" v-if="currentScout">
    <section class="header">
      <b-container>
        <b-row class="profile-row py-3" align-v="start">
          <b-col>
            <h1 class="mb-3" v-if="currentScout">
              {{ $t("hi") }} {{ currentScout.firstname }}
              {{ currentScout.lastname }}
              <span v-if="currentScout.isManager"> *</span>,
            </h1>
            <h1 class="mb-3" v-else>{{ $t("hi") }},</h1>
          </b-col>
          <b-col
            v-if="!currentScout.isManager"
            md="5"
            xl="6"
            class="text-right"
          >
            <b-button class="mt-5 mt-sm-4" to="/games">
              {{ $t("create.aReport") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="main">
      <b-container class="dashboardContainer">
        <b-row>
          <b-col lg="12" sm="12">
            <div class="calendarContainer">
              <div class="calendarContent">
                <div class="calendarHeader">
                  <div class="calendarTitle"></div>
                  <div class="calendarNavigation">
                    <div class="calendarBefore" @click="beforeScope">
                      {{ `<` }}
                    </div>
                    <div class="calendarCurrent">
                      {{ formattedScope[2] }}
                    </div>
                    <div class="calendarAfter" @click="afterScope">
                      {{ `>` }}
                    </div>
                    <b-button
                      class="calendarModeContainer"
                      @click="switchCalendarMode"
                    >
                      {{
                        $t("switchTo", {
                          mode:
                            calendarMode === "week" ? $t("month") : $t("week"),
                        })
                      }}
                    </b-button>
                  </div>
                </div>
                <div
                  :class="{
                    calendarBody: true,
                    weekMode: calendarMode === 'week',
                  }"
                >
                  <div class="calendarRow">
                    <span
                      v-for="(weekDay, weekDayIndex) in getWeekDays"
                      :key="weekDayIndex"
                      class="calendarColumn"
                    >
                      {{ weekDay.slice(0, 3) }}.
                    </span>
                  </div>
                  <div
                    class="calendarRow"
                    v-for="(
                      currentScopeRow, currentScopeRowIndex
                    ) in currentScopeRows"
                    :key="currentScopeRowIndex"
                  >
                    <span
                      v-for="(day, dayIndex) in 7"
                      :key="dayIndex"
                      :class="{
                        calendarColumn: true,
                        inactive: isInactive(currentScopeRowIndex, dayIndex),
                        invisible:
                          calendarMode === 'month' &&
                          currentScopeRowIndex === currentScopeRows &&
                          dayIndex > lastDayOfCurrentScope('weekday'),
                        today: isToday(currentScopeRowIndex, dayIndex),
                      }"
                    >
                      <div>
                        {{
                          getCalendarDay(
                            currentScopeRowIndex,
                            dayIndex,
                            "DD MMM"
                          )
                        }}
                      </div>
                      <div class="eventContainer">
                        <router-link
                          v-for="(eventItem, eventIndex) in currentDayEvents(
                            currentScopeRowIndex,
                            dayIndex
                          )"
                          :key="eventIndex"
                          :class="{
                            calendarEvent: true,
                            assignedGame: eventItem.scoutAssigned,
                          }"
                          :to="'/game/' + eventItem.id"
                        >
                          <div class="calendarEventDate">
                            {{ formatDate(eventItem.date, false, "minimal") }}
                          </div>
                          <div class="calendarEventTeams">
                            {{ eventItem.teamHome }} - {{ eventItem.teamAway }}
                          </div>
                          <div
                            v-if="eventItem.scoutAssigned"
                            class="calendarEventScout"
                          >
                            {{
                              identifyScoutById(
                                eventItem.scoutAssigned,
                                "shortenName"
                              )
                            }}
                          </div>
                          <div
                            v-else-if="
                              eventItem.addedBy && getCurrentScout.isManager
                            "
                            class="calendarEventScout"
                          >
                            {{
                              identifyScoutById(
                                eventItem.addedBy,
                                "shortenName"
                              )
                            }}
                          </div>
                        </router-link>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="6" sm="12">
            <div class="categoryTitle">
              <span>{{ $t("lastCommentedGames") }}</span>
            </div>
            <div class="listingContainer">
              <span
                @click="markGameAsSeen(game.id)"
                v-for="(game, gameIndex) in passedGames"
                :key="gameIndex"
                :class="{
                  listItemContainer: true,
                  toSee:
                    getCurrentScout.isManager &&
                    !game[`seenBy${currentScout.id}`],
                  seen: game[`seenBy${currentScout.id}`],
                }"
              >
                <div class="lastGameIntroContainer">
                  <span>
                    {{ game.teamHome }} - {{ game.teamAway }}
                    <span
                      :class="{
                        locationContainer: true,
                        videoLocation: game.location === 'video',
                        stadiumLocation: game.location === 'stadium',
                        statsLocation: game.location === 'data.dot',
                      }"
                    >
                      <font-awesome-icon
                        v-if="game.location == 'stadium'"
                        icon="map-pin"
                      />
                      <font-awesome-icon
                        v-if="game.location == 'video'"
                        icon="film"
                      />
                      <font-awesome-icon
                        v-if="game.location == 'data.dot'"
                        icon="chart-bar"
                      />
                    </span>
                  </span>
                  <span class="pillContainer">
                    {{ formatDate(game.date, false, "minimal") }}
                    <span v-if="getCurrentScout.isManager">
                      - {{ identifyScoutById(game.addedBy, "lastname") }}
                    </span>
                  </span>
                </div>
                <div class="lastGameDetailsContainer">
                  <div>
                    <span
                      v-for="(
                        player, playerIndex
                      ) in getGamePlayerssAccordingCurrentScout(game)"
                      :key="playerIndex"
                      :class="{ isGolden: isBucketPlayer(player) }"
                    >
                      {{ player.name }}
                      <span
                        v-if="
                          playerIndex !==
                          getGamePlayerssAccordingCurrentScout(game).length - 1
                        "
                        >,</span
                      >
                    </span>
                  </div>
                  <div>
                    <div class="buttonContainer">
                      {{ $tc("see.game", 1) }}
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </b-col>
          <b-col id="lastPlayersUpdatedContainer" lg="6" md="6" sm="12">
            <div class="categoryTitle">
              <span>{{ $t("lastCommentedPlayers") }}</span>
            </div>
            <div class="listingContainer">
              <router-link
                v-for="(note, noteIndex) in lastNotesAccordingCurrentUser"
                :key="noteIndex"
                class="listItemContainer"
                :to="'/player/' + note.playerId"
              >
                <div class="playerRowContainer">
                  <span>
                    {{ note.name }}
                  </span>
                  <span>
                    {{ formatDate(note.dateOfBirth, true) }}
                  </span>
                  <span>
                    {{ note.currentTeam }}
                  </span>
                  <span>
                    {{
                      note.price && note.price !== "undefined"
                        ? note.price
                        : "?"
                    }}
                    M
                  </span>
                  <div class="pillContainer">Note : {{ note.letterNote }}</div>
                </div>
              </router-link>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="getCurrentScout.isManager" lg="6" md="6" sm="12">
            <div class="categoryTitle">
              <span>{{ $tc("plural.scoutsStats", 2) }}</span>
              <span class="statsFiltersSelector">
                <b-form-select
                  v-model="selectedFilter"
                  :options="statsOptions"
                />
              </span>
            </div>
            <div
              v-if="currentScout.isManager && lastPlayersUpdated.length"
              class="listingContainer layout2"
            >
              <router-link
                v-for="(scoutNotes, noteIndex) in notesByScouts"
                :key="noteIndex"
                :to="`/players/${noteIndex}/${selectedFilter}`"
                class="listingLinkContainer"
              >
                <div
                  v-if="
                    (selectedFilter === 'week' &&
                      getScoutPlayersStats(scoutNotes.previousWeek) > 0) ||
                    (selectedFilter === 'month' &&
                      getScoutPlayersStats(scoutNotes.previousMonth) > 0) ||
                    (selectedFilter === 'year' &&
                      getScoutPlayersStats(scoutNotes.previousYear) > 0) ||
                    (selectedFilter === 'all' &&
                      getScoutPlayersStats(scoutNotes.all) > 0)
                  "
                  class="listItemContainer"
                >
                  <div class="listItemContent">
                    <span class="scoutNameContainer">
                      {{ identifyScoutById(noteIndex, "fullName") }}
                    </span>
                    <span class="scoutDatasContainer">
                      <div
                        class="scoutDataContent"
                        v-if="selectedFilter === 'week'"
                      >
                        <div class="scoutDataCounter">
                          {{
                            getScoutPlayersStats(scoutNotes.previousWeek) || 0
                          }}
                        </div>
                        {{
                          $tc(
                            "plural.note",
                            getScoutPlayersStats(scoutNotes.previousWeek) || 0
                          )
                        }}
                      </div>
                      <div
                        class="scoutDataContent"
                        v-if="selectedFilter === 'month'"
                      >
                        <div class="scoutDataCounter">
                          {{
                            getScoutPlayersStats(scoutNotes.previousMonth) || 0
                          }}
                        </div>
                        {{
                          $tc(
                            "plural.note",
                            getScoutPlayersStats(scoutNotes.previousMonth) || 0
                          )
                        }}
                      </div>
                      <div
                        class="scoutDataContent"
                        v-if="selectedFilter === 'year'"
                      >
                        <div class="scoutDataCounter">
                          {{
                            getScoutPlayersStats(scoutNotes.previousYear) || 0
                          }}
                        </div>
                        {{
                          $tc(
                            "plural.note",
                            getScoutPlayersStats(scoutNotes.previousYear) || 0
                          )
                        }}
                      </div>
                      <div
                        class="scoutDataContent"
                        v-if="selectedFilter === 'all'"
                      >
                        <div class="scoutDataCounter">
                          {{ getScoutPlayersStats(scoutNotes.all) || 0 }}
                        </div>
                        {{
                          $tc(
                            "plural.note",
                            getScoutPlayersStats(scoutNotes.previousWeek) || 0
                          )
                        }}
                      </div>
                    </span>
                    <span class="scoutDatasArrowContainer"></span>
                  </div>
                </div>
              </router-link>
            </div>
          </b-col>
          <b-col v-else lg="6" md="6" sm="12">
            <b-row class="categoryTitle">
              <b-col>
                <span>{{ $tc("plural.scoutsStats", 1) }}</span>
              </b-col>
              <b-col>
                <span class="selectContainer">
                  <b-form-select
                    :options="graphOptions"
                    v-model="selectedGraphFilter"
                  />
                </span>
              </b-col>
            </b-row>
            <div v-if="getCurrentX.length" class="graphContainer">
              <GraphComponent
                :x="getCurrentX"
                :y="getCurrentY || []"
                yLegend="notePerMonth"
              />
            </div>
          </b-col>
          <b-col id="bucketPlayersContainer" lg="6" md="6" sm="12">
            <div class="categoryTitle">
              <span v-if="getCurrentScout.isManager">
                {{ $t("gameViewlegend.bucketPlayer") }}
              </span>
              <span v-else>{{ $t("playerStatus.assignedPlayers") }}</span>
            </div>
            <div
              class="listingContainer layout2"
              v-if="getCurrentScout.isManager && getBucketsWithNoComment.length"
            >
              <router-link
                v-for="(player, playerIndex) in getBucketsWithNoComment"
                :key="playerIndex"
                class="listItemContainer"
                :to="'/player/' + player.id"
              >
                <div class="playerRowContainer">
                  <span>
                    {{ player.name }}
                  </span>
                  <span>
                    {{ formatDate(player.dateOfBirth, true) }}
                  </span>
                  <span>
                    {{ player.currentTeam }}
                  </span>
                  <span>
                    {{
                      player.price && player.price !== "undefined"
                        ? player.price
                        : "?"
                    }}
                    M
                  </span>
                  <span></span>
                </div>
              </router-link>
            </div>
            <div
              class="listingContainer layout2"
              v-if="!getCurrentScout.isManager && getAssignedPlayers.length"
            >
              <router-link
                v-for="(player, playerIndex) in getAssignedPlayers"
                :key="playerIndex"
                class="listItemContainer"
                :to="'/player/' + player.id"
              >
                <div class="playerRowContainer">
                  <span>
                    {{ player.name }}
                  </span>
                  <span>
                    {{ formatDate(player.dateOfBirth, true) }}
                  </span>
                  <span>
                    {{ player.currentTeam }}
                  </span>
                  <span>
                    {{
                      player.price && player.price !== "undefined"
                        ? player.price
                        : "?"
                    }}
                    M
                  </span>
                  <span></span>
                </div>
              </router-link>
            </div>
            <div
              class="listingContainer layout2"
              v-if="!getCurrentScout.isManager && !getAssignedPlayers.length"
            >
              {{ $t("no.assignedPlayers") }}
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container
        v-if="getCurrentScout.isManager"
        class="mt-3 globalStatsContainer"
      >
        <b-row>
          <b-col>
            <b-row class="globalStatsHeader">
              <b-col>
                <h2>
                  {{ $t("globalStats") }}
                </h2>
              </b-col>
              <b-col class="graphFiltersContainer">
                <b-row>
                  <b-col>
                    <b-select
                      v-model="selectedScoutId"
                      :options="globalStats.scoutsOptions"
                    />
                  </b-col>
                  <b-col>
                    <b-select
                      v-model="selectedPeriod"
                      :options="globalStats.periodsOptions"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-row class="cardsContainer">
                  <b-col class="cardContainer">
                    <b-row>
                      <b-col md="8">
                        <span class="highlight">
                          {{ globalStats.supervisedGames?.length }}
                        </span>
                        {{
                          $tc(
                            "plural.supervisedGames",
                            globalStats.supervisedGames?.length || 1
                          )
                        }}
                      </b-col>
                      <b-col md="4">
                        <router-link
                          v-if="globalStats.supervisedGames?.length"
                          :to="`/games/${selectedScoutId}`"
                        >
                          {{
                            $tc(
                              "see.game",
                              globalStats.supervisedGames?.length || 1
                            )
                          }}
                        </router-link>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="cardContainer">
                    <b-row>
                      <b-col md="8">
                        <span class="highlight">
                          {{ globalStats.observedPlayers?.length }}
                        </span>
                        {{
                          $tc(
                            "plural.observedPlayer",
                            globalStats.observedPlayers?.length || 1
                          )
                        }}
                      </b-col>
                      <b-col md="4">
                        <router-link
                          v-if="globalStats.observedPlayers?.length"
                          :to="`/players/${selectedScoutId}/${selectedPeriod}`"
                        >
                          {{
                            $tc(
                              "see.player",
                              globalStats.observedPlayers?.length || 1
                            )
                          }}
                        </router-link>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="cardContainer statsGraphContainer">
                <b-row>
                  <b-col>
                    <span class="highlight">
                      {{ getSumStatsNotes }}
                    </span>
                    {{ $tc("plural.mark", getSumStatsNotes || 1) }}
                  </b-col>
                </b-row>

                <b-row class="statsGraphContent">
                  <b-col>
                    <b-row class="statsGraphContentRow">
                      <b-col>
                        <router-link
                          to="/players/"
                          data-letter="A"
                          :data-detail="`${
                            globalStats.ANotes.length
                          } (${getPercentBasedOnMax(globalStats.ANote)}%)`"
                          :style="`width: ${getANotesPercentBasedOnMax}%`"
                        >
                        </router-link>
                      </b-col>
                    </b-row>
                    <b-row class="statsGraphContentRow">
                      <b-col>
                        <router-link
                          to="/players/"
                          data-letter="B"
                          :data-detail="`${
                            globalStats.BNotes.length
                          } (${getPercentBasedOnMax(
                            globalStats.BNotes.length
                          )}%)`"
                          :style="`width: ${getBNotesPercentBasedOnMax}%`"
                        >
                        </router-link>
                      </b-col>
                    </b-row>
                    <b-row class="statsGraphContentRow">
                      <b-col>
                        <router-link
                          to="/players/"
                          data-letter="C"
                          :data-detail="`${
                            globalStats.CNotes.length
                          } (${getPercentBasedOnMax(
                            globalStats.CNotes.length
                          )}%)`"
                          :style="`width: ${getCNotesPercentBasedOnMax}%`"
                        >
                        </router-link>
                      </b-col>
                    </b-row>
                    <b-row class="statsGraphContentRow">
                      <b-col>
                        <router-link
                          to="/players/"
                          data-letter="D"
                          :data-detail="`${
                            globalStats.DNotes.length
                          } (${getPercentBasedOnMax(
                            globalStats.DNotes.length
                          )}%)`"
                          :style="`width: ${getDNotesPercentBasedOnMax}%`"
                        >
                        </router-link>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import moment from "moment";
import db from "../db";
import mixinDates from "../mixins/mixinDates";
import mixinScouts from "../mixins/mixinScouts";
import GraphComponent from "../components/GraphComponent";
import mixinPlayers from "../mixins/mixinPlayers";
export default {
  name: "DashboardComponent",
  mixins: [mixinDates, mixinScouts, mixinPlayers],
  components: {
    GraphComponent,
  },
  data() {
    return {
      // isLaunched: false,
      observedPlayers: [],
      highestNoteLength: 0,
      selectedScoutId: "-",
      selectedPeriod: "week",
      globalStats: {
        scoutsOptions: [
          {
            value: "-",
            text: this.$t("select.aScout"),
          },
        ],
        periodsOptions: [
          {
            value: "week",
            text: this.$t("previous.week"),
            selected: true,
          },
          { value: "month", text: this.$t("previous.month") },
          { value: "year", text: this.$t("previous.year") },
          { value: "all", text: this.$t("previous.all") },
        ],
        supervisedGames: 0,
        observedPlayers: 0,
        ANotes: [],
        BNotes: [],
        CNotes: [],
        DNotes: [],
      },
      assignedPlayers: [],
      formattedScope: "",
      calendarMode: "week",
      currentScopeEvents: [],
      currentScopeCalendar: { week: 0, month: 0, year: 0 },
      xAxis: [],
      yAxis: {},
      selectedGraphFilter: "",
      graphOptions: [],
      ghostsScouts: [],
      selectedFilter: "month",
      displayStatsFilters: false,
      currentScoutGamesLocations: [],
      noLocationIncomingGames: [],
      scoutsIdForCurrentUser: [],
      passedGames: [],
      lastNotesAccordingCurrentUser: [],
      statsOptions: [
        {
          value: "week",
          text: this.$t("previous.week"),
          selected: true,
        },
        { value: "month", text: this.$t("previous.month") },
        { value: "year", text: this.$t("previous.year") },
        { value: "all", text: this.$t("previous.all") },
      ],
    };
  },
  mounted() {
    const self = this;
    //this.getArchivePlayers();
    // this.testMatchingPlayers();
    // this.dealWithBrokenPlayers();
    // this.mergePlayersNotesToNote();
    // this.mergePlayersNotes();
    this.formatCurrentScope();
    this.currentScopeCalendar.week = parseInt(moment().week());
    this.currentScopeCalendar.month = parseInt(moment().format("MM"));
    this.currentScopeCalendar.year = parseInt(moment().format("YYYY"));
    this.loadScopeEvents();
    if (this.getNotes.length) {
      this.setLastNotesAccordingCurrentUser();
    }
    if (!self.games.length) {
      self.bindGames();
    } else {
      self.setPassedGames();
    }
    if (self.getCurrentScout?.id) {
      self.getXAxis();
      if (!self.getCurrentScout?.isManager) {
        this.loadAssignedPlayersIds();
      }
    }
    if (self.currentScout?.isManager && !self.scouts.length) {
      self.bindScouts();
    }
    if (
      self.currentScout?.isManager &&
      self.scouts.length &&
      self.lastPlayersUpdated.length === 0
    ) {
      self.loadReports();
    }
    if (self.getScoutsForCurrentUser.length) {
      self.ghostsScouts = [];
      self.getScoutsForCurrentUser.forEach((scout) => {
        if (scout.ghost) {
          self.ghostsScouts.push(scout.id);
        }
      });
      this.loadScoutsOptions();
    }
    if (this.bucketPlayers.length === 0) {
      this.bindBucketPlayers();
    }
    self.scoutsIdForCurrentUser = self.getScoutsForCurrentUser.map((scout) => {
      return scout.id;
    });
    this.loadMatchingStats();
  },
  computed: {
    ...mapState([
      "currentScout",
      "currentScoutGames",
      "currentScoutPlayers",
      "currentScoutId",
      "scouts",
      "lastPlayersUpdated",
      "scoutsPlayers",
      "games",
      "bucketPlayers",
    ]),
    ...mapGetters([
      "getCurrentScout",
      "getNotes",
      "getScouts",
      "getScoutsForCurrentUser",
      "getCompanies",
      "getGames",
      "getState",
      "getPlayers",
    ]),
    gamesForCurrentScope() {
      const self = this;
      if (!self.getCurrentScout) {
        return [];
      }

      let toReturn = [];
      if (
        self.getCurrentScout.isManager &&
        self.getCompanies[0]?.id === self.getCurrentScout.company
      ) {
        toReturn = self.getGames.filter((game) => {
          return game.note && game.note.length;
        });
      } else if (self.getCurrentScout.isManager) {
        if (self.selectedScoutId !== "-") {
          toReturn = self.getGames.filter((game) => {
            return (
              game.companyGame === self.getCurrentScout.company &&
              game.addedBy === self.selectedScoutId &&
              game.note &&
              game.note.length
            );
          });
        } else {
          toReturn = self.getGames.filter((game) => {
            return (
              game.companyGame === self.getCurrentScout.company &&
              game.note &&
              game.note.length
            );
          });
        }
      }

      if (self.selectedScoutId !== "-") {
        toReturn = toReturn.filter((game) => {
          return game.addedBy === self.selectedScoutId;
        });
      }
      if (self.selectedPeriod !== "all") {
        toReturn = toReturn.filter((game) => {
          return game.date >= moment().subtract(1, self.selectedPeriod).unix();
        });
      }
      return toReturn;
    },
    getANotesPercentBasedOnMax() {
      return this.getPercentBasedOnMax(this.globalStats.ANotes.length);
    },
    getBNotesPercentBasedOnMax() {
      return this.getPercentBasedOnMax(this.globalStats.BNotes.length);
    },
    getCNotesPercentBasedOnMax() {
      return this.getPercentBasedOnMax(this.globalStats.CNotes.length);
    },
    getDNotesPercentBasedOnMax() {
      return this.getPercentBasedOnMax(this.globalStats.DNotes.length);
    },
    getSumStatsNotes() {
      return (
        (this.globalStats?.ANotes.length || 0) +
        (this.globalStats?.BNotes.length || 0) +
        (this.globalStats?.CNotes.length || 0) +
        (this.globalStats?.DNotes.length || 0)
      );
    },
    getWeekDays() {
      if (this.getLocale === "en") {
        return moment.weekdays();
      }
      return moment.weekdays(true);
    },
    currentScopeRows() {
      if (!this.currentScopeCalendar.year) {
        return 4;
      }
      if (this.calendarMode === "week") {
        return 1;
      }
      if (
        moment.unix(this.formattedScope[0]).week() >
        moment.unix(this.formattedScope[1]).week()
      ) {
        return moment.unix(this.formattedScope[1]).week() + 1;
      }
      return (
        moment.unix(this.formattedScope[1]).week() -
        moment.unix(this.formattedScope[0]).week() +
        1
      );
    },
    getCurrentX() {
      const self = this;
      if (self.yAxis[self.selectedGraphFilter]) {
        return Object.keys(self.yAxis[self.selectedGraphFilter]).map((item) => {
          return item;
        });
      } else {
        return [];
      }
    },
    getCurrentY() {
      const self = this;
      if (self.yAxis[self.selectedGraphFilter]) {
        return Object.keys(self.yAxis[self.selectedGraphFilter]).map(
          (index) => {
            return self.yAxis[self.selectedGraphFilter][index].length;
          }
        );
      } else {
        return [];
      }
    },
    getAssignedPlayers() {
      return this.assignedPlayers.slice(0, 10);
    },
    getBucketsWithNoComment() {
      if (
        this.getCurrentScout.isManager &&
        this.getCurrentScout.company === this.getCompanies[0]?.id
      ) {
        return this.bucketPlayers
          .filter((player) => !player.commentedGamesIdsLength)
          .slice(0, 10);
      }
      return this.bucketPlayers
        .filter(
          (player) =>
            !player[`${this.getCurrentScout.company}CommentedGamesIdsLength`]
        )
        .slice(0, 10);
    },
    notesByScouts() {
      const self = this;
      const groupedNotes = self.lodash.groupBy(self.getNotes, "authorId");
      const toReturn = {};
      self.ghostsScouts.forEach((scoutId) => {
        delete groupedNotes[scoutId];
      });
      Object.keys(groupedNotes).forEach((scoutId) => {
        if (!this.scoutsIdForCurrentUser.includes(scoutId)) {
          return;
        }
        const now = moment();
        const lastWeekTimestamp = now
          .clone()
          .subtract(7, "days")
          .startOf("day");
        const lastMonthTimestamp = now
          .clone()
          .subtract(1, "months")
          .startOf("day");
        const lastYearTimestamp = now
          .clone()
          .subtract(1, "years")
          .startOf("day");
        toReturn[scoutId] = {};

        toReturn[scoutId].previousWeek = [];
        toReturn[scoutId].previousMonth = [];
        toReturn[scoutId].previousYear = [];
        toReturn[scoutId].all = groupedNotes[scoutId];

        groupedNotes[scoutId].forEach((note) => {
          if (
            moment.unix(note.id.substring(0, 10)).isAfter(lastWeekTimestamp)
          ) {
            toReturn[scoutId].previousWeek.push(note);
            toReturn[scoutId].previousMonth.push(note);
            toReturn[scoutId].previousYear.push(note);
          } else if (
            moment.unix(note.id.substring(0, 10)).isAfter(lastMonthTimestamp)
          ) {
            toReturn[scoutId].previousMonth.push(note);
            toReturn[scoutId].previousYear.push(note);
          } else if (
            moment.unix(note.id.substring(0, 10)).isAfter(lastYearTimestamp)
          ) {
            toReturn[scoutId].previousYear.push(note);
          }
        });
      });
      if (toReturn["8r1O3XqTsQeIawoYkXaXBL6txyw1"]) {
        console.log(
          "pw",
          this.getScoutPlayersStats(
            toReturn["8r1O3XqTsQeIawoYkXaXBL6txyw1"].previousWeek
          ),
          "pm",
          this.getScoutPlayersStats(
            toReturn["8r1O3XqTsQeIawoYkXaXBL6txyw1"].previousMonth
          ),
          "py",
          this.getScoutPlayersStats(
            toReturn["8r1O3XqTsQeIawoYkXaXBL6txyw1"].previousYear
          ),
          "a",
          this.getScoutPlayersStats(
            toReturn["8r1O3XqTsQeIawoYkXaXBL6txyw1"].all
          )
        );
        delete toReturn["8r1O3XqTsQeIawoYkXaXBL6txyw1"];
      }
      return toReturn;
    },
  },
  methods: {
    ...mapActions([
      "bindScouts",
      "setLastPlayersUpdated",
      "bindGames",
      "bindBucketPlayers",
    ]),
    async mergePlayersNotesToNote() {
      console.log("in mergePlayersNotesToNote", this.getScouts);
      const self = this;
      const schema = [];
      await db // 328019623 (53) - 643552423 (4724) - 959126400 (22229) - >(9991)
        .collection("V3players")
        .where("dateOfBirth", ">=", 328019623)
        .where("dateOfBirth", "<", 643552423)
        .orderBy("dateOfBirth", "asc")
        .get()
        .then(async (playersSnapshot) => {
          console.log("playersSnapshot", playersSnapshot, playersSnapshot.size);
          for (let i = 0; i < playersSnapshot.size; i++) {
            console.log(
              "check player",
              playersSnapshot.docs[i].id,
              "=>",
              i,
              "/",
              playersSnapshot.size
            );

            const playerData = playersSnapshot.docs[i].data();

            for (const [key] of Object.entries(playerData)) {
              if (!schema.includes(key)) {
                schema.push(key);
              }
            }

            for (let j = 0; j < self.getCompanies.length; j++) {
              delete playerData[`${self.getCompanies[j].id}CommentedGamesIds`];
              delete playerData[
                `${self.getCompanies[j].id}CommentedGamesIdsLength`
              ];
              delete playerData[`${self.getCompanies[j].id}AssociatedGamesIds`];
              delete playerData[
                `${self.getCompanies[j].id}AssociatedGamesIdsLength`
              ];
              delete playerData[`${self.getCompanies[j].id}NoteAIds`];
              delete playerData[`${self.getCompanies[j].id}NoteAIdsLength`];
              delete playerData[`${self.getCompanies[j].id}NoteBIds`];
              delete playerData[`${self.getCompanies[j].id}NoteBIdsLength`];
              delete playerData[`${self.getCompanies[j].id}NoteCIds`];
              delete playerData[`${self.getCompanies[j].id}NoteCIdsLength`];
              delete playerData[`${self.getCompanies[j].id}NoteDIds`];
              delete playerData[`${self.getCompanies[j].id}NoteDIdsLength`];
              delete playerData[`${self.getCompanies[j].id}UpdatedAt`];
            }

            for (let j = 0; j < self.getScouts.length; j++) {
              delete playerData[`${self.getScouts[j].id}CommentedGamesIds`];
              delete playerData[
                `${self.getScouts[j].id}CommentedGamesIdsLength`
              ];
              delete playerData[
                `${self.getScouts[j].id}AssociatedGamesIdsLength`
              ];
              delete playerData[`${self.getScouts[j].id}NoteAIds`];
              delete playerData[`${self.getScouts[j].id}NoteAIdsLength`];
              delete playerData[`${self.getScouts[j].id}NoteBIds`];
              delete playerData[`${self.getScouts[j].id}NoteBIdsLength`];
              delete playerData[`${self.getScouts[j].id}NoteCIds`];
              delete playerData[`${self.getScouts[j].id}NoteCIdsLength`];
              delete playerData[`${self.getScouts[j].id}NoteDIds`];
              delete playerData[`${self.getScouts[j].id}NoteDIdsLength`];

              delete playerData[`${self.getScouts[j].id}AssociatedGamesIds`];
              delete playerData[`${self.getScouts[j].id}UpdatedAt`];
            }
            delete playerData.commentedGamesCounter;
            delete playerData.CommentedGamesIds;
            delete playerData.CommentedGamesIdsLength;
            delete playerData.associatedGamesId;
            delete playerData.eIsbfFQ87BeVEcZFMOVSQWl3QVr2AssociatedGamesIds;
            delete playerData.eIsbfFQ87BeVEcZFMOVSQWl3QVr2UpdatedAt;
            delete playerData.Hrsg0tGqtvX3GbarAqVST7aQT3d2UpdatedAt;
            delete playerData.PRm5vTNll4gVAv6Wf36wifNtTa13AssociatedGamesIds;
            delete playerData.PRm5vTNll4gVAv6Wf36wifNtTa13UpdatedAt;
            delete playerData.ZLlI8QbgPHeE4L6Y21O9vLPjQA23CommentedGamesIds;
            delete playerData.ZLlI8QbgPHeE4L6Y21O9vLPjQA23CommentedGamesIdsLength;
            delete playerData.mark;
            delete playerData.notesCounter;
            delete playerData.undefinedAssociatedGamesIds;
            delete playerData.undefinedCommentedGamesIds;
            delete playerData.undefinedCommentedGamesIdsLength;

            delete playerData.noteAIds;
            delete playerData.noteAIdsLength;
            delete playerData.noteBIds;
            delete playerData.noteBIdsLength;
            delete playerData.noteCIds;
            delete playerData.noteCIdsLength;
            delete playerData.noteDIds;
            delete playerData.noteDIdsLength;

            delete playerData.commentedGamesId;
            delete playerData.commentedGamesIdLength;

            delete playerData.associatedGamesId;
            delete playerData.commentedGamesId;
            delete playerData.commentedGamesIdLength;
            delete playerData.commentedGamesIf;
            delete playerData.mark;
            delete playerData.undefinedCommentedGamesIds;
            delete playerData.undefinedCommentedGamesIdsLength;

            await db.collection("V3players").doc("16238").set(playerData);
            console.log("delete done form player", "16238", "=>", playerData);
            await db
              .collection("V3players")
              .doc("16238")
              .collection("notes")
              .get()
              .then(async (playerNotesSnapshot) => {
                if (playerNotesSnapshot.size) {
                  for (let j = 0; j < playerNotesSnapshot.size; j++) {
                    const noteToUse = playerNotesSnapshot.docs[j].data();
                    noteToUse.authorCompany =
                      self.identifyScoutById(
                        playerNotesSnapshot.docs[j].id.toString(),
                        "company"
                      ) || "OL";
                    noteToUse.playerId = "16238";
                    noteToUse.isGlobal = noteToUse.gameId ? true : false;
                    noteToUse.id = parseInt(noteToUse.id);
                    console.log(
                      "setting note",
                      j,
                      "/",
                      playerNotesSnapshot.size,
                      noteToUse
                    );
                    await db
                      .collection("V3notes")
                      .doc(noteToUse.id.toString())
                      .get()
                      .then(async (noteDoc) => {
                        if (noteDoc.exists) {
                          console.log(
                            noteToUse.id.toString(),
                            "was already existing"
                          );
                        } else {
                          console.log(noteToUse.id.toString(), "was not known");
                          await db
                            .collection("V3notes")
                            .doc(noteToUse.id.toString())
                            .set(noteToUse, { merge: true });
                        }
                      });
                  }
                }
              });
          }
        })
        .then(() => {
          console.log("schema ==>", schema.sort());
        });
    },
    async mergePlayersNotes() {
      console.log("in mergePlayersNotes", this.isLaunched);
      if (this.isLaunched) {
        return;
      }
      const self = this;
      const playersToUse = {};
      console.log("nooootes", self.getNotes);
      console.log("playyyyers", self.getPlayers);
      // for (let i = 0; i < self.getPlayers.length; i++) {
      if (self.getPlayers.length) {
        for (let i = 0; i < self.getPlayers.length; i++) {
          this.isLaunched = true;
          console.log("player", i);
          playersToUse[self.getPlayers[i].id] = {};
          playersToUse[self.getPlayers[i].id].commentedGamesIds =
            self.lodash.uniq(
              self.getNotes
                .filter((note) => {
                  return (
                    note.playerId === self.getPlayers[i].id &&
                    note.gameId?.length &&
                    note.gameId !== "dashboard"
                  );
                })
                .map((note) => {
                  return note.gameId;
                })
            );

          // const currentPlayerNotes = self.getNotes.filter((note) => {
          //   return note.playerId === self.getPlayers[i].id;
          // });
          // playersToUse[self.getPlayers[i].id].noteAIds = currentPlayerNotes
          //   .filter((note) => note.letterNote === "A")
          //   .map((note) => note.id);
          // playersToUse[self.getPlayers[i].id].noteBIds = currentPlayerNotes
          //   .filter((note) => note.letterNote === "B")
          //   .map((note) => note.id);
          // playersToUse[self.getPlayers[i].id].noteCIds = currentPlayerNotes
          //   .filter((note) => note.letterNote === "C")
          //   .map((note) => note.id);
          // playersToUse[self.getPlayers[i].id].noteDIds = currentPlayerNotes
          //   .filter((note) => note.letterNote === "D")
          //   .map((note) => note.id);
          // playersToUse[self.getPlayers[i].id].noteAIdsLength =
          //   playersToUse[self.getPlayers[i].id].noteAIds.length;
          // playersToUse[self.getPlayers[i].id].noteBIdsLength =
          //   playersToUse[self.getPlayers[i].id].noteBIds.length;
          // playersToUse[self.getPlayers[i].id].noteCIdsLength =
          //   playersToUse[self.getPlayers[i].id].noteCIds.length;
          // playersToUse[self.getPlayers[i].id].noteDIdsLength =
          //   playersToUse[self.getPlayers[i].id].noteDIds.length;

          // self.getCompanies.forEach((company) => {
          //   if (company === "Eagle") {
          //     playersToUse[self.getPlayers[i].id].noteAIds = currentPlayerNotes
          //       .filter(
          //         (note) =>
          //           note.letterNote === "A" && note.authorCompany === company.id
          //       )
          //       .map((note) => note.id);
          //     playersToUse[self.getPlayers[i].id].noteAIdsLength =
          //       playersToUse[self.getPlayers[i].id].noteAIds.length;

          //     playersToUse[self.getPlayers[i].id].noteBIds = currentPlayerNotes
          //       .filter(
          //         (note) =>
          //           note.letterNote === "B" && note.authorCompany === company.id
          //       )
          //       .map((note) => note.id);
          //     playersToUse[self.getPlayers[i].id].noteBIdsLength =
          //       playersToUse[self.getPlayers[i].id].noteBIds.length;

          //     playersToUse[self.getPlayers[i].id].noteCIds = currentPlayerNotes
          //       .filter(
          //         (note) =>
          //           note.letterNote === "C" && note.authorCompany === company.id
          //       )
          //       .map((note) => note.id);
          //     playersToUse[self.getPlayers[i].id].noteCIdsLength =
          //       playersToUse[self.getPlayers[i].id].noteCIds.length;

          //     playersToUse[self.getPlayers[i].id].noteDIds = currentPlayerNotes
          //       .filter(
          //         (note) =>
          //           note.letterNote === "D" && note.authorCompany === company.id
          //       )
          //       .map((note) => note.id);
          //     playersToUse[self.getPlayers[i].id].noteDIdsLength =
          //       playersToUse[self.getPlayers[i].id].noteDIds.length;
          //   } else {
          //     playersToUse[self.getPlayers[i].id][`${company.id}NoteAIds`] =
          //       currentPlayerNotes
          //         .filter(
          //           (note) =>
          //             note.letterNote === "A" &&
          //             note.authorCompany === company.id
          //         )
          //         .map((note) => note.id);
          //     playersToUse[self.getPlayers[i].id][
          //       `${company.id}NoteAIdsLength`
          //     ] =
          //       playersToUse[self.getPlayers[i].id][
          //         `${company.id}NoteAIds`
          //       ].length;

          //     playersToUse[self.getPlayers[i].id][`${company.id}NoteBIds`] =
          //       currentPlayerNotes
          //         .filter(
          //           (note) =>
          //             note.letterNote === "B" &&
          //             note.authorCompany === company.id
          //         )
          //         .map((note) => note.id);
          //     playersToUse[self.getPlayers[i].id][
          //       `${company.id}NoteBIdsLength`
          //     ] =
          //       playersToUse[self.getPlayers[i].id][
          //         `${company.id}NoteBIds`
          //       ].length;

          //     playersToUse[self.getPlayers[i].id][`${company.id}NoteCIds`] =
          //       currentPlayerNotes
          //         .filter(
          //           (note) =>
          //             note.letterNote === "C" &&
          //             note.authorCompany === company.id
          //         )
          //         .map((note) => note.id);
          //     playersToUse[self.getPlayers[i].id][
          //       `${company.id}NoteCIdsLength`
          //     ] =
          //       playersToUse[self.getPlayers[i].id][
          //         `${company.id}NoteCIds`
          //       ].length;
          //     playersToUse[self.getPlayers[i].id][`${company.id}NoteDIds`] =
          //       currentPlayerNotes
          //         .filter(
          //           (note) =>
          //             note.letterNote === "D" &&
          //             note.authorCompany === company.id
          //         )
          //         .map((note) => note.id);
          //     playersToUse[self.getPlayers[i].id][
          //       `${company.id}NoteDIdsLength`
          //     ] =
          //       playersToUse[self.getPlayers[i].id][
          //         `${company.id}NoteDIds`
          //       ].length;
          //   }
          // });
          playersToUse[self.getPlayers[i].id].commentedGamesIdsLength =
            playersToUse[self.getPlayers[i].id].commentedGamesIds.length;
          // delete playersToUse[self.getPlayers[i].id].noteCounterA;
          // delete playersToUse[self.getPlayers[i].id].noteCounterB;
          // delete playersToUse[self.getPlayers[i].id].noteCounterC;
          // delete playersToUse[self.getPlayers[i].id].noteCounterD;
          // delete playersToUse[self.getPlayers[i].id].EagleNoteAIds;
          // delete playersToUse[self.getPlayers[i].id].EagleNoteAIdsLength;
          // delete playersToUse[self.getPlayers[i].id].EagleNoteBIds;
          // delete playersToUse[self.getPlayers[i].id].EagleNoteBIdsLength;
          // delete playersToUse[self.getPlayers[i].id].EagleNoteCIds;
          // delete playersToUse[self.getPlayers[i].id].EagleNoteCIdsLength;
          // delete playersToUse[self.getPlayers[i].id].EagleNoteDIds;
          // delete playersToUse[self.getPlayers[i].id].EagleNoteDIdsLength;
          // for (let j = 1; j < self.getCompanies.length; j++) {
          //   playersToUse[self.getPlayers[i].id][
          //     `${self.getCompanies[j].id}CommentedGamesIds`
          //   ] = self.lodash.uniq(
          //     self.getNotes
          //       .filter((note) => {
          //         return (
          //           note.playerId === self.getPlayers[i].id &&
          //           note.authorCompany === self.getCompanies[j].id
          //         );
          //       })
          //       .map((note) => note.gameId)
          //   );
          //   playersToUse[self.getPlayers[i].id][
          //     `${self.getCompanies[j].id}CommentedGamesIdsLength`
          //   ] =
          //     playersToUse[self.getPlayers[i].id][
          //       `${self.getCompanies[j].id}CommentedGamesIds`
          //     ].length;
          // }

          const noGhostScouts = self.getScouts.filter((scout) => !scout.ghost);
          for (let j = 0; j < noGhostScouts.length; j++) {
            if (!noGhostScouts[j].isManager) {
              playersToUse[self.getPlayers[i].id][
                `${noGhostScouts[j].id}CommentedGamesIds`
              ] = self.lodash.uniq(
                self.getNotes
                  .filter((note) => {
                    return (
                      note.playerId === self.getPlayers[i].id &&
                      note.authorId === noGhostScouts[j].id &&
                      note.gameId?.length &&
                      note.gameId !== "dashboard"
                    );
                  })
                  .map((note) => note.gameId)
              );
              playersToUse[self.getPlayers[i].id][
                `${noGhostScouts[j].id}CommentedGamesIdsLength`
              ] =
                playersToUse[self.getPlayers[i].id][
                  `${noGhostScouts[j].id}CommentedGamesIds`
                ].length;
            } else {
              if (noGhostScouts[j].company !== "Eagle") {
                playersToUse[self.getPlayers[i].id][
                  `${noGhostScouts[j].company}CommentedGamesIds`
                ] = self.lodash.uniq(
                  self.getNotes
                    .filter((note) => {
                      return (
                        note.playerId === self.getPlayers[i].id &&
                        note.authorCompany === noGhostScouts[j].company &&
                        note.gameId?.length &&
                        note.gameId !== "dashboard"
                      );
                    })
                    .map((note) => note.gameId)
                );
                playersToUse[self.getPlayers[i].id][
                  `${noGhostScouts[j].company}CommentedGamesIdsLength`
                ] =
                  playersToUse[self.getPlayers[i].id][
                    `${noGhostScouts[j].company}CommentedGamesIds`
                  ].length;
              }
            }
          }
        }
      }

      console.log("goooooo", playersToUse, Object.keys(playersToUse).length);
      for (let i = 0; i < Object.keys(playersToUse).length; i++) {
        console.log(
          i,
          "/",
          Object.keys(playersToUse).length,
          Object.keys(playersToUse)[i],
          playersToUse[Object.keys(playersToUse)[i]]
        );
        await db
          .collection("V3players")
          .doc(Object.keys(playersToUse)[i].toString())
          .set(playersToUse[Object.keys(playersToUse)[i]], { merge: true });
      }
    },
    // async testMatchingPlayers() {
    //   const matchingPlayers = [];
    //   await db
    //     .collection("V3players")
    //     .where("isArchivePlayer", "==", false)
    //     .where("gender", "==", "male")
    //     .where("commentedGamesIdsLength", "!=", "undefined")
    //     .orderBy("commentedGamesIdsLength", "desc")
    //     .get()
    //     .then((playersSnapshot) => {
    //       console.log(playersSnapshot.size);
    //       for (let i = 0; i < playersSnapshot.docs.length; i++) {
    //         const playerData = playersSnapshot.docs[i].data();
    //         matchingPlayers.push({
    //           id: playerData.id,
    //           name: playerData.name,
    //           isReferral: playerData.isReferral,
    //           commentedGamesIdsLength: playerData.commentedGamesIdsLength,
    //           commentedGamesIds: playerData.commentedGamesIds,
    //           OLCommentedGamesIdsLength: playerData.OLCommentedGamesIdsLength,
    //           OLCommentedGamesIds: playerData.OLCommentedGamesIds,
    //         });
    //       }
    //       // playersSnapshot.docs.forEach((playerDoc, playerIndex) => {
    //       //   console.log(playerIndex, "/", playerDoc.id);
    //       //   matchingPlayers.push(playerDoc.data());
    //       // });
    //     });
    //   console.log("ok", matchingPlayers);
    // },
    // async getArchivePlayers() {
    //   console.log(
    //     "Get archive players",
    //     this.getPlayers.length,
    //     "referral",
    //     this.getPlayers.filter((player) => player.isReferral),
    //     "archive",
    //     this.getPlayers.filter((player) => player.isArchivePlayer),
    //     "non archive",
    //     this.getPlayers.filter((player) => !player.isArchivePlayer),
    //     "non updated",
    //     this.getPlayers.filter((player) => !player.updatedAt)
    //   );
    //   const nonUpdated = this.getPlayers.filter((player) => !player.updatedAt);
    //   // const toCheck = usingPlayers.filter((player) => !player.updatedAt);
    //   // const noName = usingPlayers.filter((player) => !player.name);
    //   // console.log("noName", noName);
    //   for (let i = 0; i < nonUpdated.length; i++) {
    //     console.log(
    //       i,
    //       nonUpdated[i],
    //       nonUpdated[i].id,
    //       nonUpdated[i].updatedAt
    //     );
    //     await db
    //       .collection("V3players")
    //       .doc(nonUpdated[i].id.toString())
    //       .set({ updatedAt: 1605706583 }, { merge: true });
    //   }
    //   // await db
    //   //   .collection("V3players")
    //   //   .where("isArchivePlayer", "==", true)
    //   //   .get()
    //   //   .then(async (playersSnapshot) => {
    //   //     console.log("archive players", playersSnapshot);
    //   //     // for (let i = 0; i < playersSnapshot.size; i++) {
    //   //     //   await axios
    //   //     //     .get(
    //   //     //       "https://ds-ol-scout-app-py-api-3.azurewebsites.net/players/" +
    //   //     //         playersSnapshot.docs[i].id
    //   //     //     )
    //   //     //     .then((response) => {
    //   //     //       console.log(
    //   //     //         "response =>",
    //   //     //         i,
    //   //     //         "/",
    //   //     //         playersSnapshot.size,
    //   //     //         response
    //   //     //       );
    //   //     //     })
    //   //     //     .catch((error) => {
    //   //     //       console.log("error =>", i, "/", playersSnapshot.size, error);
    //   //     //     });
    //   //     // }
    //   //     // playersSnapshot.docs.forEach((playerDoc) => {
    //   //     //   const playerData = playerDoc.data();
    //   //     //   console.log(
    //   //     //     "Player",
    //   //     //     playerData.name,
    //   //     //     "(id:",
    //   //     //     playerData.id,
    //   //     //     ") was in",
    //   //     //     playerData.currentTeam,
    //   //     //     "on",
    //   //     //     playerData.lastValidUpdate
    //   //     //       ? moment
    //   //     //           .unix(playerData.lastValidUpdate)
    //   //     //           .format("DD/MM/YYYY HH:mm")
    //   //     //       : "unknown"
    //   //     //   );
    //   //     // });
    //   //   });
    //   // await db
    //   //   .collection("V3players")
    //   //   .where("isArchivePlayer", "==", false)
    //   //   .get()
    //   //   .then(async (playersSnapshot) => {
    //   //     console.log("non archive players", playersSnapshot);
    //   //   });
    //   // await db
    //   //   .collection("V3players")
    //   //   .where("isArchivePlayer", "==", false)
    //   //   .where("gender", "==", "male")
    //   //   .get()
    //   //   .then(async (playersSnapshot) => {
    //   //     console.log("non archive players + male", playersSnapshot);
    //   //   });
    //   // await db
    //   //   .collection("V3players")
    //   //   .get()
    //   //   .then(async (playersSnapshot) => {
    //   //     console.log("players", playersSnapshot);
    //   //   });
    // },
    // async dealWithBrokenPlayers() {
    //   await db
    //     .collection("V3players")
    //     .where("undefinedCommentedGamesIdsLength", "==", 0)
    //     .get()
    //     .then((playersSnapshot) => {
    //       for (let i = 0; i < playersSnapshot.size; i++) {
    //         if (playersSnapshot.docs[i].id.length < 12) {
    //           const data = playersSnapshot.docs[i].data();
    //           console.log(
    //             "ok",
    //             playersSnapshot.docs[i],
    //             data.updatedByCloudFunctionAt
    //           );
    //           axios
    //             .get(
    //               `https://us-central1-scouting-c5f82.cloudfunctions.net/updateManuallyPlayer?playerId=${playersSnapshot.docs[
    //                 i
    //               ].id.toString()}`
    //             )
    //             .then((response) => {
    //               console.log("response", response);
    //             })
    //             .catch((error) => {
    //               console.log("error", error);
    //             });
    //         } else {
    //           console.log("nop", playersSnapshot.docs[i].id);
    //         }
    //       }
    //     });
    // },
    loadScoutsOptions() {
      const self = this;
      self.globalStats.scoutsOptions = [];

      const toReturn = [
        {
          value: "-",
          text: self.$t("select.aScout"),
          selected: true,
        },
      ];

      if (self.getCurrentScout?.company && self.getCompanie?.length) {
        self.getScoutsForCurrentUser.forEach((scout) => {
          if (scout.ghost) {
            return;
          }
          if (self.getCurrentScout.company === self.getCompanies[0]?.id) {
            toReturn.push({
              value: scout.id,
              text: `${scout.firstname} ${scout.lastname} (${scout.company})`,
            });
          } else {
            if (self.getCurrentScout.company === scout.company) {
              toReturn.push({
                value: scout.id,
                text: scout.firstname + " " + scout.lastname,
              });
            }
          }
          self.globalStats.scoutsOptions = toReturn;
        });
      }
    },
    getPercentBasedOnMax(valueToCompare, basedOnSum = true) {
      if (basedOnSum) {
        const sum =
          this.globalStats.ANotes.length +
          this.globalStats.BNotes.length +
          this.globalStats.CNotes.length +
          this.globalStats.DNotes.length;

        return !isNaN((valueToCompare * 100) / sum)
          ? ((valueToCompare * 100) / sum).toFixed(2)
          : 0;
      }
      return !isNaN((valueToCompare * 100) / this.highestNoteLength)
        ? ((valueToCompare * 100) / this.highestNoteLength).toFixed(2)
        : 0;
    },
    async loadMatchingStats() {
      const self = this;
      const timestampForSelectedPeriod =
        this.selectedPeriod !== "all"
          ? moment().subtract(1, this.selectedPeriod).startOf("day").unix()
          : 1639304766;
      let usableNotes = [];
      if (self.selectedScoutId === "-") {
        usableNotes = self.getNotes.filter((note) => {
          if (self.getCurrentScout.company === self.getCompanies[0]?.id) {
            return note.letterNote && note.id >= timestampForSelectedPeriod;
          } else {
            return (
              note.letterNote &&
              note.id >= timestampForSelectedPeriod &&
              note.authorCompany === self.getCurrentScout.company
            );
          }
        });
      } else {
        usableNotes = self.getNotes.filter((note) => {
          return (
            note.letterNote &&
            note.authorId === self.selectedScoutId &&
            note.id >= timestampForSelectedPeriod
          );
        });
      }

      let supervisedGames = [];
      supervisedGames = self.lodash
        .uniqBy(usableNotes, "gameId")
        .map((note) => {
          return note.gameId;
        });

      self.globalStats.supervisedGames = self.lodash.uniq([
        ...supervisedGames,
        ...self.gamesForCurrentScope.map((game) => {
          return game.id;
        }),
      ]);
      self.globalStats.observedPlayers = self.lodash.uniqBy(
        usableNotes,
        "playerId"
      );
      self.globalStats.ANotes = usableNotes.filter((note) => {
        if (self.getCurrentScout.company === self.getCompanies[0]?.id) {
          return note.letterNote === "A";
        } else {
          return (
            note.letterNote === "A" &&
            note.authorCompany === self.getCurrentScout.company
          );
        }
      });
      self.globalStats.BNotes = usableNotes.filter((note) => {
        if (self.getCurrentScout.company === self.getCompanies[0]?.id) {
          return note.letterNote === "B";
        } else {
          return (
            note.letterNote === "B" &&
            note.authorCompany === self.getCurrentScout.company
          );
        }
      });
      self.globalStats.CNotes = usableNotes.filter((note) => {
        if (self.getCurrentScout.company === self.getCompanies[0]?.id) {
          return note.letterNote === "C";
        } else {
          return (
            note.letterNote === "C" &&
            note.authorCompany === self.getCurrentScout.company
          );
        }
      });
      self.globalStats.DNotes = usableNotes.filter((note) => {
        if (self.getCurrentScout.company === self.getCompanies[0]?.id) {
          return note.letterNote === "D";
        } else {
          return (
            note.letterNote === "D" &&
            note.authorCompany === self.getCurrentScout.company
          );
        }
      });

      self.highestNoteLength = Math.max(
        self.globalStats.ANotes.length,
        self.globalStats.BNotes.length,
        self.globalStats.CNotes.length,
        self.globalStats.DNotes.length
      );
    },
    async loadAssignedPlayersIds() {
      console.log("in loadAssignedPlayersIds");
      if (this.getCurrentScout) {
        const assignedPlayersIds = [];

        await db
          .collection("V3players")
          .where(
            `${this.getCurrentScout.company}`,
            "==",
            this.getCurrentScout.id.toString()
          )
          .get()
          .then((playersSnapshot) => {
            playersSnapshot.docs.forEach((playerDoc) => {
              const playerData = playerDoc.data();
              playerData.assignedPlayers.forEach((assignedPlayerId) => {
                assignedPlayersIds.push(assignedPlayerId);
              });
            });
          });

        this.loadAssignedPlayers(assignedPlayersIds);
      }
    },
    async loadAssignedPlayers(assignedPlayersIds = []) {
      console.log("in loadAssignedPlayers");
      const self = this;
      self.assignedPlayers = [];
      for (let i = 0; i < assignedPlayersIds.length; i++) {
        await db
          .collection("V3players")
          .doc(assignedPlayersIds[i])
          .get()
          .then((playerDoc) => {
            self.assignedPlayers.push(playerDoc.data());
          });
      }
    },
    formatCurrentScope() {
      const self = this;
      if (this.calendarMode === "week") {
        let firstWeekDay = moment
          .unix(this.firstDayOfCurrentScope())
          .startOf("week")
          .unix();
        let lastWeekDay = moment
          .unix(this.firstDayOfCurrentScope())
          .endOf("week")
          .unix();

        self.formattedScope = [
          firstWeekDay,
          lastWeekDay,
          self.$t("fromTo", {
            from: moment.unix(firstWeekDay).format("DD MMM YYYY"),
            to: moment.unix(lastWeekDay).format("DD MMM YYYY"),
          }),
        ];
      } else {
        let firstMonthDay = moment
          .unix(this.firstDayOfCurrentScope())
          .startOf("month")
          .unix();

        let lastMonthDay = moment
          .unix(this.firstDayOfCurrentScope())
          .endOf("month")
          .unix();
        self.formattedScope = [
          firstMonthDay,
          lastMonthDay,
          `${self.$t(
            `months.${moment(self.currentScopeCalendar.month, "MM")
              .locale("en")
              .format("MMMM")
              .toLowerCase()}`
          )} ${moment.unix(firstMonthDay).format("YYYY")}`,
        ];
      }
    },
    isToday(weekIndex, dayIndex) {
      return (
        moment
          .unix(this.formattedScope[0])
          .startOf("week")
          .add(weekIndex, "weeks")
          .add(dayIndex, "days")
          .format("DD/MM/YYYY") ==
        moment().startOf("day").format("DD/MM/YYYY").toString()
      );
    },
    switchCalendarMode() {
      this.calendarMode = this.calendarMode === "week" ? "month" : "week";
      this.currentScopeCalendar = {
        week: moment().week(),
        month: moment().format("MM"),
        year: moment().format("YYYY"),
      };
      this.loadScopeEvents();
    },
    firstDayOfCurrentScope(format = "unix") {
      let startingDate = moment();
      if (this.currentScopeCalendar.year && this.currentScopeCalendar.month) {
        if (this.calendarMode === "week") {
          if (this.getLocale === "en") {
            startingDate = moment()
              .year(this.currentScopeCalendar.year)
              .week(this.currentScopeCalendar.week)
              .startOf("week");
          } else {
            startingDate = moment()
              .year(this.currentScopeCalendar.year)
              .week(this.currentScopeCalendar.week)
              .startOf("week");
          }
        } else {
          startingDate = moment(
            `${this.currentScopeCalendar.month}/${this.currentScopeCalendar.year}`,
            "MM/YYYY"
          );
        }

        if (format === "weekday") {
          if (this.calendarMode === "week") {
            return 0;
          }
          return startingDate.weekday();
        }
        return startingDate.unix();
      }
    },
    lastDayOfCurrentScope(format = "unix") {
      if (format === "weekday") {
        if (this.calendarMode === "week") {
          return 6;
        }
        return moment(
          `${this.currentScopeCalendar.month}/${this.currentScopeCalendar.year}`,
          "MM/YYYY"
        )
          .endOf("month")
          .weekday();
      }
      let endingDate = moment();
      if (this.calendarMode === "week") {
        if (this.getLocale === "en") {
          endingDate = moment()
            .year(this.currentScopeCalendar.year)
            .week(this.currentScopeCalendar.week)
            .endOf("week")
            .endOf("day");
        } else {
          endingDate = moment()
            .year(this.currentScopeCalendar.year)
            .week(this.currentScopeCalendar.week)
            .endOf("week")
            .endOf("day");
        }
      } else {
        endingDate = moment(
          `${this.currentScopeCalendar.month}/${this.currentScopeCalendar.year}`,
          "MM/YYYY"
        )
          .endOf("month")
          .endOf("day");
      }

      return endingDate.unix();
    },
    isInactive(weekIndex, dayIndex) {
      if (this.calendarMode === "week") {
        return false;
      }
      return (
        this.getCalendarDay(weekIndex, dayIndex).isBefore(
          moment.unix(this.firstDayOfCurrentScope())
        ) ||
        this.getCalendarDay(weekIndex, dayIndex).isAfter(
          moment.unix(this.lastDayOfCurrentScope())
        )
      );
    },
    currentDayEvents(weekIndex, dayIndex) {
      const self = this;
      if (!self.currentScopeCalendar.year && !self.currentScopeCalendar.month) {
        return [];
      }
      let currentDay = "";
      if (this.getLocale === "en") {
        currentDay = self.getCalendarDay(weekIndex, dayIndex);
      } else {
        currentDay = self.getCalendarDay(weekIndex, dayIndex);
      }
      const matchingGamesForCurrentDay = self.currentScopeEvents.filter(
        (event) => {
          return (
            event.date >= currentDay.startOf("day").unix() &&
            event.date <= currentDay.endOf("day").unix()
          );
        }
      );
      return this.lodash.orderBy(matchingGamesForCurrentDay, "date", "asc");
    },
    getCalendarDay(weekIndex, dayIndex, formatToReturn) {
      const self = this;
      if (!self.currentScopeCalendar.year) {
        return "";
      }
      if (self.calendarMode === "week") {
        if (formatToReturn) {
          return moment
            .unix(self.formattedScope[0])
            .add(dayIndex, "days")
            .format(formatToReturn);
        }
        return moment
          .unix(self.formattedScope[0])
          .startOf("week")
          .add(dayIndex, "days");
      } else {
        if (
          weekIndex === 0 &&
          moment(self.formattedScope[0]).week() ===
            moment(self.formattedScope[0]).endOf("year").weeks()
        ) {
          return moment()
            .year(this.currentScopeCalendar.year - 1)
            .week(52)
            .startOf("week")
            .add(dayIndex, "days")
            .format(formatToReturn);
        }
        if (formatToReturn) {
          return moment
            .unix(this.formattedScope[0])
            .add(weekIndex, "weeks")
            .startOf("week")
            .add(dayIndex, "days")
            .format(formatToReturn);
        }
        return moment
          .unix(this.formattedScope[0])
          .add(weekIndex, "weeks")
          .startOf("week")
          .add(dayIndex, "days");
      }
    },
    beforeScope() {
      if (this.calendarMode === "week") {
        if (this.currentScopeCalendar.week === 1) {
          this.currentScopeCalendar.week = moment()
            .year(this.currentScopeCalendar.year - 1)
            .weeksInYear();

          this.currentScopeCalendar.month = 12;
          this.currentScopeCalendar.year = parseInt(
            this.currentScopeCalendar.year - 1
          );
        } else {
          this.currentScopeCalendar.week--;
        }
      } else {
        if (this.currentScopeCalendar.month > 1) {
          this.currentScopeCalendar.month--;
        } else {
          this.currentScopeCalendar.month = 12;
          this.currentScopeCalendar.year--;
        }
      }
      this.loadScopeEvents();
    },
    afterScope() {
      if (this.calendarMode === "week") {
        this.currentScopeCalendar.week++;
      } else {
        if (this.currentScopeCalendar.month == 12) {
          this.currentScopeCalendar.month = 1;
          this.currentScopeCalendar.year++;
        } else {
          this.currentScopeCalendar.month++;
        }
      }
      this.loadScopeEvents();
    },
    async loadScopeEvents() {
      const self = this;
      if (!self.firstDayOfCurrentScope() || self.lastDayOfCurrentScope()) {
        return;
      }
      if (!this.getCurrentScout?.id || !self.getCompanies?.length) {
        return [];
      }
      let toReturn = [];

      if (
        self.getCurrentScout.isManager &&
        self.getCurrentScout.company === self.getCompanies[0]?.id
      ) {
        await db
          .collection("V3games")
          .where("date", ">=", self.firstDayOfCurrentScope())
          .where("date", "<=", self.lastDayOfCurrentScope())
          .orderBy("date", "asc")
          .get()
          .then((gamesSnapshot) => {
            gamesSnapshot.docs.forEach((gameDoc) => {
              const gameData = gameDoc.data();
              toReturn.push(gameData);
            });
          });
      } else if (self.getCurrentScout.isManager) {
        await db
          .collection("V3games")
          .where("date", ">=", self.firstDayOfCurrentScope())
          .where("date", "<=", self.lastDayOfCurrentScope())
          .where("companyGame", "==", self.getCurrentScout.company)
          .orderBy("date", "asc")
          .get()
          .then((gamesSnapshot) => {
            gamesSnapshot.docs.forEach((gameDoc) => {
              const gameData = gameDoc.data();
              toReturn.push(gameData);
            });
          });
      } else {
        const scoutGames = await db
          .collection("V3games")
          .where("date", ">=", self.firstDayOfCurrentScope())
          .where("date", "<=", self.lastDayOfCurrentScope())
          .where("addedBy", "==", self.getCurrentScout.id)
          .orderBy("date", "asc")
          .get()
          .then((gamesSnapshot) => {
            return gamesSnapshot.docs.map((gameDoc) => {
              const gameData = gameDoc.data();
              return gameData;
            });
          });
        const assignedGames = await db
          .collection("V3games")
          .where("date", ">=", self.firstDayOfCurrentScope())
          .where("date", "<=", self.lastDayOfCurrentScope())
          .where("scoutAssigned", "==", self.getCurrentScout.id)
          .orderBy("date", "asc")
          .get()
          .then((gamesSnapshot) => {
            return gamesSnapshot.docs.map((gameDoc) => {
              const gameData = gameDoc.data();
              return gameData;
            });
          });
        toReturn = [...scoutGames, ...assignedGames];
      }

      self.currentScopeEvents = self.lodash.uniqBy(toReturn, "id");
    },
    markGameAsSeen(gameId) {
      const seenBy = {};
      seenBy[`seenBy${this.currentScout.id}`] = true;
      db.collection("V3games")
        .doc(gameId.toString())
        .set(seenBy, { merge: true });
      this.$router.push({ path: `/game/${gameId}` });
    },
    async getXAxis() {
      const self = this;
      const toReturn = {};
      if (!self.getCurrentScout) {
        return;
      }
      if (!self.getCurrentScout?.isManager) {
        let getCurrentMonth = moment().month() + 1;
        let getCurrentYear = moment().year();
        const seasonsTimestamps = [];
        if (getCurrentMonth > 6) {
          seasonsTimestamps.push(moment().unix());
        }
        while (getCurrentYear >= 2020) {
          for (let i = 0; i < 12; i++) {
            let currentMonthLastDay = 0;
            if (i > 5) {
              currentMonthLastDay = moment(
                `01/${i + 1}/${getCurrentYear - 1}`,
                "DD/MM/YYYY"
              )
                .endOf("month")
                .unix();
            } else {
              currentMonthLastDay = moment(
                `01/${i + 1}/${getCurrentYear}`,
                "DD/MM/YYYY"
              )
                .endOf("month")
                .unix();
            }
            if (i === 5) {
              seasonsTimestamps.push(currentMonthLastDay);
            }
          }
          getCurrentYear--;
        }

        const timestampNotesGroupedBySeason = {};
        seasonsTimestamps.forEach((seasonTimestamp) => {
          timestampNotesGroupedBySeason[seasonTimestamp] = [];
        });
        await db
          .collection("V3notes")
          .where("authorId", "==", self.getCurrentScout.id)
          .orderBy("id", "asc")
          .get()
          .then((notesSnapshot) => {
            notesSnapshot.docs.forEach((noteDoc) => {
              for (let i = 0; i < seasonsTimestamps.length; i++) {
                if (
                  i !== seasonsTimestamps.length - 1 &&
                  parseInt(noteDoc.id) < seasonsTimestamps[i] &&
                  parseInt(noteDoc.id) >= seasonsTimestamps[i + 1]
                ) {
                  timestampNotesGroupedBySeason[seasonsTimestamps[i + 1]].push(
                    parseInt(noteDoc.id)
                  );
                }
              }
            });
            for (
              let i = 0;
              i < Object.keys(timestampNotesGroupedBySeason).length;
              i++
            ) {
              if (
                timestampNotesGroupedBySeason[
                  Object.keys(timestampNotesGroupedBySeason)[i]
                ]?.length
              ) {
                timestampNotesGroupedBySeason[
                  Object.keys(timestampNotesGroupedBySeason)[i]
                ].forEach((timestamp) => {
                  const currentNoteMonth = moment.unix(timestamp).format("MM");
                  if (
                    !toReturn[Object.keys(timestampNotesGroupedBySeason)[i]]
                  ) {
                    toReturn[Object.keys(timestampNotesGroupedBySeason)[i]] = {
                      x07: [],
                      x08: [],
                      x09: [],
                      x10: [],
                      x11: [],
                      x12: [],
                      x01: [],
                      x02: [],
                      x03: [],
                      x04: [],
                      x05: [],
                      x06: [],
                    };
                  }
                  toReturn[Object.keys(timestampNotesGroupedBySeason)[i]][
                    `x${currentNoteMonth}`
                  ].push(timestamp);
                });
              }
            }
          });
      }
      self.xAxis = Object.keys(toReturn).map((itemToReturn) => {
        return itemToReturn;
      });
      self.graphOptions = Object.keys(toReturn).map((seasonTimestamp) => {
        return {
          value: seasonTimestamp,
          text:
            moment.unix(seasonTimestamp).format("YYYY") +
            "/" +
            moment.unix(seasonTimestamp).add(1, "years").format("YYYY"),
        };
      });
      self.selectedGraphFilter =
        Object.keys(toReturn)[Object.keys(toReturn).length - 1];
      self.yAxis = toReturn;
    },
    getGamePlayerssAccordingCurrentScout(game) {
      const self = this;
      if (!self.getCurrentScout?.isManager) {
        return game[`${self.getCurrentScout.id}Players`]
          ? game[`${self.getCurrentScout.id}Players`]
          : [];
      } else if (
        self.getCurrentScout.isManager &&
        self.getCurrentScout.company !== self.getCompanies[0].id
      ) {
        return game[`${self.getCurrentScout.company}Players`]
          ? game[`${self.getCurrentScout.company}Players`]
          : [];
      }
      return this.lodash.uniqBy(game.players, "name") || [];
    },
    async setLastNotesAccordingCurrentUser() {
      let lastNotesForCurrentUser = [];
      let notesToUse = this.lodash.uniqBy(this.getNotes, "playerId");
      if (!this.getCurrentScout.isManager) {
        lastNotesForCurrentUser = notesToUse
          .filter((note) => {
            return note.authorId === this.getCurrentScout.id && note.playerId;
          })
          .slice(0, 20);
      } else {
        if (!this.getCompanies.length) {
          return;
        }
        if (this.getCurrentScout.company === this.getCompanies[0].id) {
          lastNotesForCurrentUser = notesToUse
            .filter((note) => {
              return note.playerId;
            })
            .slice(0, 20);
        } else {
          lastNotesForCurrentUser = notesToUse
            .filter((note) => {
              return (
                note.authorCompany === this.getCurrentScout.company &&
                note.playerId
              );
            })
            .slice(0, 20);
        }
      }
      for (let i = 0; i < lastNotesForCurrentUser.length; i++) {
        await db
          .collection("V3players")
          .doc(lastNotesForCurrentUser[i].playerId.toString())
          .get()
          .then((playerDoc) => {
            const playerData = playerDoc.data();
            lastNotesForCurrentUser[i].name = playerData.name;
            lastNotesForCurrentUser[i].dateOfBirth = playerData.dateOfBirth;
            lastNotesForCurrentUser[i].currentTeam = playerData.currentTeam;
            lastNotesForCurrentUser[i].price = playerData.price;
            lastNotesForCurrentUser[i].primaryPosition =
              playerData.primaryPosition;
          });
      }
      this.lastNotesAccordingCurrentUser = lastNotesForCurrentUser;
    },
    async setPassedGames() {
      const self = this;
      if (!self.getCurrentScout?.id) {
        return;
      }
      let toReturn = [];
      if (!self.getCurrentScout?.isManager) {
        toReturn = self.games
          .filter((game) => {
            return (
              game.date <= moment().unix() &&
              game.addedBy === self.getCurrentScout.id &&
              (game[`${self.getCurrentScout.id}PlayersLength`] || game.note)
            );
          })
          .slice(0, 10);
      } else {
        if (self.getCurrentScout.company === self.getCompanies[0]?.id) {
          toReturn = self.games
            .filter((game) => {
              return (
                game.date <= moment().unix() &&
                (game.playersLength || game.note)
              );
            })
            .slice(0, 10);
        } else {
          toReturn = self.games
            .filter((game) => {
              return (
                game.date <= moment().unix() &&
                game.companyGame === self.getCurrentScout.company &&
                (game[`${self.getCurrentScout.company}PlayersLength`] ||
                  game.note)
              );
            })
            .slice(0, 10);
        }
      }

      this.passedGames = toReturn;
    },
    getScoutPlayersStats(scoutPlayers) {
      return this.lodash.uniqBy(scoutPlayers, "playerId").length;
    },
    async loadReports() {
      const self = this;
      let notesToUse = self.getNotes;
      if (
        self.getCompanies[0] &&
        self.getCurrentScout &&
        self.getCurrentScout.isManager &&
        self.getCurrentScout.company !== self.getCompanies[0].id
      ) {
        notesToUse = self.getNotes.filter((note) => {
          return note.authorCompany === self.getCurrentScout.company;
        });
      } else if (self.getCurrentScout && !self.getCurrentScout.isManager) {
        notesToUse = self.getNotes.filter((note) => {
          return note.authorId === self.getCurrentScout.id;
        });
      }

      let newestNotes = self.lodash.uniqBy(notesToUse, "playerId");

      const players = [];
      await newestNotes.slice(0, 4).forEach(async (newestNote) => {
        await db
          .collection("V3players")
          .doc(newestNote.playerId.toString())
          .get()
          .then((playerDoc) => {
            if (playerDoc.exists) {
              const playerData = playerDoc.data();
              playerData.lastNoteId = newestNote.id;
              playerData.lastNoteLetter = newestNote.letterNote;
              players.push(playerData);
            }
          });
      });

      this.$store.dispatch("setLastPlayersUpdated", players);
    },
  },
  watch: {
    // getScouts() {
    //   this.mergePlayersNotesToNote();
    // },
    globalStats: {
      handler() {
        if (this.globalStats.observedPlayers?.length) {
          this.observedPlayersId = this.globalStats.observedPlayers
            .map((note) => {
              return parseInt(note.playerId);
            })
            .sort(function (a, b) {
              return a - b;
            });
        }
      },
      deep: true,
    },
    selectedScoutId() {
      this.loadMatchingStats();
    },
    selectedPeriod() {
      this.loadMatchingStats();
    },
    games: {
      handler() {
        this.setPassedGames();
      },
    },
    getScoutsForCurrentUser() {
      const self = this;
      self.scoutsIdForCurrentUser = self.getScoutsForCurrentUser.map(
        (scout) => {
          return scout.id;
        }
      );
      self.loadReports();
      self.ghostsScouts = [];
      self.getScoutsForCurrentUser.forEach((scout) => {
        if (scout.ghost) {
          self.ghostsScouts.push(scout.id);
        }
      });

      if (self.games.length === 0) {
        self.bindGames();
      }
      this.loadScoutsOptions();
    },
    scoutsPlayers() {
      if (this.scoutsPlayers.length) {
        this.loadReports();
      }
    },
    getNotes() {
      this.loadMatchingStats();
      this.loadReports();
      if (this.getCompanies.length) {
        this.setLastNotesAccordingCurrentUser();
      }
    },
    getCompanies: {
      handler() {
        this.loadScopeEvents();
        if (this.getNotes.length) {
          this.setLastNotesAccordingCurrentUser();
        }
      },
      deep: true,
    },
    getCurrentScout: {
      handler() {
        this.loadScopeEvents();
        this.getXAxis();
        this.loadReports();
        if (!this.getCurrentScout?.isManager) {
          this.loadAssignedPlayersIds();
        }
      },
      deep: true,
    },
    currentScopeCalendar: {
      handler() {
        this.formatCurrentScope();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.globalStatsContainer .globalStatsHeader > .col {
  padding: 0;
}

.globalStatsContainer .cardsContainer {
  gap: 20px;
}

.globalStatsContainer .cardsContainer .row {
  width: 100%;
}

.globalStatsContainer .cardContainer {
  border: none;
  border-radius: 0.75rem;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
  background-color: white;
  padding: 15px !important;
  display: flex;
  justify-content: center;
  margin: 5px 0;
}

.globalStatsContainer .cardContainer.statsGraphContainer {
  justify-content: flex-start;
  flex-direction: column;
  padding: 15px 30px !important;
}

.globalStatsContainer .graphFiltersContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.globalStatsContainer .graphFiltersContainer > .row:first-of-type {
  margin: 0;
  width: 100%;
  gap: 15px;
}

.globalStatsContainer .graphFiltersContainer > .row:first-of-type > .col {
  padding: 0;
}

.globalStatsContainer .cardContainer.statsGraphContainer > .row:first-of-type {
  margin-bottom: 10px;
}

.globalStatsContainer
  .cardContainer.statsGraphContainer
  > .row:first-of-type
  .col {
  padding: 0 0 20px 0;
  border-bottom: 1px solid #d0ccff;
  margin: 0 15px;
}

.globalStatsContainer .statsGraphContainer .statsGraphContent > .col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 15px;
}

.globalStatsContainer .statsGraphContentRow {
  color: #060052;
  font-weight: bold;
  margin: 12px 0;
  width: 80%;
}

.globalStatsContainer .statsGraphContentRow > .col {
  display: flex;
  align-content: center;
  padding-left: 0;
  width: 80%;
}

.globalStatsContainer .statsGraphContentRow > .col > a:first-of-type {
  display: inline-block;
  height: 48px;
  position: relative;
  background-color: #060052 !important;
  border-radius: 3px;
  min-width: 3px;
  margin-left: 40px;
  transition: 0.3s;
}

.globalStatsContainer .statsGraphContentRow > .col > a:first-of-type:before {
  content: attr(data-letter);
  position: absolute;
  display: block;
  left: -30px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.globalStatsContainer .statsGraphContentRow > .col > a:first-of-type:after {
  content: attr(data-detail);
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  left: calc(100% + 20px);
  top: 50%;
  transform: translate(0, -50%);
}

.globalStatsContainer .cardContainer span.highlight {
  font-weight: bold;
  font-size: 2rem;
}

.globalStatsContainer .cardContainer .row > div:last-of-type button,
.globalStatsContainer .cardContainer .row > div:last-of-type a {
  border-radius: 4px;
  background: #f6f5ff;
  color: #060052;
  border: 0;
  padding: 9px 8px;
  font-weight: bold;
  font-size: 0.7rem;
}

.globalStatsContainer .cardsContainer .cardContainer .row > *:last-of-type {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.globalStatsContainer > .row > .col > .row {
  margin-bottom: 20px;
}

.calendarBefore,
.calendarAfter {
  cursor: pointer;
}

.calendarNavigation {
  position: relative;
  display: flex;
  margin: 20px 20px 0 20px;
  text-align: center;
  background: #0b0790;
  color: white;
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.calendarNavigation > * {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.calendarModeContainer {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  border-radius: 3px;
  background: #dbdef8 !important;
  color: #0b0790;
  padding: 2px 7px;
  height: initial;
  font-size: 0.7rem;
  font-weight: 500;
  border: 0;
}

.calendarModeContainer:focus,
.calendarModeContainer:active,
.calendarModeContainer:hover,
.calendarModeContainer:visited {
  background: #dbdef8 !important;
  color: #0b0790 !important;
}

.calendarRow {
  display: flex;
  justify-content: space-between;
}

.calendarRow:first-of-type {
  color: white;
}

.calendarRow:first-of-type .calendarColumn {
  overflow-y: hidden;
  text-align: center;
  padding: 0;
}

.calendarColumn {
  width: calc(100% - 40px);
  color: rgba(0, 0, 0);
  flex: 1;
  padding: 3px;
}

.calendarColumn.inactive {
  background-color: rgba(0, 0, 0, 0.09);
  color: rgba(0, 0, 0, 0.3);
}

.calendarColumn.today {
  background-color: #f0d1d2;
}

.calendarColumn:first-of-type {
  flex: 1;
}

.calendarBody {
  border-right: 1px solid #dbdef8;
  border-bottom: 1px solid #dbdef8;
  border-left: 1px solid #dbdef8;
  margin: 0 20px;
}

.eventContainer {
  height: 80px;
  overflow-x: hidden;
  overflow-y: auto;
}

.calendarBody.weekMode .eventContainer {
  height: 207px;
}

.eventContainer::-webkit-scrollbar {
  width: 5px;
}

.eventContainer::-webkit-scrollbar-thumb {
  border-radius: 0.75rem;
  background: #0a008f;
  margin-right: 20px;
  display: block;
}

.calendarEventTeams {
  font-weight: bold;
}

.calendarEvent {
  cursor: pointer;
  font-size: 0.7rem;
  display: block;
  width: calc(100% - 6px);
  padding: 2px 3px;
  background-color: #dbdef8;
  color: #0b0790;
  margin: 0 3px 3px 3px;
}

.calendarEvent:hover {
  text-decoration: none;
}

.calendarEvent:not(.assignedGame) {
  border-left: 4px solid #2a66ea;
}

.assignedGame {
  background-color: #ef9b9a;
  border-left: 4px solid #d62828;
}

#lastPlayersUpdatedContainer .playerRowContainer > span {
  color: #8b95ea;
}
#lastPlayersUpdatedContainer .playerRowContainer > span:first-of-type {
  font-weight: bold;
  color: #000000;
}

.buttonContainer {
  display: inline-block;
  background-color: #da5856;
  padding: 3px 7px;
  font-size: 0.7rem;
  color: white;
}

.buttonContainer:hover {
  background-color: #be504e;
  text-decoration: none;
}

.dashboardContainer {
  background-color: white;
  margin-bottom: 50px;
}

.dashboardContainer > .row:not(:first-of-type) > .col-sm-12:first-of-type {
  padding-left: 40px;
}

.dashboardContainer > .row:last-of-type {
  padding-bottom: 20px;
}

.listingContainer,
.listingContainer.layout2 {
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 0.9rem;
}

.listingContainer {
  max-height: 224px;
  padding-right: 10px;
  margin-bottom: 30px;
}

.listingContainer.layout2 {
  max-height: 250px;
  margin-bottom: 5px;
}

.listingLinkContainer:hover {
  text-decoration: none;
}

.listingContainer::-webkit-scrollbar,
.listingContainer.layout2::-webkit-scrollbar {
  width: 8px;
}

.listingContainer::-webkit-scrollbar-thumb,
.listingContainer.layout2::-webkit-scrollbar-thumb {
  border-radius: 0.75rem;
}

.listingContainer::-webkit-scrollbar-thumb {
  background: #0a008f;
}

.listingContainer.layout2::-webkit-scrollbar-thumb {
  background-color: #be504e;
}
.selectContainer {
  display: block;
  text-align: right;
}
.selectContainer select {
  width: fit-content;
}

.categoryTitle {
  color: #0b0790;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
}

.categoryTitle select {
  width: 220px;
  border: 1px solid rgba(10, 0, 143, 0.506);
  color: #0a008f;
  font-weight: 600;
  font-size: 0.8rem;
}

.lastGameIntroContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
}

.lastGameDetailsContainer {
  display: flex;
  margin: 10px 0 0 0;
  justify-content: space-between;
}

.lastGameDetailsContainer .buttonContainer {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pillContainer {
  border-radius: 20px;
  background-color: #dbdef8;
  font-size: 0.7rem;
  padding: 3px 8px;
}
.isGolden {
  color: goldenrod;
}

.letterContainer span {
  background-color: #0a008f !important;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.matchContent {
  margin: 10px 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.matchContentHeader {
  width: 100%;
  padding: 10px;
  display: flex;
  text-align: center;
}

.matchDate {
  margin-right: 20px;
  flex: 1;
}

.matchContent .matchTeams {
  text-transform: uppercase;
  flex: 2;
}

.matchTeams {
  flex: 2;
}

.matchPlayers {
  flex: 1;
  text-align: center;
}

.pinStadium {
  color: white;
}

.locationsContainer {
  display: flex;
  align-items: center;
}

.listItemContainer {
  background-color: #f6f9fc;
  color: black;
  padding: 8px 12px;
  margin: 5px 0;
  display: block;
  position: relative;
  cursor: pointer;
}

.listItemContainer.toSee:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 5px;
  height: 100%;
  background: #da5856;
}

.listItemContainer.toSee {
  font-weight: bold;
}

.listItemContainer.toSee .lastGameIntroContainer {
  position: relative;
  padding-left: 20px;
}

.listItemContainer.toSee .lastGameIntroContainer:before {
  content: "";
  position: absolute;
  left: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #0a008f;
  border-radius: 50px;
}

.listItemContainer.seen:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 5px;
  height: 100%;
  background: #dbdef8;
}

.layout2 .listItemContainer {
  background-color: #f0d1d2;
  color: #0b0790;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 13px 10px;
}

.layout2 .listItemContainer:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  min-height: 100%;
  width: 4px;
  background-color: #da5856;
}

.listItemContainer:hover {
  text-decoration: none;
  color: black;
}

.listItemContent {
  display: flex;
  justify-content: space-between;
}

.listItemContent > span {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.playerRowContainer {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

.playerRowContainer > * {
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
}

.playerRowContainer > *:nth-child(odd) {
  flex: 2;
}

.playerRowContainer > *:nth-child(even) {
  flex: 1;
}

#bucketPlayersContainer {
  font-weight: bold;
}

#bucketPlayersContainer
  .listItemContainer
  .playerRowContainer
  span:last-of-type {
  flex: 1;
  justify-content: flex-end;
}

#bucketPlayersContainer
  .listItemContainer
  .playerRowContainer
  span:last-of-type:after {
  content: url(../assets/img/blueArrow.svg);
  width: 15px;
  display: block;
  margin-right: 10px;
}

.playerRowContainer > span:first-of-type {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.playerRowContainer > div.pillContainer {
  flex: initial;
  height: fit-content;
}

.playerRowContainer > div.pillContainer:after {
  content: url(../assets/img/blueArrow.svg);
  display: inline-block;
  width: 12px;
  margin-left: 7px;
}

.listItemContent > span:last-of-type {
  justify-content: flex-end;
}

.listItemContent .scoutDatasArrowContainer:after {
  content: url(../assets/img/blueArrow.svg);
  width: 15px;
  display: block;
  margin-right: 10px;
}

.locationContainer {
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 10px;
  width: 20px;
  height: 20px;
  border-radius: 50px;
}

.locationContainer svg {
  width: 11px;
  height: 11px;
}

.stadiumLocation {
  background-color: #0b0790;
}
.videoLocation {
  background-color: #d62828;
}
.statsLocation {
  background-color: #b362d0;
}

.matchActionsContent {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
  flex: 3;
}

.submitButton {
  width: 30px;
  height: 30px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #0b0790;
  background: transparent;
  color: #0b0790;
  position: relative;
  border-width: 1px;
  transition: 1s;
}

.submitButton.validated {
  border: 0;
  background: #0b0790;
  color: white;
}

.submitButton:before {
  content: url(../assets/img/iconValidate.svg);
  display: block;
  width: 100%;
}

.submitButton.validated:before {
  content: url(../assets/img/iconValidateValidated.svg);
}
.notesCirclesContainer {
  margin-right: 10px;
}

.notesCircles {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  background-color: #0a008f;
  font-size: 1.2rem;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

#statsFilterCard {
  z-index: 10;
  backdrop-filter: none;
}

#statsFiltersSelector {
  position: relative;
}

#statsFiltersLabel {
  text-align: right;
  cursor: pointer;
}

#statsFiltersLabel:after {
  content: url(../assets/img/arrow.svg);
  display: inline-block;
  width: 13px;
}

#statsFiltersContainer {
  position: absolute;
  background: #0a008f;
  width: 100%;
  right: 0;
  top: 150%;
}

#statsFiltersContainer ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: 100%;
  padding: 10px 0;
}

#statsFiltersContainer ul li {
  display: block;
  margin: 5px 0;
  font-size: 0.8rem;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}

#statsFiltersContainer ul li:last-of-type {
  margin-bottom: 0;
}

#statsFiltersContainer ul li:after {
  display: block;
  content: "";
  width: 20%;
  margin: 0 auto;
  padding: 5px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

#statsFiltersContainer ul li:last-of-type:after {
  border-bottom-width: 0px;
}

.scoutContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.scoutNameContainer {
  font-weight: bold;
  justify-content: flex-start;
  font-size: 0.8rem;
}

.scoutDatasContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scoutDataContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.listItemContent > span.scoutNameContainer {
  font-weight: bold;
  justify-content: flex-start;
  font-size: 0.8rem;
}

.scoutDataCounter {
  margin-right: 10px;
  padding: 4px 8px;
  background: #dbdef8;
  border-radius: 20px;
  font-weight: bold;
}

.scoutDataContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .globalStatsHeader {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .globalStatsContainer .cardsContainer {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .dashboardContainer > .row:not(:first-of-type) > .col-sm-12:first-of-type {
    padding-left: 15px;
  }

  .calendarBody {
    overflow-x: auto;
  }

  .calendarCurrent {
    font-size: 0.8rem;
  }

  .calendarColumn {
    min-width: 100px;
  }
}
</style>
