var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"minisquad-container",class:{ errored: _vm.errored }},[_c('div',{staticClass:"minisquad-content"},_vm._l((_vm.positionsAcronyms),function(positionAcronym,index){return _c('div',{key:'position_' + index,class:{
        position: true,
        active: positionAcronym === _vm.pickedPositionAcronym,
        editable: _vm.editable,
      },style:(_vm.sanitize(
          _vm.getPositionMap[positionAcronym][_vm.disposition][_vm.dispositionVersion]
        )),on:{"click":function($event){return _vm.clickOnPosition(positionAcronym)}}},[_c('span',[_vm._v(_vm._s(positionAcronym))])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }