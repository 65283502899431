import firebase from "firebase/app";
import "firebase/database";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
//Prod
let firebaseConfig = {
  apiKey: "AIzaSyCrApH7YoQ4Tflmzs28thGAhbYQ8o7gv24",
  authDomain: "scouting-c5f82.firebaseapp.com",
  databaseURL: "https://scouting-c5f82.firebaseio.com",
  projectId: "scouting-c5f82",
  storageBucket: "scouting-c5f82.appspot.com",
  messagingSenderId: "566535916352",
  appId: "1:566535916352:web:0c2d01ea25c36c5d9f48a5",
  measurementId: "G-LKR0528QMT",
};

// Test
// firebaseConfig = {
//   apiKey: "AIzaSyDUOD4Stv924OpWudX53eaIUkK1QjBkgl0",
//   authDomain: "scouting-test-47c47.firebaseapp.com",
//   projectId: "scouting-test-47c47",
//   storageBucket: "scouting-test-47c47.appspot.com",
//   messagingSenderId: "259481091235",
//   appId: "1:259481091235:web:1344977f4d2be742491316",
//   measurementId: "G-J4Q49WZGBR",
// };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
