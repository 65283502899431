import Vue from "vue";
import firebase from "../firebase";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard";
import Players from "../views/Players";
import Shadows from "../views/Shadows";
import Shadow from "../views/Shadow.vue";
import SquadManagement from "../views/SquadManagement";
import Player from "../views/Player";
import Games from "../views/GamesView";
import GameView from "../views/GameView";
import Login from "../views/Login";

Vue.use(VueRouter);
const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login - Scouting",
    },
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard - Scouting",
      requiresAuth: true,
    },
  },
  {
    path: "/players/:scoutId?/:filter?",
    name: "Players",
    component: Players,
    meta: {
      title: "Players - Scouting",
      requiresAuth: true,
    },
  },
  {
    path: "/games",
    name: "GamesView",
    component: Games,
    meta: {
      title: "Games - Scouting",
      requiresAuth: true,
    },
  },
  {
    path: "/games/:scoutId",
    name: "GamesViewDetail",
    component: Games,
    meta: {
      title: "Games - Scouting",
      requiresAuth: true,
    },
  },
  {
    path: "/game/:id",
    name: "Game",
    component: GameView,
    meta: {
      title: "Game - Scouting",
      requiresAuth: true,
    },
  },
  {
    path: "/managePlayer/:scoutId/:playerId",
    name: "ManagePlayer",
    component: Player,
    meta: {
      title: "Manage Player - Scouting",
      requiresAuth: true,
    },
  },
  {
    path: "/globalReportForPlayer/:playerId",
    name: "GlobalReportForPlayer",
    component: Player,
    meta: {
      title: "Global Report For A Player - Scouting",
      requiresAuth: true,
    },
  },
  {
    path: "/shadows",
    name: "Shadows",
    component: Shadows,
    meta: {
      title: "Shadow Teams - Scouting",
      requiresAuth: true,
    },
  },
  {
    path: "/shadow/:shadowID",
    name: "Shadow",
    component: Shadow,
    meta: {
      title: "Shadow Team - Scouting",
      requiresAuth: true,
    },
  },
  {
    path: "/squad_management",
    name: "Squad Management",
    component: SquadManagement,
    meta: {
      title: "Squad Management - Scouting",
      requiresAuth: true,
    },
  },
  {
    path: "/player/:playerId",
    name: "Player",
    component: Player,
    meta: {
      title: "Player - Scouting",
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  document.title = to.meta.title;
  window.scrollTo(0, 0);

  if (requiresAuth && !currentUser) next("login");
  else if (!requiresAuth && currentUser) next("dashboard");
  else next();
});

export default router;
