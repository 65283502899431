var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.getCurrentScout.isManager ? 'noteManagerContainer' : 'noteScoutContainer',
    _vm.note.category == 'cell' ? 'cellNote noteContainer' : 'noteContainer',
  ]},[_c('b-row',{staticClass:"noteIdentificators"},[(!_vm.note.gameId && _vm.note.authorId == _vm.getCurrentScout.id)?_c('div',{staticClass:"deleteNote",on:{"click":_vm.deleteNote}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1):_vm._e(),_c('b-col',[(_vm.getCurrentScout.isManager)?_c('span',[_vm._v(" "+_vm._s(_vm.getNoteAuthorName(_vm.note.authorId))+" "),(_vm.getCurrentScout.company === _vm.getCompanies[0].id)?_c('span',[_vm._v(" ("+_vm._s(_vm.getNoteAuthorCompany(_vm.note.authorId))+") ")]):_vm._e()]):_vm._e(),(_vm.note.category)?_c('span',{staticClass:"noteCategory"},[_vm._v(" "+_vm._s(_vm.$t("category." + _vm.note.category)))]):_vm._e(),_c('span',{staticClass:"noteDate"},[_vm._v(_vm._s(_vm.formatDate(_vm.note.id, false, "date")))])])],1),(_vm.note.letterNote)?_c('b-row',{staticClass:"noteContent"},[_c('b-col',[_c('p',[_vm._v(_vm._s(_vm.note.comment))])])],1):_vm._e(),(_vm.note.letterNote)?_c('b-row',[_c('b-col',{staticClass:"lettersContainer"},[_c('b-row',{staticClass:"text-center"},[_c('b-col',[_c('span',{class:{
              ABCDNoteContainer: true,
              selected: _vm.note.letterNote == 'A',
            }},[_vm._v(" A ")])]),_c('b-col',[_c('span',{class:{
              ABCDNoteContainer: true,
              selected: _vm.note.letterNote == 'B',
            }},[_vm._v(" B ")])]),_c('b-col',[_c('span',{class:{
              ABCDNoteContainer: true,
              selected: _vm.note.letterNote == 'C',
            }},[_vm._v(" C ")])]),_c('b-col',[_c('span',{class:{
              ABCDNoteContainer: true,
              selected: _vm.note.letterNote == 'D',
            }},[_vm._v(" D ")])])],1)],1)],1):_vm._e(),(_vm.note.letterNote)?_c('b-row',[_c('b-col',[(_vm.note.tacticalNote?.length)?_c('p',[_c('span',{staticClass:"noteCategory"},[_vm._v(_vm._s(_vm.$t("category.tactical"))+" :")]),_c('br'),_vm._v(" "+_vm._s(_vm.note.tacticalNote)+" ")]):_vm._e(),(_vm.note.mentalNote?.length)?_c('p',[_c('span',{staticClass:"noteCategory"},[_vm._v(_vm._s(_vm.$t("category.mental"))+" :")]),_c('br'),_vm._v(" "+_vm._s(_vm.note.mentalNote)+" ")]):_vm._e(),(_vm.note.athleticalNote?.length)?_c('p',[_c('span',{staticClass:"noteCategory"},[_vm._v(_vm._s(_vm.$t("category.athletic"))+" :")]),_c('br'),_vm._v(" "+_vm._s(_vm.note.athleticalNote)+" ")]):_vm._e(),(_vm.note.technicalNote?.length)?_c('p',[_c('span',{staticClass:"noteCategory"},[_vm._v(_vm._s(_vm.$t("category.technique"))+" :")]),_c('br'),_vm._v(" "+_vm._s(_vm.note.technicalNote)+" ")]):_vm._e()])],1):_c('b-row',[_c('b-col',[_c('p',[_vm._v(_vm._s(_vm.note.comment))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }