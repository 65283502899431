<template>
  <b-row class="fields-group">
    <b-col cols="12" md="6" class="pb-2">
      <TextInput
        label="Club"
        :disabled="disableFilterByClub"
        :value="storedValue('findGameByClub', clubDatalist)"
        example="Ex: Botafogo"
        :placeholder="$t('searchBy.placeholderFindByClub')"
        :datalist="clubDatalist"
        name="findGameByClub"
        @change="changeShallow"
        @error="addPopinError"
        @unerror="removePopinError"
      />
    </b-col>
    <b-col cols="12" md="6" class="pb-2">
      <TextInput
        :label="$t('ligue')"
        :disabled="disableFilterByLigue"
        :value="storedValue('findGameByLigue', ligueDatalist)"
        example="Ex: Serie A"
        :placeholder="$t('searchBy.placeholderFindByLigue')"
        :datalist="ligueDatalist"
        name="findGameByLigue"
        @change="changeShallow"
        @error="addPopinError"
        @unerror="removePopinError"
      />
    </b-col>
    <b-col cols="12" md="6" class="pb-2">
      <TextInput
        :label="$t('country/Continent')"
        :disabled="disableFilterByCountry"
        :value="storedValue('findGameByCountryContinent', countryDatalist)"
        :placeholder="$t('searchBy.placeholderFindByContinent')"
        name="findGameByCountryContinent"
        :datalist="countryDatalist"
        @change="changeShallow"
        @error="addPopinError"
        @unerror="removePopinError"
      />
    </b-col>
    <b-col cols="12" md="6" class="pb-2">
      <DateInput
        :label="$t('matchDate')"
        :disabled="disableFilterByDate"
        :max="getMaxDate"
        :value="0"
        name="findGameByDate"
        @change="changeShallow"
        @error="addPopinError"
        @unerror="removePopinError"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DateInput from "../../../Fields/DateInput.vue";
import TextInput from "../../../Fields/TextInput.vue";
import moment from "moment";
export default {
  data() {
    return {
      findGameDate: "",
      ligueDatalist: [],
      clubDatalist: [],
      countryDatalist: [],
      activeFilter: "",
      fieldsOptions: [
        "findGameByClub",
        "findGameByLigue",
        "findGameByCountry",
        "findGameByDate",
      ],
    };
  },
  components: { DateInput, TextInput },
  mounted() {
    if (!this.getCountries.length) {
      this.$store.dispatch("matchs/bindCountries");
    }
    if (!this.getLigues.length) {
      this.$store.dispatch("matchs/bindLigues");
    }
    if (!this.getClubs.length) {
      this.$store.dispatch("matchs/bindClubs");
    }
    if (!this.getMatchs.length) {
      this.$store.dispatch("matchs/bindMatchs");
    }
    if (this.getPopinShallow[0]) {
      this.activeFilter = Object.keys(this.getPopinShallow[0])[0];
    }
  },
  computed: {
    ...mapActions("matchs", [
      "bindCountries",
      "bindLigues",
      "bindClubs",
      "bindMatchs",
      "setPayload",
    ]),
    ...mapGetters("matchs", [
      "getCountries",
      "getLigues",
      "getClubs",
      "getPopinShallow",
      "getMatchs",
    ]),
    disableFilterByClub() {
      return this.activeFilter?.length
        ? this.fieldsOptions.includes(this.activeFilter) &&
            this.activeFilter !== "findGameByClub"
        : false;
    },
    disableFilterByLigue() {
      return this.activeFilter?.length
        ? this.fieldsOptions.includes(this.activeFilter) &&
            this.activeFilter !== "findGameByLigue"
        : false;
    },
    disableFilterByCountry() {
      return this.activeFilter?.length
        ? this.fieldsOptions.includes(this.activeFilter) &&
            this.activeFilter !== "findGameByCountry"
        : false;
    },
    disableFilterByDate() {
      return this.activeFilter?.length
        ? this.fieldsOptions.includes(this.activeFilter) &&
            this.activeFilter !== "findGameByDate"
        : false;
    },
    getMinDate() {
      return moment().subtract(4, "months").startOf("day").toDate();
    },
    getCurrentTimestamp() {
      return moment().startOf("day").unix();
    },
    getMaxDate() {
      return moment().add(1, "weeks").startOf("day").toDate();
    },
    minDate() {
      return moment().startOf("day").toDate();
    },
  },
  methods: {
    ...mapActions("popin", [
      "addPopinError",
      "removePopinError",
      "setShallowPayload",
    ]),
    storedValue(name) {
      return (this.getPopinShallow[0] && this.getPopinShallow[0][name]) || "";
    },
    changeShallow(value) {
      if (Object.values(value)[0]) {
        this.$store.dispatch("popin/setShallowPayload", value);
        this.activeFilter = Object.keys(value)[0];
      }
    },
    generateDatalist(item) {
      const self = this;
      switch (item) {
        case "ligue":
          self.ligueDatalist = self.lodash.orderBy(
            self.getLigues.map((item, index) => {
              return { label: item, id: index };
            }),
            "label",
            "asc"
          );
          break;
        case "club":
          self.clubDatalist = self.lodash.orderBy(
            self.getClubs.map((item, index) => {
              return { label: item, id: index };
            }),
            "label",
            "asc"
          );
          break;
        case "country":
          self.countryDatalist = self.lodash.orderBy(
            self.getCountries.map((item, index) => {
              return { label: item, id: index };
            }),
            "label",
            "asc"
          );
          break;
        default:
          break;
      }
    },
  },
  watch: {
    getCountries: {
      handler() {
        this.generateDatalist("country");
      },
      immediate: true,
      deep: true,
    },
    getLigues: {
      handler() {
        this.generateDatalist("ligue");
      },
      immediate: true,
      deep: true,
    },
    getClubs: {
      handler() {
        this.generateDatalist("club");
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
div.fields-group {
  padding: 24px;
  border-radius: 8px;
  background-color: #fafafa;
}
</style>
