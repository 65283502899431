<template>
  <ActionBase
    @click="handleClick()"
    :disabled="popinHasErrors"
    :label="$t('validate')"
    :primary="true"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ActionBase from "../../../Popin/Actions/ActionBase.vue";
import db from "../../../../db";
import mixinPlayers from "../../../../mixins/mixinPlayers";
import mixinText from "../../../../mixins/mixinText";
export default {
  mixins: [mixinPlayers, mixinText],
  components: { ActionBase },
  computed: {
    ...mapGetters("popin", [
      "getPopinShallowPayload",
      "popinPayload",
      "additionalContext",
      "popinHasErrors",
    ]),
    ...mapGetters(["getCurrentCompanyTeams"]),
  },
  methods: {
    ...mapMutations("popin", ["closePopin"]),
    ...mapActions("popin", ["openToast"]),
    async handleClick() {
      const oldPosition = this.$t(
        this.getPositionTranslationKeyFromPlayer(this.popinPayload)
      );
      const newPosition = this.$t(
        this.getPositionTranslationKeyFromPlayer(this.getPopinShallowPayload)
      );
      const playerId = this.getPopinShallowPayload.id;
      const updatePayload = {
        movingPosition: this.getPopinShallowPayload.movingPosition,
        positionIndex: null,
      };

      const isInPlayers = this.updatePlayerFieldInList(
        this.additionalContext.players,
        playerId,
        updatePayload
      );
      const isInPlayersIn =
        !isInPlayers &&
        this.updatePlayerFieldInList(
          this.additionalContext.playersIn,
          playerId,
          updatePayload
        );

      if (!isInPlayers && !isInPlayersIn) {
        this.additionalContext.players.push(this.getPopinShallowPayload);
      }

      const keyToUpdate = isInPlayersIn ? "playersIn" : "players";

      await db
        .collection("V3shadowsMercato")
        .doc(this.additionalContext.id)
        .set(
          { [keyToUpdate]: this.additionalContext[keyToUpdate] },
          { merge: true }
        );
      Object.assign(this.popinPayload, this.getPopinShallowPayload);
      this.openToast({
        translationKey: "squad.toasts.changePlayerPosition",
        payload: { player: this.popinPayload.name, oldPosition, newPosition },
        delay: 5000,
      });
      this.closePopin();
    },
  },
};
</script>

<style lang="scss" scoped></style>
