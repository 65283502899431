<template>
  <b-row class="fields-group">
    <b-col cols="12" md="4">
      <DateInput
        :label="$t('endOfContractAcronym')"
        :name="'endOfContract'"
        :value="getPopinShallowPayload.endContract"
        :disabled="true"
      />
    </b-col>
    <b-col cols="12" md="4">
      <NumberInput
        :label="$t('salary')"
        :value="getPopinShallowPayload.salary"
        :name="'salary'"
        :suffix="$t('millionsPerYear')"
        :disabled="true"
      />
    </b-col>
    <b-col cols="12" md="4">
      <NumberInput
        :label="$t('marketValue')"
        :value="getPopinShallowPayload.price"
        :name="'price'"
        :suffix="$t('millions')"
        :disabled="true"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NumberInput from "../../../Fields/NumberInput.vue";
import DateInput from "../../../Fields/DateInput.vue";
import moment from "moment";
export default {
  components: { NumberInput, DateInput },
  computed: {
    ...mapGetters("popin", ["getPopinShallowPayload"]),
    minDate() {
      return moment().startOf("day").toDate();
    },
  },
  methods: {
    ...mapActions("popin", ["addPopinError", "removePopinError"]),
  },
};
</script>
<style lang="scss" scoped>
div.fields-group {
  padding: 24px;
  border-radius: 8px;
  background-color: #fafafa;
}
</style>
