<template>
  <div>
    <b-sidebar
      id="sidebar-add-player"
      :title="$t('add.aPlayerToGame')"
      backdrop-variant="dark"
      v-model="props.display"
      backdrop
      shadow
    >
      <div class="px-3 py-2">
        <b-form @submit.prevent="addPlayer">
          <b-form-group
            :label="$tc('plural.team', 2)"
            label-for="teams"
            class="text-left"
          >
            <b-form-radio-group
              id="teams"
              v-model="props.selectedTeam"
              :options="teamOptions"
              name="teams"
              required
            />
          </b-form-group>
          <b-form-group
            :label="$tc('plural.position', 1)"
            label-for="position"
            class="text-left"
          >
            <b-form-select
              id="position"
              v-model="props.positionLabel"
              :options="positionOptions"
              required
            />
          </b-form-group>
          <b-form-group
            id="player"
            :label="$tc('plural.player', 1)"
            label-for="player"
            class="text-left"
          >
            <b-form-input
              id="player"
              v-model="props.playerName"
              type="text"
              required
              autocomplete="off"
            />
            <div
              v-if="
                matchingPlayerName.length &&
                matchingPlayerName != props.playerName
              "
            >
              <div
                v-for="matchingPlayer in matchingPlayers"
                :key="matchingPlayer.id"
              >
                <div
                  @click="setProPlayer(matchingPlayer.id)"
                  id="matchingPlayerNameContainer"
                >
                  <font-awesome-icon class="mr-2" icon="question-circle" />
                  {{ matchingPlayer.name }}
                  ({{ matchingPlayer.currentTeam }})
                </div>
              </div>
            </div>
          </b-form-group>
          <b-form-group
            id="jerseyNumber"
            :label="$tc('plural.jerseyNumber', 1)"
            label-for="jerseyNumber"
            class="text-left"
          >
            <b-form-input
              id="jerseyNumber"
              v-model="props.jerseyNumber"
              type="number"
              min="1"
              step="1"
              required
              autocomplete="off"
            />
          </b-form-group>
          <div class="mt-2 p-4 text-left error" v-if="err">
            <ul>
              <li>
                <font-awesome-icon class="mr-2" icon="exclamation-circle" />
              </li>
              <li>
                <p class="mb-0">{{ err }}</p>
              </li>
            </ul>
          </div>
          <b-button class="submit mt-4" type="submit" variant="primary">{{
            $t("add.thePlayer")
          }}</b-button>
        </b-form>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import db from "../db";
import axios from "axios";
import moment from "moment";

export default {
  name: "SidebarAddPlayer",
  data() {
    return {
      err: "",
      props: this.prop,
      name: "",
      playerInfos: {},
      matchingPlayers: [],
      matchingPlayerName: "",
    };
  },
  emits: ["emitPlayerSuccessfullyAdded"],
  props: {
    prop: Object,
    homeTeam: String,
    awayTeam: String,
  },
  methods: {
    setProPlayer(matchingPlayerId) {
      const self = this;
      const matchingPlayer = self.lodash.filter(
        self.addedPlayersAndApiPlayers,
        {
          id: matchingPlayerId,
        }
      )[0];
      this.props.playerName = matchingPlayer.name;
      self.matchingPlayers = [];
      this.matchingPlayerName = "";
    },
    accentLessText(text) {
      return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    async managePlayerGamePositionForThisGame(
      playerId,
      gameId,
      scoutId,
      gamePositionId
    ) {
      const self = this;
      await db
        .collection("V3players")
        .doc(playerId.toString())
        .collection("games")
        .doc(gameId.toString())
        .collection("gamesPositions")
        .where("addedBy", "==", scoutId)
        .get()
        .then(async (playerGameGamePositionSnapshot) => {
          if (playerGameGamePositionSnapshot.size > 0) {
            for (let i = 0; i < playerGameGamePositionSnapshot.size; i++) {
              if (playerGameGamePositionSnapshot.docs[i].exists) {
                const playerGameGamePositionData =
                  playerGameGamePositionSnapshot.docs[i].data();

                await db
                  .collection("V3gamesPositions")
                  .doc(playerGameGamePositionData.gamePositionId)
                  .collection("players")
                  .where("addedBy", "==", self.currentScoutId)
                  .where("gameId", "==", gameId.toString())
                  .where("id", "==", playerId.toString())
                  .get()
                  .then(async (gamesPositionsPlayersSnapshot) => {
                    for (
                      let j = 0;
                      j < gamesPositionsPlayersSnapshot.size;
                      j++
                    ) {
                      await db
                        .collection("V3gamesPositions")
                        .doc(
                          playerGameGamePositionData.gamePositionId.toString()
                        )
                        .collection("players")
                        .doc(gamesPositionsPlayersSnapshot.docs[j].id)
                        .delete();
                    }
                  });
                await db
                  .collection("V3players")
                  .doc(playerId.toString())
                  .collection("games")
                  .doc(gameId.toString())
                  .collection("gamesPositions")
                  .doc(playerGameGamePositionSnapshot.docs[i].id.toString())
                  .delete()
                  .catch(function (error) {
                    self.err = error;
                  });
                await db
                  .collection("V3players")
                  .doc(playerId.toString())
                  .collection("gamesPositions")
                  .doc(playerGameGamePositionData.gamePositionId)
                  .collection("games")
                  .where("addedBy", "==", scoutId)
                  .where("gameId", "==", gameId)
                  .get()
                  .then(async (playerGamePositionsGamesSnapshot) => {
                    for (
                      let j = 0;
                      j < playerGamePositionsGamesSnapshot.size;
                      j++
                    ) {
                      if (playerGamePositionsGamesSnapshot.docs[j].exists) {
                        await db
                          .collection("V3players")
                          .doc(playerId.toString())
                          .collection("gamesPositions")
                          .doc(
                            playerGameGamePositionData.gamePositionId.toString()
                          )
                          .collection("games")
                          .doc(
                            playerGamePositionsGamesSnapshot.docs[
                              j
                            ].id.toString()
                          )
                          .delete()
                          .then(async () => {
                            await db
                              .collection("V3players")
                              .doc(playerId.toString())
                              .collection("gamesPositions")
                              .doc(playerGameGamePositionData.gamePositionId)
                              .collection("games")
                              .get()
                              .then(async (snapshot) => {
                                if (snapshot.size == 0) {
                                  await db
                                    .collection("V3players")
                                    .doc(playerId.toString())
                                    .collection("gamesPositions")
                                    .doc(
                                      playerGameGamePositionData.gamePositionId.toString()
                                    )
                                    .delete();
                                }
                              });
                          })
                          .catch(function (error) {
                            self.err = error;
                          });
                      }
                    }
                  })
                  .catch(function (error) {
                    self.err = error;
                  });
              }
            }
          }
        })
        .then(async () => {
          await db
            .collection("V3players")
            .doc(playerId.toString())
            .collection("games")
            .doc(gameId.toString())
            .collection("gamesPositions")
            .add({
              addedBy: scoutId.toString(),
              gamePositionId: gamePositionId.toString(),
            })
            .then(async (docRef) => {
              await db
                .collection("V3players")
                .doc(playerId.toString())
                .collection("gamesPositions")
                .doc(gamePositionId.toString())
                .collection("games")
                .doc(docRef.id.toString())
                .set({
                  addedBy: scoutId.toString(),
                  date: parseInt(self.props.date),
                  gameId: gameId.toString(),
                  teamAway: self.awayTeam.toString(),
                  teamHome: self.homeTeam.toString(),
                })
                .catch(function (error) {
                  self.err = error;
                });
            })
            .catch(function (error) {
              self.err = error;
            });
        });
    },
    async managePlayerInGamePositions(
      scoutId,
      playerId,
      gameId,
      gamePositionId
    ) {
      const self = this;
      await this.managePlayerGamePositionForThisGame(
        playerId,
        gameId,
        scoutId,
        gamePositionId
      );
      await db
        .collection("V3gamesPositions")
        .doc(gamePositionId)
        .collection("players")
        .where("addedBy", "==", scoutId)
        .where("gameId", "==", gameId)
        .where("id", "==", playerId)
        .limit(1)
        .get()
        .then((gamePositionSnapshot) => {
          gamePositionSnapshot.docs.map(async (gamePositionDoc) => {
            if (gamePositionDoc.exists) {
              await db
                .collection("V3gamesPositions")
                .doc(gamePositionId)
                .collection("players")
                .doc(gamePositionDoc.id)
                .delete()
                .catch(function (error) {
                  self.err = error;
                });
            }
          });
        })
        .then(async () => {
          await db
            .collection("V3gamesPositions")
            .doc(gamePositionId)
            .collection("players")
            .add({
              addedBy: scoutId.toString(),
              gameId: gameId.toString(),
              id: playerId.toString(),
              name: self.name.toString(),
            })
            .catch(function (error) {
              self.err = error;
            });
        });
    },

    async addPlayer() {
      var self = this;
      if (!self.props.playerId) {
        self.err = self.$t("error.selecteAnExistingPlayer");
      } else if (!self.props.jerseyNumber) {
        self.err = self.$t("error.selectjerseyNumber");
      } else {
        const playerGamePosition = self.lodash.filter(
          self.gamesPositions,
          function (o) {
            return o.position == self.props.position;
          }
        );

        self.managePlayerInGamePositions(
          self.currentScoutId.toString(),
          self.props.playerId.toString(),
          self.$route.params.id.toString(),
          playerGamePosition[0].id.toString()
        );

        await db
          .collection("V3games")
          .doc(self.$route.params.id.toString())
          .get()
          .then(async (gamesDoc) => {
            if (gamesDoc.exists) {
              const gameDatas = gamesDoc.data();
              db.collection("V3players")
                .doc(self.props.playerId.toString())
                .collection("games")
                .doc(gameDatas.id.toString())
                .set({
                  date: parseInt(gameDatas.date),
                  id: gameDatas.id.toString(),
                  teamAway: gameDatas.teamAway.toString(),
                  teamHome: gameDatas.teamHome.toString(),
                })
                .catch(function (error) {
                  self.err = error;
                });
            }
          });

        await axios
          .get(
            "https://ds-ol-scout-app-py-api-3.azurewebsites.net/players/" +
              self.props.playerId
          )
          .then((response) => {
            const playerDatas = response.data;
            self.playerInfos = {
              citizenship: playerDatas.citizenship || [],
              currentTeam: playerDatas.current_team.toString(),
              dateOfBirth: !isNaN(parseInt(moment(playerDatas.DoB).unix()))
                ? parseInt(moment(playerDatas.DoB).unix())
                : "undefined",
              height:
                playerDatas.height && !isNaN(parseInt(playerDatas.height))
                  ? parseInt(playerDatas.height)
                  : "undefined",
              id: self.props.playerId.toString(),
              isReferral: true,
              name: playerDatas.player_name.toString(),
              nationality: playerDatas.Nationality.toString(),
              weight:
                playerDatas.weight && !isNaN(parseInt(playerDatas.weight))
                  ? parseInt(playerDatas.weight)
                  : "undefined",
            };
            self.err = "";
          })
          .catch(function (error) {
            self.err = error;
            const index = self.lodash.findIndex(
              self.addedPlayers,
              function (o) {
                return o.id == self.props.playerId;
              }
            );

            if (index > -1) {
              self.err = null;
              const addedPlayerDatas = self.addedPlayers[index];

              self.playerInfos = {
                associatedGamesIds: [self.$route.params.id],
                citizenship: addedPlayerDatas.citizenship || [],
                currentTeam: addedPlayerDatas.currentTeam.toString(),
                dateOfBirth: !isNaN(parseInt(addedPlayerDatas.dateOfBirth))
                  ? parseInt(addedPlayerDatas.dateOfBirth)
                  : "undefined",
                height:
                  addedPlayerDatas.height &&
                  !isNaN(parseInt(addedPlayerDatas.height))
                    ? parseInt(addedPlayerDatas.height)
                    : "undefined",
                id: addedPlayerDatas.id.toString(),
                isReferral: false,
                name: addedPlayerDatas.name.toString(),
                nationality: addedPlayerDatas.nationality.toString(),
                weight:
                  addedPlayerDatas.weight &&
                  !isNaN(parseInt(addedPlayerDatas.weight))
                    ? parseInt(addedPlayerDatas.weight)
                    : "undefined",
              };
            }
          });

        await db
          .collection("V3players")
          .doc(self.props.playerId.toString())
          .get()
          .then(async (playerDoc) => {
            if (!playerDoc.exists) {
              await db
                .collection("V3players")
                .doc(self.props.playerId.toString())
                .set({
                  associatedGamesIds: [self.$route.params.id],
                  citizenship: self.playerInfos.citizenship || [],
                  currentTeam: self.playerInfos.currentTeam.toString(),
                  dateOfBirth: !isNaN(parseInt(self.playerInfos.dateOfBirth))
                    ? parseInt(self.playerInfos.dateOfBirth)
                    : "undefined",
                  height:
                    self.playerInfos.height &&
                    !isNaN(parseInt(self.playerInfos.height))
                      ? parseInt(self.playerInfos.height)
                      : "undefined",
                  id: self.props.playerId.toString(),
                  isReferral: true,
                  name: self.playerInfos.name.toString(),
                  nationality: self.playerInfos.nationality.toString(),
                  updatedBy: self.currentScoutId.toString(),
                  weight:
                    self.playerInfos.weight &&
                    !isNaN(parseInt(self.playerInfos.weight))
                      ? parseInt(self.playerInfos.weight)
                      : "undefined",
                })
                .catch(function (error) {
                  self.err = error;
                });
            } else {
              const playerDatas = playerDoc.data();
              const newPlayerAssociatedGamesId = playerDatas.associatedGamesIds
                ? playerDatas.associatedGamesIds
                : [];
              if (
                self.lodash.indexOf(
                  newPlayerAssociatedGamesId,
                  self.$route.params.id
                ) == -1
              ) {
                newPlayerAssociatedGamesId.push(self.$route.params.id);
              }

              await db
                .collection("V3scouts")
                .doc(self.currentScoutId.toString())
                .collection("players")
                .doc(self.props.playerId.toString())
                .get()
                .then(async (scoutPlayerDoc) => {
                  if (scoutPlayerDoc.exists) {
                    const scoutPlayerDatas = scoutPlayerDoc.data();
                    if (scoutPlayerDatas.associatedGamesIds) {
                      scoutPlayerDatas.associatedGamesIds.push(
                        self.$route.params.id
                      );
                    } else {
                      scoutPlayerDatas.associatedGamesIds = [
                        self.$route.params.id,
                      ];
                    }
                    await db
                      .collection("V3scouts")
                      .doc(self.currentScoutId.toString())
                      .collection("players")
                      .doc(self.props.playerId.toString())
                      .set(scoutPlayerDatas, { merge: true });
                  } else {
                    await db
                      .collection("V3scouts")
                      .doc(self.currentScoutId.toString())
                      .collection("players")
                      .doc(self.props.playerId.toString())
                      .set(
                        {
                          associatedGamesIds: [self.$route.params.id],
                          citizenship: self.citizenship || [],
                          currentTeam: self.playerInfos.currentTeam.toString(),
                          dateOfBirth: !isNaN(
                            parseInt(self.playerInfos.dateOfBirth)
                          )
                            ? parseInt(self.playerInfos.dateOfBirth)
                            : "undefined",
                          height:
                            self.playerInfos.height &&
                            !isNaN(parseInt(self.playerInfos.height))
                              ? parseInt(self.playerInfos.height)
                              : "undefined",
                          id: self.playerInfos.id,
                          isReferral: self.playerInfos.isReferral,
                          name: self.playerInfos.name.toString(),
                          nationality: self.playerInfos.nationality.toString(),
                          weight:
                            self.playerInfos.weight &&
                            !isNaN(parseInt(self.playerInfos.weight))
                              ? parseInt(self.playerInfos.weight)
                              : "undefined",
                        },
                        { merge: true }
                      );
                  }
                });

              await db
                .collection("V3players")
                .doc(self.props.playerId.toString())
                .set(
                  {
                    associatedGamesIds: newPlayerAssociatedGamesId,
                    currentTeam: self.playerInfos.currentTeam.toString(),
                    height:
                      self.playerInfos.height &&
                      !isNaN(parseInt(self.playerInfos.height))
                        ? parseInt(self.playerInfos.height)
                        : "undefined",
                    updatedBy: self.currentScoutId.toString(),
                    weight:
                      self.playerInfos.weight &&
                      !isNaN(parseInt(self.playerInfos.weight))
                        ? parseInt(self.playerInfos.weight)
                        : "undefined",
                  },
                  { merge: true }
                )
                .catch(function (error) {
                  self.err = error;
                });
            }
          });

        await db
          .collection("V3players")
          .doc(self.props.playerId.toString())
          .collection("gamesPositions")
          .doc(playerGamePosition[0].id.toString())
          .get()
          .then(async (gameDoc) => {
            if (gameDoc.exists) {
              const gameDatas = gameDoc.data();
              if (
                !gameDatas.abv ||
                !gameDatas.id ||
                !gameDatas.label ||
                !gameDatas.position
              ) {
                const gamePosition = self.findGamesPositionsByPosition(
                  playerGamePosition[0].id.toString()
                );

                await db
                  .collection("V3players")
                  .doc(self.props.playerId.toString())
                  .collection("gamesPositions")
                  .doc(playerGamePosition[0].id.toString())
                  .update({
                    abv: gamePosition.abv,
                    id: gamePosition.id,
                    label: gamePosition.label,
                    position: gamePosition.position,
                  })
                  .catch(function (error) {
                    self.err = error;
                  });
              }
            } else {
              const gamePosition = self.findGamesPositionsByPosition(
                playerGamePosition[0].id.toString()
              );
              await db
                .collection("V3players")
                .doc(self.props.playerId.toString())
                .collection("gamesPositions")
                .doc(playerGamePosition[0].id.toString())
                .set({
                  abv: gamePosition.abv.toString(),
                  id: gamePosition.id.toString(),
                  label: gamePosition.label.toString(),
                  position: parseInt(gamePosition.position),
                })
                .catch(function (error) {
                  self.err = error;
                });
            }
          });

        await db
          .collection("V3games")
          .doc(self.$route.params.id.toString())
          .collection("players")
          .doc(self.props.playerId.toString())
          .set({
            id: self.props.playerId.toString(),
            gamePositionId: playerGamePosition[0].id.toString(),
            name: self.props.playerName,
            jerseyNumber: self.props.jerseyNumber,
            team: self.props.selectedTeam.toString(),
          });

        await db
          .collection("V3scouts")
          .doc(self.currentScoutId)
          .collection("games")
          .doc(self.$route.params.id.toString())
          .collection("players")
          .doc(self.props.playerId.toString())
          .set({
            gamePositionId: playerGamePosition[0].id.toString(),
            id: self.props.playerId.toString(),
            name: self.name.toString(),
            jerseyNumber: parseInt(self.props.jerseyNumber),
            team: self.props.selectedTeam.toString(),
          })
          .then(() => {
            self.props.display = false;
            self.props.playerName = "";
            self.props.playerId = null;
            self.props.jerseyNumber = "";
            self.err = "";
            this.$emit("emitPlayerSuccessfullyAdded");
          })
          .catch(function (error) {
            self.err = error;
          });
      }
    },
    findGamesPositionsByPosition(position) {
      const self = this;
      let gameToReturn = "";

      Object.keys(self.gamesPositions).forEach((gamePosition) => {
        if (self.gamesPositions[gamePosition].id == position) {
          gameToReturn = self.gamesPositions[gamePosition];
        }
      });

      return gameToReturn;
    },
  },
  computed: {
    ...mapState([
      "currentScoutId",
      "playersFromApi",
      "gamesPositions",
      "addedPlayers",
      "locale",
    ]),
    teamOptions() {
      return [
        {
          value: "home",
          text: this.$t("home"),
        },
        {
          value: "away",
          text: this.$t("away"),
        },
      ];
    },
    positionOptions() {
      return this.lodash
        .chain(this.gamesPositions)
        .map((value) => {
          let lowerPosition = value.label.split(" ").join("");
          const translatedPosition = this.$t("position." + lowerPosition);
          return {
            value: value.label,
            text: translatedPosition,
          };
        })
        .value();
    },
    addedPlayersAndApiPlayers() {
      var nonReferralPlayers = this.lodash
        .chain(this.addedPlayers)
        .map((value) => ({
          id: value.id,
          name: value.name,
          currentTeam: value.currentTeam,
        }))
        .value();
      return this.lodash.concat(this.playersFromApi, nonReferralPlayers);
    },
  },
  watch: {
    "props.playerName": function () {
      var name = this.props.playerName.split(" (")[0];

      var matchingPlayers =
        this.props.playerName.length > 2
          ? this.lodash.map(
              this.lodash.pickBy(this.addedPlayersAndApiPlayers, function (o) {
                return (
                  o.name
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .indexOf(
                      name
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                    ) !== -1
                );
              })
            )
          : [];
      this.matchingPlayers = matchingPlayers.slice(0, 5);
      this.matchingPlayerName = matchingPlayers[0]
        ? matchingPlayers[0].name
        : "";

      if (this.props.playerName) {
        if (!matchingPlayers.length) {
          this.err =
            this.props.playerName + this.$t("error.doesNotExistInDatabase");
          this.props.playerId = null;
        } else {
          this.err = "";
          this.props.playerId = matchingPlayers[0].id;
          this.name = name;
        }
      } else {
        this.err = "";
      }
    },
    "props.positionLabel": function () {
      var self = this;
      var id = this.lodash.findIndex(this.gamesPositions, function (o) {
        return o.label == self.props.positionLabel;
      });
      if (id == -1) {
        this.err = "Please select a position in datalist";
        this.props.position = null;
      } else {
        this.err = "";
        this.props.position = this.gamesPositions[id].position;
      }
    },
    "props.playerId": {
      immediate: true,
      handler(playerId) {
        let self = this;
        if (playerId) {
          axios
            .get(
              "https://ds-ol-scout-app-py-api-3.azurewebsites.net/players/" +
                playerId.toString()
            )
            .then((response) => {
              this.playerInfo = response.data;
              self.err = "";
            })
            .catch(function (error) {
              self.err = error;
              let index = self.lodash.findIndex(
                self.addedPlayers,
                function (o) {
                  return o.id == playerId;
                }
              );

              if (index > -1) {
                self.err = null;
                self.playerInfo = self.addedPlayers[index];
              }
            });
        } else {
          this.playerInfo = null;
          self.err = "";
        }
      },
    },
  },
};
</script>
<style scoped>
#matchingPlayerNameContainer {
  padding: 10px 15px;
  margin-top: 10px;
  background: rgba(10, 0, 143, 0.1);
  border-radius: 5px;
}
</style>
