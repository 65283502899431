<template>
  <div>
    <ActionBase
      @click="handleClick()"
      :disabled="checkRequirement"
      :label="$t('create.aGame')"
      :primary="true"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import mixinDates from "../../../../mixins/mixinDates";
import mixinGames from "../../../../mixins/mixinGames";
import ActionBase from "../../../Popin/Actions/ActionBase.vue";
export default {
  data() {
    return {};
  },
  mixins: [mixinDates, mixinGames],
  components: { ActionBase },
  mounted() {
    this.clearRequirement();
  },
  computed: {
    ...mapGetters("matchs", [
      "getSelectedMatch",
      "getSelectedObservationMatch",
      "getPopinShallow",
    ]),
    ...mapGetters("popin", [
      "popinPayload",
      "getPopinShallowPayload",
      "additionalContext",
      "popinInfos",
    ]),
    ...mapGetters(["getCurrentScout"]),
    checkRequirement() {
      if (this.popinInfos.title.key === "create.aGame") {
        return this.getPopinShallowPayload.homeTeam?.length &&
          this.getPopinShallowPayload.awayTeam?.length &&
          this.getPopinShallowPayload.observation?.length &&
          this.getPopinShallowPayload.date?.toString().length
          ? false
          : true;
      } else if (this.popinInfos.title.key === "search.aGame") {
        return !(
          this.getSelectedMatch?.match_id &&
          this.getSelectedObservationMatch.length
        );
      }
      return false;
    },
  },
  methods: {
    ...mapActions("popin", ["openToast", "closePopin"]),
    clearRequirement() {
      if (this.popinInfos.title.key === "create.aGame") {
        this.$store.dispatch("popin/setShallowPayload", {
          homeTeam: "",
          awayTeam: "",
          country: "",
          date: "",
          observation: "",
        });
      } else if (this.popinInfos.title.key === "search.aGame") {
        this.$store.dispatch("matchs/setSelectedMatch", "");
        this.$store.dispatch("matchs/setSelectedMatchObservation", "");
      }
    },
    async handleClick() {
      let gameToCreate = {};
      if (this.popinInfos.title.key === "create.aGame") {
        gameToCreate = {
          addedBy: this.getCurrentScout.id.toString(),
          companyGame: this.getCurrentScout.company.toString(),
          country: this.getPopinShallowPayload.country || "",
          date: this.getPopinShallowPayload.date,
          id: "",
          location: this.getPopinShallowPayload.observation,
          teamAway: this.getPopinShallowPayload.awayTeam,
          teamHome: this.getPopinShallowPayload.omeTeam,
        };
      } else if (this.popinInfos.title.key === "search.aGame") {
        gameToCreate = {
          addedBy: this.getCurrentScout.id.toString(),
          apiGameId: this.getSelectedMatch.match_id.toString(),
          companyGame: this.getCurrentScout.company.toString(),
          competition: this.getSelectedMatch.competition_name.toString(),
          competitionLabel: this.getSelectedMatch.competition_name.toString(),
          date: this.convertDate(
            this.getSelectedMatch.match_date,
            "YYYY-MM-DD",
            "unix"
          ),
          id: "",
          location: this.getSelectedObservationMatch,
          teamAway: this.getSelectedMatch.away_team_name,
          teamHome: this.getSelectedMatch.home_team_name,
        };

        if (
          this.getSelectedMatch.home_score &&
          this.getSelectedMatch.away_score
        ) {
          gameToCreate.score = [
            this.getSelectedMatch.home_score,
            this.getSelectedMatch.away_score,
          ];
        }
      } else {
        this.openToast({
          translationKey: "matchs.toasts.error.creation",
          payload: {
            homeTeam: this.getSelectedMatch.home_team_name,
            awayTeam: this.getSelectedMatch.away_team_name,
          },
          delay: 5000,
        });
        throw new Error(`Match not found`);
      }

      this.createGame(gameToCreate);
      this.openToast({
        translationKey: "matchs.toasts.success.creation",
        payload: {
          homeTeam: this.getSelectedMatch.home_team_name,
          awayTeam: this.getSelectedMatch.away_team_name,
        },
        delay: 5000,
      });
      this.closePopin();
    },
  },
};
</script>

<style lang="scss" scoped></style>
