import { GoBackAction, CancelAction } from "../../components/Popin/Actions";
import * as fieldsGroups from "../../components/SquadManagement/Popin/FieldsGroups";
import * as actions from "../../components/SquadManagement/Popin/Actions";
import { popinVariants } from "../../enums/popinVariants";

export const squadPopins = (state, _getters, _rootState, rootGetters) => ({
  [popinVariants.ADD_PLAYER_TO_SQUAD]: {
    title: {
      key: "addPlayerToSquadTitle",
      payload: { player: state.popinPayload?.name },
    },
    subtitle: rootGetters["squad/playerPositionSubtitle"],
    fieldsGroups: [fieldsGroups.UpdatePlayerPosition],
    actions: [GoBackAction, actions.ConfirmAddPlayerToSquad],
  },
  [popinVariants.CHANGE_PLAYER_POSITION]: {
    title: {
      key: "changePlayerPosition",
      payload: { player: state.popinPayload?.name },
    },
    subtitle: rootGetters["squad/playerPositionSubtitle"],
    fieldsGroups: [fieldsGroups.UpdatePlayerPosition],
    actions: [GoBackAction, actions.ConfirmChangePlayerPosition],
  },
  [popinVariants.CANCEL_RECRUIT_PROSPECT]: {
    title: {
      key: "cancelRecruitProspectTitle",
      payload: { player: state.popinPayload?.name },
    },
    subtitle: rootGetters["squad/playerPositionSubtitle"],
    actions: [GoBackAction, actions.ConfirmAbortRecruitPlayer],
  },
  [popinVariants.CREATE_SQUAD]: {
    title: { key: "create.aSquad" },
    fieldsGroups: [fieldsGroups.SquadCreationInfos],
    actions: [CancelAction, actions.ConfirmCreateSquad],
  },
  [popinVariants.DELETE_SQUAD]: {
    title: {
      key: "remove.thisSquad",
    },
    subtitle: {
      key: "remove.thisSquadConfirmation",
      payload: { label: state.popinPayload?.label },
    },
    actions: [CancelAction, actions.DeleteSquad],
  },
  [popinVariants.ORGANIZE_POSITION_PLAYERS]: {
    title: [
      {
        key: `position.${rootGetters.getGamesPosition
          .find(
            (p) =>
              p.abv ===
              ({
                G: "GK",
                RF: "RCF",
                LF: "LCF",
              }[state.popinPayload.positionAcronym] ??
                state.popinPayload.positionAcronym)
          )
          ?.label?.split(" ")
          .reduce((acc, curr) => (acc ? acc + curr : curr.toLowerCase()), "")}`,
      },
      ` (${state.popinPayload.positionAcronym})`,
    ],
    fieldsGroups: [fieldsGroups.OrganizePositionPlayers],
    actions: [actions.ConfirmOrganizePositionPlayers],
  },
  [popinVariants.PAYROLL_BALANCE]: {
    title: [
      {
        key: "projectedPayroll",
      },
      ` : ${state.popinPayload?.summary}`,
    ],
    fieldsGroups: [
      fieldsGroups.IncomingPayroll,
      fieldsGroups.OutgoingPayroll,
      fieldsGroups.UpdatedPayroll,
    ],
  },
  [popinVariants.SEARCH_PROSPECTS]: {
    title: {
      key: "search.aProspect",
    },
    fieldsGroups: [fieldsGroups.ProspectSearchFilters],
    actions: [
      {
        to: popinVariants.SEARCH_PROSPECTS_RESULTS,
        translationKey: "search.dot",
        primary: true,
      },
    ],
  },
  [popinVariants.SEARCH_PROSPECTS_RESULTS]: {
    title: {
      key: "search.aProspect",
    },
    subtitle: {
      key: "plural.searchPlayersResults",
      payload: {
        nb: rootGetters["popin/popinPayload"]?.results?.length ?? 0,
      },
      plural: true,
    },
    fieldsGroups: [fieldsGroups.ProspectSearchResults],
    actions: [GoBackAction],
  },
  [popinVariants.PREVENT_SELL]: {
    title: state.popinPayload?.name,
    subtitle: rootGetters["squad/playerPositionSubtitle"],
    fieldsGroups: [fieldsGroups.UpdateSoldContract],
    actions: [
      actions.UpdateSoldContractInfos,
      {
        to: popinVariants.PREVENT_SELL_CONFIRM,
        translationKey: "abortSell",
        primary: true,
      },
    ],
  },
  [popinVariants.PREVENT_SELL_CONFIRM]: {
    title: {
      key: "confirmation.preventSell.player",
      payload: { player: state.popinPayload?.name },
    },
    subtitle: rootGetters["squad/playerPositionSubtitle"],
    actions: [GoBackAction, actions.ConfirmAbortSell],
  },
  [popinVariants.RECRUIT_PROSPECT]: {
    title: {
      key: "confirmation.recruit.player",
      payload: { player: state.popinPayload?.name },
    },
    subtitle: [
      rootGetters["squad/playerPositionSubtitle"],
      ` - ${state.popinPayload.currentTeam}`,
    ],
    fieldsGroups: [fieldsGroups.UpdateProspectContractAndPosition],
    actions: [GoBackAction, actions.ConfirmRecruitPlayer],
  },
  [popinVariants.RENAME_SQUAD]: {
    title: {
      key: "update.squadName",
    },
    fieldsGroups: [fieldsGroups.SquadRenameInfos],
    actions: [CancelAction, actions.ConfirmRenameSquad],
  },
  [popinVariants.SELL_PLAYER]: {
    title: state.popinPayload?.name,
    subtitle: rootGetters["squad/playerPositionSubtitle"],
    fieldsGroups: [fieldsGroups.UpdateContract],
    actions: [
      actions.UpdateContractInfos,
      {
        to: popinVariants.ADD_PLAYER_TO_SQUAD,
        translationKey: "addPlayerToSquad",
        primary: false,
        disabled: rootGetters["popin/popinHasErrors"],
      },
      {
        to: popinVariants.SELL_PLAYER_CONFIRM,
        translationKey: "sell",
        primary: true,
      },
    ],
  },
  [popinVariants.SELL_PLAYER_CONFIRM]: {
    title: {
      key: "confirmation.sell.player",
      payload: { player: state.popinPayload?.name },
    },
    subtitle: rootGetters["squad/playerPositionSubtitle"],
    fieldsGroups: [fieldsGroups.UpdateSellPrice],
    actions: [GoBackAction, actions.ConfirmSellPlayer],
  },
  [popinVariants.SELL_PLAYER_FROM_COMPO_CONFIRM]: {
    title: {
      key: "confirmation.sell.player",
      payload: { player: state.popinPayload?.name },
    },
    subtitle: rootGetters["squad/playerPositionSubtitle"],
    fieldsGroups: [fieldsGroups.UpdateSellPrice],
    actions: [GoBackAction, actions.ConfirmSellPlayer],
  },
  [popinVariants.SHOW_PROSPECT_INFOS]: {
    title: state.popinPayload?.name,
    subtitle: [
      rootGetters["squad/playerPositionSubtitle"],
      ` - ${state.popinPayload.currentTeam}`,
    ],
    fieldsGroups: [fieldsGroups.ShowProspectContractInfos],
    actions: [
      {
        to: popinVariants.RECRUIT_PROSPECT,
        translationKey: "recruitProspect",
        primary: true,
      },
    ],
  },
  [popinVariants.TRANSFERS_BALANCE]: {
    title: [
      {
        key: "transfersBalance",
      },
      ` : ${state.popinPayload?.summary}`,
    ],
    fieldsGroups: [
      fieldsGroups.IncomingTransfers,
      fieldsGroups.OutgoingTransfers,
    ],
  },
  [popinVariants.UPDATE_PLAYER_FROM_COMPO]: {
    title: state.popinPayload?.name,
    subtitle: rootGetters["squad/playerPositionSubtitle"],
    fieldsGroups: [fieldsGroups.UpdateContract],
    actions: [
      actions.UpdateContractInfos,
      {
        to: popinVariants.CHANGE_PLAYER_POSITION,
        translationKey: "changePosition",
        primary: false,
      },
      state.popinPayload?.isIncoming
        ? {
            to: popinVariants.CANCEL_RECRUIT_PROSPECT,
            translationKey: "cancelRecruitProspect",
            primary: true,
          }
        : {
            to: popinVariants.SELL_PLAYER_FROM_COMPO_CONFIRM,
            translationKey: "sell",
            primary: true,
          },
    ],
  },
});
