<template>
  <div class="list-container">
    <div class="list-header list-item" v-if="showColumnsTitle">
      <div
        class="list-item-column"
        v-for="(column, columnIndex) of columnsDefinitions"
        :key="`column_title_${columnIndex}`"
        :style="{
          'width': column.width,
          'align-items': getFlexAlignment(column.align),
        }"
      >
        <span>
          {{ column.title }}
        </span>
      </div>
    </div>
    <Draggable
      class="list"
      ghost-class="temporary-item"
      :dragClass="'dragging'"
      :disabled="!orderable"
      draggable=".draggable"
      v-model="computedItems"
      :animation="100"
    >
      <div
        class="list-item"
        v-for="index of Math.max(maxItems, computedItems.length)"
        :key="`item_${index}`"
        @dragstart="startDrag"
        @dragend="endDrag"
        :class="{
          'draggable': computedItems[index - 1],
          orderable,
          'is-incoming': computedItems[index - 1]?.isIncoming,
        }"
      >
        <template v-if="computedItems[index - 1]">
          <div
            class="list-item-column"
            v-for="(column, columnIndex) of columnsDefinitions"
            :key="`column_${index}_${columnIndex}`"
            :class="{ clickable: !!column.href || !!column.action }"
            :style="{
              'width': column.width,
              'align-items': getFlexAlignment(column.align),
            }"
            @click="handleColumnClick(computedItems[index - 1], column)"
          >
            <img
              class="picto"
              v-if="column?.pictoSrc"
              :src="column.pictoSrc(computedItems[index - 1])"
            />
            <div class="draggable-icon" v-else-if="column.name === 'dragIcon'">
              <img src="../../assets/img/draggable.svg" />
            </div>
            <span v-else-if="column">
              {{ column.value(computedItems[index - 1]) }}
            </span>
          </div>
        </template>
      </div>
    </Draggable>
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import mixinPlayers from "../../mixins/mixinPlayers";
import mixinDates from "../../mixins/mixinDates";

export default {
  components: {
    Draggable,
  },
  emits: ["change"],
  mixins: [mixinPlayers, mixinDates],
  name: "li-st",
  data() {
    return {
      computedItems: this.items,
    };
  },
  props: {
    items: { type: Array, default: () => [] },
    columns: { type: Object, required: true },
    maxItems: { type: Number, default: 5 },
    orderable: { type: Boolean, default: true },
    showColumnsTitle: { type: Boolean, default: false },
  },
  computed: {
    totalWeight() {
      return Object.values(this.columns).reduce(
        (acc, curr) => acc + (curr.weight ?? 1),
        0
      );
    },
    columnsDefinitions() {
      const returnValue = Object.entries(this.columns).map(
        ([name, column]) => ({
          ...column,
          name,
          width: `${((100 * (column.weight ?? 1)) / this.totalWeight).toFixed(
            2
          )}%`,
        })
      );
      return returnValue;
    },
  },
  methods: {
    getFlexAlignment(alignment) {
      return {
        left: "flex-start",
        right: "flex-end",
        center: "center",
      }[alignment];
    },
    hideNativeDrag(event) {
      let img = new Image();
      event.dataTransfer.setDragImage(img, 0, 0);
    },
    startDrag(event) {
      this.hideNativeDrag(event);
    },
    endDrag() {
      this.$emit("change", this.computedItems);
    },
    handleColumnClick(item, column) {
      if (column.href) {
        this.$router.push(column.href(item));
      }
      if (column.action) {
        column.action(item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";
div.temporary-item {
  opacity: 50%;
}
div.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.grabbing {
  cursor: grabbing !important;
}

div.list-item {
  user-select: none;
  height: 48px;
  &.draggable.orderable {
    cursor: grab;
  }
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 12px;
  border-radius: 4px;
  background-color: #fafafa;
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 700;
  color: $blueDarkest;
  &.is-incoming {
    background-color: #ffe1e1;
    .draggable-icon {
      background-color: #fdcfce;
    }
  }
  &.orderable:not(.draggable) {
    cursor: default;
    position: relative;
    clip-path: inset(0 round 4px 4px 4px 4px);
    &::before {
      content: "";
      position: absolute;
      left: -15px;
      right: -15px;
      top: -15px;
      bottom: -15px;
      border: calc(15px + 1px) dashed #e6e6e6;
      border-radius: calc(15px + 4px);
      box-sizing: border-box;
    }
  }
  img.picto {
    height: 25px;
    width: 25px;
    background-color: $blueDarkest;
    border-radius: 100px;
    padding: 4px;
  }
}

div.list-header {
  position: sticky;
  top: 0;
  background-color: $blueDarkest;
  color: #ffffff;
  border-radius: 4px;
}

div.list-item-column {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.clickable {
    cursor: pointer;
  }
}

div.draggable-icon {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6e6e6;
  border-radius: 100px;
}
</style>
