var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pitch-container"},[_c('div',{staticClass:"pitch-header"},[_c('div',{staticClass:"pitch-top-pill left"},[_c('span',[_vm._v(_vm._s(_vm.compoLabel))])]),_c('div',{staticClass:"pitch-top-pill right"},[_c('span',[_vm._v(_vm._s(_vm.players.length)+" "+_vm._s(_vm.$tc("plural.player", _vm.players.length || 1)))])])]),_c('div',{staticClass:"pitch",style:({
      paddingBottom: `calc(60px + ${
        (_vm.playersPerPosition.G?.length ?? 0) * 3
      }rem)`,
    })},[_c('div',{staticClass:"pitch-content"},_vm._l((_vm.positionsAcronyms),function(positionAcronym,index){return _c('div',{key:'position_' + index,staticClass:"position-container",style:(_vm.sanitize(_vm.getPositionMap[positionAcronym][_vm.compo][_vm.compoVersion]))},[_c('div',{staticClass:"position-acronym",on:{"click":function($event){return _vm.clickOnPosition(positionAcronym)}}},[_c('span',[_vm._v(_vm._s(positionAcronym))])]),(_vm.playersPerPosition[positionAcronym]?.length)?_c('div',{staticClass:"position-badge"},[_c('span',[_vm._v(_vm._s(_vm.playersPerPosition[positionAcronym].length))])]):_vm._e(),(_vm.playersPerPosition[positionAcronym]?.length)?_c('div',{staticClass:"compo-players-list"},_vm._l((_vm.playersPerPosition[positionAcronym]),function(player,index){return _c('div',{key:`compo_player_${index}`,staticClass:"compo-players-item",class:{
              ..._vm.getEndContractColorClass(player.endContract),
              'is-incoming': _vm.isPlayerIncoming(player),
            },on:{"click":function($event){return _vm.openChangePlayerPopin(player)}}},[_c('span',{staticClass:"compo-player-name"},[_vm._v(" "+_vm._s(_vm.getMinifiedPlayerName(player.name))+" ")]),(player.endContract)?_c('span',{staticClass:"compo-player-eoc"},[_vm._v(" "+_vm._s(_vm.$t("endOfContractAcronym"))+" : "+_vm._s(_vm.formatDate(player.endContract, false, "MMMM YYYY"))+" ")]):_vm._e()])}),0):_vm._e()])}),0)]),_c('div',{staticClass:"pitch-footer-container"},[_c('div',{staticClass:"pitch-footer"},_vm._l((_vm.endContractThresholds),function({ className, text },index){return _c('div',{key:`threshold_${index}`,staticClass:"pitch-footer-pill",class:className},[_c('span',[_vm._v(" "+_vm._s(text)+" ")])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }