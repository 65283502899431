var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headerContainer"},[_c('b-row',{staticClass:"headerContent"},[_c('b-col',{staticClass:"text-left",attrs:{"sm":"6","md":"3"}},[_c('div',{staticClass:"text-center headerColumnTitle"},[_vm._v(" / "+_vm._s(_vm.$t("clubAndNationality"))+" / ")]),_c('div',{staticClass:"geographicalDatas",attrs:{"id":"team"}},[_vm._v(" "+_vm._s(_vm.player.currentTeam)+" ")]),_c('div',{staticClass:"geographicalDatas",attrs:{"id":"nationality"}},[_vm._v(" "+_vm._s(_vm.getPlayerNationalities)+" ")]),_c('div',{staticClass:"geographicalDatas"},[_vm._v(" ("+_vm._s(_vm.isCommunalPlayer(_vm.player.citizenship))+") ")])]),_c('b-col',{staticClass:"text-center",attrs:{"xs":"12","md":"6"}},[_c('div',{class:{ isGolden: _vm.player.isInBucket },attrs:{"id":"nameContainer"}},[_vm._v(" "+_vm._s(_vm.player.name)+" ")]),(
          (_vm.player.price &&
            _vm.player.price !== 'undefined' &&
            _vm.player.price !== 'unknown') ||
          (_vm.player.salary &&
            _vm.player.salary !== 'undefined' &&
            _vm.player.salary !== 'unknown')
        )?_c('div',{class:{
          pricesContainer: true,
          hasManyPrices:
            _vm.player.price &&
            _vm.player.salary &&
            _vm.player.price != 'undefined' &&
            _vm.player.salary != 'undefined',
        }},[(_vm.player.price && _vm.player.price != 'undefined')?_c('div',{staticClass:"priceContainer",attrs:{"id":"price"}},[_vm._v(" "+_vm._s(_vm.player.price + "M.€")),_c('br'),(_vm.player.bookValue && _vm.player.bookValue != 'undefined')?_c('span',[_vm._v(_vm._s(_vm.$t("bookValue"))+" : "+_vm._s(_vm.player.bookValue)+"M.€")]):_vm._e()]):_vm._e(),(
            _vm.player.salary &&
            _vm.player.salary != 'undefined' &&
            _vm.player.salary != 'unknown'
          )?_c('div',{staticClass:"priceContainer",attrs:{"id":"salary"}},[_vm._v(" "+_vm._s(_vm.player.salary + "M.€")),_c('span',[_vm._v("/"+_vm._s(_vm.$t("year")))])]):_vm._e()]):_vm._e(),_c('div',{attrs:{"id":"endContract"}},[_vm._v(" "+_vm._s(_vm.$t("contractEnds"))+" : "),_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.player.endContract, false, "date")))]),(_vm.player.isLoan)?_c('span',{attrs:{"id":"isLoanPlayer"}},[_vm._v(_vm._s(_vm.$t("loanPlayer")))]):_vm._e()]),_c('b-row',{attrs:{"id":"physicalDatasContainer"}},[_c('b-col',[_c('div',[_vm._v(_vm._s(_vm.$t("birth")))]),_c('div',[_vm._v(_vm._s(_vm.formatDate(_vm.player.dateOfBirth, false, "date")))])]),_c('b-col',[_c('div',[_vm._v(_vm._s(_vm.$t("height")))]),(_vm.player.height && _vm.player.height != 'undefined')?_c('div',[_vm._v(" "+_vm._s(_vm.player.height)+" cm ")]):_c('div',[_vm._v(_vm._s(_vm.$t("undefined")))])]),_c('b-col',[_c('div',[_vm._v(_vm._s(_vm.$t("weight")))]),(_vm.player.weight && _vm.player.weight != 'undefined')?_c('div',[_vm._v(" "+_vm._s(_vm.player.weight)+" kg ")]):_c('div',[_vm._v(_vm._s(_vm.$t("undefined")))])]),_c('b-col',[_c('div',[_vm._v(_vm._s(_vm.$t("foot")))]),(_vm.player.foot && _vm.player.foot != 'undefined')?_c('div',[_vm._v(" "+_vm._s(_vm.getFoot(_vm.player.foot))+" ")]):_c('div',[_vm._v(_vm._s(_vm.$t("undefined")))])]),(
            _vm.player.manager &&
            _vm.player.manager != 'undefined' &&
            _vm.player.manager != 'unknown'
          )?_c('b-col',[_c('div',[_vm._v(_vm._s(_vm.$t("agent")))]),_c('div',[_vm._v(_vm._s(_vm.player.manager))])]):_vm._e()],1)],1),_c('b-col',{staticClass:"text-center",attrs:{"sm":"6","md":"3"}},[_c('div',{staticClass:"headerColumnTitle"},[_vm._v(" / "+_vm._s(_vm.getPrimaryPositionTranslation(_vm.player.primaryPosition))+" / ")]),_c('div',{attrs:{"id":"pitchContainer"}},[_c('img',{attrs:{"src":_vm.getPrimaryPositionId(_vm.player.primaryPosition),"alt":_vm.$t('pitchView')}})])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }