<template>
  <b-row class="fields-group">
    <b-col cols="12">
      <TextInput
        :label="$t('squadName')"
        name="title"
        :required="true"
        @change="setTitle"
        :placeholder="$t('instructions.defineASquadName')"
        @error="addPopinError"
        @unerror="removePopinError"
      />
    </b-col>
    <b-col cols="12">
      <DropdownInput
        :label="$t('selectSheet')"
        @change="changeCompo"
        @error="addPopinError"
        @unerror="removePopinError"
        :closeOnSelect="true"
        :options="composList"
        :placeholder="$t('allSheets')"
        :required="true"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DropdownInput from "../../../Fields/DropdownInput.vue";
import TextInput from "../../../Fields/TextInput.vue";
export default {
  components: { DropdownInput, TextInput },
  computed: {
    ...mapGetters("popin", ["getPopinShallowPayload"]),
    ...mapGetters(["getLocale", "getCompos"]),
    composList() {
      if (!this.getCompos) {
        return [];
      }
      return this.getCompos.map((compo) => {
        let label = compo.schema;
        if (compo.label) {
          label =
            this.getLocale === "fr"
              ? compo.schema + " version " + compo.label
              : compo.schema + " " + compo.label;
        }
        return { label, id: `${compo.schema}.${compo.version}` };
      });
    },
  },
  methods: {
    ...mapActions("popin", ["addPopinError", "removePopinError"]),
    changeCompo(pickedCompo) {
      const [compo, compoVersion] = pickedCompo.id.split(".");
      Object.assign(this.getPopinShallowPayload, {
        compo,
        compoVersion,
        compoLabel: pickedCompo.label,
      });
    },
    setTitle({ title }) {
      Object.assign(this.getPopinShallowPayload, { squadTitle: title });
    },
  },
};
</script>
<style lang="scss" scoped>
div.fields-group {
  padding: 24px;
  border-radius: 8px;
  background-color: #fafafa;
  gap: 16px;
}
</style>
