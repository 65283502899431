<template>
  <div class="radioInputsContainer">
    <b-row class="fields-header">
      <b-col>
        {{ $t(title) }}
      </b-col>
    </b-row>
    <b-row class="input-container">
      <b-col
        v-for="(option_slug, option_index) in options"
        :key="option_index"
        :class="{
          radioInputContainer: true,
          isActiveOption: option_slug === selectOptionLabel,
        }"
        @click="triggerRadioInput"
      >
        <div class="radioInputContent">
          <input
            type="radio"
            :name="radioName"
            :id="`${option_index}_${option_slug}`"
          />
          <label
            :for="`${option_index}_${option_slug}`"
            :data-slug="option_slug"
          >
            {{ $t(`${option_slug}`) }}
          </label>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "RadioInputs",
  props: {
    title: {
      type: String,
      required: true,
    },
    radioName: {
      type: String,
      required: true,
    },
    options: { type: Array, default: () => [], required: true },
  },
  emits: ["change"],
  data() {
    return {
      selectOptionLabel: "",
    };
  },
  methods: {
    triggerRadioInput(event) {
      if (event.srcElement.nodeName === "DIV") {
        event.target.querySelector("label").click();
        this.selectOptionLabel = event.target
          .querySelector("label")
          .getAttribute("data-slug");
      } else {
        this.selectOptionLabel = event.target.parentNode
          .querySelector("label")
          .getAttribute("data-slug");
      }
      const toDispatch = { [this.radioName]: this.selectOptionLabel };
      this.$store.dispatch("matchs/setPayload", toDispatch);
      this.$store.dispatch(
        "matchs/setSelectedMatchObservation",
        this.selectOptionLabel
      );
      this.$store.dispatch("popin/setShallowPayload", toDispatch);
      this.$emit("change", toDispatch);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";

.radioInputsContainer {
  margin-bottom: 30px;
}

.fields-header {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #060052;
  font-size: 1rem;
  font-weight: bold;
  padding: 0 0 24px 0;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.radioInputContainer {
  border-radius: 4px;
  font-size: 1rem;
  font-family: "Proxima Nova", sans-serif;
  padding: 0 15px;
}

.radioInputContent {
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 12px 5px;
  color: $blueDarkest;
  font-weight: 600;
  cursor: pointer;

  [type="radio"] {
    margin-right: 5px;
    margin-left: 5px;
    cursor: pointer;
  }
  label {
    margin-bottom: 0px;
    cursor: pointer;
  }
}

.isActiveOption {
  .radioInputContent {
    background-color: #f6f5ff;
    border: 2px solid #060052;
    box-shadow: 0px 0px 8px 0px rgba(6, 0, 82, 0.08);
  }
}
</style>
