<template>
  <div class="pitchPlayerContainer">
    <div v-if="player && player.id">
      <div
        :class="{
          pitchPlayerContent: true,
          isHovered,
          isShadowPlayer: isCurrentPlayerACurrentScopeShadowPlayer(player),
          isCompoPlayer: player.isStartingPlayer != undefined,
          isSelectedPlayer: player && player.id == getSelectedPlayer.id,
          isInBucket: isBucketPlayer(player),
        }"
        @click="selectPlayer(player)"
      >
        <div
          :class="{
            pitchPlayerJerseyContainer: true,
            homePlayer: team === 'home',
            awayPlayer: team === 'away',
          }"
        >
          <div class="notifsContainer">
            <div
              v-if="isGoaler && isGoaler.id"
              :class="{
                playerEventContainer: true,
                isGoaler,
              }"
            >
              <img
                v-if="isGoaler.action === 'Goal'"
                src="../assets/img/pictos/goal.svg"
                alt="commentImg"
              />
              <img
                v-if="isGoaler.action === 'Own Goal Against'"
                src="../assets/img/pictos/goal_csc.svg"
                alt="commentImg"
              />
            </div>
            <div
              v-if="
                hasCard.action === 'Yellow Card' ||
                hasCard.action === 'Red Card'
              "
              :class="{
                playerEventContainer: true,
                hasCard,
              }"
            >
              <img
                v-if="hasCard.action === 'Yellow Card'"
                v-b-popover.hover.top="
                  $t('cardTakenAt', { minute: hasCard.minute })
                "
                :id="`yellowCard_${hasCard.id}`"
                src="../assets/img/pictos/yellowCard.svg"
                alt="commentImg"
              />
              <img
                v-if="hasCard.action === 'Red Card'"
                v-b-popover.hover.top="
                  $t('cardTakenAt', { minute: hasCard.minute })
                "
                :id="`redCard_${hasCard.id}`"
                src="../assets/img/pictos/redCard.svg"
                alt="commentImg"
              />
            </div>
            <div
              :class="{
                playerEventContainer: true,
                isPlayerHavingNote: isPlayerHavingNote,
              }"
              v-if="isPlayerHavingNote"
            >
              <img
                src="../assets/img/pictos/playerComment.svg"
                alt="commentImg"
              />
            </div>
            <div
              v-if="hasBeenReplaced"
              :class="{
                playerEventContainer: true,
                hasBeenReplaced,
              }"
            >
              <img
                :id="`substitution_${hasBeenReplaced.id}`"
                src="../assets/img/pictos/substitution.svg"
                alt="commentImg"
              />
              <b-popover
                :target="`substitution_${hasBeenReplaced.id}`"
                triggers="hover focus"
                placement="top"
              >
                <b-row>
                  <b-col>
                    {{
                      $t("substitutionAskedAt", {
                        minute: hasBeenReplaced.minute,
                        substituerName:
                          hasBeenReplaced.replacement.name || "mimi",
                      })
                    }}
                  </b-col>
                </b-row>
              </b-popover>
            </div>
          </div>
          <div class="pitchPlayerJerseyContent">
            {{ player.jerseyNumber }}
          </div>
        </div>
        <div class="pitchPlayerName">
          {{ shorteredName(player, isHovered) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinPlayers from "../mixins/mixinPlayers";
export default {
  name: "PitchPlayer",
  mixins: [mixinPlayers],
  props: {
    player: Object,
    team: String,
    hasBeenReplaced: Object,
    gameNotes: Array,
    hasCard: Object,
    isGoaler: Object,
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    isPlayerHavingNote() {
      if (!this.gameNotes.length) {
        return false;
      }

      return this.hasNote(this.player.id, this.gameNotes);
    },
  },
  methods: {
    selectPlayer(player) {
      this.$store.dispatch("setSelectedPlayer", player);
    },
    shorteredName(player) {
      const splittedName = player.nickname
        ? player.nickname.split(" ")
        : player.name.split(" ");
      let shorteredName = "";
      if (splittedName.length > 1) {
        splittedName.forEach((namePart, index) => {
          if (index == 0) {
            shorteredName = namePart.charAt(0).toUpperCase() + ".";
          } else {
            shorteredName = shorteredName + " " + namePart;
          }
        });
      } else {
        shorteredName = player.nickname ? player.nickname : player.name;
      }

      return shorteredName;
    },
  },
};
</script>

<style scoped>
.pitchPlayerContainer {
  position: relative;
  margin-bottom: 10px;
}

.substituerContent .pitchPlayerContainer {
  margin-bottom: 24px;
}

.isInShadow .pitchPlayerJerseyContent,
.isInShadow .pitchPlayerName {
  background-color: #b34442;
  color: white;
}
.isSelectedPlayer .pitchPlayerJerseyContent,
.isSelectedPlayer .pitchPlayerName {
  color: #0b0790;
  background-color: #ffffff;
}

.pitchPlayerJerseyContainer {
  border-radius: 50px;
  width: 2rem;
  padding-top: 2.5rem;
  position: relative;
  cursor: pointer;
}

.notifsContainer {
  position: absolute;
  top: 0;
  width: 100%;
}

.notifsContainer > div {
  position: absolute;
  border-radius: 50px;
  background: white;
  width: 22px;
  height: 22px;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
}

.notifsContainer > div:first-of-type {
  top: -0px;
  right: -16px;
}

.notifsContainer > div:nth-child(2) {
  top: -13px;
  left: 14px;
}

.notifsContainer > div:nth-child(3) {
  top: -13px;
  left: -4px;
}

.notifsContainer > div:nth-child(4) {
  top: 0px;
  left: 23px;
}

.playerEventContainer.isGoaler img {
  height: 16px;
  width: 16px;
}

.notifsContainer img {
  max-width: 18px;
}

.hasCard img {
  max-width: 11px;
}

.isPlayerHavingNote img,
.hasBeenReplaced img {
  width: 16px;
  max-width: 16px;
  height: 14px;
  max-height: 14px;
}

.pitchPlayerJerseyContent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 5px;
  width: 2rem;
  height: 2rem;
  border-radius: 50px;
  color: #ffffff;
  font-weight: 400;
  font-family: "Proxima Nova", sans-serif;
  border: solid 1px #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.pitchPlayerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.isShadowPlayer .pitchPlayerJerseyContent,
.isShadowPlayer .pitchPlayerName {
  background-color: #b34442;
  color: white;
  border: 0;
}

.isSelectedPlayer.isShadowPlayer .pitchPlayerJerseyContent,
.isSelectedPlayer.isShadowPlayer .pitchPlayerName {
  background-color: white;
  color: #b34442;
  border: solid 2px #b34442;
  font-weight: bold;
}

.isInBucket .pitchPlayerJerseyContent,
.isInBucket .pitchPlayerName {
  background-color: #c88c28;
  color: white;
  border: solid 2px #c88c28;
  font-weight: bold;
}

.isSelectedPlayer.isInBucket .pitchPlayerJerseyContent,
.isSelectedPlayer.isInBucket .pitchPlayerName,
.isSelectedPlayer.isInBucket.isShadowPlayer .pitchPlayerJerseyContent,
.isSelectedPlayer.isInBucket.isShadowPlayer .pitchPlayerName {
  background-color: white;
  color: #c88c28;
}
.pitchPlayerContent:not(.isCompoPlayer) {
  opacity: 0.4;
}

.pitchPlayerContent:not(.isCompoPlayer):hover {
  opacity: 1;
}

.teamChangesContainer .pitchPlayerName {
  width: 100%;
}

.pitchPlayerName {
  white-space: nowrap;
  border: solid 1px white;
  border-radius: 20px;
  padding: 0 6px;
  max-width: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
  cursor: pointer;
  font-weight: 400;
  font-family: "Proxima Nova", sans-serif;
  text-align: center;
  width: 100%;
  font-size: 0.875rem;
}

.pitchPlayerContent:has(.pitchPlayerName:hover) .pitchPlayerJerseyContent,
.pitchPlayerName:hover,
.pitchPlayerJerseyContent:hover,
.pitchPlayerContent:has(.pitchPlayerJerseyContent:hover) .pitchPlayerName {
  background-color: #ffffff;
  color: #0b0790;
}

.pitchPlayerContent.isShadowPlayer:has(.pitchPlayerName:hover)
  .pitchPlayerJerseyContent,
.pitchPlayerContent.isShadowPlayer .pitchPlayerJerseyContent:hover,
.pitchPlayerContent.isSelectedPlayer.isShadowPlayer:has(.pitchPlayerName:hover)
  .pitchPlayerJerseyContent,
.isSelectedPlayer.isShadowPlayer .pitchPlayerName:hover,
.isSelectedPlayer.isShadowPlayer .pitchPlayerJerseyContent:hover,
.pitchPlayerContent.isSelectedPlayer.isShadowPlayer:has(.pitchPlayerJerseyContent:hover)
  .pitchPlayerName,
.pitchPlayerContent.isShadowPlayer .pitchPlayerName:hover,
.pitchPlayerContent.isShadowPlayer:has(.pitchPlayerJerseyContent:hover)
  .pitchPlayerName {
  background-color: white;
  color: #b34442;
  border: solid 2px #b34442;
  font-weight: bold;
}

.isSelectedPlayer.isInBucket .pitchPlayerName:hover,
.isSelectedPlayer.isInBucket .pitchPlayerJerseyContent:hover,
.pitchPlayerContent.isInBucket:has(.pitchPlayerName:hover)
  .pitchPlayerJerseyContent,
.pitchPlayerContent.isInBucket:has(.pitchPlayerJerseyContent:hover)
  .pitchPlayerName,
.pitchPlayerContent.isInBucket .pitchPlayerJerseyContent:hover,
.pitchPlayerContent.isInBucket .pitchPlayerName:hover {
  background-color: white;
  color: #c88c28;
}

.pitchPlayerContent.isSelectedPlayer.isInBucket.isShadowPlayer:has(.pitchPlayerName:hover)
  .pitchPlayerJerseyContent,
.isSelectedPlayer.isInBucket.isShadowPlayer .pitchPlayerName:hover,
.isSelectedPlayer.isInBucket.isShadowPlayer .pitchPlayerJerseyContent:hover,
.pitchPlayerContent.isSelectedPlayer.isInBucket.isShadowPlayer:has(.pitchPlayerJerseyContent:hover)
  .pitchPlayerName,
.pitchPlayerContent.isSelectedPlayer.isInBucket:has(.pitchPlayerName:hover)
  .pitchPlayerJerseyContent,
.pitchPlayerContent.isSelectedPlayer.isInBucket:has(.pitchPlayerJerseyContent:hover)
  .pitchPlayerName,
.pitchPlayerContent.isSelectedPlayer.isInBucket .pitchPlayerJerseyContent:hover,
.pitchPlayerContent.isSelectedPlayer.isInBucket .pitchPlayerName:hover {
  background-color: white;
  color: #c88c28;
  border: solid 2px #c88c28;
  font-weight: bold;
}

@media screen and (max-width: 576px) {
  .pitchPlayerJerseyContainer {
    width: 50px;
    height: 50px;
    padding-top: unset;
  }
}
</style>
