<template>
  <div id="app">
    <Navbar v-if="isNavbarVisible" />
    <router-view />
  </div>
</template>

<script type="text/javascript">
import Navbar from "./components/Navbar";
export default {
  components: {
    Navbar,
  },
  computed: {
    isNavbarVisible: function () {
      return this.$route.path !== "/login";
    },
  },
};
</script>
