<template>
  <BalancePlayers
    type="incomingPayroll"
    :summary="popinPayload.incomingSummary"
    :players="popinPayload.playersIn"
  />
</template>

<script>
import { mapGetters } from "vuex";
import BalancePlayers from "../../BalancePlayers.vue";
export default {
  components: { BalancePlayers },
  computed: {
    ...mapGetters("popin", ["popinPayload"]),
  },
};
</script>

<style lang="scss" scoped></style>
