import { popinLibraryFactory } from "../helpers/popin";

export default {
  namespaced: true,
  state: () => ({
    additionalContext: {},
    popinPayload: {},
    popinShallowPayload: {},
    isPopinOpened: false,
    variantsStack: [],
    previousPopinVariant: null,
    popinVariant: null,
    popinFieldsErrors: {},
    popinHasErrors: false,
    toastInfos: {
      isOpened: false,
      translationKey: "",
      payload: {},
    },
  }),
  mutations: {
    setAdditionalContext(state, additionalContext) {
      state.additionalContext = additionalContext;
    },
    openPopinVariant(state, { popinVariant, popinPayload, additionalContext }) {
      if (additionalContext) {
        state.additionalContext = {
          ...state.additionalContext,
          ...additionalContext,
        };
      }
      if (state.popinVariant) {
        state.previousPopinVariant = state.popinVariant;
      }
      state.popinFieldsErrors = {};
      state.popinVariant = popinVariant;
      state.isPopinOpened = true;
      if (popinPayload) {
        state.popinPayload = popinPayload;
        state.popinShallowPayload = structuredClone(popinPayload);
      }
      state.variantsStack.push({
        popinVariant,
        popinPayload: state.popinPayload,
        additionalContext: state.additionalContext,
      });
    },
    closePopin(state) {
      state.previousPopinVariant = state.popinVariant;
      state.popinVariant = null;
      state.isPopinOpened = false;
      state.variantsStack = [];
    },
    commitAddError(state, name) {
      state.popinFieldsErrors[name] = true;
      state.popinHasErrors = true;
    },
    commitRemoveError(state, name) {
      state.popinFieldsErrors[name] = false;
      state.popinHasErrors = Object.values(state.popinFieldsErrors).some(
        (value) => value
      );
    },
    commitOpenToast(state, { translationKey, payload }) {
      state.toastInfos = {
        translationKey,
        payload,
        isOpened: true,
      };
    },
    commitCloseToast(state) {
      state.toastInfos = {
        translationKey: "",
        payload: {},
        isOpened: false,
      };
    },
    goBack(state) {
      state.variantsStack.pop();
      const targetVariant = state.variantsStack.pop();
      this.commit("popin/openPopinVariant", targetVariant);
    },
    updateShallowPayload(state, payload) {
      state.popinShallowPayload = { ...state.popinShallowPayload, ...payload };
    },
    updatePayload(state, payload) {
      state.popinPayload = { ...state.popinPayload, ...payload };
    },
  },
  actions: {
    addPopinError({ commit }, name) {
      commit("commitAddError", name);
    },
    removePopinError({ commit }, name) {
      commit("commitRemoveError", name);
    },
    openToast({ commit }, { translationKey, payload, delay }) {
      commit("commitOpenToast", { translationKey, payload, delay });
      setTimeout(() => {
        commit("commitCloseToast");
      }, delay);
    },
    closePopin({ commit }) {
      commit("closePopin");
    },
    closeToast({ commit }) {
      commit("commitCloseToast");
    },
    setShallowPayload({ commit }, payload) {
      commit("updateShallowPayload", payload);
    },
    setPayload({ commit }, payload) {
      commit("updatePayload", payload);
    },
  },
  getters: {
    getToastInfos: (state) => {
      return state.toastInfos;
    },
    getPopinVariant: (state) => {
      return state.popinVariant;
    },
    popinHasErrors: (state) => {
      return state.popinHasErrors;
    },
    popinPayload: (state) => {
      return state.popinPayload;
    },
    previousPopinVariant: (state) => {
      return state.previousPopinVariant;
    },
    getPopinShallowPayload: (state) => {
      return state.popinShallowPayload;
    },
    additionalContext: (state) => {
      return state.additionalContext;
    },
    isPopinOpened: (state) => {
      return state.isPopinOpened;
    },
    popinInfos: (state, getters, rootState, rootGetters) => {
      const popinConfigs = popinLibraryFactory(
        state,
        getters,
        rootState,
        rootGetters
      );
      const returnValue = popinConfigs[state.popinVariant];
      if (!returnValue) {
        return {};
      }
      return returnValue;
    },
  },
};
