<template>
  <div>
    <b-row class="fields-group">
      <b-col>
        <b-row class="fields-header">
          <b-col>
            {{ $t("matchInfo") }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="pb-2">
            <TextInput
              @error="addPopinError"
              @unerror="removePopinError"
              @change="setNewGameData"
              :label="$t('homeClub')"
              value=""
              :datalist="clubDatalist"
              example="Ex: Botafogo"
              :placeholder="$t('searchBy.placeholderFindByClub')"
              name="homeTeam"
            />
          </b-col>
          <b-col cols="12" md="6" class="pb-2">
            <TextInput
              @error="addPopinError"
              @unerror="removePopinError"
              @change="setNewGameData"
              :label="$t('awayClub')"
              value=""
              example="Ex: Santos"
              :datalist="clubDatalist"
              :placeholder="$t('searchBy.placeholderFindByClub')"
              name="awayTeam"
            />
          </b-col>
          <b-col cols="12" md="6" class="pb-2">
            <TextInput
              @change="setNewGameData"
              :label="$t('country/Continent')"
              value=""
              :datalist="countryDatalist"
              :placeholder="$t('searchBy.placeholderFindByContinent')"
              name="country"
            />
          </b-col>
          <b-col cols="12" md="6" class="pb-2">
            <DateInput
              @error="addPopinError"
              @unerror="removePopinError"
              @change="setNewGameData"
              :label="$t('matchDate')"
              :max="getMaxDate"
              :min="getMinDate"
              :value="0"
              name="date"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="fields-group">
      <b-col>
        <b-row>
          <b-col cols="12" md="12" class="pb-2">
            <RadioInputs
              :title="createGameRadioOptionsTitle"
              :options="createGameRadioOptions"
              radioName="observation"
              @change="setNewGameData"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DateInput from "../../../Fields/DateInput.vue";
import TextInput from "../../../Fields/TextInput.vue";
import RadioInputs from "../../../Fields/RadioInputs.vue";
import moment from "moment";
import mixinScouts from "../../../../mixins/mixinScouts";
export default {
  mixins: [mixinScouts],
  name: "CreateGame",
  components: { DateInput, TextInput, RadioInputs },
  data() {
    return {
      createGameRadioOptions: ["stadium", "video", "data.dot"],
      clubDatalist: [],
      countryDatalist: [],
      newMatch: {
        homeTeam: "",
        awayTeam: "",
        country: "",
        date: "",
        observation: "",
      },
    };
  },
  computed: {
    ...mapGetters("matchs", ["getCountries", "getClubs"]),
    createGameRadioOptionsTitle() {
      return "gameViewlegend.observationType";
    },
    getCurrentTimestamp() {
      return moment().startOf("day").unix();
    },
    getMinDate() {
      return moment().subtract(4, "months").startOf("day").toDate();
    },
    getMaxDate() {
      return moment().add(1, "weeks").startOf("day").toDate();
    },
    minDate() {
      return moment().startOf("day").toDate();
    },
  },
  methods: {
    ...mapActions("popin", ["addPopinError", "removePopinError"]),
    setNewGameData(value) {
      this.newMatch[Object.keys(value)[0]] = Object.values(value)[0];
    },
    generateDatalist(item) {
      const self = this;
      switch (item) {
        case "club":
          self.clubDatalist = self.lodash.orderBy(
            self.getClubs.map((item, index) => {
              return { label: item, id: index };
            }),
            "label",
            "asc"
          );
          break;
        case "country":
          self.countryDatalist = self.lodash.orderBy(
            self.getCountries.map((item, index) => {
              return { label: item, id: index };
            }),
            "label",
            "asc"
          );
          break;
        default:
          break;
      }
    },
  },
  watch: {
    getCountries: {
      handler() {
        this.generateDatalist("country");
      },
      immediate: true,
      deep: true,
    },
    getClubs: {
      handler() {
        this.generateDatalist("club");
      },
      immediate: true,
      deep: true,
    },
    newMatch: {
      handler(newValue) {
        if (
          newValue.awayTeam?.length &&
          newValue.homeTeam?.length &&
          newValue.observation?.length &&
          newValue.date?.toString().length
        ) {
          this.$store.dispatch("popin/setShallowPayload", newValue);
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
div.fields-group {
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 48px;
  background-color: #fafafa;
}

div.fields-header {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #060052;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 0 0 24px 0;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 24px;
  text-transform: uppercase;
}
</style>
