import NotificationComponent from "../components/Notification";

export default {
  data() {
    return {
      notification: {
        display: false,
        type: "",
        details: "",
      },
    };
  },
  components: { NotificationComponent },
  methods: {
    toggleNotification(notificationType, notificationDetails) {
      this.notification = notificationDetails.message
        ? {
            display: true,
            type: notificationType,
            details: this.$t(
              notificationDetails.message,
              notificationDetails.params
            ),
          }
        : {
            display: true,
            type: notificationType,
            details: this.$t(notificationDetails),
          };
      setTimeout(() => {
        this.notification.display = false;
      }, 6000);
    },
  },
};
