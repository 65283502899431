import db from "../db";
export default {
  data() {},
  methods: {
    async createGame(game) {
      if (game.id?.length) {
        db.collection("V3games").doc(game.id).set(game, { merge: true });
      } else {
        await db
          .collection("V3games")
          .add(game)
          .then((gameResponse) => {
            db.collection("V3games")
              .doc(gameResponse.id)
              .set({ id: gameResponse.id.toString() }, { merge: true });
          });
      }
    },
  },
};
