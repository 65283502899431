import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations, firestoreAction } from "vuexfire";
import db from "../db.js";
import axios from "axios";
import i18n from "../i18n.js";
import moment from "moment";
import lodash from "lodash";
import squadStore from "./squad.store.js";
import matchsStore from "./matchs.store.js";
import popinStore from "./popin.store.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    playersSearchFilters: {},
    initialScoutId: null,
    currentScout: {},
    currentScoutId: null,
    currentScoutToken: null,
    currentScoutGames: [],
    currentScoutPlayers: [],
    currentScoutShadows: [],
    scouts: [],
    scoutsPlayers: [],
    bucketPlayers: [],
    games: [],
    scoutsShadows: [],
    lastPlayersUpdated: [],
    teamsFromApi: [],
    playersFromApi: [],
    addedPlayers: [],
    locale: i18n.locale,
    gamesPositions: [],
    shadowsPositions: [],
    compos: [],
    companies: [],
    scoutsPlayersByGamePosition: [],
    currentScoutPlayersByGamePosition: [],
    notes: [],
    scoutsForCurrentUser: [],
    managersForCurrentUser: [],
    selectedPlayer: {},
  },
  mutations: {
    ...vuexfireMutations,
    updatePlayerSearchFilters(state, filters) {
      state.playersSearchFilters = filters.data;
    },
    updateInitialScoutId(state, id) {
      state.initialScoutId = id;
    },
    updateScoutId(state, id) {
      state.currentScoutId = id;
    },
    updateLanguage(state, payload) {
      i18n.locale = payload;
      state.locale = payload;
      moment.locale(payload);
    },
    updateCurrentScoutToken(state, payload) {
      state.currentScoutToken = payload;
    },
    updateLastPlayersUpdated(state, payload) {
      state.lastPlayersUpdated = payload;
    },
    updateTeamsFromApi(state, teams) {
      state.teamsFromApi = teams;
    },
    updatePlayersFromApi(state, players) {
      state.playersFromApi = players;
    },
    updatePlayersByPosition(state, payload) {
      state.scoutsPlayersByGamePosition = payload;
    },
    updateCurrentScoutPlayersByPosition(state, payload) {
      state.currentScoutPlayersByGamePosition = payload;
    },
    setScoutsForCurrentUser(state, payload) {
      state.scoutsForCurrentUser = payload;
    },
    setManagersForCurrentUser(state, payload) {
      state.managersForCurrentUser = payload;
    },
    selectPlayer(state, payload) {
      if (!payload) {
        state.selectedPlayer = {};
      } else {
        state.selectedPlayer = payload;
      }
    },
  },
  actions: {
    setPlayerSearchFilters({ commit }, payload) {
      commit("updatePlayerSearchFilters", payload);
    },
    setInitialScoutId({ commit }, payload) {
      commit("updateInitialScoutId", payload);
    },
    setCurrentScoutId({ commit }, payload) {
      commit("updateScoutId", payload.uid);
    },
    setLanguage({ commit }, payload) {
      commit("updateLanguage", payload);
    },
    setCurrentScoutToken({ commit }, payload) {
      commit("updateCurrentScoutToken", payload);
    },
    setLastPlayersUpdated({ commit }, payload) {
      commit("updateLastPlayersUpdated", payload);
    },
    bindCurrentScout: firestoreAction(({ bindFirestoreRef, state }) => {
      return bindFirestoreRef(
        "currentScout",
        db.collection("V3scouts").doc(state.currentScoutId)
      );
    }),
    bindCurrentScoutGames: firestoreAction(({ bindFirestoreRef, state }) => {
      return bindFirestoreRef(
        "currentScoutGames",
        db
          .collection("V3scouts")
          .doc(state.currentScoutId)
          .collection("games")
          .orderBy("date")
      );
    }),
    bindCurrentScoutPlayers: firestoreAction(({ bindFirestoreRef, state }) => {
      return bindFirestoreRef(
        "currentScoutPlayers",
        db
          .collection("V3scouts")
          .doc(state.currentScoutId)
          .collection("players")
          .orderBy("updatedAt", "desc")
      );
    }),
    bindCurrentScoutShadows: firestoreAction(({ bindFirestoreRef, state }) => {
      return bindFirestoreRef(
        "currentScoutShadows",
        db
          .collection("V3scouts")
          .doc(state.currentScoutId)
          .collection("shadows")
          .orderBy("id", "asc")
      );
    }),
    bindNotes: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "notes",
        db.collection("V3notes").orderBy("id", "desc")
      );
    }),
    bindScouts: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "scouts",
        db.collection("V3scouts").orderBy("lastname")
      );
    }),
    bindScoutsShadows: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "scoutsShadows",
        db.collection("V3shadows").orderBy("date", "desc")
      );
    }),
    bindGames: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "games",
        db.collection("V3games").orderBy("date", "desc")
      );
    }),
    bindScoutsPlayers: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "scoutsPlayers",
        db
          .collection("V3players")
          .where("gender", "==", "male")
          .where("isArchivePlayer", "==", false)
          .orderBy("updatedAt", "desc")
      );
    }),
    bindBucketPlayers: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "bucketPlayers",
        db
          .collection("V3players")
          .where("gender", "==", "male")
          .where("isInBucket", "==", true)
      );
    }),
    bindGamesPositions: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "gamesPositions",
        db.collection("V3gamesPositions").orderBy("position")
      );
    }),
    bindShadowsPositions: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "shadowsPositions",
        db.collection("V3shadowsPositions")
      );
    }),
    bindCompos: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "compos",
        db.collection("V3compos").orderBy("schema", "asc")
      );
    }),
    bindCompanies: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "companies",
        db.collection("V3companies").orderBy("weight", "desc")
      );
    }),
    bindAddedPlayers: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "addedPlayers",
        db
          .collection("V3players")
          .where("gender", "==", "male")
          .where("isReferral", "==", false)
      );
    }),
    loadPlayersByPosition({ commit, getters }) {
      const playersByPosition = [];
      const availablePlayers = getters.getPlayers;
      for (const bucketPlayer of getters.getBucketPlayers) {
        if (
          lodash.indexOf(availablePlayers, function (o) {
            return o.id == bucketPlayer.id;
          }) == -1
        ) {
          availablePlayers.push(bucketPlayer);
        }
      }
      for (const gamePosition of getters.getGamesPosition) {
        playersByPosition[gamePosition.id] = [];
        db.collection("V3gamesPositions")
          .doc(gamePosition.id.toString())
          .collection("players")
          .get()
          .then((playersForPositionSnapshot) => {
            playersForPositionSnapshot.docs.map((playersForPositionDoc) => {
              if (playersForPositionDoc.exists) {
                const playersForPositionDatas = playersForPositionDoc.data();
                if (
                  !lodash.find(playersByPosition[gamePosition.id], {
                    id: playersForPositionDatas.id,
                  })
                ) {
                  if (
                    lodash.filter(availablePlayers, {
                      id: playersForPositionDatas.id,
                    })[0]
                  ) {
                    playersByPosition[gamePosition.id].push(
                      lodash.filter(availablePlayers, {
                        id: playersForPositionDatas.id,
                      })[0]
                    );
                  }
                }
              }
            });
          });
      }
      for (const bucketPlayer of getters.getBucketPlayers) {
        if (bucketPlayer.primaryPosition) {
          const matchingPosition = lodash.filter(
            getters.getGamesPosition,
            function (o) {
              return o.label == bucketPlayer.primaryPosition;
            }
          );
          playersByPosition[matchingPosition[0].id].push(
            lodash.filter(getters.getBucketPlayers, {
              id: bucketPlayer.id,
            })[0]
          );
        }
      }
      commit("updatePlayersByPosition", playersByPosition);
    },
    loadCurrentScoutPlayersByPosition({ commit, getters }) {
      const currentScoutPlayersByPosition = [];
      getters.getGamesPosition.forEach((gamePosition) => {
        currentScoutPlayersByPosition[gamePosition.id] = [];
        db.collection("V3gamesPositions")
          .doc(gamePosition.id.toString())
          .collection("players")
          .where("addedBy", "==", getters.getCurrentScout.id)
          .get()
          .then((currentScoutPlayersForPositionSnapshot) => {
            currentScoutPlayersForPositionSnapshot.docs.map(
              (currentScoutPlayersForPositionDoc) => {
                if (currentScoutPlayersForPositionDoc.exists) {
                  const currentScoutPlayersForPositionDatas =
                    currentScoutPlayersForPositionDoc.data();
                  if (
                    !lodash.find(
                      currentScoutPlayersByPosition[gamePosition.id],
                      {
                        id: currentScoutPlayersForPositionDatas.id,
                      }
                    ) &&
                    lodash.filter(getters.getCurrentScoutPlayers, {
                      id: currentScoutPlayersForPositionDatas.id,
                    })[0]
                  ) {
                    currentScoutPlayersByPosition[gamePosition.id].push(
                      lodash.filter(getters.getCurrentScoutPlayers, {
                        id: currentScoutPlayersForPositionDatas.id,
                      })[0]
                    );
                  }
                }
              }
            );
          });
      });
      commit(
        "updateCurrentScoutPlayersByPosition",
        currentScoutPlayersByPosition
      );
    },
    loadTeams({ commit }) {
      axios
        .get("https://ds-ol-scout-app-py-api-3.azurewebsites.net/teams/")
        .then((response) => {
          var teams = response.data.sort((a, b) =>
            a.Team > b.Team ? 1 : b.Team > a.Team ? -1 : 0
          );
          commit("updateTeamsFromApi", teams);
        });
    },
    loadPlayers({ commit }) {
      axios
        .get("https://ds-ol-scout-app-py-api-3.azurewebsites.net/players/")
        .then((response) => {
          var players = response.data.sort((a, b) =>
            a.Team > b.Team ? 1 : b.Name > a.Name ? -1 : 0
          );
          var playersNewFormat = players.map((player) => {
            return {
              name: player.Name,
              id: player.player_id,
              currentTeam: player.team_name,
            };
          });
          commit("updatePlayersFromApi", playersNewFormat);
        });
    },
    setSelectedPlayer({ commit }, payload) {
      commit("selectPlayer", payload);
    },
    setScoutsForCurrentUser({ commit, getters, dispatch }) {
      let filteredScoutsList = getters.getScouts;
      if (
        getters.getCurrentScout?.isManager &&
        getters.getCurrentScout?.company
      ) {
        if (!getters.getCompanies.length) {
          dispatch("bindCompanies");
        } else {
          if (getters.getCurrentScout.company === getters.getCompanies[0].id) {
            filteredScoutsList = filteredScoutsList.filter((scout) => {
              return !scout.isManager;
            });
            commit("setScoutsForCurrentUser", filteredScoutsList);
          } else {
            filteredScoutsList = filteredScoutsList.filter((scout) => {
              return (
                !scout.isManager &&
                getters.getCurrentScout.company === scout.company
              );
            });
            commit("setScoutsForCurrentUser", filteredScoutsList);
          }
        }
      }
    },
    setManagersForCurrentUser({ commit, getters, dispatch }) {
      let filteredManagersList = getters.getScouts;
      if (
        getters.getCurrentScout?.isManager &&
        getters.getCurrentScout?.company
      ) {
        if (!getters.getCompanies.length) {
          dispatch("bindCompanies");
        } else {
          if (getters.getCurrentScout.company === getters.getCompanies[0].id) {
            filteredManagersList = filteredManagersList.filter((scout) => {
              return scout.isManager && !scout.ghost;
            });

            commit("setManagersForCurrentUser", filteredManagersList);
          } else {
            filteredManagersList = filteredManagersList.filter((scout) => {
              return (
                scout.isManager &&
                getters.getCurrentScout.company === scout.company &&
                !scout.ghost
              );
            });
            commit("setManagersForCurrentUser", filteredManagersList);
          }
        }
      }
    },
  },
  getters: {
    getShadows: (state) => {
      return state.scoutsShadows;
    },
    getCurrentScopeShadows: (state) => {
      return state.scoutsShadows.filter((shadow) => {
        if (
          state.companies.length &&
          state.currentScout?.isManager &&
          state.currentScout?.company === state.companies[0].id
        ) {
          return !shadow.isGhost;
        } else if (state.currentScout?.isManager) {
          return (
            !shadow.isGhost && shadow.usedInClub === state.currentScout?.company
          );
        }
        return !shadow.isGhost && shadow.addedBy === state.currentScout?.id;
      });
    },
    getCurrentScopeShadowsPlayers: (state) => {
      return lodash.uniqBy(
        state.scoutsShadows
          .filter((shadow) => {
            if (
              state.companies.length &&
              state.currentScout?.isManager &&
              state.currentScout?.company === state.companies[0].id
            ) {
              return !shadow.isGhost;
            }
            return (
              !shadow.isGhost &&
              shadow.usedInClub === state.currentScout?.company
            );
          })
          .map((shadow) => {
            if (!shadow.players) {
              return [];
            }
            return shadow.players;
          })
          .flat(),
        "id"
      );
    },
    getCurrentScopeScouts: (state) => {
      return state.scouts.filter((scout) => {
        if (
          state.companies.length &&
          state.currentScout.isManager &&
          state.currentScout.company === state.companies[0].id
        ) {
          return !scout.isGhost;
        } else if (state.currentScout.isManager) {
          return !scout.isGhost && scout.company === state.currentScout.company;
        }
        return scout.id === state.currentScout.id;
      });
    },
    getGamesPosition: (state) => {
      return state.gamesPositions;
    },
    getPlayers: (state) => {
      return state.scoutsPlayers;
    },
    getState: (state) => {
      return state;
    },
    getBucketPlayers: (state) => {
      return state.bucketPlayers;
    },
    getCurrentScout: (state) => {
      return state.currentScout;
    },
    getCurrentScoutPlayers: (state) => {
      return state.currentScoutPlayers;
    },
    getCurrentScoutGames: (state) => {
      return state.currentScoutGames;
    },
    getNotes: (state) => {
      return state.notes;
    },
    getScouts: (state) => {
      return state.scouts;
    },
    getLocale: (state) => {
      return state.locale;
    },
    getGames: (state) => {
      return state.games;
    },
    getShadowsPositions: (state) => {
      return state.shadowsPositions;
    },
    getCompos: (state) => {
      return state.compos;
    },
    getCompanies: (state) => {
      return state.companies;
    },
    getInitialScoutId: (state) => {
      return state.initialScoutId;
    },
    getScoutsForCurrentUser: (state) => {
      return state.scoutsForCurrentUser;
    },
    getManagersForCurrentUser: (state) => {
      return state.managersForCurrentUser;
    },
    getPlayersFilters: (state) => {
      return state.playersSearchFilters;
    },
    getSelectedPlayer: (state) => {
      return state.selectedPlayer;
    },
    getCurrentCompanyTeams(state) {
      return (
        state.companies.filter((company) => {
          return company.id === state.currentScout?.company;
        })[0]?.teams || []
      );
    },
    getPlayersForCurrentCompany(state, getters) {
      if (
        getters.getCurrentCompanyTeams?.length <= 10 &&
        getters.getPlayers?.length
      ) {
        return getters.getPlayers.filter((player) => {
          return (
            player.currentTeam &&
            getters.getCurrentCompanyTeams.includes(player.currentTeam)
          );
        });
      }
      return [];
    },
  },
  modules: {
    squad: squadStore,
    matchs: matchsStore,
    popin: popinStore,
  },
});
