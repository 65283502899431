<template>
  <div class="available-players-list">
    <div class="title-line">
      <span class="title">{{ $t("squad.availablePlayers") }}</span>
      <PlayersCountPill class="pill" :playersCount="players.length" />
    </div>
    <div class="players-list">
      <PlayersList listVariant="available" :players="players" />
    </div>
  </div>
</template>
<script>
import PlayersCountPill from "./PlayersCountPill.vue";
import PlayersList from "./PlayersList.vue";

export default {
  name: "AvailablePlayersList",
  components: { PlayersCountPill, PlayersList },
  props: {
    players: Array,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";
span.title {
  color: $blueDarkest;
}

div.available-players-list {
  display: flex;
  width: 100%;
  flex-direction: column;
}

div.title-line {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  width: 100%;
  margin-bottom: 24px;
  > .title {
    align-self: flex-start;
    font-weight: 700;
    flex-grow: 1;
  }
  > .pill {
    align-self: flex-end;
    margin-right: 12px;
  }
}
</style>
