<template>
  <div class="input-group-container">
    <div class="input-group-header">
      <span class="input-label">{{ label }}</span>
      <span class="input-example">{{ example }}</span>
    </div>
    <div class="input-container">
      <input
        :value="computedValue"
        autocomplete="off"
        :placeholder="placeholder"
        type="text"
        :disabled="(Array.isArray(datalist) && !datalist.length) || disabled"
        :class="{
          errored: errored,
        }"
        @input="handleChange"
        :list="`datalist-${name}`"
      />
      <datalist
        v-if="datalist?.length && !disabled"
        class="datalistContainer"
        :id="`datalist-${name}`"
      >
        <option v-for="(listItem, listIndex) in datalist" :key="listIndex">
          {{ listItem.label }}
        </option>
      </datalist>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: "" },
    value: { type: String },
    disabled: { type: Boolean },
    required: { type: Boolean, default: false },
    example: { type: String, default: "" },
    placeholder: { type: String, default: "", required: true },
    name: { type: String },
    datalist: {
      type: Array,
      validator: (list) =>
        Array.isArray(list) && list.every((item) => item.label != null),
    },
  },
  data() {
    return {
      errored: false,
      computedValue: this.value,
    };
  },
  emits: ["change", "error", "unerror"],
  mounted() {
    if (this.required && !this.value) {
      this.setErrored();
    }
  },
  methods: {
    setErrored() {
      this.errored = true;
      this.$emit("error", this.name);
    },
    setNonErrored() {
      this.errored = false;
      this.$emit("unerror", this.name);
    },
    handleChange({ data, target }) {
      const emptiedField = !data && !target.value;
      if (emptiedField) {
        target.value = "";
        this.computedValue = "";
      } else {
        this.computedValue = target.value;
      }
    },
  },
  watch: {
    computedValue: {
      immediate: true,
      handler(value) {
        this.setNonErrored();
        this.errored = false;
        if (value === "") {
          if (this.required) {
            this.setErrored();
          }
          this.$emit("change", { [this.name]: "" });
          return;
        }
        const toReturn = {};
        toReturn[this.name] = value;
        this.$emit("change", toReturn);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";
div.input-group-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
}

div.input-group-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

span.input-label {
  color: #111111;
  font-family: "Proxima Nova", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}
span.input-example {
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #7a7a7a;
  font-family: "Proxima Nova", sans-serif;
}

input {
  height: 40px;
  border: solid 1px #e6e6e6;
  border-radius: 4px;
  padding: 12px;
  font-weight: 700;
  font-size: 0.875rem;
  color: $blueDarkest;
  box-shadow: none;
  width: 100%;
  &:focus {
    border: solid 2px $blueDarkest;
    box-shadow: 0px 0px 8px 0px rgba(6, 0, 82, 0.08) !important;
  }
  &:hover:not(:focus):not(.errored) {
    border: solid 1px #e6e6e6;
    box-shadow: 0px 0px 8px 0px rgba(6, 0, 82, 0.08);
  }
  &.errored {
    border: solid 2px #b34442;
  }
}

div.input-container {
  position: relative;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

input[disabled] {
  background-color: #e6e6e6;
  cursor: not-allowed;
}
</style>
