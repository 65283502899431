<template>
  <ActionBase
    @click="handleClick"
    :disabled="disabled"
    :label="$t(translationKey)"
    :primary="primary"
  ></ActionBase>
</template>

<script>
import { mapMutations } from "vuex";
import ActionBase from "./ActionBase.vue";
export default {
  components: { ActionBase },
  props: {
    to: { type: String, required: true },
    translationKey: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    primary: { type: Boolean, default: false },
  },
  methods: {
    ...mapMutations("popin", ["openPopinVariant"]),
    handleClick() {
      this.openPopinVariant({
        popinVariant: this.to,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
