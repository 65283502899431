<template>
  <div
    id="gamePlayersContainer"
    ref="gamePlayersContainer"
    v-if="getSelectedPlayer.id"
    :key="getSelectedPlayer.id"
  >
    <div class="gameBlocTitle" id="gamePlayerObservationTitle">
      <h3 class="gamePlayerObservationTitle">{{ getSelectedPlayer.name }}</h3>
      <div class="gamePlayerStatusContainer">
        <span id="gamePlayerIsBucket" v-if="isBucketPlayer(getSelectedPlayer)">
          {{ $t("gameViewlegend.bucketPlayer") }}
        </span>
        <span
          id="gamePlayerIsInShadow"
          v-if="isCurrentPlayerACurrentScopeShadowPlayer(getSelectedPlayer)"
        >
          {{ $t("gameViewlegend.shadowPlayer") }}
        </span>
      </div>
    </div>
    <div
      v-if="getCurrentScout?.isManager && !playerGlobalComment.length"
      class="noCommentedPlayer"
    >
      <p>{{ $t("gameViewlegend.playerWasntCommented") }}</p>
    </div>
    <div v-else>
      <div class="gamePlayerCommentsContainer">
        <div id="globalCommentContainer">
          <p
            class="playerCommentsLabel"
            v-if="
              (getCurrentScout?.isManager && playerGlobalComment.length) ||
              getCurrentScout?.id === game?.addedBy
            "
          >
            {{ $t("gameViewlegend.globalPlayerObservationLabel") }}
          </p>
          <textarea
            v-if="getCurrentScout?.id === game?.addedBy && isEditable"
            :placeholder="$t('yourComment')"
            v-model="playerGlobalComment"
            class="playerComment"
          ></textarea>
          <p
            class="playerCommentsContent"
            v-else-if="playerGlobalComment.length"
          >
            {{ playerGlobalComment }}
          </p>
        </div>
      </div>
      <div
        class="gamePlayerCommentsContainer"
        v-if="
          (getCurrentScout?.isManager && scoreSelected !== '') ||
          !getCurrentScout?.isManager
        "
      >
        <p class="playerCommentsLabel">
          {{ $t("gameViewlegend.playerScore") }}
        </p>
        <div class="scoresContainer">
          <span
            :class="{
              ABCDNoteContainer: true,
              selected: scoreSelected == 'A',
              editable: isUserTheAuthor,
            }"
            v-on:click="
              isUserTheAuthor && isEditable ? setPlayerABCDNote('A') : null
            "
          >
            A
          </span>

          <span
            :class="{
              ABCDNoteContainer: true,
              selected: scoreSelected == 'B',
              editable: isUserTheAuthor,
            }"
            @click="
              isUserTheAuthor && isEditable ? setPlayerABCDNote('B') : null
            "
          >
            B
          </span>

          <span
            :class="{
              ABCDNoteContainer: true,
              selected: scoreSelected == 'C',
              editable: isUserTheAuthor,
            }"
            @click="
              isUserTheAuthor && isEditable ? setPlayerABCDNote('C') : null
            "
          >
            C
          </span>

          <span
            :class="{
              ABCDNoteContainer: true,
              selected: scoreSelected == 'D',
              editable: isUserTheAuthor,
            }"
            @click="
              isUserTheAuthor && isEditable ? setPlayerABCDNote('D') : null
            "
          >
            D
          </span>
        </div>
      </div>
      <div
        v-if="
          (isUserTheAuthor &&
            (isEditable || (!isEditable && tacticalComment.length))) ||
          (getCurrentScout?.isManager && tacticalComment.length)
        "
        class="gamePlayerCommentsContainer"
      >
        <p
          :class="{
            playerCommentsLabel: true,
          }"
        >
          {{ $t("gameViewlegend.tacticalLegend") }}
          <span
            v-if="!allFieldsRequired && isUserTheAuthor"
            class="optionalLabel"
            >{{ $t("gameViewlegend.optional") }}</span
          >
        </p>
        <textarea
          v-if="isUserTheAuthor && isEditable"
          :placeholder="$t('yourComment')"
          v-model="tacticalComment"
          class="playerComment"
        ></textarea>
        <p v-else class="playerCommentsContent">
          {{ tacticalComment }}
        </p>
      </div>
      <div
        v-if="
          (isUserTheAuthor &&
            (isEditable || (!isEditable && mentalComment.length))) ||
          (getCurrentScout?.isManager && mentalComment.length)
        "
        class="gamePlayerCommentsContainer"
      >
        <p
          :class="{
            playerCommentsLabel: true,
          }"
        >
          {{ $t("gameViewlegend.mentalLegend") }}
          <span
            v-if="!allFieldsRequired && isUserTheAuthor"
            class="optionalLabel"
            >{{ $t("gameViewlegend.optional") }}</span
          >
        </p>
        <textarea
          v-if="isUserTheAuthor && isEditable"
          :placeholder="$t('yourComment')"
          v-model="mentalComment"
          class="playerComment"
        ></textarea>
        <p v-else class="playerCommentsContent">
          {{ mentalComment }}
        </p>
      </div>
      <div
        v-if="
          (isUserTheAuthor &&
            (isEditable || (!isEditable && athleticalComment.length))) ||
          (getCurrentScout?.isManager && athleticalComment.length)
        "
        class="gamePlayerCommentsContainer"
      >
        <p
          :class="{
            playerCommentsLabel: true,
          }"
        >
          {{ $t("gameViewlegend.athleticalLegend") }}
          <span
            v-if="!allFieldsRequired && isUserTheAuthor"
            class="optionalLabel"
            >{{ $t("gameViewlegend.optional") }}</span
          >
        </p>
        <textarea
          v-if="isUserTheAuthor && isEditable"
          :placeholder="$t('yourComment')"
          v-model="athleticalComment"
          class="playerComment"
        ></textarea>
        <p v-else class="playerCommentsContent">
          {{ athleticalComment }}
        </p>
      </div>
      <div
        v-if="
          (isUserTheAuthor &&
            (isEditable || (!isEditable && technicalComment.length))) ||
          (getCurrentScout?.isManager && technicalComment.length)
        "
        class="gamePlayerCommentsContainer"
      >
        <p
          :class="{
            playerCommentsLabel: true,
          }"
        >
          {{ $t("gameViewlegend.technicalLegend") }}
          <span
            v-if="!allFieldsRequired && isUserTheAuthor"
            class="optionalLabel"
            >{{ $t("gameViewlegend.optional") }}</span
          >
        </p>
        <textarea
          v-if="isUserTheAuthor && isEditable"
          :placeholder="$t('yourComment')"
          v-model="technicalComment"
          class="playerComment"
        ></textarea>
        <p v-else class="playerCommentsContent">
          {{ technicalComment }}
        </p>
      </div>
      <div
        v-if="isUserTheAuthor && isEditable"
        id="gamePlayerValidateGameNoteContainer"
      >
        <button
          :class="{
            formSubmitButton: true,
            disabled: isSubmitButtonDisabled,
          }"
          @click="submitNewComments"
          :disabled="isSubmitButtonDisabled"
        >
          {{ $t("gameViewlegend.savePlayerObservation") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import mixinPlayers from "../../mixins/mixinPlayers";

export default {
  name: "GamePlayerObservation",
  mixins: [mixinPlayers],
  data() {
    return {
      playerGlobalComment: "",
      technicalComment: "",
      tacticalComment: "",
      mentalComment: "",
      athleticalComment: "",
      allFieldsRequired: true,
      scoreSelected: "",
    };
  },
  emits: ["setABCDScore", "submitGamePlayerNote"],
  props: {
    game: { required: true },
    isEditable: {
      type: Boolean,
      default: true,
    },
    selectedPlayerABCDNote: {
      type: String,
    },
    gamePlayerNote: {
      type: String,
      required: true,
    },
    tacticalPlayerNote: {
      type: String,
    },
    mentalPlayerNote: {
      type: String,
    },
    athleticalPlayerNote: {
      type: String,
    },
    technicalPlayerNote: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["getCurrentScout", "getSelectedPlayer"]),
    isUserTheAuthor() {
      return (
        !this.getCurrentScout?.isManager &&
        this.getCurrentScout?.id === this.game?.addedBy
      );
    },
    isSubmitButtonDisabled() {
      if (
        !this.selectedPlayerABCDNote?.length ||
        !this.playerGlobalComment?.length
      ) {
        return true;
      }

      if (
        (this.selectedPlayerABCDNote == "A" ||
          this.selectedPlayerABCDNote == "B") &&
        !this.getCurrentScout?.isManager
      ) {
        return (
          (!this.athleticalComment.length && this.allFieldsRequired) ||
          (!this.mentalComment.length && this.allFieldsRequired) ||
          (!this.tacticalComment.length && this.allFieldsRequired) ||
          (!this.technicalComment.length && this.allFieldsRequired) ||
          !this.playerGlobalComment.length
        );
      } else {
        return !this.playerGlobalComment.length;
      }
    },
    displaySubmitButtonContainer() {
      const toReturn = { display: true, disable: false };
      if (!this.selectedPlayerABCDNote?.length) {
        toReturn.display = false;
        toReturn.disable = true;
        return toReturn;
      }
      if (
        ((this.selectedPlayerABCDNote === "A" ||
          this.selectedPlayerABCDNote === "B") &&
          this.gamePlayerNote.length &&
          this.tacticalPlayerNote.length &&
          this.mentalPlayerNote.length &&
          this.athleticalPlayerNote.length &&
          this.technicalPlayerNote.length) ||
        (this.selectedPlayerABCDNote?.length &&
          this.selectedPlayerABCDNote !== "A" &&
          this.selectedPlayerABCDNote !== "B" &&
          this.gamePlayerNote.length)
      ) {
        toReturn.disable = false;
        return toReturn;
      }
      toReturn.disable = true;
      return toReturn;
    },
  },
  methods: {
    setPlayerABCDNote(param) {
      this.scoreSelected = param;
      this.$emit("setABCDScore", param);
    },
    submitNewComments() {
      this.$emit("submitGamePlayerNote", {
        selectedPlayerABCDNote: this.selectedPlayerABCDNote,
        tacticalPlayerNote: this.tacticalComment,
        mentalPlayerNote: this.mentalComment,
        athleticalPlayerNote: this.athleticalComment,
        technicalPlayerNote: this.technicalComment,
        globalComment: this.playerGlobalComment,
      });
    },
  },
  watch: {
    gamePlayerNote: {
      immediate: true,
      deep: true,
      handler() {
        this.playerGlobalComment = this.gamePlayerNote || "";
      },
    },
    tacticalPlayerNote: {
      immediate: true,
      deep: true,
      handler() {
        this.tacticalComment = this.tacticalPlayerNote || "";
      },
    },
    mentalPlayerNote: {
      immediate: true,
      deep: true,
      handler() {
        this.mentalComment = this.mentalPlayerNote || "";
      },
    },
    athleticalPlayerNote: {
      immediate: true,
      deep: true,
      handler() {
        this.athleticalComment = this.athleticalPlayerNote || "";
      },
    },
    technicalPlayerNote: {
      immediate: true,
      deep: true,
      handler() {
        this.technicalComment = this.technicalPlayerNote || "";
      },
    },
    selectedPlayerABCDNote: {
      immediate: true,
      deep: true,
      handler() {
        if (
          (this.selectedPlayerABCDNote == "A" ||
            this.selectedPlayerABCDNote == "B") &&
          !this.getCurrentScout?.isManager
        ) {
          this.allFieldsRequired = true;
        } else {
          this.allFieldsRequired = false;
        }
        this.scoreSelected = this.selectedPlayerABCDNote || "";
      },
    },
    getSelectedPlayer: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.gamePlayerNote.length) {
          this.playerGlobalComment = "";
        }
        if (!this.tacticalPlayerNote.length) {
          this.tacticalComment = "";
        }
        if (!this.mentalPlayerNote.length) {
          this.mentalComment = "";
        }
        if (!this.athleticalPlayerNote.length) {
          this.athleticalComment = "";
        }
        if (!this.technicalPlayerNote.length) {
          this.technicalComment = "";
        }
      },
    },
  },
};
</script>
<style>
.gamePlayerStatusContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
}

.gamePlayerStatusContainer span {
  flex: 1;
  padding: 0 8px;
}
#gamePlayersContainer {
  padding: 32px 40px 40px;
  margin-bottom: 16px !important;
  margin-bottom: 16px;
}

#gamePlayerObservationTitle {
  border-bottom: solid 1px #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 32px;
}

#gamePlayerIsBucket,
#gamePlayerIsInShadow {
  font-size: 0.875rem;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
}
#gamePlayerIsBucket {
  color: #c88c28;
}
#gamePlayerIsInShadow {
  color: #b34442;
}

#gamePlayerIsBucket::before {
  content: url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.00227 14.2698L13.1523 16.7798C13.9123 17.2398 14.8423 16.5598 14.6423 15.6998L13.5423 10.9798L17.2123 7.79977C17.8823 7.21977 17.5223 6.11977 16.6423 6.04977L11.8123 5.63977L9.92227 1.17977C9.58227 0.369766 8.42227 0.369766 8.08227 1.17977L6.19227 5.62977L1.36227 6.03977C0.48227 6.10977 0.12227 7.20977 0.79227 7.78977L4.46227 10.9698L3.36227 15.6898C3.16227 16.5498 4.09227 17.2298 4.85227 16.7698L9.00227 14.2698Z' fill='%23C88C28'/%3E%3C/svg%3E%0A");
  margin-right: 8px;
}

#gamePlayerIsInShadow::before {
  content: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.4686 13.9657H3.49714L2.92571 13.3943V5.78286H1.31429L0.765714 5.34857L0 2.28571L0.377143 1.6L5.24572 0L5.97714 0.365714C6.04181 0.540228 6.12214 0.708533 6.21714 0.868571C6.29829 0.986286 6.39086 1.09714 6.49143 1.2C6.68343 1.39314 6.91314 1.54514 7.16571 1.64571C7.42401 1.75606 7.70198 1.81295 7.98286 1.81295C8.26374 1.81295 8.5417 1.75606 8.8 1.64571C9.05291 1.54487 9.28241 1.39317 9.47429 1.2C9.57714 1.09143 9.672 0.977143 9.76 0.857143L9.93143 0.525714C9.95374 0.473603 9.97282 0.420168 9.98857 0.365714L10.72 0L15.5886 1.62286L16 2.28571L15.2457 5.32571L14.6857 5.76H13.04V13.3714L12.4686 13.9657Z' fill='%23B34442'/%3E%3C/svg%3E%0A");
  margin-right: 8px;
}

.playerCommentsLabel {
  color: #111111;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.optionalLabel {
  display: block;
  color: #111111;
  font-size: 0.875rem;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 400;
}

.gamePlayerCommentsContainer {
  display: flex;
  flex-direction: column;
}

.playerComment {
  background-color: #ffffff;
  border: solid 1px #e6e6e6;
  width: 100%;
  color: #111111;
  font-size: 1rem;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 400;
  padding: 12px 16px;
  border-radius: 4px;
  height: 130px;
}

.playerComment::placeholder {
  color: #7a7a7a;
  font-size: 1rem;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 400;
}

.playerComment::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.gamePlayerCommentsContainer {
  margin-top: 24px;
}

.playerCommentsContent {
  font-size: 1rem;
  font-family: "Proxima Nova", sans-serif;
  padding: 12px 16px;
  background-color: #ffffff;
  border: solid 1px #e6e6e6;
  border-radius: 4px;
  margin-bottom: 0;
}

.scoresContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
}

.ABCDNoteContainer {
  border: solid 2px #060052;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #060052;
  font-size: 1rem;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 700;
}

.ABCDNoteContainer.editable {
  cursor: pointer;
}

.ABCDNoteContainer.selected,
.ABCDNoteContainer.editable:hover {
  background-color: #060052;
  color: #ffffff;
}

.formSubmitButton {
  background-color: #060052;
  color: #ffffff;
  padding: 12px 24px;
  border-radius: 100px;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  outline: 0;
  border: 0;
}

.formSubmitButton.disabled {
  pointer-events: none;
  color: #f6f5ff;
  background-color: hsla(244, 100%, 16%, 0.6);
}

#gamePlayersContainer #gamePlayerValidateGameNoteContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 44px 0 8px;
}

.noCommentedPlayer {
  margin: 32px 0 0;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Proxima Nova", sans-serif;
  text-align: center;
}
</style>
