<template>
  <div class="current-squad-card">
    <div class="title-container">
      <span class="title">{{ $t("currentSquadTitle") }}</span>
      <PlayersCountPill
        class="pill"
        :playersCount="players.length + playersOut.length"
      />
    </div>
    <div class="separator"></div>
    <AvailablePlayersList :players="players" />
    <SoldPlayersList :playersOut="playersOut" />
  </div>
</template>

<script>
import AvailablePlayersList from "../AvailablePlayersList.vue";
import PlayersCountPill from "../PlayersCountPill.vue";
import SoldPlayersList from "../SoldPlayersList.vue";
export default {
  props: {
    playersOut: { type: Array, default: () => [] },
    players: { type: Array, default: () => [] },
  },
  components: {
    SoldPlayersList,
    AvailablePlayersList,
    PlayersCountPill,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

div.current-squad-card {
  background-color: $white;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  padding: 24px 11px 24px 24px;
  gap: 24px;
}
div.separator {
  width: 100%;
  height: 1px;
  background-color: #e6e6e6;
}

div.title-container {
  display: flex;
  width: 100%;
  padding-right: 10px;
}

span.title {
  flex-basis: 0;
  flex-grow: 1;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  color: $blueDarkest;
}

div.pill {
  height: fit-content;
}
</style>
