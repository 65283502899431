import { mapGetters } from "vuex";
import translations from "../locales/fr.json";
import mixinDates from "./mixinDates";
import mixinText from "./mixinText";

export default {
  mounted() {
    if (!this.getBucketPlayers.length) {
      this.$store.dispatch("bindBucketPlayers");
    }
  },
  mixins: [mixinDates, mixinText],
  computed: {
    ...mapGetters([
      "getShadow",
      "getCurrentScopeShadows",
      "getBucketPlayers",
      "getGamesPosition",
      "getSelectedPlayer",
      "getCurrentScopeShadowsPlayers",
      "getLocale",
    ]),
    shadowPlayersIds() {
      const toReturn = [];
      this.getCurrentScopeShadows.forEach((shadow) => {
        if (shadow.contributors?.length && shadow.players.length) {
          toReturn.push(shadow.players);
        }
      });
      return this.lodash
        .uniq(
          toReturn.map((players) => {
            return players.map((player) => {
              return player.id;
            });
          })
        )
        .flat();
    },
    getBucketPlayersIds() {
      return this.getBucketPlayers.map((bucketPlayer) => {
        return bucketPlayer.id;
      });
    },
    getPositionMap() {
      return {
        CF: {
          433: {
            1: { top: "0", left: "50%" },
            2: { top: "0", left: "50%" },
          },
          541: {
            1: { top: "5%", left: "49%" },
          },
          4231: {
            1: { top: "0%", left: "50%" },
          },
        },
        LW: {
          433: {
            1: { top: "16.6%", left: "10%" },
            2: { top: "16.6%", left: "10%" },
          },
          4231: {
            1: { top: "25%", left: "0%" },
          },
        },
        RW: {
          433: {
            1: { top: "16.6%", right: "10%" },
            2: { top: "16.6%", right: "10%" },
          },
          4231: {
            1: { top: "25%", right: "0%" },
          },
        },
        LCM: {
          433: { 1: { top: "33.2%", left: "30%" } },
          442: {
            1: { top: "39%", left: "30%" },
            2: { top: "33%", left: "35%" },
          },
          541: { 1: { top: "33%", left: "28%" } },
          4231: {
            1: { top: "45%", left: "30%" },
          },
        },
        RCM: {
          433: { 1: { top: "33.2%", right: "30%" } },
          442: {
            1: { top: "39%", right: "30%" },
            2: { top: "33%", right: "35%" },
          },
          541: { 1: { top: "33%", right: "28%" } },
          4231: {
            1: { top: "45%", left: "70%" },
          },
        },
        CDM: {
          433: {
            1: { top: "55%", left: "50%" },
          },
          442: {
            1: { top: "58%", left: "50%" },
          },
        },
        LB: {
          433: {
            1: { bottom: "16.6%", left: "10%" },
            2: { bottom: "16.6%", left: "10%" },
          },
          442: {
            1: { bottom: "16.6%", left: "10%" },
            2: { bottom: "33%", left: "10%" },
          },
          532: {
            1: { top: "70%", left: "30%" },
          },
          541: { 1: { bottom: "33%", left: "5%" } },
          4231: {
            1: { top: "70%", left: "5%" },
          },
        },
        LCB: {
          343: { 1: { top: "73%", left: "25%" } },
          433: {
            1: { bottom: "16.6%", left: "30%" },
            2: { bottom: "16.6%", left: "35%" },
          },
          442: {
            1: { bottom: "16.6%", left: "35%" },
            2: { bottom: "33%", left: "35%" },
          },
          532: {
            1: { top: "70%", left: "5%" },
          },
          541: { 1: { bottom: "33%", left: "28%" } },
          4231: {
            1: { top: "70%", left: "30%" },
          },
        },
        RCB: {
          343: { 1: { top: "73%", right: "25%" } },
          433: {
            1: { bottom: "16.6%", right: "35%" },
            2: { bottom: "16.6%", right: "35%" },
          },
          442: {
            1: { bottom: "16.6%", right: "35%" },
            2: { bottom: "33%", right: "35%" },
          },
          532: {
            1: { top: "70%", right: "5%" },
          },
          541: { 1: { bottom: "33%", right: "28%" } },
          4231: {
            1: { top: "70%", left: "70%" },
          },
        },
        RB: {
          433: {
            1: { bottom: "16.6%", right: "10%" },
            2: { bottom: "16.6%", right: "10%" },
          },
          442: {
            1: { bottom: "16.6%", right: "10%" },
            2: { bottom: "33%", right: "10%" },
          },
          532: {
            1: { top: "80%", left: "50%" },
          },
          541: { 1: { bottom: "33%", right: "5%" } },
          4231: {
            1: { top: "70%", left: "95%" },
          },
        },
        G: {
          343: {
            1: { bottom: "0%", left: "49%" },
          },
          433: {
            1: { bottom: "0%", left: "50%" },
            2: { bottom: "0%", left: "50%" },
          },
          442: {
            1: { bottom: "0%", left: "50%" },
            2: { bottom: "5%", left: "50%" },
          },
          532: {
            1: { bottom: "0%", left: "50%" },
          },
          541: {
            1: { bottom: "5%", left: "49%" },
          },
          4231: {
            1: { bottom: "0%", left: "50%" },
          },
        },
        LF: {
          343: {
            1: { top: "0%", left: "25%" },
          },
          442: {
            1: { top: "0", left: "25%" },
            2: { top: "5%", left: "33%" },
          },
          532: {
            1: { top: "0%", left: "35%" },
          },
        },
        RF: {
          343: {
            1: { top: "0%", right: "25%" },
          },
          442: {
            1: { right: "25%", top: "0" },
            2: { right: "33%", top: "5%" },
          },
          532: {
            1: { top: "0%", left: "65%" },
          },
        },
        LAM: {
          532: {
            1: { top: "30%", left: "35%" },
          },
        },
        RAM: {
          532: {
            1: { top: "30%", left: "65%" },
          },
        },
        CM: {
          433: {
            2: { top: "33.2%", left: "50%" },
          },
          532: {
            1: { top: "50%", left: "50%" },
          },
        },
        CB: {
          343: {
            1: { top: "73%", left: "49%" },
          },
          532: {
            1: { top: "70%", left: "70%" },
          },
          541: {
            1: { bottom: "33%", left: "49%" },
          },
        },
        CAM: {
          343: {
            1: { top: "25%", left: "49%" },
          },
          442: { 1: { top: "16.6%", right: "49%" } },
          4231: {
            1: { top: "25%", left: "50%" },
          },
        },
        LWB: { 343: { 1: { top: "48%", left: "0%" } } },
        LDM: {
          343: { 1: { top: "48%", left: "25%" } },
          433: { 2: { top: "55%", left: "30%" } },
        },
        RDM: {
          343: { 1: { top: "48%", right: "25%" } },
          433: { 2: { top: "55%", right: "30%" } },
        },
        RWB: { 343: { 1: { top: "48%", right: "0%" } } },
        LM: {
          442: { 2: { top: "33%", left: "10%" } },
          541: { 1: { top: "33%", left: "5%" } },
        },
        RM: {
          442: {
            2: { top: "33%", right: "10%" },
          },
          541: { 1: { top: "33%", right: "5%" } },
        },
      };
    },
    getSpecificAcronymsMap() {
      return {
        G: "GK",
        RF: "RCF",
        LF: "LCF",
      };
    },
    getReverseSpecificAcronymsMap() {
      return {
        GK: "G",
        RCF: "RF",
        LCF: "LF",
        SS: "S",
        CF: "S",
      };
    },
  },
  methods: {
    hasNote(playerId, notesReference) {
      return this.lodash.filter(notesReference, { playerId: playerId }).length;
    },
    isCurrentPlayerACurrentScopeShadowPlayer(player) {
      return (
        player?.id &&
        this.getCurrentScopeShadowsPlayers
          .map((player) => {
            return player.id;
          })
          .includes(player.id)
      );
    },
    isBucketPlayer(player) {
      return this.getBucketPlayersIds
        ? this.getBucketPlayersIds.includes(player?.id)
        : false;
    },
    getPositionTranslationKey(position) {
      const lowerPosition = position
        ?.split(" ")
        .reduce((acc, curr) => (acc ? acc + curr : curr.toLowerCase()), "");
      if (translations.position[lowerPosition]) {
        return `position.${lowerPosition}`;
      }
      return "position.unknown";
    },
    getPositionFromAcronym(acronym) {
      const correctAcronym = this.getSpecificAcronymsMap[acronym] ?? acronym;
      return this.getGamesPosition.find((p) => p.abv === correctAcronym);
    },
    getPositionAcronymFromPosition(position) {
      return this.getReverseSpecificAcronymsMap[position.abv] ?? position.abv;
    },
    getPositionTranslationKeyFromAcronym(acronym) {
      const position = this.getPositionFromAcronym(acronym)?.label;
      return this.getPositionTranslationKey(position);
    },
    getPositionTranslationKeyFromPlayer(player) {
      if (player.movingPosition) {
        return this.getPositionTranslationKeyFromAcronym(player.movingPosition);
      }
      return this.getPositionTranslationKey(player.primaryPosition);
    },
    getEndContractThresholds() {
      const possibleValues = 4;
      const returnValue = {};
      let i = 1;
      const currentYear = new Date().getFullYear();
      while (i <= possibleValues) {
        returnValue[`player-accent-color-${i}`] = new Date(
          currentYear + i,
          5,
          30
        ).getTime();
        ++i;
      }
      return returnValue;
    },
    getEndContractColorClass(endContract) {
      if (!this.isValidDate(endContract)) {
        return { "player-accent-color-unknown": true };
      }
      const endContractMS = endContract * 1000;

      const thresholds = this.getEndContractThresholds();

      for (const [classKey, limit] of Object.entries(thresholds)) {
        if (endContractMS < limit) {
          return { [classKey]: true };
        }
      }
      return { "player-accent-color-5": true };
    },
    getMinifiedPlayerName(name) {
      const splitted = name.split(" ");
      const lastName = splitted.pop();
      return `${splitted
        .map(
          (part) =>
            part
              .split("-")
              .map((subPart) => subPart.charAt(0))
              .join("-") + "."
        )
        .join(" ")} ${lastName}`;
    },
    getPlayerInList(list, playerId) {
      return list.find(({ id }) => playerId === id);
    },
    updatePlayerFieldInList(list, playerId, updatePayload) {
      const foundPlayer = this.getPlayerInList(list, playerId);
      if (!foundPlayer) {
        return false;
      }
      for (const [key, value] of Object.entries(updatePayload)) {
        foundPlayer[key] = value;
      }
      return true;
    },
    sortByPositionIndex(players) {
      return players.sort((playerA, playerB) => {
        let [aIndex, bIndex] = [playerA.positionIndex, playerB.positionIndex];
        if (isNaN(aIndex)) {
          aIndex = parseInt(playerA.id);
        }
        if (isNaN(bIndex)) {
          bIndex = parseInt(playerB.id);
        }
        return aIndex - bIndex;
      });
    },
  },
};
