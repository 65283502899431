<template>
  <div class="input-group-container">
    <span class="input-label">{{ label }}</span>
    <div class="input-container">
      <input
        :value="inputValue"
        autocomplete="off"
        placeholder="XXX"
        :disabled="disabled"
        :min="min"
        :max="max"
        :step="step"
        :class="{
          errored,
          disabled,
        }"
        @input="handleChange"
        @keydown.up="addStep"
        @keydown.down="decreaseStep"
      />
      <span class="suffix" v-if="suffix">
        <span class="hidden">{{ placeholderValue }}</span> {{ suffix }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: "" },
    min: { type: Number },
    max: { type: Number },
    step: { type: Number, default: 1 },
    suffix: { type: String, default: "" },
    value: { type: [String, Number] },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      placeholderValue: "XXX",
      errored: false,
      inputValue: this.value,
    };
  },
  emits: ["change", "error", "unerror"],
  mounted() {
    this.setNonErrored();
    if (isNaN(parseFloat(this.value))) {
      this.inputValue = "";
      this.placeholderValue = "XXX";
    }
    if ((this.value && this.value !== "undefined") || this.value === 0) {
      this.placeholderValue = this.value;
    } else if (this.required) {
      this.setErrored();
    }
  },
  methods: {
    setErrored() {
      this.errored = true;
      this.$emit("error", this.id)
    },
    setNonErrored() {
      this.errored = false;
      this.$emit("unerror", this.id)
    },
    shouldBeIgnored(character) {
      if (![..."0123456789.,"].includes(character)) {
        return true;
      }
      if (!this.inputValue) {
        return false;
      }
      const dots = [",", "."];
      const currentValueContainsDot = dots.some((char) =>
        this.inputValue.toString().includes(char)
      );
      const characterIsDot = dots.includes(character);
      return currentValueContainsDot && characterIsDot;
    },
    handleChange({ data, target }) {
      if (data && this.shouldBeIgnored(data)) {
        target.value = target.value.slice(0, -1);
        this.inputValue = target.value;
        return;
      }
      const emptiedField = !data && !target.value;
      if (target.value?.length > 8) {
        target.value = target.value.slice(0, -1);
      } else if (emptiedField) {
        target.value = "";
      }
      this.inputValue = target.value;
    },
    addStep() {
      let newValue;
      if (this.max) {
        newValue = Math.min(parseFloat(this.inputValue) + this.step, this.max);
      } else {
        newValue = parseFloat(this.inputValue) + this.step;
      }
      this.inputValue = newValue.toFixed(
        this.step.toString().split(".").pop().length
      );
    },
    decreaseStep() {
      let newValue;
      if (this.min) {
        newValue = Math.max(parseFloat(this.inputValue) - this.step, this.min);
      } else {
        newValue = parseFloat(this.inputValue) - this.step;
      }
      this.inputValue = newValue.toFixed(
        this.step.toString().split(".").pop().length
      );
    },
  },
  watch: {
    inputValue: {
      immediate: true,
      handler(value) {
        this.setNonErrored();
        const float = parseFloat(value);
        if (value === "" || (typeof value !== "number" && !value)) {
          if (this.required) {
            this.setErrored();
          }
          this.placeholderValue = "XXX";
          this.$emit("change", "");
          return;
        }
        if (isNaN(float)) {
          this.setErrored();
          return;
        }
        this.placeholderValue = value;
        this.$emit("change", float);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";
div.input-group-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
}

span.input-label {
  color: #111111;
  font-family: "Proxima Nova", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

input {
  height: 40px;
  border: solid 1px #e6e6e6;
  border-radius: 4px;
  padding: 12px;
  font-weight: 700;
  font-size: 0.875rem;
  color: $blueDarkest;
  box-shadow: none;
  width: 100%;
  &:focus {
    border: solid 2px $blueDarkest;
    box-shadow: 0px 0px 8px 0px rgba(6, 0, 82, 0.08) !important;
  }
  &:hover:not(:focus):not(.errored):not(.disabled) {
    border: solid 1px #e6e6e6;
    box-shadow: 0px 0px 8px 0px rgba(6, 0, 82, 0.08);
  }
  &.errored {
    border: solid 2px #b34442;
  }
}

div.input-container {
  position: relative;
}

span.suffix {
  position: absolute;
  top: 10px;
  left: 14px;
  > .hidden {
    pointer-events: none;
    opacity: 0;
  }
  cursor: text;
  user-select: none;
  pointer-events: none;
  color: $blueDarkest;
  font-weight: 700;
  font-size: 0.875rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
</style>
