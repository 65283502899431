<template>
  <b-row class="fields-group">
    <b-col cols="12" md="4">
      <DateInput
        :label="$t('endOfContractAcronym')"
        name="endContract"
        :min="minDate"
        :value="getPopinShallowPayload.endContract"
        @change="changeEndContract"
        @error="addPopinError"
        @unerror="removePopinError"
        :required="true"
      />
    </b-col>
    <b-col cols="12" md="4">
      <NumberInput
        @error="addPopinError"
        @unerror="removePopinError"
        :label="$t('salary')"
        :value="
          getPopinShallowPayload.newSalary ?? getPopinShallowPayload.salary
        "
        @change="changeSalary"
        name="salary"
        :min="0"
        :step="0.1"
        :suffix="$t('millionsPerYear')"
        :required="true"
      />
    </b-col>
    <b-col cols="12" md="4">
      <NumberInput
        @error="addPopinError"
        @unerror="removePopinError"
        :label="
          getPopinShallowPayload.isIncoming
            ? $t('purchasePrice')
            : $t('sellingPrice')
        "
        :value="getPopinShallowPayload.price"
        @change="changePrice"
        name="price"
        :min="0"
        :step="0.1"
        :suffix="$t('millions')"
        :required="true"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NumberInput from "../../../Fields/NumberInput.vue";
import DateInput from "../../../Fields/DateInput.vue";
import moment from "moment";
export default {
  components: { NumberInput, DateInput },
  computed: {
    ...mapGetters("popin", ["getPopinShallowPayload"]),
    minDate() {
      return moment().startOf("day").toDate();
    },
  },
  methods: {
    ...mapActions("popin", ["addPopinError", "removePopinError"]),
    changeSalary(newSalary) {
      this.$store.dispatch("popin/setShallowPayload", { newSalary });
    },
    changePrice(price) {
      this.$store.dispatch("popin/setShallowPayload", { price });
    },
    changeEndContract(datePayload) {
      this.$store.dispatch("popin/setShallowPayload", datePayload);
    },
  },
};
</script>
<style lang="scss" scoped>
div.fields-group {
  padding: 24px;
  border-radius: 8px;
  background-color: #fafafa;
}
</style>
