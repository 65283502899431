<template>
  <div class="notification-container closable">
    <div class="notification-card col-5">
      <div class="notification-content">
        <span v-for="i in groupsLength" :key="'text_part_' + i">
          <span v-if="startsWithBold" class="bold-text">{{
            boldTexts[i - 1] ?? ""
          }}</span>
          <span class="standard-text">{{ standardTexts[i - 1] ?? "" }}</span>
          <span v-if="!startsWithBold" class="bold-text">{{
            boldTexts[i - 1] ?? ""
          }}</span>
        </span>
      </div>
      <div class="close-action">
        <img
          class="exit-button closable"
          @click="handleCloseClick"
          id="close_notification"
          src="../assets/img/crossClose.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    translationKey: { type: String, default: "" },
    payload: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      standardTexts: [],
      boldTexts: [],
      groupsLength: 0,
      startsWithBold: false,
    };
  },
  mounted() {
    this.parseText();
  },
  computed: {
    ...mapGetters(["getLocale"]),
  },
  methods: {
    ...mapActions("popin", ["closeToast"]),
    handleCloseClick(event) {
      if (event.target.classList.contains("closable")) {
        event.stopPropagation();
        this.closeToast();
      }
    },
    parseText() {
      const translation = this.$t(this.translationKey);
      const regex = /(?<stringToReplace>\{(?<translationKey>[\w.]+)\})/g;
      const matchedGroups = [...translation.matchAll(regex)];
      this.startsWithBold = matchedGroups?.[0]?.index === 0;
      let currentIndex = 0;
      for (const group of matchedGroups) {
        const { translationKey, stringToReplace } = group.groups;
        this.standardTexts.push(translation.slice(currentIndex, group.index));

        const boldText = translationKey.includes(".")
          ? this.$t(translationKey)
          : this.payload[translationKey];
        this.boldTexts.push(boldText);
        currentIndex = group.index + stringToReplace.length;
      }
      if (currentIndex < translation.length) {
        this.standardTexts.push(translation.slice(currentIndex));
      }
      if (this.startsWithBold) {
        this.standardTexts.shift();
      }
      this.groupsLength = Math.max(
        this.standardTexts.length,
        this.boldTexts.length
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
div.close-action {
  cursor: pointer;
}
div.notification-container {
  pointer-events: none;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: default;
}
div.notification-card {
  position: relative;
  z-index: 10001;
  border-radius: 8px;
  border: solid 1px #d0ccff;
  padding: 24px;
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
}

div.notification-content {
  color: $blueDarkest;
  flex-grow: 1;
  .bold-text {
    font-weight: 700;
  }
}
</style>
