<template>
  <div class="popinContainer">
    <div class="popinContent">
      <div
        class="crossContainer"
        @click="triggerClosingPopin($event, currentPositionPlayers)"
      ></div>
      <div v-if="scenario === 'createShadow'">
        <h2>
          {{ $t("create.aShadow") }}
        </h2>
        <div class="popinContribution">
          <b-form>
            <b-form-group :label="$t('teamName')" label-for="shadowTeamLabel">
              <b-form-input
                id="shadowTeamLabel"
                v-model="newShadow.label"
                type="text"
                :placeholder="$t('instructions.defineATeamName')"
                required
              />
            </b-form-group>
            <b-form-group
              :label="$t('selectSheet')"
              label-for="shadowTeamCompo"
            >
              <b-form-select
                id="shadowTeamCompo"
                v-model="newShadow.compo"
                :options="compoOptions"
                required
              />
            </b-form-group>

            <div class="popinCTAContainer">
              <button class="cancelButton" @click="triggerClosingPopin($event)">
                {{ $t("cancel") }}
              </button>
              <button
                @click="createShadow"
                :disabled="
                  !(
                    newShadow.label &&
                    newShadow.label.length &&
                    newShadow.compo &&
                    newShadow.compo.length
                  )
                "
                class="main"
              >
                {{ $t("validate") }}
              </button>
            </div>
          </b-form>
        </div>
      </div>
      <div v-if="scenario === 'updateShadowName'">
        <h2>
          {{ $t("update.shadowName") }}
        </h2>
        <div class="popinContribution">
          <b-form @submit="updateShadow">
            <b-form-group :label="$t('teamName')" label-for="shadowTeamLabel">
              <b-form-input
                id="shadowTeamLabel"
                v-model="contentToEdit.label"
                type="text"
                :placeholder="$t('instructions.defineATeamName')"
                required
              />
            </b-form-group>
            <div class="popinCTAContainer">
              <button class="cancelButton" @click="triggerClosingPopin($event)">
                {{ $t("cancel") }}
              </button>
              <button type="submit" class="main">{{ $t("validate") }}</button>
            </div>
          </b-form>
        </div>
      </div>
      <div v-if="scenario === 'deleteShadow'">
        <h2>
          {{ $t("delete.aShadow") }}
        </h2>
        <div class="popinContribution">
          <b-form @submit="hideShadow">
            <p>{{ $t("confirmation.delete.thisShadowTeam") }}</p>
            <div class="popinCTAContainer">
              <button class="cancelButton" @click="triggerClosingPopin($event)">
                {{ $t("cancel") }}
              </button>
              <button type="submit" class="main">{{ $t("validate") }}</button>
            </div>
          </b-form>
        </div>
      </div>
      <div v-if="scenario === 'addPlayer'">
        <h2>
          {{ getCurrentPosition }}
        </h2>
        <div class="popinContribution">
          <b-form>
            <div
              v-for="i in 5"
              :key="i"
              :class="{
                playerToAdd: true,
                hasPlayer: currentPositionPlayers[i - 1],
              }"
              :draggable="currentPositionPlayers[i - 1]?.id"
              @dragstart="dragStart(currentPositionPlayers[i - 1]?.id)"
              @dragend="dragEnd(currentPositionPlayers[i - 1]?.id)"
              @dragover="dragOver"
              @drop="drop(currentPositionPlayers[i - 1]?.id)"
            >
              <div
                v-if="currentPositionPlayers[i - 1]"
                class="playerToAddContent"
              >
                <div class="draggeableIconContainer smallCol">
                  <img
                    class="draggeableIconContent"
                    src="../assets/img/draggable.svg"
                    alt="Draggable button"
                  />
                </div>
                <div class="largeCol">
                  {{ currentPositionPlayers[i - 1].name }}
                </div>
                <div class="smallCol">
                  {{
                    formatDate(
                      currentPositionPlayers[i - 1].dateOfBirth,
                      "year"
                    )
                  }}
                </div>
                <div class="largeCol clubContainer">
                  {{ currentPositionPlayers[i - 1].currentTeam }}
                </div>
                <div class="smallCol">
                  {{ getPlayerPrice(currentPositionPlayers[i - 1].price) }}
                </div>
                <div class="playerActionsContainer smallCol">
                  <b-link
                    class="linkContainer"
                    target="_blank"
                    :to="`/player/${currentPositionPlayers[i - 1].id}`"
                  >
                    <img src="../assets/img/account.svg" alt="link to user" />
                  </b-link>
                  <div
                    class="trashContainer"
                    @click="removePlayerFromPosition(i - 1)"
                  >
                    <img
                      src="../assets/img/white_trash.svg"
                      alt="Trash button"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="popinCTAContainer">
              <button
                class="closeButton"
                @click="triggerClosingPopin($event, currentPositionPlayers)"
              >
                <span>{{ $t("close") }}</span>
              </button>
              <button
                class="main"
                @click="addAPlayer"
                :disabled="currentPositionPlayers.length >= 5"
              >
                {{ $t("add.aPlayer") }}
              </button>
            </div>
          </b-form>
        </div>
      </div>
      <div v-if="scenario === 'addPlayerForGivenPosition'">
        <h2>
          {{ $t("add.aPlayer") }}
        </h2>
        <div class="popinContribution">
          <b-form>
            <b-form-group
              id="playerName"
              :label="$t('playerName')"
              label-for="playerName"
              class="text-left"
            >
              <b-form-input
                id="playerName"
                v-model="newPlayer.name"
                :class="{ highlight: newPlayer.name.length }"
                type="text"
                required
                list="playersList"
                autocomplete="off"
              />
              <datalist id="playersList">
                <option
                  v-for="(player, index) in playersOptions"
                  :key="index"
                  :value="player.value"
                  :data-after="`${$t('from')} ${player.text}`"
                ></option>
              </datalist>
            </b-form-group>

            <b-form-group
              id="teamName"
              :label="$t('teamName')"
              label-for="teamName"
              class="text-left"
            >
              <b-form-input
                id="teamName"
                v-model="newPlayer.currentTeam"
                :class="{ highlight: newPlayer.currentTeam.length }"
                type="text"
                required
                list="clubsList"
              />
              <datalist id="clubsList">
                <option
                  v-for="(club, index) in teamsOptions"
                  :key="index"
                  :value="club.value"
                >
                  {{ club.text }}
                </option>
              </datalist>
            </b-form-group>

            <div class="popinCTAContainer">
              <button
                class="returnButton"
                @click="returnToPopin('addPlayer', $event)"
              >
                {{ $t("return") }}
              </button>
              <button
                @click="searchPlayer"
                :class="{
                  doSearch: true,
                  main: newPlayer.name.length || newPlayer.currentTeam.length,
                  loop: true,
                  disabled:
                    !newPlayer.name.length && !newPlayer.currentTeam.length,
                }"
              >
                {{ $t("doSearch") }}
              </button>
            </div>
          </b-form>
        </div>
      </div>
      <div v-if="scenario === 'findMatchingPlayers'">
        <div class="popinContributionTitleContainer">
          <button
            class="popinContributionTitleArrow"
            @click="returnToPopin('addPlayerForGivenPosition', $event)"
          ></button>
          <h2 class="popinContributionTitle">
            {{ $t("add.aPlayer") }}
          </h2>
        </div>
        <div class="popinContribution">
          <b-form v-if="currentMatchingPlayers">
            <div>
              <p class="popinContributionNumberResults">
                {{
                  $tc("xMatchingResults", currentMatchingPlayers.length || 1, {
                    nbResults: currentMatchingPlayers.length,
                  })
                }}
              </p>
            </div>
            <div v-if="currentMatchingPlayers.length" class="listingContainer">
              <div class="arrayHeader">
                <div class="largeCol">{{ $t("name") }}</div>
                <div class="smallCol">{{ $t("year") }}</div>
                <div class="largeCol">{{ $t("filters.currentTeam") }}</div>
                <div class="smallCol">{{ $t("price") }}</div>
                <div class="smallCol">{{ $t("add.short") }}</div>
              </div>
              <div
                v-for="(player, playerIndex) in currentMatchingPlayers"
                :key="playerIndex"
                :class="{
                  arrayRow: true,
                  isAddedPlayer: isCurrentPlayerInSelectedPosition(player.id),
                  isNotCommented: isNotCommented(player),
                }"
              >
                <div class="largeCol">
                  <b-link
                    :to="`/player/${player.id}`"
                    class="linkToPlayerPageContainer"
                    target="_blank"
                  >
                    <span class="linkToPlayerPagePicto">
                      <img
                        src="../assets/img/account.svg"
                        alt="lien vers page"
                      />
                    </span>
                    <span class="linkToPlayerPageContent">
                      {{ player.name }}
                    </span>
                  </b-link>
                </div>
                <div class="smallCol">
                  {{ formatDate(player.dateOfBirth, false, "year") }}
                </div>
                <div class="largeCol">{{ player.currentTeam }}</div>
                <div class="smallCol">{{ getPlayerPrice(player.price) }}</div>
                <div
                  :class="{
                    smallCol: true,
                    addButtonContainer: true,
                  }"
                  @mouseover="displayPopover(true, $event, player)"
                  @mouseleave="displayPopover(false, $event, player)"
                >
                  <span
                    v-if="
                      (!isCurrentPlayerInSelectedPosition(player.id) &&
                        getCurrentScout.isManager) ||
                      (!isCurrentPlayerInSelectedPosition(player.id) &&
                        !getCurrentScout.isManager)
                    "
                    :class="{
                      addMoreContainer: true,
                      isHidden: hideCurrentPositionAddMoreOption(),
                    }"
                    @click="selectCurrentPlayer(player)"
                  >
                    +
                  </span>
                  <span
                    v-else
                    class="removePlayerContainer"
                    @click="removeCurrentPlayer(player)"
                  >
                    +
                  </span>
                  <CustomPopover
                    v-if="notCommentedPlayerHovered"
                    :content="$t('popover.playerNotCommented')"
                  />
                </div>
              </div>
            </div>
            <div class="popinCTAContainer">
              <button
                class="returnButton"
                @click="returnToPopin('addPlayerForGivenPosition', $event)"
              >
                {{ $t("return") }}
              </button>
              <button
                v-if="currentMatchingPlayers.length"
                @click="addPlayerForGivenPosition"
                :class="{
                  main: newPlayer.name.length || newPlayer.currentTeam.length,
                  checkIcon: true,
                  disabled:
                    !newPlayer.name.length && !newPlayer.currentTeam.length,
                }"
              >
                {{ $t("validate") }}
              </button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import db from "../db";
import { mapGetters } from "vuex";
import mixinDates from "../mixins/mixinDates";
import CustomPopover from "./CustomPopover";
import mixinPlayers from "../mixins/mixinPlayers";
export default {
  name: "ShadowPopin",
  components: {
    CustomPopover,
  },
  props: {
    scenario: { type: String },
    content: { type: Object },
  },
  emits: [
    "addPlayersToShadow",
    "triggerPopin",
    "updatePlayersList",
    "toggleNotification",
    "setContributors",
    "closePopin",
  ],
  mixins: [mixinDates, mixinPlayers],
  mounted() {
    const self = this;
    if (self.getCompos) {
      self.defineCompoOptions();
    }
    if (self.content) {
      self.contentToEdit = { ...self.content };
    }
    if (self.scenario === "addPlayer") {
      self.currentPositionPlayers = [];
      self.content.players?.forEach((player) => {
        if (player.position === self.content.selectedPosition) {
          self.currentPositionPlayers.push({
            addedBy: player.addedBy,
            id: player.id,
            currentTeam: player.currentTeam,
            dateOfBirth: player.dateOfBirth,
            name: player.name,
            position: self.content.selectedPosition,
            price: player.price || "undefined",
          });
        }
      });
    }
    if (!self.teamsOptions.length) {
      self.loadTeams();
    }
    if (!self.playersOptions.length) {
      self.loadPlayers();
    }
  },
  data() {
    return {
      draggingPlayerId: null,
      newShadow: {
        label: "",
        compo: null,
      },
      newPlayer: {
        name: "",
        currentTeam: "",
      },
      compoOptions: [],
      contentToEdit: {},
      currentPositionPlayers: [],
      playersOptions: [],
      teamsOptions: [],
      currentMatchingPlayers: null,
      notCommentedPlayerHovered: false,
    };
  },
  computed: {
    ...mapGetters([
      "getGamesPosition",
      "getCurrentScout",
      "getCompos",
      "getLocale",
      "getCompanies",
    ]),
    getCurrentPosition() {
      const self = this;
      const toReturn = self.getGamesPosition.filter((gamePosition) => {
        const specificCases = this.getReverseSpecificAcronymsMap;
        const acronymToCheck =
          specificCases[gamePosition.abv] ?? gamePosition.abv;
        return acronymToCheck === self.content.selectedPosition;
      })[0];
      if (toReturn) {
        return self.$t(`position.${toReturn.label.replaceAll(" ", "")}`);
      }
      return "";
    },
  },
  methods: {
    showPopin() {
      this.openPopinVariant({
        popinVariant: this.getPopinVariant,
      });
    },
    isNotCommented(player) {
      if (!this.getCurrentScout.isManager) {
        return !player[`${this.getCurrentScout.id}CommentedGamesIdsLength`];
      }
      return false;
    },
    displayPopover(isDisplayed, event, player) {
      if (
        !this.getCurrentScout.isManager &&
        !player[`${this.getCurrentScout.id}CommentedGamesIdsLength`] &&
        isDisplayed
      ) {
        event.target.classList.add("isHovered");
      } else {
        event.target.classList.remove("isHovered");
      }
      this.notCommentedPlayerHovered = isDisplayed;
    },
    dragStart(item) {
      this.draggingPlayerId = item;
    },
    dragEnd() {
      this.draggingPlayerId = null;
    },
    dragOver(event) {
      event.preventDefault();
    },
    drop(targetItemIndex) {
      const self = this;
      const draggedItemIndex = this.lodash.findIndex(
        self.currentPositionPlayers,
        {
          id: self.draggingPlayerId,
        }
      );
      const targettedItemIndex = this.lodash.findIndex(
        self.currentPositionPlayers,
        {
          id: targetItemIndex,
        }
      );
      const currentPositionPlayers = [...self.currentPositionPlayers];
      currentPositionPlayers.splice(draggedItemIndex, 1);
      currentPositionPlayers.splice(
        targettedItemIndex,
        0,
        self.currentPositionPlayers[draggedItemIndex]
      );
      self.currentPositionPlayers = currentPositionPlayers;
    },
    hideCurrentPositionAddMoreOption() {
      let currentPositionPlayers =
        this.content.players?.filter((player) => {
          return player.position === this.content.selectedPosition;
        }) || [];

      currentPositionPlayers = this.currentPositionPlayers;
      if (currentPositionPlayers && currentPositionPlayers.length < 5) {
        return false;
      }
      return true;
    },
    addPlayerForGivenPosition(e) {
      e.preventDefault();
      this.$emit("addPlayersToShadow", {
        target: "",
        data: this.currentPositionPlayers,
      });
    },
    isCurrentPlayerInSelectedPosition(playerId) {
      const currentPlayersIdForSelectedPosition =
        this.currentPositionPlayers.map((player) => {
          return player.id;
        });
      return currentPlayersIdForSelectedPosition.includes(playerId);
    },
    removeCurrentPlayer(player) {
      const matchingIndex = this.currentPositionPlayers.findIndex(
        (currentPositionPlayer) => {
          return currentPositionPlayer.id === player.id;
        }
      );
      if (matchingIndex > -1) {
        this.currentPositionPlayers.splice(matchingIndex, 1);
      }
    },
    selectCurrentPlayer(player) {
      const self = this;
      if (!this.isNotCommented(player)) {
        player.position = self.content.selectedPosition;
        self.currentPositionPlayers.push({
          addedBy: player.addedBy || self.getCurrentScout.id,
          currentTeam: player.currentTeam,
          dateOfBirth: player.dateOfBirth,
          id: player.id,
          name: player.name,
          position: player.position,
          price: player.price || "undefined",
        });
      }
    },
    getPlayerPrice(price) {
      if (!price || price === "undefined" || price === "unknown") {
        return "";
      }
      return price + "M €";
    },
    async loadMatchingPlayers() {
      const self = this;
      if (self.newPlayer.name?.length && self.newPlayer.currentTeam?.length) {
        self.currentMatchingPlayers = [];
        await db
          .collection("V3players")
          .where("name", "==", self.newPlayer.name.toString())
          .where("currentTeam", "==", self.newPlayer.currentTeam.toString())
          .get()
          .then((playersSnapshot) => {
            playersSnapshot.docs.forEach((playerDoc) => {
              const playerData = playerDoc.data();
              self.currentMatchingPlayers.push(playerData);
            });
          });
      } else if (self.newPlayer.name?.length) {
        self.currentMatchingPlayers = [];
        await db
          .collection("V3players")
          .where("name", "==", self.newPlayer.name.toString())
          .get()
          .then((playersSnapshot) => {
            playersSnapshot.docs.forEach((playerDoc) => {
              const playerData = playerDoc.data();
              self.currentMatchingPlayers.push(playerData);
            });
          });
      } else if (self.newPlayer.currentTeam?.length) {
        self.currentMatchingPlayers = [];
        await db
          .collection("V3players")
          .where("currentTeam", "==", self.newPlayer.currentTeam.toString())
          .get()
          .then((playersSnapshot) => {
            playersSnapshot.docs.forEach((playerDoc) => {
              const playerData = playerDoc.data();
              self.currentMatchingPlayers.push(playerData);
            });
          });
      }
    },
    returnToPopin(action) {
      this.$emit("triggerPopin", { target: action });
    },
    searchPlayer(e) {
      e.preventDefault();
      if (this.newPlayer.name.length || this.newPlayer.currentTeam.length) {
        this.$emit("triggerPopin", {
          target: "findMatchingPlayers",
          data: this.newPlayer,
        });
      }
    },
    addAPlayer() {
      if (this.currentPositionPlayers.length >= 5) {
        return;
      }
      this.$emit("triggerPopin", { target: "addPlayerForGivenPosition" });
    },
    removePlayerFromPosition(playerIndex) {
      const playerId = this.currentPositionPlayers[playerIndex]?.id;
      if (!playerId) {
        return;
      }
      let newPlayersContent = [...this.currentPositionPlayers];
      this.currentPositionPlayers.splice(playerIndex, 1);
      newPlayersContent.splice(playerIndex, 1);
      this.$emit("updatePlayersList", { data: newPlayersContent });
    },
    async loadTeams() {
      const self = this;
      self.teamsOptions = [];
      await axios
        .get("https://ds-ol-scout-app-py-api-3.azurewebsites.net/teams/")
        .then(async (apiResponse) => {
          apiResponse.data.forEach((game) => {
            self.teamsOptions.push({
              text: game["Team"],
              value: game["Team"],
            });
          });
        })
        .catch((error) => {
          console.log("Error while loading teams", error);
        });
    },
    async loadPlayers() {
      const self = this;
      self.playersOptions = [];
      await axios
        .get("https://ds-ol-scout-app-py-api-3.azurewebsites.net/players/")
        .then(async (apiResponse) => {
          apiResponse.data.forEach((player) => {
            self.playersOptions.push({
              text: player["team_name"],
              value: player["Name"],
            });
          });
        })
        .catch((error) => {
          console.log("Error while loading players", error);
        });
    },
    defineCompoOptions() {
      const self = this;
      const newCompos = [
        { value: null, text: this.$t("allSheets"), disabled: "disabled" },
      ];
      self.getCompos.forEach((compo) => {
        if (compo.label) {
          newCompos.push({
            value: compo.id,
            text: compo.schema + "-" + self.$t(compo.label.toLowerCase()),
          });
        } else {
          newCompos.push({ value: compo.id, text: compo.schema });
        }
      });
      self.compoOptions = newCompos;
    },
    async createShadow(e) {
      e.preventDefault();
      if (!this.newShadow.compo) {
        this.$emit("toggleNotification", {
          type: "error",
          details: "notification.error.form.incomplete",
        });
        return;
      }
      const self = this;
      const now = moment().unix();
      if (self.newShadow.label?.length && self.newShadow.compo?.length) {
        const shadowToAdd = {
          addedBy: self.getCurrentScout.id.toString(),
          compoId: self.newShadow.compo,
          contributors: [self.getCurrentScout.id.toString()],
          date: parseInt(now),
          label: self.newShadow.label.toString(),
          players: [],
          updatedAt: parseInt(now),
          usedInClub: self.getCurrentScout.company.toString(),
        };
        try {
          const shadowDoc = await db.collection("V3shadows").add(shadowToAdd);
          const newShadowId = shadowDoc.id.toString();
          self.contentToEdit.id = newShadowId;
          await db
            .collection("V3shadows")
            .doc(newShadowId)
            .set(
              { id: newShadowId, updatedAt: moment().unix() },
              { merge: true }
            );
          self.$emit("setContributors", {
            contributor: self.getCurrentScout.id,
          });
          this.$router.push({ path: `/shadow/${newShadowId}` });
          self.$emit("closePopin", {
            contributor: self.getCurrentScout.id.toString(),
            newShadowUpdatedAt: now,
            popin: {
              type: "success",
              details: "notification.success.create.shadow",
              params: {
                shadowName: self.newShadow.label,
                compo: self.newShadow.compo,
              },
            },
          });
        } catch (error) {
          console.log("Error while adding shadow", error);
        }
      }
    },
    async updateShadow(e) {
      e.preventDefault();
      const self = this;
      const now = moment().unix();
      if (
        self.getCurrentScout.isManager &&
        self.contentToEdit.contributors?.length
      ) {
        for (let i = 0; i < self.contentToEdit.contributors.length; i++) {
          await db
            .collection("V3scouts")
            .doc(self.contentToEdit.contributors[i].toString())
            .collection("shadows")
            .doc(self.contentToEdit.id.toString())
            .set(
              {
                label: self.contentToEdit.label,
                updatedAt: now,
                updatedBy: self.getCurrentScout.id.toString(),
              },
              { merge: true }
            );
        }
      }
      await db
        .collection("V3shadows")
        .doc(self.contentToEdit.id.toString())
        .set(
          {
            label: self.contentToEdit.label,
            updatedAt: now,
            updatedBy: self.getCurrentScout.id.toString(),
          },
          { merge: true }
        );
      self.$emit("closePopin", {
        contributor: self.getCurrentScout.id.toString(),
        newShadowName: self.contentToEdit.label,
        newShadowUpdatedAt: now,
        popin: {
          type: "success",
          details: "notification.success.update.shadow",
        },
      });
    },
    async hideShadow(e) {
      e.preventDefault();
      const self = this;
      if (self.contentToEdit.id) {
        await db
          .collection("V3shadows")
          .doc(self.contentToEdit.id.toString())
          .set({ isGhost: true, updatedAt: moment().unix() }, { merge: true })
          .then(() => {
            this.$router.push("/shadows");
            self.$emit("closePopin", {
              popin: {
                type: "success",
                details: "notification.success.delete.shadow",
                params: {
                  shadowName: self.contentToEdit.label,
                },
              },
            });
          })
          .catch(() => {
            self.$emit("closePopin", {
              contributor: self.getCurrentScout.id.toString(),
              newShadowName: self.contentToEdit.label,
              popin: {
                type: "error",
                details: "notification.error.delete.shadow",
                params: {
                  shadowName: self.contentToEdit.label,
                },
              },
            });
          });
      }
    },
    triggerClosingPopin(e, players) {
      const self = this;
      e.preventDefault();
      if (players) {
        self.$emit("closePopin", {
          contributor: self.getCurrentScout.id.toString(),
          players,
        });
      } else {
        self.$emit("closePopin");
      }
    },
  },
  watch: {
    scenario() {
      this.contentToEdit = this.content;

      if (this.scenario === "findMatchingPlayers") {
        this.loadMatchingPlayers();
      }
    },
    getCompos() {
      this.defineCompoOptions();
    },
    getLocale() {
      this.defineCompoOptions();
    },
  },
};
</script>
<style scoped>
.linkToPlayerPageContainer {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #060052;
  white-space: nowrap;
  overflow: hidden;
}

.linkToPlayerPageContent {
  overflow: hidden;
  text-overflow: ellipsis;
}

.linkToPlayerPagePicto {
  background: #060052;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 40px;
}

.linkToPlayerPagePicto img {
  width: 12px;
}

.listingContainer {
  padding-bottom: 50px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 0.8rem;
}

.listingContainer .largeCol,
.listingContainer .smallCol {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 5px 12px;
  margin: 5px 0;
}

.listingContainer .largeCol {
  min-width: 25%;
  width: 25%;
  max-width: 25%;
}

.listingContainer .smallCol {
  min-width: 9%;
  width: 9%;
  max-width: 9%;
}

.playerToAddContent .largeCol {
  flex: 3;
  overflow-x: hidden;
}

.clubContainer {
  text-align: center;
}

.playerToAddContent .smallCol {
  flex: 1;
}

.addMoreContainer,
.removePlayerContainer {
  margin: 0 auto;
  color: white;
  border-radius: 50px;
  padding: 10px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.addMoreContainer {
  background: #060052;
  color: white;
}

.addButtonContainer {
  position: relative;
}

.addMoreContainer.isHidden {
  display: none;
}

.removePlayerContainer {
  background-color: #b34442;
  transform: rotate(45deg);
  font-size: 1.3rem;
  font-weight: 100;
}

.addMoreContainer:hover {
  background-color: #b34442;
}

.linkToPlayerPagePicto {
  background-color: #060052;
}

.linkToPlayerPageContainer:hover .linkToPlayerPagePicto {
  background-color: #b34442;
}

.linkToPlayerPageContainer:hover {
  text-decoration: none;
  color: #b34442;
}

.arrayRow,
.arrayHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.popinContributionTitleContainer {
  position: relative;
}

.popinContributionNumberResults {
  color: #060052;
  font-family: "Monserrat", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin: 24px 0;
}

.popinContributionTitle {
  margin: 20px 0;
}

.popinContributionTitleContainer .popinContributionTitleArrow {
  position: absolute;
  width: 24px;
  height: 24px;
  margin-top: 6px;
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.6195 0.989793C10.5034 0.873385 10.3655 0.781028 10.2136 0.718012C10.0617 0.654995 9.89893 0.622559 9.73451 0.622559C9.5701 0.622559 9.40729 0.654995 9.25543 0.718012C9.10357 0.781028 8.96563 0.873385 8.84951 0.989793L0.539515 9.29979C0.446811 9.39231 0.373263 9.5022 0.323082 9.62317C0.272901 9.74414 0.24707 9.87383 0.24707 10.0048C0.24707 10.1358 0.272901 10.2654 0.323082 10.3864C0.373263 10.5074 0.446811 10.6173 0.539515 10.7098L8.84951 19.0198C9.33951 19.5098 10.1295 19.5098 10.6195 19.0198C11.1095 18.5298 11.1095 17.7398 10.6195 17.2498L3.37951 9.99979L10.6295 2.74979C11.1095 2.26979 11.1095 1.46979 10.6195 0.989793Z' fill='%23060052'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: unset;
}

.popinContributionTitleContainer .popinContributionTitleArrow:hover {
  background-color: unset;
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.6195 0.989793C10.5034 0.873385 10.3655 0.781028 10.2136 0.718012C10.0617 0.654995 9.89893 0.622559 9.73451 0.622559C9.5701 0.622559 9.40729 0.654995 9.25543 0.718012C9.10357 0.781028 8.96563 0.873385 8.84951 0.989793L0.539515 9.29979C0.446811 9.39231 0.373263 9.5022 0.323082 9.62317C0.272901 9.74414 0.24707 9.87383 0.24707 10.0048C0.24707 10.1358 0.272901 10.2654 0.323082 10.3864C0.373263 10.5074 0.446811 10.6173 0.539515 10.7098L8.84951 19.0198C9.33951 19.5098 10.1295 19.5098 10.6195 19.0198C11.1095 18.5298 11.1095 17.7398 10.6195 17.2498L3.37951 9.99979L10.6295 2.74979C11.1095 2.26979 11.1095 1.46979 10.6195 0.989793Z' fill='%23b34442'/%3E%3C/svg%3E%0A");
}

.arrayHeader {
  background: #060052;
  color: #f6f5ff;
  border-radius: 5px;
  font-weight: 700;
}

.arrayRow {
  color: #060052;
  background-color: #fafafa;
  margin: 12px 0;
  padding: 8px 0;
  border-radius: 4px;
}

.arrayRow.isNotCommented,
.isNotCommented .linkToPlayerPageContainer {
  color: #b1afc7;
}

.isNotCommented .linkToPlayerPagePicto,
.isNotCommented .linkToPlayerPageContainer:hover .linkToPlayerPagePicto,
.isNotCommented .addMoreContainer {
  background: #b1afc7;
  cursor: default;
}

.isAddedPlayer {
  background-color: #ffe1e1;
  color: #721f1d;
}

.isAddedPlayer .linkToPlayerPagePicto {
  background-color: #b34442;
}

.isAddedPlayer .linkToPlayerPageContainer {
  color: #721f1d;
}

#playersList option {
  position: relative;
}

#playersList option:after {
  content: attr(after);
  display: block;
}

.playerToAdd {
  border: 2px dotted #7a7a7a;
  color: #7a7a7a;
  padding: 12px 8px;
  width: 100%;
  margin: 5px;
  min-height: 48px;
}

.playerToAdd.hasPlayer {
  border: 2px solid #f0f0f0;
  background: #f0f0f0;
  color: #060052;
  cursor: grab;
}

.playerToAddContent {
  display: flex;
  justify-content: space-around;
}

.playerActionsContainer {
  display: flex;
  gap: 10px;
}

.popinContribution form {
  display: flex;
  flex-direction: column;
}

.draggeableIconContainer,
.linkContainer,
.trashContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  padding: 5px;
  color: white;
}

.draggeableIconContainer {
  cursor: grab;
}

.draggeableIconContent {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #e6e6e6;
  width: 20px;
  height: 20px;
  padding: 5px;
  border-radius: 50px;
}

.linkContainer,
.trashContainer {
  background-color: #060052;
  cursor: pointer;
}

.linkContainer:hover,
.trashContainer:hover {
  background-color: #b34442;
}

.draggeableIconContainer:before,
.linkContainer:before,
.trashContainer:before {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.returnButton:before {
  content: url("../assets/img/arrowBack.svg");
  width: 20px;
  height: 20px;
  display: block;
  margin-right: 5px;
}

.closeButton span {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  word-wrap: break-word;
}

.closeButton span:before {
  position: absolute;
  right: 100%;
  margin-right: 2px;
  content: url("../assets/img/crossClose.svg");
  color: #060052;
}

.popinCTAContainer {
  display: flex;
  gap: 20px;
}

.popinContainer {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
}

.popinContent {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 24px;
  padding: 24px;
  max-width: calc(100% - 40px);
  width: 800px;
}

.crossContainer {
  background-image: url("../assets/img/crossClose.svg");
  cursor: pointer;
  background-position: center;
  background-size: 100%;
  width: 30px;
  height: 30px;
  display: block;
  top: 20px;
  right: 20px;
  position: absolute;
}

h2 {
  font-weight: 700;
  font-size: 1.75rem;
  text-align: center;
  color: #060052;
  margin-top: 20px;
  margin-bottom: 48px;
}

.popinContainer form input.form-control,
.popinContainer form select.custom-select {
  background-color: #fafafa !important;
  color: #333;
  border: 1px solid #e6e6e6;
  font-weight: 300;
}

.popinContainer form input.form-control.highlight {
  background-color: #f6f5ff !important;
  border: 1px solid #d0ccff;
  color: #060052;
  font-weight: bold;
}

.popinContainer button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 0;
  background-color: #f6f5ff;
  color: #060052;
  width: 50%;
  margin: 15px auto 0 auto;
  padding: 12px;
  font-weight: bold;
}

.popinContainer button.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.popinContainer button:hover {
  background-color: #eeeeee;
}

.popinContainer button.main {
  background-color: #060052;
  color: white;
}

.popinContainer button.loop:before {
  content: url(../assets/img/loop_white.svg);
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  line-height: 1.1rem;
}

button.main:before {
  font-weight: 100;
  content: "+";
  color: white;
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  font-size: 1.7rem;
}

button.checkIcon:before {
  content: url("../assets/img/check-icon-white.svg");
  margin-bottom: 6px;
}

.popinContainer button.main:disabled {
  opacity: 0.5;
}

.popinContainer button.main:hover {
  background-color: #b34442;
}
</style>
