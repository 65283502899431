<template>
  <div
    class="dropdown-container"
    tabindex="-1"
    :class="{
      primary: isPrimary,
    }"
  >
    <div class="input-group-header" v-if="label">
      <span class="input-label">{{ label }}</span>
    </div>
    <div
      :class="{ errored }"
      class="input-container"
      @click="toggleDropdown"
      v-click-outside="closeDropdown"
    >
      <span v-if="!selectedOption && placeholder" class="dropdown-placeholder">
        {{ placeholder }}
      </span>
      <span v-else-if="selectedOption" class="dropdown-selected-option">{{
        selectedOption?.label
      }}</span>
      <img
        v-if="!isOpened"
        src="../../assets/img/pictos/chevron_down.svg"
      />
      <img v-else src="../../assets/img/pictos/chevron_up.svg" />
    </div>
    <div class="options-container" v-if="isOpened">
      <div
        @click="selectOption(option)"
        class="option"
        v-for="(option, index) of options"
        :key="'option_' + index"
      >
        <span>{{ option.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: { type: Object, required: false },
    options: { type: Array, required: true },
    closeOnSelect: { type: Boolean, default: true },
    placeholder: { type: String, required: false },
    isPrimary: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    label: { type: String, required: false },
  },
  data() {
    return {
      isOpened: false,
      selectedOption: this.getOptionById(this.selected?.id),
      id: this._uid,
      errored: false,
    };
  },
  mounted() {
    if (this.required && !this.selected) {
      this.setErrored();
    }
  },
  emits: ["change", "error", "unerror"],
  methods: {
    getOptionById(id) {
      return this.options.find((option) => option.id === id);
    },
    setErrored() {
      this.errored = true;
      this.$emit("error", this.id)
    },
    setNonErrored() {
      this.errored = false;
      this.$emit("unerror", this.id);
    },
    toggleDropdown() {
      this.isOpened = !this.isOpened;
    },
    openDropdown() {
      this.isOpened = true;
    },
    closeDropdown() {
      this.isOpened = false;
    },
    selectOption(option) {
      this.setNonErrored();
      this.selectedOption = option;
      this.$emit("change", option);
      if (this.closeOnSelect) {
        this.closeDropdown();
      }
    },
  },
  watch: {
    selected: {
      deep: true,
      handler(newValue) {
        this.selectedOption = newValue;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";
div.dropdown-container {
  position: relative;
  &:not(.primary) {
    width: 100%;
  }
  &.primary {
    width: 300px;
    font-weight: 700;
    font-size: 1rem;
  }
}
div.input-container {
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  border: solid 1px #d0ccff;
  cursor: pointer;
  color: $blueDarkest;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  user-select: none;
  > .dropdown-selected-option {
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: pre;
    overflow: hidden;
  }
  > .dropdown-placeholder {
    font-weight: 400;
    font-size: 0.875rem;
    color: #7a7a7a;
  }
  &.errored {
    border: solid 2px #b34442;
  }
}

div.options-container {
  width: 100%;
  transform: translate(0, 0.5rem);
  user-select: none;
  position: absolute;
  z-index: 10;
  border: solid 1px #dbdbdb;
  border-radius: 4px;
}

div.option {
  &:hover {
    background-color: #ffffff;
  }
  cursor: pointer;
  appearance: none;
  border-bottom: solid 1px #dbdbdb;
  background-color: #f1f1f1;
  padding: 1rem;
  font-weight: 400;
  transition: 0.3s;
  font-size: 0.875rem;
  line-height: 0.875rem;
}


div.input-group-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

span.input-label {
  color: #111111;
  font-family: "Proxima Nova", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}
</style>
