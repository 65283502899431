<template>
  <div id="gameObservationContainer">
    <div id="gameObservationContent">
      <div class="gameBlocTitle text-center">
        <h3>{{ $t("gameViewlegend.observationTitle") }}</h3>
      </div>
      <div
        class="globalObservationContent"
        v-if="
          !getCurrentScout?.isManager && getCurrentScout?.id === game.addedBy
        "
      >
        <div id="gameSelectObservationContainer">
          <div id="gameSelectObservationContent">
            <label for="observationType">
              {{ $t("gameViewlegend.observationType") }}
            </label>
            <select
              v-if="
                !getCurrentScout.isManager &&
                getCurrentScout?.id === game.addedBy &&
                isEditable
              "
              name="observationType"
              id="observationType"
              :value="gameNoteCategory"
            >
              <option value="stadium">Stadium</option>
              <option value="video">Video</option>
              <option value="data.dot">Data</option>
            </select>
            <span v-else> {{ gameNoteCategory }} </span>
          </div>
        </div>
        <div
          v-if="
            !getCurrentScout.isManager && getCurrentScout?.id === game.addedBy
          "
          id="gameCommentContainer"
        >
          <div id="gameCommentContent">
            <label
              v-if="
                isEditable ||
                (!isEditable && textareaContent && textareaContent.length)
              "
              for="gameComment"
            >
              {{ $t("gameViewlegend.observationComment") }}
            </label>
            <textarea
              v-if="isEditable"
              :placeholder="$t('gameViewlegend.generalObservation')"
              id="gameComment"
              v-model="textareaContent"
            >
            </textarea>
            <span v-else id="gameComment">
              {{
                textareaContent && textareaContent.length ? textareaContent : ""
              }}
            </span>
          </div>
        </div>
        <div v-if="isEditable" id="gamePlayerValidateGameNoteContainer">
          <Button
            :class="{
              formSubmitButton: true,
              buttonDisable: !textareaContent || !textareaContent.length,
            }"
            @click="submitNewNote"
            >{{ $t("gameViewlegend.submitGeneralObservation") }}</Button
          >
        </div>
      </div>
      <div v-if="getCurrentScout?.isManager" class="globalObservationContent">
        <div
          v-if="gameNoteCategory.length"
          class="gameObservationElementContainer"
        >
          <span class="observationLegend">{{
            $t("gameViewlegend.observationType")
          }}</span>
          <p class="gameObservationElementContent">
            {{ gameNoteCategory }}
          </p>
        </div>
        <div
          v-if="game.addedBy?.length"
          class="gameObservationElementContainer"
        >
          <span class="observationLegend">{{
            $t("gameViewlegend.gameCreatedBy")
          }}</span>
          <p
            class="gameObservationElementContent"
            v-if="getCurrentScout?.isManager"
          >
            {{ identifyScoutById(game.addedBy, "fullName") }}
          </p>
        </div>
        <div
          v-if="textareaContent && textareaContent.length"
          class="gameObservationElementContainer"
        >
          <span class="observationLegend">{{
            $t("gameViewlegend.observationComment")
          }}</span>
          <p class="gameObservationElementContent gameNoteContent">
            {{ textareaContent }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import mixinScouts from "../../mixins/mixinScouts";

export default {
  name: "GameObservation",
  mixins: [mixinScouts],
  data() {
    return {
      textareaContent: "",
    };
  },
  props: {
    game: {},
    isEditable: {
      type: Boolean,
      default: true,
    },
    gameNoteCategory: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.textareaContent = this.game.note;
  },
  emits: ["updateGameNoteHandler", "setGameNoteCategory"],
  methods: {
    submitNewNote() {
      this.$emit("updateGameNoteHandler", this.textareaContent);
    },
  },
  computed: {
    ...mapGetters([
      "getCurrentScout",
      "getScouts",
      "getLocale",
      "getGamesPosition",
      "getCompanies",
    ]),
  },
  watch: {
    game: {
      deep: true,
      immediate: true,
      handler() {
        this.textareaContent = this.game.note || "";
      },
    },
    gameNoteCategory() {
      this.$emit("setGameNoteCategory", this.gameNoteCategory);
    },
  },
};
</script>
<style>
#gameObservationContent {
  border: solid 1px #f0f0f0;
  padding: 24px;
  border-radius: 8px;
}

.gameBlocTitle h3 {
  color: #060052;
  font-weight: 700;
  font-family: "Proxima Nova", sans-serif;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  margin-top: 24px;
  margin-bottom: 15px;
}

#gameSelectObservationContent,
#gameCommentContent {
  display: flex;
  flex-direction: column;
  margin: 16px 0 8px;
  width: 100%;
}

#gameCommentContent {
  margin: 16px 0;
}

#gameSelectObservationContainer,
#gameCommentContainer {
  display: flex;
  width: 100%;
}

#gameSelectObservationContent label,
#gameCommentContent label,
.observationLegend {
  color: #060052;
  font-weight: 700;
  font-family: "Proxima Nova", sans-serif;
  font-size: 1rem;
}

#gameSelectObservationContent select {
  border-radius: 4px;
  border: solid 2px #060052;
  padding: 12px;
  font-size: 1rem;
  color: #060052;
  font-family: "Proxima Nova", sans-serif;
  font-size: 1rem;
}

#gameCommentContent textarea {
  border: solid 1px #e6e6e6;
  border-radius: 4px;
  padding: 12px;
  font-size: 1rem;
  font-family: "Proxima Nova", sans-serif;
  height: 135px;
}

#gameCommentContent textarea::placeholder {
  color: #7a7a7a;
  font-weight: 400;
  font-size: 1rem;
  font-family: "Proxima Nova", sans-serif;
}

#gamePlayerValidateGameNoteContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0 24px;
}

.gameNoteContent {
  font-size: 1rem;
  font-family: "Proxima Nova", sans-serif;
}

.formSubmitButton {
  background-color: #060052;
  color: #ffffff;
  padding: 12px 24px;
  border-radius: 100px;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  outline: 0;
  border: 0;
}

.formSubmitButton.buttonDisable {
  pointer-events: none;
  color: #f6f5ff;
  background-color: hsla(244, 100%, 16%, 0.6);
}

.gameObservationElementContainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.gameObservationElementContainer:last-of-type {
  margin-bottom: 0;
}

.gameObservationElementContent {
  font-size: 1rem;
  font-family: "Proxima Nova";
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: 0;
}
</style>
