export const popinVariants = Object.freeze({
  ADD_PLAYER_TO_SQUAD: "addPlayerToSquad",
  CHANGE_PLAYER_POSITION: "changePlayerPosition",
  PREVENT_SELL: "preventSell",
  PREVENT_SELL_CONFIRM: "preventSellConfirm",
  RECRUIT_PROSPECT: "recruitProspect",
  CANCEL_RECRUIT_PROSPECT: "cancelRecruitProspect",
  CREATE_SQUAD: "createSquad",
  DELETE_SQUAD: "deleteSquad",
  ORGANIZE_POSITION_PLAYERS: "organizePositionPlayers",
  PAYROLL_BALANCE: "payrollBalance",
  RENAME_SQUAD: "renameSquad",
  SEARCH_PROSPECTS: "searchProspects",
  SEARCH_PROSPECTS_RESULTS: "searchProspectsResults",
  SELL_PLAYER: "sellPlayer",
  SELL_PLAYER_CONFIRM: "sellPlayerConfirm",
  SELL_PLAYER_FROM_COMPO_CONFIRM: "sellPlayerFromCompoConfirm",
  SHOW_PROSPECT_INFOS: "showProspectInfos",
  UPDATE_PLAYER_FROM_COMPO: "updatePlayerFromCompo",
  FIND_GAME: "findGame",
  CREATE_GAME: "createGame",
  QUERY_GAME: "queryGame",
  TRANSFERS_BALANCE: "transfersBalance",
});
