<template>
  <b-row class="fields-group">
    <b-col cols="12" xl="6" class="contract-group">
      <DateInput
        :label="$t('endOfContractAcronym')"
        name="endContract"
        :min="minDate"
        :value="getPopinShallowPayload.endContract"
        @change="changeEndContract"
        @error="addPopinError"
        @unerror="removePopinError"
        :required="true"
      />
      <NumberInput
        @error="addPopinError"
        @unerror="removePopinError"
        :label="$t('salary')"
        :value="getPopinShallowPayload.salary"
        @change="changeSalary"
        :name="'salary'"
        :min="0"
        :step="0.1"
        :suffix="$t('millionsPerYear')"
        :required="true"
      />
      <NumberInput
        @error="addPopinError"
        @unerror="removePopinError"
        :label="$t('purchasePrice')"
        :value="getPopinShallowPayload.price"
        @change="changePrice"
        :name="'price'"
        :min="0"
        :step="0.1"
        :suffix="$t('millions')"
        :required="true"
      />
    </b-col>
    <b-col cols="12" xl="6" class="squad-group">
      <MiniSquadInput
        class="squad-input"
        :editable="true"
        :disposition="additionalContext.compo"
        :dispositionVersion="additionalContext.compoVersion"
        :required="true"
        :position="this.getPopinShallowPayload?.primaryPosition"
        @change="changePosition"
        @error="addPopinError"
        @unerror="removePopinError"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NumberInput from "../../../Fields/NumberInput.vue";
import DateInput from "../../../Fields/DateInput.vue";
import moment from "moment";
import MiniSquadInput from "../../../Fields/MiniSquadInput.vue";
import mixinText from "../../../../mixins/mixinText";
export default {
  components: { NumberInput, DateInput, MiniSquadInput },
  mixins: [mixinText],
  computed: {
    ...mapGetters("popin", ["additionalContext", "getPopinShallowPayload"]),
    minDate() {
      return moment().startOf("day").toDate();
    },
  },
  methods: {
    ...mapActions("popin", ["addPopinError", "removePopinError"]),
    changeSalary(newSalary) {
      this.$store.dispatch("popin/setShallowPayload", { newSalary });
    },
    changePrice(price) {
      this.$store.dispatch("popin/setShallowPayload", { price });
    },
    changeEndContract(datePayload) {
      this.$store.dispatch("popin/setShallowPayload", datePayload);
    },
    changePosition(value) {
      this.$store.dispatch("popin/setShallowPayload", {
        movingPosition: value,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
div.contract-group {
  padding: 24px;
  border-radius: 8px;
  background-color: #fafafa;
}
</style>
