export default {
  namespaced: true,
  getters: {
    playerPositionSubtitle: (state, _getters, rootState, rootGetters) => {
      const popinPayload = rootGetters["popin/popinPayload"];
      let positionKey = popinPayload?.primaryPosition;
      if (popinPayload?.movingPosition) {
        positionKey = rootGetters.getGamesPosition.find(
          (position) =>
            position.abv ===
            ({
              G: "GK",
              RF: "RCF",
              LF: "LCF",
            }[popinPayload.movingPosition] ?? popinPayload.movingPosition)
        )?.label;
      }
      return {
        key: `position.${positionKey
          ?.split(" ")
          ?.reduce(
            (acc, curr) => (acc ? acc + curr : curr.toLowerCase()),
            ""
          )}`,
        fallbackKey: "position.unknown",
      };
    },
  },
};
