<template>
  <div class="container">
    <div
      class="prioritiesContainer managerPriorities"
      v-if="getCurrentScout.isManager"
    >
      <div
        id="reviewPriority"
        :class="{
          priorityContainer: true,
          active: isThisPrioritySelected('1'),
        }"
      >
        {{ $t("review") }}
      </div>
      <div
        id="followPriority"
        :class="{
          priorityContainer: true,
          active: isThisPrioritySelected('2'),
        }"
      >
        {{ $t("follow") }}
      </div>
      <div
        id="urgentPriority"
        :class="{
          priorityContainer: true,
          active: isThisPrioritySelected('5'),
        }"
      >
        {{ $t("urgent") }}
      </div>
    </div>
    <div v-else class="prioritiesContainer">
      <div
        id="reviewPriority"
        :class="{
          priorityContainer: true,
          active: isTheScoutPlayerPriorityEqualTo('1'),
        }"
        @click="updatePlayerPriority('review')"
      >
        {{ $t("review") }}
      </div>
      <div
        id="followPriority"
        :class="{
          priorityContainer: true,
          active: isTheScoutPlayerPriorityEqualTo('2'),
        }"
        @click="updatePlayerPriority('follow')"
      >
        {{ $t("follow") }}
      </div>
      <div
        id="urgentPriority"
        :class="{
          priorityContainer: true,
          active: isTheScoutPlayerPriorityEqualTo('5'),
        }"
        @click="updatePlayerPriority('urgent')"
      >
        {{ $t("urgent") }}
      </div>
    </div>
    <b-row id="playerCareerContent">
      <b-col md="6" class="cardContainer">
        <div class="cardContent">
          <b-row class="cardTitleContainer">
            <b-col>
              <p class="cardTitleContent">
                {{ $t("careerStats") }}
              </p>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <b-col md="6" v-if="filteredSelectedCompetitionData.length">
              <span class="selectTitle">{{
                $t("instructions.selectCompetition")
              }}</span>
            </b-col>
            <b-col md="6" v-if="filteredSelectedCompetitionData.length">
              <select
                name="competitionSelector"
                id="competitionSelector"
                v-model="selectedCompetitionIndex"
              >
                <option value="all">{{ $t("all") }}</option>
                <option
                  v-for="(
                    competition, index
                  ) in competitionIdAndCompetitionName"
                  :key="index"
                  :value="competition.id"
                >
                  {{ competition.label }}
                </option>
              </select>
            </b-col>
          </b-row>
          <div class="statsContainer">
            <div v-if="filteredSelectedCompetitionData.length">
              <div class="statsHeaderContainer">
                <div class="statsHeaderContent largeColumn">
                  {{ $tc("plural.season", 2) }}
                </div>
                <div class="statsHeaderContent tinyColumn">
                  {{ $t("games") }}
                </div>
                <div class="statsHeaderContent tinyColumn">
                  {{ $t("tenures") }}
                </div>
                <div class="statsHeaderContent largeColumn">
                  {{ $t("playedMinutes") }}
                </div>
                <div class="statsHeaderContent tinyColumn">
                  {{ $t("goals") }}
                </div>
                <div class="statsHeaderContent tinyColumn">
                  {{ $t("assists") }}
                </div>
              </div>
              <div
                class="statsContentContainer"
                v-for="(competition, index) in filteredSelectedCompetitionData"
                :key="index"
              >
                <div class="statsContentContent largeColumn">
                  {{ competition.label }}
                </div>
                <div class="statsContentContent tinyColumn">
                  {{ formatNumber(competition.appearances) }}
                </div>
                <div class="statsContentContent tinyColumn">
                  {{ formatNumber(competition.startingPlayer) }}
                </div>
                <div class="statsContentContent largeColumn">
                  {{ formatNumber(competition.minutesPlayed, "int") }}
                </div>
                <div class="statsContentContent tinyColumn">
                  {{
                    selectedCompetitionIndex == "all"
                      ? Math.round(competition.npGoals)
                      : getGoals(competition.npGoals, competition.minutesPlayed)
                  }}
                  <span>
                    (+
                    {{
                      selectedCompetitionIndex == "all"
                        ? Math.round(competition.penGoals)
                        : getGoals(
                            competition.penGoals,
                            competition.minutesPlayed
                          )
                    }}
                    pen.)
                  </span>
                </div>
                <div class="statsContentContent tinyColumn">
                  {{
                    selectedCompetitionIndex == "all"
                      ? Math.round(competition.assists)
                      : getDecisivePasses(
                          competition.assists,
                          competition.minutesPlayed
                        )
                  }}
                </div>
              </div>
            </div>
            <div v-else class="statsContainer">
              {{ $t("no.statsForCareer") }}
            </div>
            <div id="attackingStatsContainer" v-if="player.careerStats">
              <b-row class="attackingStatsContent statsContent">
                <b-col>
                  <span>{{ formatNumber(player.careerStats.quantity) }}</span>
                  <p>{{ $t("shootPerMatch") }}</p>
                </b-col>
                <b-col>
                  <span>{{ formatNumber(player.careerStats.quality) }}%</span>
                  <p>{{ $t("qualityShoot") }}</p>
                </b-col>
                <b-col>
                  <span
                    >{{
                      formatNumber(player.careerStats.efficiency, "int")
                    }}%</span
                  >
                  <p>{{ $t("efficiencyShoot") }}</p>
                </b-col>
              </b-row>
            </div>
            <div id="defensiveStatsContainer" v-if="player.careerStats">
              <b-row class="defensiveStatsContent statsContent">
                <b-col>
                  <span>{{ formatNumber(player.careerStats.kp) }}</span>
                  <p>{{ $t("keyPassesPerMatch") }}</p>
                </b-col>
                <b-col>
                  <span>{{ formatNumber(player.careerStats.passAtt) }}</span>
                  <p>{{ $t("passesPerMatch") }}</p>
                </b-col>
                <b-col>
                  <span>
                    {{
                      formatNumber(
                        player.careerStats.passingRatio * 100,
                        "roundedToOne"
                      )
                    }}%
                  </span>
                  <p>{{ $t("succeededPasses") }}</p>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="6" class="cardContainer">
        <div class="cardContent">
          <div class="cardTitleContainer">
            <p class="cardTitleContent">
              {{ $t("currentSeasonStats") }}
            </p>
          </div>

          <div class="statsContainer">
            <div class="statsHeaderContainer" v-if="lastSeasonForPlayer.length">
              <div class="statsHeaderContent largeColumn">
                {{ $tc("plural.competition", 1) }}
              </div>
              <div class="statsHeaderContent tinyColumn">
                {{ $t("games") }}
              </div>
              <div class="statsHeaderContent tinyColumn">
                {{ $t("tenures") }}
              </div>
              <div class="statsHeaderContent largeColumn">
                {{ $t("playedMinutes") }}
              </div>
              <div class="statsHeaderContent largeColumn">
                {{ $t("goals") }}<br />
              </div>
              <div class="statsHeaderContent tinyColumn">
                {{ $t("assists") }}
              </div>
            </div>
            <div
              class="statsContentContainer"
              v-for="(lastSeasonCompetition, index) in lastSeasonForPlayer"
              :key="index"
            >
              <div class="statsContentContent largeColumn">
                {{ getCompetitionById(lastSeasonCompetition.competitionId) }}
              </div>
              <div class="statsContentContent tinyColumn">
                {{ lastSeasonCompetition.appearances }}
              </div>
              <div class="statsContentContent tinyColumn">
                {{ formatNumber(lastSeasonCompetition.startingPlayer) }}
              </div>
              <div class="statsContentContent largeColumn">
                {{ formatNumber(lastSeasonCompetition.minutesPlayed, "int") }}
              </div>
              <div class="statsContentContent largeColumn">
                {{
                  getGoals(
                    lastSeasonCompetition.npGoals,
                    lastSeasonCompetition.minutesPlayed
                  )
                }}
                <span>
                  (+
                  {{
                    getGoals(
                      lastSeasonCompetition.penGoals,
                      lastSeasonCompetition.minutesPlayed
                    )
                  }}
                  pen.)
                </span>
              </div>
              <div class="statsContentContent tinyColumn">
                {{
                  formatNumber(
                    lastSeasonCompetition.assists *
                      (lastSeasonCompetition.minutesPlayed / 90)
                  )
                }}
              </div>
            </div>
            <div class="statsContainer" v-if="!lastSeasonForPlayer.length">
              {{ $t("no.statsForLastSeason") }}
            </div>
            <p class="sectionTitle">{{ $t("athleticalStats") }}</p>
            <div id="athleticalStatsContainer" v-if="player.physicalStats">
              <b-row class="athleticalStatsContent statsContent">
                <b-col>
                  <span v-if="player.physicalStats.rkSpeed"
                    >{{
                      parseInt(toPercent(player.physicalStats.rkSpeed))
                    }}%</span
                  >
                  <span v-else class="undefinedStat">{{
                    $t("undefined")
                  }}</span>
                  <p>{{ $t("maxSpeed") }}</p>
                </b-col>
                <b-col>
                  <span v-if="player.physicalStats.rkDist"
                    >{{
                      parseInt(toPercent(player.physicalStats.rkDist))
                    }}%</span
                  >
                  <span v-else class="undefinedStat">{{
                    $t("undefined")
                  }}</span>
                  <p>{{ $t("totalDistance") }}</p>
                </b-col>
                <b-col>
                  <span v-if="player.physicalStats.rkHighSpeedRunning"
                    >{{
                      parseInt(
                        toPercent(player.physicalStats.rkHighSpeedRunning)
                      )
                    }}%</span
                  >
                  <span v-else class="undefinedStat">{{
                    $t("undefined")
                  }}</span>
                  <p>{{ $t("distanceMoreThan20kmh") }}</p>
                </b-col>
                <b-col>
                  <span v-if="player.physicalStats.rkAccelerationMoreThan3m"
                    >{{
                      parseInt(
                        toPercent(player.physicalStats.rkAccelerationMoreThan3m)
                      )
                    }}%</span
                  >
                  <span v-else class="undefinedStat">{{
                    $t("undefined")
                  }}</span>
                  <p>
                    {{ $t("nbAccelerationMore3mPerSec") }}
                  </p>
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <p>{{ $t("error.unableToLoadAthleticStats") }}</p>
            </div>
            <p class="sectionTitle">{{ $t("technicalStats") }}</p>
            <div id="attackingStatsContainer">
              <b-row class="attackingStatsContent statsContent">
                <b-col>
                  <span>{{ formatNumber(shootsPerMatch) }}</span>
                  <p>{{ $t("shootPerMatch") }}</p>
                </b-col>
                <b-col>
                  <span>{{ formatNumber(qualityShoots) }}%</span>
                  <p>{{ $t("qualityShoot") }}</p>
                </b-col>
                <b-col>
                  <span>{{ parseInt(formatNumber(efficiency)) }}%</span>
                  <p>{{ $t("efficiencyShoot") }}</p>
                </b-col>
              </b-row>
            </div>
            <div>
              <b-row class="defensiveStatsContent statsContent">
                <b-col>
                  <span>{{ formatNumber(oppositeSidePassesPerMatch) }}</span>
                  <p>
                    {{ $t("oppositeSidePassesPerMatch") }}
                  </p>
                </b-col>
                <b-col>
                  <span>{{ formatNumber(centerPerMatch) }}</span>
                  <p>
                    {{ $t("centerPerMatch") }}<br />({{
                      formatNumber(succeededCross)
                    }}% {{ $t("successCross") }})
                  </p>
                </b-col>
                <b-col>
                  <span>
                    {{ formatNumber(succeededPasses, "roundedToOne") }}%
                  </span>
                  <p>{{ $t("succeededPasses") }}</p>
                </b-col>
              </b-row>
            </div>
            <div>
              <div class="footballStatsContent statsContent">
                <div>
                  <span>{{ formatNumber(turnover) }}</span>
                  <p>{{ $t("turnovers") }}</p>
                </div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="chartsRow">
      <b-col class="fullCardContainer">
        <div id="chartsTitle" class="cardTitleContainer">
          <p class="cardTitleContent">{{ $t("perSeasonsStats") }}</p>
        </div>
        <b-row class="chartsContainer">
          <b-col class="chartContainer" md="6">
            <div class="chartContent">
              <Chart
                :chartData="minutesDatas"
                :options="chartOptions"
                :chartColors="minutesColors"
                label="playedMinutes"
              />
            </div>
          </b-col>
          <b-col class="chartContainer" md="6">
            <div class="chartContent">
              <Chart
                :chartData="goalsDatas"
                :options="chartOptions"
                :chartColors="goalsColors"
                label="goalsAndAssists"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Chart from "@/components/Chart";
import db from "../db";

export default {
  name: "PlayerCareer",
  components: { Chart },
  props: {
    playerPriority: Number,
    player: Object,
  },
  data() {
    return {
      minutesDatas: [],
      goalsDatas: [],
      shootsPerMatch: 0,
      qualityShoots: 0,
      efficiency: 0,
      oppositeSidePassesPerMatch: 0,
      centerPerMatch: 0,
      succeededCross: 0,
      succeededPasses: 0,
      turnover: 0,
      playerSeasons: [],
      selectedCompetitionIndex: "all",
      loadingCareer: false,
      playerCareer: {},
      filteredSelectedCompetitionData: [],
      selectedCompetitionData: [],
      selectedSeason: [],
      selectedPriority: null,
      chartFilter: "minutes",
      careerCompetitions: [],
      competitionIdAndCompetitionName: [],
      lastSeasonForPlayer: [],
      minutesColors: {
        backgroundColor: "#da5856",
      },
      goalsColors: {
        backgroundColor: "#2A66EA",
      },
      chartOptions: {
        responsive: true,
        chartArea: "top",
        maintainAspectRatio: false,
        legend: { display: false, position: "top" },
      },
    };
  },
  mounted() {
    this.loadPlayerSeasons();
    this.loadPlayerCareer();
    this.loadLastSeasonPlayer();
    this.selectedPriority = this.playerPriority;
  },
  computed: {
    ...mapGetters(["getCurrentScout"]),
  },
  methods: {
    getCompetitionById(competitionId) {
      const matchingCompetition = this.competitionIdAndCompetitionName.filter(
        (playerCompetition) => {
          return playerCompetition.id == competitionId;
        }
      );
      if (matchingCompetition.length) {
        return matchingCompetition[0].label;
      }
      return this.$t("undefined");
    },
    getSeasonById(seasonId) {
      const self = this;
      const matchingSeasonsIds = Object.keys(self.playerSeasons).filter(
        (seasonApi) => {
          return seasonApi == seasonId;
        }
      );
      if (!self.playerSeasons[matchingSeasonsIds[0]]) {
        return self.$t("undefined");
      }
      return self.playerSeasons[matchingSeasonsIds[0]].label;
    },
    async loadPlayerSeasons() {
      const self = this;

      db.collection("V3players")
        .doc(self.$route.params.playerId.toString())
        .collection("seasons")
        .get()
        .then((playerSeasonsSnapshot) => {
          playerSeasonsSnapshot.docs.forEach((playerSeasonsDoc) => {
            const playerSeasonsData = playerSeasonsDoc.data();

            self.playerSeasons[playerSeasonsData.id] = playerSeasonsData;
          });
        });
    },
    async loadPlayerCareer() {
      const self = this;
      self.playerCareer = {};
      self.loadingCareer = true;
      self.competitionIdAndCompetitionName = [];

      let playerCareerMinutes = [];
      let playerCareerGoalsAndAssists = [];
      await db
        .collection("V3players")
        .doc(self.$route.params.playerId.toString())
        .collection("competitions")
        .get()
        .then(async (playerCompetitionsSnapshot) => {
          for (let playerCompetitionsDoc of playerCompetitionsSnapshot.docs) {
            const playerCompetitionsData = playerCompetitionsDoc.data();
            if (playerCompetitionsData.id) {
              self.competitionIdAndCompetitionName.push({
                id: playerCompetitionsData.id,
                label: playerCompetitionsData.competitionLabel,
              });

              if (!self.playerCareer[playerCompetitionsData.id]) {
                self.playerCareer[playerCompetitionsData.id] = {
                  competitionId: playerCompetitionsData.id,
                };
              }

              await db
                .collection("V3players")
                .doc(self.$route.params.playerId.toString())
                .collection("competitions")
                .doc(playerCompetitionsData.id.toString())
                .collection("seasons")
                .get()
                .then(async (playerSeasonsForCompetitionSnapshot) => {
                  for (let playerSeasonsForCompetitionDoc of playerSeasonsForCompetitionSnapshot.docs) {
                    const playerSeasonsForCompetitionData =
                      playerSeasonsForCompetitionDoc.data();
                    if (playerSeasonsForCompetitionData.seasonId) {
                      self.playerCareer[playerCompetitionsData.id][
                        playerSeasonsForCompetitionData.seasonId
                      ] = playerSeasonsForCompetitionData;
                    }

                    if (
                      !playerCareerMinutes[
                        playerSeasonsForCompetitionDoc.id.toString()
                      ]
                    ) {
                      playerCareerMinutes[
                        playerSeasonsForCompetitionDoc.id.toString()
                      ] = [];
                    }

                    if (
                      !playerCareerGoalsAndAssists[
                        playerSeasonsForCompetitionDoc.id.toString()
                      ]
                    ) {
                      playerCareerGoalsAndAssists[
                        playerSeasonsForCompetitionDoc.id.toString()
                      ] = [];
                    }

                    playerCareerMinutes[
                      playerSeasonsForCompetitionDoc.id.toString()
                    ].push(playerSeasonsForCompetitionData.minutesPlayed);

                    playerCareerGoalsAndAssists[
                      playerSeasonsForCompetitionDoc.id.toString()
                    ].push(
                      ((playerSeasonsForCompetitionData.npGoals +
                        playerSeasonsForCompetitionData.assists) *
                        playerSeasonsForCompetitionData.minutesPlayed) /
                        90
                    );
                  }

                  self.minutesDatas = [];
                  playerCareerMinutes.forEach((playerSeason, index) => {
                    const matchingSeasonIndex = self.lodash.filter(
                      Object.keys(self.playerSeasons),
                      (seasonIndex) => {
                        return self.playerSeasons[seasonIndex].id == index;
                      }
                    );
                    self.minutesDatas.push({
                      x: self.playerSeasons[matchingSeasonIndex].label,
                      y: Math.round(self.lodash.sum(playerSeason)),
                    });
                  });
                  self.goalsDatas = [];
                  playerCareerGoalsAndAssists.forEach((playerSeason, index) => {
                    const matchingSeasonIndex = self.lodash.filter(
                      Object.keys(self.playerSeasons),
                      (seasonIndex) => {
                        return self.playerSeasons[seasonIndex].id == index;
                      }
                    );
                    self.goalsDatas.push({
                      x: self.playerSeasons[matchingSeasonIndex].label,
                      y: Math.round(self.lodash.sum(playerSeason)),
                    });
                  });
                });
            }
          }
        });
      self.setMatchingSeasonsToCareer();
      self.loadingCareer = false;
    },
    async loadLastSeasonPlayer() {
      const self = this;
      self.lastSeasonForPlayer = [];

      let sumMinutesForLastSeason = 0;
      let quantityForLastSeason = 0;
      let qualityForLastSeason = 0;
      let efficiencyForLastSeason = 0;
      let succeededPassesForLastSeason = 0;
      let oppositeSidePassesPerMatchForLastSeason = 0;
      let centerPerMatchForLastSeason = 0;
      let succeededCrossForLastSeason = 0;
      let turnoverForLastSeason = 0;
      let lastSeasonCompetitionLength = 0;
      await db
        .collection("V3players")
        .doc(this.$route.params.playerId.toString())
        .collection("seasons")
        .where("isLastSeason", "==", true)
        .get()
        .then(async (playerLastSeasonSnapshot) => {
          if (playerLastSeasonSnapshot.docs[0]) {
            await db
              .collection("V3players")
              .doc(self.$route.params.playerId.toString())
              .collection("seasons")
              .doc(playerLastSeasonSnapshot.docs[0].id.toString())
              .collection("competitions")
              .get()
              .then((playerSeasonsCompetitionSnapshot) => {
                lastSeasonCompetitionLength =
                  playerSeasonsCompetitionSnapshot.size;
                playerSeasonsCompetitionSnapshot.forEach(
                  (playerSeasonsCompetitionDoc) => {
                    const playerSeasonsCompetitionData =
                      playerSeasonsCompetitionDoc.data();
                    sumMinutesForLastSeason +=
                      playerSeasonsCompetitionData.minutesPlayed;

                    quantityForLastSeason =
                      quantityForLastSeason +
                      playerSeasonsCompetitionData.npQuantity *
                        playerSeasonsCompetitionData.minutesPlayed;

                    qualityForLastSeason +=
                      playerSeasonsCompetitionData.npQuality *
                      playerSeasonsCompetitionData.minutesPlayed;

                    efficiencyForLastSeason +=
                      playerSeasonsCompetitionData.efficiency;

                    succeededPassesForLastSeason +=
                      playerSeasonsCompetitionData.passingRatio *
                      playerSeasonsCompetitionData.minutesPlayed;

                    oppositeSidePassesPerMatchForLastSeason +=
                      playerSeasonsCompetitionData.opPib *
                      playerSeasonsCompetitionData.minutesPlayed;

                    centerPerMatchForLastSeason +=
                      playerSeasonsCompetitionData.crosses *
                      playerSeasonsCompetitionData.minutesPlayed;

                    succeededCrossForLastSeason +=
                      playerSeasonsCompetitionData.crossingRatio *
                      playerSeasonsCompetitionData.minutesPlayed;

                    turnoverForLastSeason +=
                      playerSeasonsCompetitionData.turnovers *
                      playerSeasonsCompetitionData.minutesPlayed;

                    self.lastSeasonForPlayer.push(playerSeasonsCompetitionData);
                  }
                );
              });
          }
          self.shootsPerMatch = quantityForLastSeason / sumMinutesForLastSeason;
          self.qualityShoots =
            (qualityForLastSeason / sumMinutesForLastSeason) * 100;
          self.efficiency =
            efficiencyForLastSeason / lastSeasonCompetitionLength;
          self.succeededPasses =
            (succeededPassesForLastSeason / sumMinutesForLastSeason) * 100;

          self.oppositeSidePassesPerMatch =
            oppositeSidePassesPerMatchForLastSeason / sumMinutesForLastSeason;

          self.centerPerMatch =
            centerPerMatchForLastSeason / sumMinutesForLastSeason;

          self.succeededCross =
            (succeededCrossForLastSeason / sumMinutesForLastSeason) * 100;

          self.turnover = turnoverForLastSeason / sumMinutesForLastSeason;
        });
    },
    filterSelectedCompetitionData() {
      const self = this;
      const toReturn = { ...self.selectedCompetitionData };
      delete toReturn["competitionId"];
      Object.keys(toReturn).forEach((seasonCompetitionIndex) => {
        toReturn[seasonCompetitionIndex].label = self.getSeasonById(
          seasonCompetitionIndex
        );
      });
      self.filteredSelectedCompetitionData = self.lodash.orderBy(
        toReturn,
        ["label"],
        ["desc"]
      );
    },
    async setMatchingSeasonsToCareer() {
      const self = this;
      const playerCareer = {};
      playerCareer["all"] = { competitionId: "all" };
      Object.keys(self.playerCareer).forEach((competitionIndex) => {
        if (competitionIndex != "all") {
          Object.keys(self.playerCareer[competitionIndex]).forEach(
            (seasonIndex) => {
              if (seasonIndex != "competitionId") {
                const matchingSeasonId = self.lodash.filter(
                  Object.keys(self.playerSeasons),
                  function (playerSeasonIndex) {
                    if (
                      playerSeasonIndex.toString() == seasonIndex.toString()
                    ) {
                      return self.playerSeasons[playerSeasonIndex];
                    }
                  }
                );
                if (matchingSeasonId[0]) {
                  if (!playerCareer[competitionIndex]) {
                    playerCareer[competitionIndex] = {};
                  }
                  if (!playerCareer[competitionIndex][seasonIndex]) {
                    playerCareer[competitionIndex][seasonIndex] = {};
                  }
                  playerCareer[competitionIndex][seasonIndex].label =
                    self.playerSeasons[matchingSeasonId[0]].label;

                  Object.keys(
                    self.playerCareer[competitionIndex][seasonIndex]
                  ).forEach((attribute) => {
                    if (attribute != "label" && competitionIndex != "all") {
                      if (!playerCareer["all"][seasonIndex]) {
                        playerCareer["all"][seasonIndex] = {};
                      }
                      if (!playerCareer["all"][seasonIndex][attribute]) {
                        playerCareer["all"][seasonIndex][attribute] = 0;
                      }
                      if (attribute == "npGoals" || attribute == "penGoals") {
                        playerCareer["all"][seasonIndex][attribute] =
                          playerCareer["all"][seasonIndex][attribute] +
                          (self.playerCareer[competitionIndex][seasonIndex][
                            attribute
                          ] *
                            (self.playerCareer[competitionIndex][seasonIndex][
                              "minutesPlayed"
                            ] /
                              90) || 0);
                      } else if (attribute == "assists") {
                        playerCareer["all"][seasonIndex]["assists"] =
                          playerCareer["all"][seasonIndex]["assists"] +
                          (self.playerCareer[competitionIndex][seasonIndex][
                            "assists"
                          ] *
                            (self.playerCareer[competitionIndex][seasonIndex][
                              "minutesPlayed"
                            ] /
                              90) || 0);
                      } else if (
                        attribute == "seasonId" ||
                        attribute == "competitionId"
                      ) {
                        playerCareer["all"][seasonIndex][attribute] =
                          self.playerCareer[competitionIndex][seasonIndex][
                            attribute
                          ];
                      } else {
                        playerCareer["all"][seasonIndex][attribute] =
                          playerCareer["all"][seasonIndex][attribute] +
                          (self.playerCareer[competitionIndex][seasonIndex][
                            attribute
                          ] || 0);
                      }
                    }
                  });
                }
              }
            }
          );
        }
      });
      self.playerCareer.all = playerCareer["all"];
      const matchingCompetitionIndex =
        self.lodash.filter(
          Object.keys(self.playerCareer),
          function (competitionIndex) {
            return (
              competitionIndex.toString() ==
              self.selectedCompetitionIndex.toString()
            );
          }
        )[0] || 0;
      if (matchingCompetitionIndex) {
        self.selectedCompetitionData =
          self.playerCareer[matchingCompetitionIndex];
        self.filterSelectedCompetitionData();
      }
    },
    toPercent(number) {
      return parseFloat(number * 100).toFixed(2) + "%";
    },
    isTheScoutPlayerPriorityEqualTo(priority) {
      return this.selectedPriority == priority;
    },
    isThisPrioritySelected(priority) {
      return (
        this.lodash.indexOf(this.player.priorities, parseInt(priority)) > -1
      );
    },
    async updatePlayerPriority(priority) {
      const self = this;
      const priorityCode =
        priority === "urgent" ? 5 : priority === "follow" ? 2 : 1;
      if (priorityCode != this.selectedPriority) {
        await db
          .collection("V3players")
          .doc(self.$route.params.playerId)
          .get()
          .then(async (playerDoc) => {
            if (playerDoc.exists) {
              const playerDatas = playerDoc.data();

              let playerPriorities = [];
              if (playerDatas.priorities) {
                playerPriorities = playerDatas.priorities;
              }
              if (self.selectedPriority) {
                const priorityIndex = self.lodash.indexOf(
                  playerPriorities,
                  self.selectedPriority
                );
                if (priorityIndex != -1) {
                  playerPriorities.splice(priorityIndex, 1);
                }
              }
              playerPriorities.push(parseInt(priorityCode));
              await db
                .collection("V3players")
                .doc(self.$route.params.playerId)
                .update({ priorities: playerPriorities });
            }
          });
        this.selectedPriority = parseInt(priorityCode);
      }
      db.collection("V3scouts")
        .doc(self.getCurrentScout.id.toString())
        .collection("players")
        .doc(self.$route.params.playerId.toString())
        .get()
        .then((scoutPlayerDoc) => {
          if (scoutPlayerDoc.exists) {
            db.collection("V3scouts")
              .doc(self.getCurrentScout.id.toString())
              .collection("players")
              .doc(self.$route.params.playerId.toString())
              .update({ priority: parseInt(priorityCode) })
              .catch(function (error) {
                self.err = error;
              });
          } else {
            this.selectedPriority = 0;
          }
        });
    },
    getGoals(goals, minutes) {
      if (goals && minutes) {
        const toReturn = goals * (minutes / 90);
        return this.formatNumber(toReturn);
      }
      return 0;
    },
    getDecisivePasses(assists, minutes) {
      if (assists && minutes) {
        const toReturn = assists * (minutes / 90);
        return this.formatNumber(toReturn);
      }
      return 0;
    },
    formatNumber(number, format = null) {
      if (!number || isNaN(number)) {
        return 0;
      }
      let toReturn = parseFloat(number).toFixed(2);

      if (format === "int") {
        return parseInt(toReturn);
      } else if (format === "roundedToOne") {
        toReturn = parseFloat(number).toFixed(1);
      }
      return toReturn % 1 === 0 ? Math.round(number) : toReturn;
    },
  },
  watch: {
    playerPriority() {
      this.selectedPriority = this.playerPriority;
    },
    selectedCompetitionIndex() {
      const self = this;
      const matchingCompetitionIndex =
        self.lodash.filter(
          Object.keys(self.playerCareer),
          function (competitionIndex) {
            return (
              competitionIndex.toString() ==
              self.selectedCompetitionIndex.toString()
            );
          }
        )[0] || 0;
      if (matchingCompetitionIndex) {
        self.selectedCompetitionData =
          self.playerCareer[matchingCompetitionIndex];
        self.filterSelectedCompetitionData();
      }
    },
  },
};
</script>
<style scoped>
.selectTitle {
  display: block;
  margin: 0 10px 20px 0;
}

#competitionSelector {
  border-radius: 5px;
  margin: 0 10px 20px 0;
  border-color: #0b0790;
  border-width: 1px;
  background: #eeeffb;
  color: #0b0790;
  font-weight: 700;
  max-width: 80%;
}

.cardTitleContainer {
  padding: 20px 20px 0px 20px;
}
.statsContainer {
  padding: 0 20px;
}

.prioritiesContainer {
  display: flex;
  margin-top: 20px;
}

.priorityContainer {
  padding: 7px 20px;
  margin: 0 10px;
  border-radius: 20px;
  color: white;
}

.prioritiesContainer:not(.managerPriorities) .priorityContainer {
  cursor: pointer;
}

.priorityContainer:first-of-type {
  margin-left: -15px;
}

#reviewPriority {
  background-color: rgba(4, 103, 241, 0.3);
}
#followPriority {
  background-color: rgba(179, 98, 208, 0.3);
}
#urgentPriority {
  background-color: rgba(214, 40, 40, 0.3);
}

#reviewPriority.active {
  background-color: #0467f1;
}
#followPriority.active {
  background-color: #b362d0;
}
#urgentPriority.active {
  background-color: #d62828;
}

.statsContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 15px 0;
}

.statsContent > div > .undefinedStat {
  line-height: 1rem;
  font-size: 1.2rem;
}

#attackingStatsContainer {
  margin-top: 20px;
}

.attackingStatsContent:before {
  content: url(../assets/img/attackingPlayer.svg);
}

.defensiveStatsContent:before {
  content: url(../assets/img/defensivePlayer.svg);
}

.footballStatsContent:before {
  content: url(../assets/img/missingStats.svg);
}

.statsContent > div {
  text-align: center;
  padding: 0 20px;
  width: 100%;
}

.statsContent > div:not(:first-of-type) {
  border-left: 1px solid rgba(139, 149, 234, 0.3);
}

.statsContent > div > span {
  font-size: 1.7rem;
  font-weight: 900;
}

.athleticalStatsContent > div > span {
  font-size: 1.4rem;
}

.attackingStatsContent > div > span {
  color: #da5856;
}
.defensiveStatsContent > div > span {
  color: #2a66ea;
}
.footballStatsContent > div > span {
  color: #b362d0;
}

.statsContent > div > p {
  color: #0b0790;
  font-size: 0.625rem;
  font-weight: bold;
}

.chartsRow .fullCardContainer {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.fullCardContainer {
  background-color: white;
  border-radius: 20px;
  padding: 0px !important;
  margin-top: 25px;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  flex-direction: column;
}

.cardContent {
  background-color: white;
  border-radius: 20px;
  padding: 0px !important;
  margin-top: 25px;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
}

#playerCareerContent .cardContainer:first-of-type {
  padding-left: 0 !important;
}

#playerCareerContent .cardContainer:last-of-type {
  padding-right: 0 !important;
}

.chartsContainer {
  padding: 10px 30px 30px 30px;
}

.chartContainer {
  padding: 0 10px;
}

.chartContent {
  width: 90%;
  margin: 0 auto;
}

#chartsTitle {
  text-align: center;
  padding-top: 30px;
}

.cardTitleContainer {
  font-size: 1.25rem;
  color: #0b0790;
  font-weight: bold;
  margin-bottom: 20px;
}

.cardTitleContent {
  margin-bottom: 0;
  border-bottom: 3px solid #8b95ea;
  padding-bottom: 10px;
  display: inline-block;
}

.sectionTitle {
  color: #0b0790;
  margin-top: 20px;
  font-weight: bold;
}

.statsHeaderContainer {
  display: flex;
  align-items: center;
  text-align: center;
  background: #0b0790;
  color: white;
  padding: 21px 0;
  font-size: 0.75rem;
  text-transform: capitalize;
}

.statsContentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #0b0790;
}

.statsContentContainer:nth-child(even) {
  background: #dcdffa;
}

.statsContentContainer:nth-child(odd) {
  background: #eeeffb;
}

.tinyColumn span,
.largeColumn span {
  font-weight: 500;
}

.tinyColumn {
  flex: 2;
}

.largeColumn {
  flex: 3;
}

@media screen and (max-width: 992px) {
  .statsContent > div > span {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 786px) {
  .statsContent > div > span {
    font-size: 1.1rem;
  }

  #playerCareerContent .cardContainer:first-of-type {
    padding-right: 0 !important;
  }

  #playerCareerContent .cardContainer:last-of-type {
    padding-left: 0 !important;
  }
}

@media screen and (max-width: 576px) {
  #playerCareerContent .cardContainer:first-of-type {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  #playerCareerContent .cardContainer:last-of-type {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .chartsRow {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .prioritiesContainer {
    padding-left: 25px;
    overflow-x: auto;
  }
}
</style>
